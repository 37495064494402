import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ palette }: AppTheme) {
  return appCreateStyles({
    root: {
      borderBottom: '1px solid rgba(216, 216, 216, 0.1)',
    },
    tab: {
      fontSize: 16,
      color: 'rgba(235,235,235, 0.7)',
      textTransform: 'initial',
      maxWidth: 200,
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          width: 'calc(100% / 3)',
        },
    },
    flexContainer: {
      justifyContent: 'center',
    },
    selectedTab: {
      color: palette.primary.main,
    },
  })
}
