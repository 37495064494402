import { DepartmentGetQuery } from 'shared/models/department/get-model'

export const processTeams = (
  teams: Array<DepartmentGetQuery>,
): { [key: string]: Array<DepartmentGetQuery> } => {
  return teams.reduce((acc, team) => {
    const teams = acc[team.genre] || []
    teams.push(team)
    acc[team.genre] = teams

    return acc
  }, {})
}
