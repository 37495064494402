import React from 'react'
import Grid from '@material-ui/core/Grid'

import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'
import { CarouselDataItem, CarouselItemDimensions } from '../../carousel.types'
import { Flex } from 'shared/components/flex'

import { styles } from './item.styles'

export type ItemProps = AppWithStyles<typeof styles> & {
  data: CarouselDataItem
  dimensions: CarouselItemDimensions
  renderItem: (data: CarouselDataItem) => React.ReactNode
}

const ItemComponent: React.FC<ItemProps> = ({
  classes,
  data,
  renderItem,
  dimensions,
}) => {
  return (
    <Grid item classes={{ root: classes.root }}>
      <Flex alignItems="flex-end" justify="center" style={dimensions}>
        {renderItem(data)}
      </Flex>
    </Grid>
  )
}

export const Item = appWithStyles(styles)(React.memo(ItemComponent))
