import { Application, Model, Url } from 'services'
import SourceModel from '../source.model'
import moment from 'moment'
import { MOVIE, MOVIE_POPULAR, MOVIE_TRENDING } from 'const/api/movie'
import TrailerModel from '../media/trailer.model'
import CreditsModel from '../credits.model'
import DepartmentModel from '../departments.model'
import { GridModel } from 'models'
import { processContentImages } from 'shared/utils/image'

export default class SportModel extends Model {
  get relations() {
    return {
      credits: {
        class: CreditsModel,
        type: 'single',
      },
      departments: {
        class: DepartmentModel,
        type: 'collection',
      },
    }
  }

  get fillable() {
    return {
      id: 'string',
      attribute: 'string',
      mediaType: 'string',
      popularity: 'float',
      score: 'float',
      title: 'string',
      overview: 'string',
      tagline: 'string',
      runtime: 'number',
      posterPath: 'string',
      backdropPath: 'string',
      homepage: 'string',
      budget: 'number',
      status: 'string',
      revenue: 'number',
      imdbId: 'string',
      originalTitle: 'string',
      originalLanguage: 'string',
      releaseDate: 'data',
      mediaTrailers: 'collection',
      images: 'array',
      adult: 'boolean',
      contentType: 'string',
      description: 'string',
      genres: 'array',
      url: 'string',
      grids: 'array',
      gridsList: 'collection',
      // children:'array'
    }
  }

  clear() {
    return new this.constructor()
  }

  /**
   * Get type of model
   *
   * @returns {string}
   */
  getType() {
    return this.contentType
  }

  /**
   * Get release date
   *
   * @returns {number}
   */
  getDate(type = 'start') {
    return this.releaseDate
      ? moment(this.releaseDate, 'YYYY-MM-DD').year()
      : null
  }

  /**
   * Get show rating
   *
   * @returns {null|*}
   */
  getRating() {
    if (this.releaseDatesShort) {
      return (
        (this.releaseDatesShort['GB'] &&
          this.releaseDatesShort['GB'].certification) ||
        (this.releaseDatesShort['US'] &&
          this.releaseDatesShort['US'].certification)
      )
    }
    return null
  }

  /**
   * Get preview Image
   *
   * @returns {string}
   */
  getImage(size = 185) {
    return processContentImages(this.images)?.POSTER?.url.large
  }

  /**
   * Get movie poster
   *
   * @returns {Promise<string | string>}
   */
  getPoster() {
    // return this.images.filter(image => image.type === "BACKDROP")[0] && this.images.filter(image => image.type === "BACKDROP")[0].url || null;
    return (
      (this.images.filter(image => image.type === 'POSTER')[0] &&
        this.images.filter(image => image.type === 'POSTER')[0].url) ||
      null
    )

    // return this.backdropPath && `${TMDB_IMAGE}original${this.backdropPath}`;
  }

  getbackDropPoster() {
    return (
      (this.images.filter(image => image.type === 'BACKDROP')[0] &&
        this.images.filter(image => image.type === 'BACKDROP')[0].url) ||
      null
    )
    // return this.images.filter(image => image.type === "POSTER")[0] && this.images.filter(image => image.type === "POSTER")[0].url || null;

    // return this.backdropPath && `${TMDB_IMAGE}original${this.backdropPath}`;
  }

  /**
   * Get movie link
   *
   * @returns {string}
   */
  getLink() {
    return `/content/${encodeURIComponent(this.id)}`
  }

  /**
   * Get trailers
   *
   * @returns {Promise<unknown>}
   */
  getMediaTrailers() {
    const app = Application.instance()
    app.request
      .get(`${MOVIE}/${this.id}/media/videos`)
      .then(response => {
        this.mediaTrailers = TrailerModel.createFromResponse(response.data)
      })
      .catch(error => error)
  }

  getGridsList() {
    if (this.grids) {
      const gridsList = this.grids.map((grid, i) =>
        new GridModel().fillFromResponse({
          id: grid,
          order: i,
          type: 'CONTENT',
        }),
      )

      this.gridsList = gridsList
    }
  }

  getTrailer() {
    return (
      this.mediaTrailers &&
      this.mediaTrailers.isNotEmpty() &&
      this.mediaTrailers.first().getUrl()
    )
  }

  /**
   * Get movie by id
   *
   * @param id
   * @returns {Promise<unknown>}
   */
  getById(id) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.request
        .get(`${MOVIE}/${id}`)
        .then(response => {
          response
            ? resolve(self.fillFromResponse(response.data))
            : app.router.push('/not-found')
        })
        .catch(error => reject(error))
    })
  }

  getInfo(id) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.request
        .get(`contents/${id}`)
        .then(response => {
          response
            ? resolve(self.fillFromResponse(response.data))
            : app.router.push('/not-found')
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get movie sources
   *
   * @returns {Promise<any> | Promise<postcss.Result> | undefined}
   */
  getSources() {
    const app = Application.instance()
    const endpoint = `${MOVIE}/${this.id}/content_source`
    return app.request
      .get(endpoint)
      .then(response => SourceModel.createFromResponse(response.data))
      .catch(error => error)
  }

  /**
   * Get credits (Cast && Crew)
   *
   * @returns {Promise<any>}
   */
  getCredits() {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      const endpoint = `${MOVIE}/${self.id}/credits`
      app.request
        .get(endpoint)
        .then(response => {
          this.credits = response.data
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get movie latest list
   *
   * @param params
   * @returns {Promise<any>}
   */
  static latest(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${MOVIE}/latest${query}`
      app.request
        .get(endpoint)
        .then(response => resolve(self.createFromResponse(response.data)))
        .catch(error => reject(error))
    })
  }

  /**
   * Get movie recommended list
   *
   * @param id
   * @returns {Promise<any>}
   */
  static recommended(id) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.request
        .get(`${MOVIE}/${id}/recommended`)
        .then(response => resolve(self.createFromResponse(response.data)))
        .catch(error => reject(error))
    })
  }

  /**
   * Get movie trending by (day, week) list
   *
   * @param params
   * @param by
   * @returns {Promise<any>}
   */
  static trending(params, by = 'week') {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${MOVIE_TRENDING}/${by}${query}`
      app.request
        .get(endpoint)
        .then(response =>
          resolve(self.createFromResponse(response.data, endpoint)),
        )
        .catch(error => reject(error))
    })
  }

  /**
   * Get movie popular list
   *
   * @param params
   * @returns {Promise<any>}
   */
  static popular(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${MOVIE_POPULAR}${query}`
      app.request
        .get(endpoint)
        .then(response =>
          resolve(self.createFromResponse(response.data, endpoint)),
        )
        .catch(error => reject(error))
    })
  }

  /**
   * Get movie list
   *
   * @param params
   * @returns {Promise<any>}
   */
  static list(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${MOVIE}${query}`
      app.request
        .get(endpoint)
        .then(response =>
          resolve(self.createFromResponse(response.data, endpoint)),
        )
        .catch(error => reject(error))
    })
  }
}
