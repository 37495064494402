import { Application, Model, Url } from 'services'
import moment from 'moment'
import { SHOW, SHOW_POPULAR, SHOW_TRENDING } from 'const/api/show'
import SourceModel from '../source.model'
import ProvidersModel from '../providers.model'
import DepartmentModel from '../departments.model'
import SeasonModel from './season.model'
import TrailerModel from '../media/trailer.model'
import { GridModel } from 'models'
import { processContentImages } from 'shared/utils/image'

export default class ShowModel extends Model {
  get relations() {
    return {
      children: {
        class: SeasonModel,
        type: 'collection',
      },

      departments: {
        class: DepartmentModel,
        type: 'collection',
      },
      videoProviders: {
        class: ProvidersModel,
        type: 'single',
      },
    }
  }

  get fillable() {
    return {
      id: 'string',
      attribute: 'string',
      mediaType: 'string',
      popularity: 'float',
      score: 'float',
      title: 'string',
      overview: 'string',
      tagline: 'string',
      runtime: 'number',
      posterPath: 'string',
      backdropPath: 'string',
      homepage: 'string',
      budget: 'number',
      status: 'string',
      revenue: 'number',
      imdbId: 'string',
      originalTitle: 'string',
      originalLanguage: 'string',
      releaseDate: 'data',
      mediaTrailers: 'collection',
      images: 'array',
      adult: 'boolean',
      contentType: 'string',
      description: 'string',
      genres: 'array',
      url: 'string',
      // grids: 'array',
      gridsList: 'collection',
    }
  }

  /**
   * Get type of model
   *
   * @returns {string}
   */
  getType() {
    return 'SHOW'
  }

  /**
   * Get release date
   *
   * @returns {number}
   */
  getDate(type = 'start') {
    const date = this[type === 'start' ? 'firstAirDate' : 'lastAirDate']
    return date
      ? moment(
          this[type === 'start' ? 'firstAirDate' : 'lastAirDate'],
          'YYYY-MM-DD',
        )?.year()
      : ''
  }

  /**
   * Get show rating
   *
   * @returns {null|*}
   */
  getRating() {
    if (this.contentRatings) {
      return (
        (this.contentRatings['GB'] && this.contentRatings['GB']) ||
        (this.contentRatings['US'] && this.contentRatings['US'])
      )
    }
    return null
  }

  /**
   * Get preview Image
   *
   * @returns {string}
   */
  getImage() {
    return processContentImages(this.images)?.POSTER?.url.large
  }

  getbackDropPoster() {
    return (
      (this.images.filter(image => image.type === 'BACKDROP')[0] &&
        this.images.filter(image => image.type === 'BACKDROP')[0].url) ||
      null
    )
  }

  getPoster() {
    return (
      (this.images.filter(image => image.type === 'POSTER')[0] &&
        this.images.filter(image => image.type === 'POSTER')[0].url) ||
      null
    )
  }

  /**
   * Get tv show link
   *
   * @returns {string}
   */
  getLink() {
    return `/content/${encodeURIComponent(this.id)}`
  }

  /**
   * Get trailers
   *
   * @returns {Promise<unknown>}
   */
  getGridsList() {
    if (this.grids) {
      const gridsList = this.grids.map((grid, i) =>
        new GridModel().fillFromResponse({
          id: grid,
          order: i,
          type: 'CONTENT',
        }),
      )

      this.gridsList = gridsList
    }
  }

  getMediaTrailers() {
    const app = Application.instance()
    app.request
      .get(`${SHOW}/${this.id}/media/videos`)
      .then(response => {
        this.mediaTrailers = TrailerModel.createFromResponse(response.data)
      })
      .catch(error => error)
  }

  getTrailer() {
    return (
      this.mediaTrailers &&
      this.mediaTrailers.isNotEmpty() &&
      this.mediaTrailers.first().getUrl()
    )
  }

  /**
   * Get tv show by id
   *
   * @param id
   * @returns {Promise<unknown>}
   */
  getById(id) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.request
        .get(`${SHOW}/${id}`)
        .then(response => {
          response
            ? resolve(self.fillFromResponse(response.data))
            : app.router.push('/not-found')
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get show sources
   *
   * @returns {Promise<any> | Promise<postcss.Result> | undefined}
   */
  getSources() {
    const app = Application.instance()
    const endpoint = `${SHOW}/${this.id}/content_source`
    return app.request
      .get(endpoint)
      .then(response => SourceModel.createFromResponse(response.data))
      .catch(error => error)
  }

  /**
   * Get credits (Cast && Crew)
   *
   * @returns {Promise<any>}
   */
  getCredits() {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      const endpoint = `${SHOW}/${self.id}/credits`
      app.request
        .get(endpoint)
        .then(response => {
          this.credits = response.data
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get show latest list
   *
   * @param params
   * @returns {Promise<any>}
   */
  static latest(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${SHOW}/latest${query}`
      app.request
        .get(endpoint)
        .then(response => resolve(self.createFromResponse(response.data)))
        .catch(error => reject(error))
    })
  }

  /**
   * Get show recommended list
   *
   * @param id
   * @returns {Promise<any>}
   */
  static recommended(id) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.request
        .get(`${SHOW}/${id}/recommended`)
        .then(response => resolve(self.createFromResponse(response.data)))
        .catch(error => reject(error))
    })
  }

  /**
   * Get show trending by (day, week) list
   *
   * @param params
   * @param by
   * @returns {Promise<any>}
   */
  static trending(params, by = 'week') {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${SHOW_TRENDING}/${by}${query}`
      app.request
        .get(endpoint)
        .then(response =>
          resolve(self.createFromResponse(response.data, endpoint)),
        )
        .catch(error => reject(error))
    })
  }

  /**
   * Get show popular list
   *
   * @param params
   * @returns {Promise<any>}
   */
  static popular(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${SHOW_POPULAR}${query}`
      app.request
        .get(endpoint)
        .then(response =>
          resolve(self.createFromResponse(response.data, endpoint)),
        )
        .catch(error => reject(error))
    })
  }

  /**
   * Get tv show list
   *
   * @param params
   * @returns {Promise<any>}
   */
  static list(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${SHOW}${query}`
      app.request
        .get(endpoint)
        .then(response =>
          resolve(self.createFromResponse(response.data, endpoint)),
        )
        .catch(error => reject(error))
    })
  }
}
