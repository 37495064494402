import { BaseModel } from 'core/models/base'

export interface UserListQuery {
  avatar?: string
  firstName: string
  id: string
  lastName: string
  followed: boolean
  fullName?: string
}

export class UserListModel extends BaseModel<UserListQuery> {
  private avatar?: string
  private firstName: string
  private id: string
  private lastName: string
  private followed: boolean
  private fullName: string

  constructor(data: UserListQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): UserListQuery {
    return {
      avatar: this.avatar,
      firstName: this.firstName,
      id: this.id,
      lastName: this.lastName,
      followed: this.followed,
      fullName: this.fullName,
    }
  }
}
