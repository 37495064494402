import React, { useEffect, useState } from 'react'

import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'
import { getDDMMYYYYHHMMTimeFormat } from 'utils/formatTime'

import { AnnouncementGetListModel } from 'shared/models/announcement/announcements-get-list'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { styles } from './announcement-item.styles'

type NotificationItemProps = {
  item: AnnouncementGetListModel
  isNewMessage: boolean
}

export type Props = AppWithStyles<typeof styles> & NotificationItemProps

const SHORT_MESSAGE_LENGTH = 100

const NotificationItemComponent: React.FC<Props> = ({
  classes,
  item,
  isNewMessage,
}) => {
  const { title, text, date } = item.asJson

  const [isLongMessage, setIsLongMessage] = useState(false)
  const [isShowFull, setIsShowFull] = useState(false)

  useEffect(() => {
    if (text) {
      setIsLongMessage(text.length > SHORT_MESSAGE_LENGTH)
    }
  }, [text])

  const formatMessage = (text: string): string => {
    if (!text) {
      console.error(
        `Announcement with ${title} title has not correct text field`,
      )
      return ''
    }

    if (isLongMessage) {
      return !isShowFull
        ? `${text.slice(0, SHORT_MESSAGE_LENGTH).trim()}... `
        : `${text} `
    }
    return text
  }

  const handleShowMore = () => setIsShowFull(true)
  const handleShowLess = () => setIsShowFull(false)

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <FiberManualRecordIcon
          classes={{
            root: isNewMessage ? classes.newRootIcon : classes.readRootIcon,
          }}
        />
      </div>
      <div className={classes.box}>
        {title && <span className={classes.title}>{title}</span>}
        <div className={classes.text}>
          <pre>{formatMessage(text)}</pre>
          {isLongMessage && !isShowFull && (
            <span className={classes.show} onClick={handleShowMore}>
              Show more
            </span>
          )}
          {isLongMessage && isShowFull && (
            <span className={classes.show} onClick={handleShowLess}>
              Show less
            </span>
          )}
        </div>
        <div className={classes.date}>{getDDMMYYYYHHMMTimeFormat(date)}</div>
      </div>
    </div>
  )
}

export const AnnouncementItem = appWithStyles(styles)(NotificationItemComponent)
