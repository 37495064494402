import { BaseModel } from 'core/models/base'
import { Image } from 'shared/models/image/model'
import { PersonDepartment } from './department'

export interface PersonGetQuery {
  id: string
  images: Array<Image>
  name: string
  role: string
  birth: string
  biography: string
  departments: Array<PersonDepartment>
}

export class PersonGetModel extends BaseModel<PersonGetQuery> {
  private id: string
  private images: Array<Image>
  private name: string
  private role: string
  private birth: string
  private biography: string
  private departments: Array<PersonDepartment>

  constructor(data: PersonGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): PersonGetQuery {
    return {
      id: this.id,
      images: this.images,
      name: this.name,
      role: this.role,
      birth: this.birth,
      biography: this.biography,
      departments: this.departments,
    }
  }
}
