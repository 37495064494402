import { Collection } from '../'

export default class LocalStorage {
  constructor() {
    this.provider = window.localStorage
  }

  /**
   * Set value to the storage
   *
   * @param key
   * @param value
   * @returns {LocalStorage}
   */
  set(key, value) {
    if (key instanceof Array) {
      for (const [key, value] of Object.entries(this.configs)) {
        this.set(key, value)
      }
    } else {
      this.provider.setItem(key, value)
    }
    return this
  }

  /**
   * Return the record or list of records
   *
   * @param key
   * @param defaultValue
   * @returns {*}
   */
  get(key = false, defaultValue = false) {
    if (key) {
      return this.has(key) ? this.provider.getItem(key) : defaultValue
    }
    return this.list()
  }

  /**
   * Check if exists the record in the storage
   *
   * @param key
   * @returns {boolean}
   */
  has(key) {
    return !!this.provider.getItem(key)
  }

  /**
   * Remove the record
   *
   * @param key
   * @returns {boolean}
   */
  remove(key) {
    return this.provider.removeItem(key)
  }

  /**
   * Clear the storage
   *
   * @returns {boolean}
   */
  clear() {
    return !!this.provider.clear()
  }

  /**
   * Get list of the records
   *
   * @returns {*}
   */
  list() {
    const collection = new Collection({})
    for (const [key, value] of Object.entries(this.provider)) {
      collection.set(key, value)
    }
    return collection
  }
}
