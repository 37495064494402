import { Model } from 'services'
import { PERSON } from 'const/mediaTypes'
import { processContentImages } from 'shared/utils/image'
export default class GridPersonModel extends Model {
  get fillable() {
    return {
      biography: 'string',
      birthday: 'string',
      id: 'string',
      images: 'array',
      name: 'string',
      type: 'string',
    }
  }

  getType() {
    return PERSON
  }

  getImage() {
    return processContentImages(this.images)?.PERSON?.url.large
  }
}
