import React from 'react'
import cx from 'classnames'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { styles } from 'components/Buttons/Default/default-button.styles'

export type DefaultButtonProps = AppWithStyles & {
  color?: string
  icon?: React.ReactNode
  type?: string
  size?: string
  bgColor?: string
  customClass?: string
  style?: React.CSSProperties
  disabled?: boolean
  active?: boolean
  onClick?: () => void
  children?: React.ReactNode
}

const DefaultButton: React.FC<DefaultButtonProps> = ({
  color = 'gray',
  children,
  icon = null,
  type = 'rectangular',
  size = 'small',
  bgColor = 'white',
  customClass = '',
  style = {},
  disabled = false,
  active = false,
  classes,
  onClick,
}) => {
  return (
    <button
      className={cx({
        button: true,
        active,
        disabled,
        [size]: !!size,
        [color]: !!color,
        [type]: !!type,
        [`bg-${bgColor}`]: !!bgColor,
        'with-icon': !!icon,
        [customClass]: !!customClass,
        ...classes,
      })}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {children}
    </button>
  )
}

export default appWithStyles(styles)(DefaultButton)
