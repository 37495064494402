import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ palette, spacing, custom }: AppTheme) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: '100%',
      backdropFilter: 'blur(16px)',
      padding: spacing(12, 50),
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          padding: spacing(14, 1, 2, 1),
        },
      '@media only screen and (orientation: landscape) and (max-width: 980px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          padding: spacing(14, 2, 1, 2),
        },
    },
    paper: {
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      position: 'initial',
      margin: 0,
      backgroundColor: appFade(palette.common.black, 0.6),
      borderRadius: 20,
      boxShadow: '-1px 9px 10px rgba(0, 0, 0, 0.25)',
    },
  })
}
