import React, { useEffect, useMemo, useState } from 'react'

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'
import { appObserver } from 'core/state-management/utils'
import { notificationsOpen } from '../../../../services/analytics.service'

import { useLockScrollBody } from 'hooks/useLockScrollBody'

import { AnnouncementViewModel } from './announcement.vm'
import { NotificationList } from './components/announcements-list/announcement-list'

import { styles } from './announcements.styles'
import { useLocation } from 'react-router'

export type Props = AppWithStyles<typeof styles>

const ANNOUNCEMENTS_PATH_UPDATE = [
  '/',
  '/tv-shows',
  '/sports',
  '/movies',
  '/lives',
]
const PROFILE_ROUTE = '/profile/'

const NotificationsComponent: React.FC<Props> = ({ classes }) => {
  const location = useLocation()

  const $vm = useMemo(() => new AnnouncementViewModel(), [])

  const [isOpen, setIsOpen] = useState(false)

  const setLockScroll = useLockScrollBody()

  useEffect(() => {
    setLockScroll(isOpen)
  }, [isOpen, setLockScroll])

  useEffect(() => {
    if (!$vm.isAuthLoading) {
      $vm.getNotificationList()
    }
  }, [$vm, $vm.isAuthLoading])

  useEffect(() => {
    const { pathname } = location

    const isUpdateRoute =
      ANNOUNCEMENTS_PATH_UPDATE.includes(pathname) ||
      pathname.includes(PROFILE_ROUTE)

    if (isUpdateRoute) {
      $vm.getNotificationList()
    }
    // eslint-disable-next-line
  }, [$vm, location.pathname])

  const handleOpen = async () => {
    await $vm.getNotificationList()
    notificationsOpen()
    setIsOpen(open => !open)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleAllMarkAsRead = () => {
    $vm.markAllAsRead()
  }

  return (
    <div className={classes.container}>
      <div className={classes.icon} onClick={handleOpen}>
        <NotificationsNoneIcon
          classes={{ root: classes.iconRoot }}
          fontSize="large"
        />
        {$vm.hasOfNewAnnouncements && (
          <span className={classes.count}>{$vm.countOfNewAnnouncements}</span>
        )}
      </div>
      {isOpen && (
        <NotificationList
          items={$vm.announcements}
          isLogged={$vm.isLoggedIn}
          hasUnread={$vm.hasOfNewAnnouncements}
          onClose={handleClose}
          onMarkAllAsRead={handleAllMarkAsRead}
        />
      )}
    </div>
  )
}

export const Announcements = appWithStyles(styles)(
  appObserver(NotificationsComponent),
)
