import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { UserGetQuery } from 'shared/models/user/get-model'
import { IFollowersService } from 'shared/types/services/followers'
import { IOnboardingService } from 'shared/types/services/onboarding'

export class FourthStepViewModel {
  private onboardingService = inject<IOnboardingService>(
    DI_TOKENS.onboardingService,
  )
  private followersService = inject<IFollowersService>(
    DI_TOKENS.followersService,
  )

  getUsers: IOnboardingService['getUsers'] = () => {
    return this.onboardingService.getUsers()
  }

  updateList = (selectedUsers: Array<UserGetQuery['id']>) => {
    return this.followersService.followBulk(selectedUsers)
  }
}
