import { Provider, Notify } from 'services'
import { notify } from 'configs'

export default class NotifyProvider extends Provider {
  boot(app) {
    app.bind('notify', () => new Notify(this.config))
  }

  get config() {
    return notify
  }
}
