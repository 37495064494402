import { Model } from 'services'

export default class SourceModel extends Model {
  get fillable() {
    return {
      service: 'string',
      sellingOption: 'string',
      quality: 'string',
      language: 'string',
      link: 'string',
      pricePlainText: 'string',
      availableFrom: 'date',
      availableTo: 'date',
    }
  }
}
