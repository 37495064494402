export default class Notify {
  constructor(configs) {
    this.driver = configs.driver
    this.configs = { ...this.defaults, ...configs.options }
  }

  get defaults() {
    return {}
  }

  /**
   * Announcements to simple information
   *
   * @param args
   * @returns {never}
   */
  default(args) {
    this.driver(args)
  }

  error(args) {
    this.driver.error(args)
  }

  success(args) {
    this.driver.success(args)
  }

  info(args) {
    this.driver.info(args)
  }

  warning(args) {
    this.driver.warning(args)
  }

  //TODO: discuss this
  confirmEmail() {
    this.driver.warning(
      "Please confirm your email. Didn't receive on email? Resend",
    )
  }
}
