import { Provider, RouterService } from 'services'
import { router } from 'configs'

export default class RouterProvider extends Provider {
  boot(app) {
    app.bind('router', () => new RouterService(this.config))
  }

  get config() {
    return router
  }
}
