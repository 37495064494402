import React, { useCallback } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import Button from 'components/Buttons/Default';
import {
    COOKIES_CONSENT_KEY,
    CookiesConsentResult,
    setAutomaticDataCollectionEnabled
} from "../../services/analytics.service";
import { AppWithStyles, appWithStyles } from "../../core/theme/utils/with-styles";

import { styles } from "./cookeis-content.styles";

export type Props = AppWithStyles<typeof styles>;
const CookiesConsentComponent: React.FC<Props> = ({classes}) => {
    const [open, setOpen] = React.useState(true);
    const handleAccept = useCallback( () => {
        setOpen(false);
        localStorage.setItem(COOKIES_CONSENT_KEY, CookiesConsentResult.ACCEPTED);
        setAutomaticDataCollectionEnabled(true);
    }, []);
    const handleCancel = useCallback( () => {
        setOpen(false);
        localStorage.setItem(COOKIES_CONSENT_KEY, CookiesConsentResult.REJECTED);
        setAutomaticDataCollectionEnabled(false);
    }, []);
    return (
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={open} className={classes.root}>
            <Grid className={classes.content}>
                <Grid xs={12} className={classes.title}>We value your privacy</Grid>

                <Grid xs={12} className={classes.text}>We use cookies to enhance your browsing experience.
                    By clicking “Accept”, you consent to our <Link to="/privacy-policy" className={classes.link}>Privacy Policy</Link>.</Grid>

                <Grid xs={12} container justify="flex-end">
                    <Button type="rounded" customClass={classes.buttonCancel} bgColor="gray" color="white" onClick={handleCancel}>
                        Reject
                    </Button>
                    <Button type="rounded" customClass={classes.buttonAccept} bgColor="green" color="white" onClick={handleAccept}>
                        Accept
                    </Button>
                </Grid>
            </Grid>
        </Snackbar>
    )
}

export const CookiesConsent = appWithStyles(styles)(CookiesConsentComponent)