import React, { useMemo } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Flex } from 'shared/components/flex'

import { styles } from './section-title.styles'

export type SectionTitleProps = AppWithStyles<typeof styles> & {
  title: React.ReactNode
  hint?: React.ReactNode
  counter?: number
}

const SectionTitleComponent: React.FC<SectionTitleProps> = ({
  classes,
  title,
  hint,
  counter: _counter,
}) => {
  const counter = useMemo(() => {
    if (_counter !== null && _counter !== undefined) {
      return (
        <h5 className={classes.counter}>{`${_counter} item${
          _counter !== 1 ? 's' : ''
        } selected`}</h5>
      )
    }
  }, [_counter, classes.counter])

  return (
    <Flex alignItems="flex-end" classes={{ root: classes.root }}>
      <h2 className={classes.title}>{title}</h2>
      {hint && <h5 className={classes.hint}>{hint}</h5>}
      {counter}
    </Flex>
  )
}

export const SectionTitle = appWithStyles(styles)(SectionTitleComponent)
