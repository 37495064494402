import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Button } from 'shared/components/button'
import { Flex } from 'shared/components/flex'

import { styles } from './controls.styles'

export type ControlsProps = AppWithStyles<typeof styles> & {
  isFinalStep?: boolean
  loading: boolean
  canProceedForward: boolean
  onProceedForward: (skip: boolean) => Promise<void>
}

const ControlsComponent: React.FC<ControlsProps> = ({
  classes,
  isFinalStep,
  canProceedForward,
  loading,
  onProceedForward,
}) => {
  const handleProceedForward = useCallback(() => {
    onProceedForward(false)
  }, [onProceedForward])

  const handleSkip = useCallback(() => {
    onProceedForward(true)
  }, [onProceedForward])

  const buttons = useMemo(() => {
    if (isFinalStep) {
      return (
        <Button
          loading={loading}
          text="Finish"
          classes={{ root: classes.finish }}
          onClick={handleProceedForward}
        />
      )
    }

    return (
      <>
        <Button
          loading={loading}
          text="Skip"
          classes={{ root: classes.skip }}
          onClick={handleSkip}
        />
        <Button
          loading={loading}
          text="Next"
          classes={{
            root: cx(classes.next, {
              [classes.nextDisabled]: !canProceedForward,
            }),
          }}
          onClick={handleProceedForward}
        />
      </>
    )
    // eslint-disable-next-line
  }, [onProceedForward, isFinalStep, classes, canProceedForward])

  return (
    <Flex
      alignItems="center"
      justify="flex-end"
      classes={{ root: classes.root }}
    >
      {buttons}
    </Flex>
  )
}

export const Controls = appWithStyles(styles)(ControlsComponent)
