import React, { Component } from 'react'
import Logo from '../Logo'
import AppStore from 'assets/images/store/app-store.png'
import GoogleStore from 'assets/images/store/google-store.png'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { ROUTES } from 'shared/constants/routes'
import LogoutIcon from 'assets/images/logout.svg'
import { inject as injectInternal } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { convertDateToIso, formatDate } from 'shared/utils/date'

class FooterComponent extends Component {
  configService = injectInternal(DI_TOKENS.configService)

  componentDidMount() {
    const { FooterStore } = this.props
    FooterStore.collection.isEmpty() && FooterStore.getCollection()
  }

  render() {
    const { isModeratorRole, isAdminRole } = this.$app.auth
    const { FooterStore } = this.props
    const logoutUrl = `${window.location.origin}/admin`
    const releaseYear = formatDate(
      convertDateToIso(this.configService.get().releaseDate, {
        inputFormat: 'DD-MM-YYYY',
      }),
      {
        format: 'YYYY',
      },
    )

    return (
      <footer className="footer">
        <div className="container">
          <div className="footer__copyright">
            <div>
              <Logo />
              <div className="copy">
                <span className="gray-opacity-05">
                  © {releaseYear} Your World of Streaming
                </span>
              </div>
              <div className="app__stores">
                <a
                  /* eslint-disable-next-line react/jsx-no-target-blank */
                  target="_blank"
                  href="https://apps.apple.com/by/app/skroote-movies-tv-sport/id1500248075"
                  className="store apple__store"
                >
                  <img src={AppStore} alt="App Store" />
                </a>
                <a
                  /* eslint-disable-next-line react/jsx-no-target-blank */
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.skroote"
                  className="store google__store"
                >
                  <img src={GoogleStore} alt="Google Store" />
                </a>
              </div>
            </div>

            {(isAdminRole || isModeratorRole) && (
              <a className="logout" href={logoutUrl}>
                <img className="logout__icon" src={LogoutIcon} alt="logout" />
                <span className="logout__text">Go to admin panel</span>
              </a>
            )}
          </div>
          <div className="footer__info">
            <div className="footer__sitemap">
              <h6 className="gray-opacity-04">Trending</h6>
              <ul>
                {FooterStore.collection.toArray().map(item => (
                  <li key={item.id}>
                    <Link to={`/grid/${encodeURIComponent(item.id)}`}>
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer__sitemap">
              <h6 className="gray-opacity-04">Company</h6>
              <ul>
                <li>
                  <Link to={ROUTES.public.about}>About Skroote</Link>
                </li>
                <li>
                  <Link to={ROUTES.public.termsOfUse}>Terms of Use </Link>
                </li>
                <li>
                  <Link to={ROUTES.public.privacyPolicy}>Privacy Policy </Link>
                </li>
              </ul>
            </div>
            <div className="footer__sitemap second">
              <ul>
                <li>
                  <Link to="/faq">FAQs </Link>
                </li>
                <li>
                  <Link to="/partners">Partners</Link>
                </li>
              </ul>
            </div>
            <div className="footer__desc">
              <h6 className="gray-opacity-04">What is Skroote?</h6>
              <p className="gray-opacity-04">
                Our mission is to connect movie, TV and sports lovers to the
                streaming content they want to watch. Skroote is the world’s
                most comprehensive guide to online streaming content. It helps
                you find any movie, TV series or sports event that is currently
                available - and our price comparison service always shows you
                the cheapest provider including FREE legitimate streaming
                options when available. Just sit back and enjoy it all with no
                hassle.
              </p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export const Footer = inject('FooterStore')(observer(FooterComponent))
