import { Application, Model } from 'services'

export default class FollowersGridModel extends Model {
  get fillable() {
    return {
      items: 'array',
    }
  }

  /**
   *
   * @param params
   * @returns {Promise<any>}
   */
  static list(endpoint) {
    const app = Application.instance()
    const self = this

    return new Promise((resolve, reject) => {
      app.newrequest
        .get(endpoint)
        .then(response =>
          resolve(self.createGridFromResponse(response.data.items)),
        )
        .catch(error => reject(error))
    })
  }
}
