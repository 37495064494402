import React from 'react'

export const SportsIcon = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Fill 1"
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2511 10.4117C14.452 12.4417 12.9077 13.7867 11.1204 13.7867H10.9512V14.4983C13.361 14.8692 15.2071 16.8183 15.2071 19.1667C15.2071 19.6267 14.8356 20 14.3778 20H5.86549C5.40768 20 5.03612 19.6267 5.03612 19.1667C5.03612 16.8183 6.88231 14.8692 9.29246 14.4983V13.7867H9.12286C7.4384 13.7867 5.96709 12.5933 5.1344 10.7558C4.99299 10.6925 4.86568 10.5929 4.77528 10.4525L0.132869 3.24167C-0.0321764 2.98542 -0.0442023 2.65875 0.100938 2.39083C0.246078 2.1225 0.525577 1.95583 0.829542 1.95583H4.08317L4.01972 0.8825C4.00645 0.653333 4.08773 0.42875 4.24407 0.261667C4.40082 0.0945833 4.61936 0 4.84785 0H15.3954C15.6239 0 15.8424 0.0945833 15.9992 0.261667C16.1559 0.42875 16.2368 0.653333 16.2235 0.8825L16.1601 1.95583H19.1707C19.4743 1.95583 19.7538 2.1225 19.8989 2.39083C20.0445 2.65875 20.032 2.98542 19.867 3.24167L15.2511 10.4117ZM6.0832 7.69958L5.72782 1.66667H14.5154L14.1601 7.69958C14.1178 8.41833 13.9788 9.08417 13.7682 9.6725C13.757 9.69833 13.7487 9.72458 13.7404 9.75125C13.2067 11.1812 12.233 12.12 11.1204 12.12H9.12286C7.53834 12.12 6.23166 10.2196 6.0832 7.69958ZM4.18145 3.6225L4.36557 6.7475L2.35351 3.6225H4.18145ZM15.9026 6.33125L17.6463 3.6225H16.0618L15.9026 6.33125ZM13.4203 18.3333H6.823C7.2294 17.0442 8.55432 16.0963 10.1218 16.0963C11.6889 16.0963 13.0139 17.0442 13.4203 18.3333Z"
        fill="white"
      />
    </svg>
  )
}
