import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { processContentImages } from 'shared/utils/image'
import { Ellipsis } from 'shared/components/ellipsis'
import { Flex } from 'shared/components/flex'
import { DepartmentGetQuery } from 'shared/models/department/get-model'

import { styles } from './item.styles'

export type ItemProps = AppWithStyles<typeof styles> &
  Required<Pick<DepartmentGetQuery, 'id' | 'images' | 'name'>> & {
    selected?: boolean
    onChange: (id: DepartmentGetQuery['id']) => void
  }

const ItemComponent: React.FC<ItemProps> = ({
  classes,
  id,
  images,
  name,
  selected,
  onChange,
}) => {
  const image = useMemo(() => {
    return processContentImages(images)?.LOGO?.url?.large
  }, [images])

  const handleChange = useCallback(() => {
    onChange(id)
  }, [id, onChange])

  return (
    <Flex
      autoWidth={false}
      role="button"
      direction="column"
      alignItems="center"
      justify="center"
      className={cx(classes.root, { [classes.rootSelected]: selected })}
      onClick={handleChange}
    >
      <img src={image} className={classes.icon} alt="icon-img" />
      <Ellipsis maxWidth="100%" text={name} classes={{ root: classes.name }} />
    </Flex>
  )
}

export const Item = appWithStyles(styles)(React.memo(ItemComponent))
