import { Application, Model } from 'services'
import GridDeailsModel from './gridDetails.model'
import { GRID_DETAILS } from 'const/api/grids'

export default class CustomGridModel extends Model {
  get fillable() {
    return {
      title: 'string',
      items: 'array',
    }
  }

  get relations() {
    return {
      details: {
        class: GridDeailsModel,
        type: 'single',
      },
    }
  }

  /**
   *
   * @param params
   * @returns {Promise<any>}
   */
  static list(endpoint) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.newrequest
        .get(endpoint)
        .then(response =>
          resolve(self.createGridFromResponse(response.data.items)),
        )
        .catch(error => reject(error))
    })
  }

  getDetails() {
    const app = Application.instance()
    // const self = this;
    return new Promise((resolve, reject) => {
      const endpoint = `${GRID_DETAILS}/${encodeURIComponent(this.id)}`
      app.newrequest
        .get(endpoint)
        .then(response => {
          this.details = response.data
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  static create(title, items = []) {
    const app = Application.instance()
    return new Promise((resolve, reject) => {
      app.newrequest
        .post('grids/custom', { title, items })
        .then(response => {
          this.details = response.data
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
}
