import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles() {
  return appCreateStyles({
    root: {},
    title: {
      color: '#5A5A5A',
    },
    item: {
      height: '100%',
      width: '100%',
    },
  })
}
