import { useEffect, useState } from 'react'

export const useLockScrollBody = () => {
  const [look, setLook] = useState(false)

  useEffect(() => {
    if (!look) return

    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [look])

  return setLook
}
