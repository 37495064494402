import React from 'react'

const TitleActionButton = ({
  icon = '',
  name = '',
  onClick,
  active = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  bgColor = '',
  border = true,
  disabled = false,
}) => {
  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type="button"
      className={`button-title__action ${border && 'border'} ${
        disabled && 'disabled'
      } ${active && 'active'} ${bgColor}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={icon} alt={name} />
      <span className="tooltip white-opacity-05">{name}</span>
    </button>
  )
}

export default TitleActionButton
