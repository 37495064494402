export const ROUTES = {
  private: {
    settings: {
      root: '/settings',
      children: {
        security: () => `${ROUTES.private.settings.root}/security`,
      },
    },
    users: {
      root: '/users',
      children: {
        followers: () => `${ROUTES.private.users.root}/followers`,
        following: () => `${ROUTES.private.users.root}/following`,
      },
    },
    profile: {
      root: (id = ':id') => `/profile/${id}`,
      children: {
        lists: (id = ':id') => `${ROUTES.private.profile.root(id)}/lists`,
        subscriptions: (id = ':id') =>
          `${ROUTES.private.profile.root(id)}/subscriptions`,
        activity: (id = ':id') => `${ROUTES.private.profile.root(id)}/activity`,
      },
    },
  },
  public: {
    content: (id = ':id') => `/content/${id}`,
    person: (id = ':id') => `/person/${id}`,
    grid: (id = ':id') => `/grid/${id}`,
    sports: '/sports',
    termsOfUse: '/terms-of-use',
    privacyPolicy: '/privacy-policy',
    about: '/about',
  },
  initial: '/',
}
