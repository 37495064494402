import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, custom, palette }: AppTheme) {
  return appCreateStyles({
    root: {
      padding: spacing(13),
    },
    logo: {
      width: 154,
      height: 40,
      marginBottom: spacing(12),
    },
    title: {
      color: custom.colors.gray[200],
      fontSize: '24px !important',
      fontWeight: 700,
      marginBottom: spacing(6),
    },
    message: {
      color: custom.colors.gray[200],
      textAlign: 'center',
      fontWeight: 700,
      marginBottom: spacing(14),
    },
    button: {
      width: 270,
      height: 50,
      color: palette.common.white,
    },
  })
}
