import { BaseModel } from 'core/models/base'
import { Image } from '../image/model'
import { GridItemType } from './item-type'
import { GridOrientation } from './orientation'
import { GridSize } from './size'
import { GridType } from './type'

export interface GridGetQuery {
  id: string
  images: Array<Image>
  items: Array<string>
  itemType: GridItemType
  linkUrl?: string
  order: number
  orientation: GridOrientation
  size: GridSize
  title: string
  totalItems: number
  type: GridType
  hideItemsTitle: boolean
  hideTitle: boolean
  loopInterval?: number
}

export class GridGetModel extends BaseModel<GridGetQuery> {
  private id: string
  private images: Array<Image>
  private items: Array<string>
  private itemType: GridItemType
  private linkUrl?: string
  private order: number
  private orientation: GridOrientation
  private size: GridSize
  private title: string
  private totalItems: number
  private type: GridType
  private hideItemsTitle: boolean
  private hideTitle: boolean
  private loopInterval?: number

  constructor(data: GridGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): GridGetQuery {
    return {
      id: this.id,
      images: this.images,
      items: this.items,
      itemType: this.itemType,
      linkUrl: this.linkUrl,
      order: this.order,
      orientation: this.orientation,
      size: this.size,
      title: this.title,
      totalItems: this.totalItems,
      type: this.type,
      hideItemsTitle: this.hideItemsTitle,
      hideTitle: this.hideTitle,
      loopInterval: this.loopInterval,
    }
  }
}
