import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    container: {
      position: 'absolute',
      top: 60,
      right: -15,
      backgroundColor: 'rgba(65, 65, 68, 1)',
      color: palette.grey['200'],
      padding: spacing(6),
      fontSize: 16,
      width: 400,
      borderRadius: 20,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: spacing(3),
    },
    title: {
      fontWeight: 700,
      color: '#78787A',
    },
    close: {
      cursor: 'pointer',
    },
    list: {
      paddingTop: spacing(2),
      maxHeight: 400,
      overflow: 'scroll',
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: spacing(6),
    },
    buttonMark: {
      margin: spacing(0, 7, 0, 8),
      width: '100%',
      height: 40,
      backgroundColor: '#515255',
      color: palette.common.white,
      border: 'none',
    },
  })
}
