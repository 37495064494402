import React, { useEffect, useState, useCallback } from 'react'
import Fade from '@material-ui/core/Fade'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { styles } from './notification.styles'

export enum NotificationType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export type NotificationProps = AppWithStyles<typeof styles> & {
  autoHideDuration?: number | null
}

const defaultProps = {
  autoHideDuration: 5000,
  className: '',
}

let showNotificationFn

export const showNotification = (
  message: React.ReactNode,
  type: NotificationType = NotificationType.info,
  notificationProps?: Partial<NotificationProps>,
) => {
  showNotificationFn(message, type, notificationProps)
}

const NotificationComponent: React.FC<NotificationProps> = ({ classes }) => {
  const [notificationMessage, setNotificationMessage] =
    useState<React.ReactNode>('')
  const [notificationType, setNotificationType] = useState(
    NotificationType.info,
  )
  const [notificationProps, setNotificationProps] = useState(defaultProps)
  const [isOpen, setOpen] = useState(false)

  const open = useCallback(
    (
      message: React.ReactNode,
      type: NotificationType = NotificationType.info,
      props: NotificationProps,
    ) => {
      setNotificationMessage(message)
      setNotificationType(type)
      setNotificationProps({
        ...defaultProps,
        ...props,
      })
      setOpen(true)
    },
    [],
  )

  useEffect(() => {
    showNotificationFn = open
    // eslint-disable-next-line
  }, [])

  const close = useCallback(() => {
    setNotificationProps(defaultProps)
    setOpen(false)
  }, [])

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      TransitionComponent={Fade}
      autoHideDuration={notificationProps.autoHideDuration}
      classes={{ root: classes.root }}
      onClose={close}
    >
      <Alert elevation={6} onClose={close} severity={notificationType}>
        {notificationMessage}
      </Alert>
    </Snackbar>
  )
}

export default appWithStyles(styles)(NotificationComponent)
