import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, shadows, palette, shape }: AppTheme) {
  return appCreateStyles({
    root: {},
    header: {
      marginBottom: spacing(7),
    },
    title: {
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          width: '100% !important',
        },
    },
    actions: {},
    list: {
      width: '100%',
      paddingBottom: spacing(5),
    },
    item: {},
    skeleton: {
      boxShadow: shadows[1],
      borderRadius: shape.borderRadius,
    },
    errorState: {
      fontWeight: 700,
      color: palette.common.white,
    },
  })
}
