import React from 'react'

export const getEmptyState = (
  isLoggedIn: boolean,
  total?: number,
): React.ReactNode | null => {
  const config = [
    {
      heading: (
        <>
          Please enter your search text or apply filters <br /> to find desired
          content, people {isLoggedIn ? 'or users' : ''}
        </>
      ),
    },
    {
      condition: !total,
      heading: 'Sorry, there is no result to display',
    },
  ]

  const message = config.find(({ condition }) => condition)

  if (message) {
    return message.heading
  }
  return null
}
