import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles() {
  return appCreateStyles({
    root: {},
    rootHidden: {
      display: 'none',
    },
    loading: {
      borderRadius: '50%',
    },
  })
}
