import { ContentType } from 'shared/models/content/content-type'

export const CONTENT_TYPE_LABELS: Record<ContentType, string> = {
  [ContentType.movie]: 'Movie',
  [ContentType.season]: 'Season',
  [ContentType.show]: 'TV Show',
  [ContentType.sport]: 'Sport',
}

export const CONTENT_LIST_FIELDS = {
  id: 'id',
}
