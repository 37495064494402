import { BaseModel } from 'core/models/base'

export enum AnnouncementStatus {
  NEW = 'NEW',
  READ = 'READ',
}

export interface AnnouncementGetListQuery {
  id: string
  date: string
  title?: string
  text: string
  status: AnnouncementStatus
}

export class AnnouncementGetListModel extends BaseModel<AnnouncementGetListQuery> {
  private id: string
  private title: string
  private text: string
  private date: string
  private status: AnnouncementStatus

  constructor(data: AnnouncementGetListQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): AnnouncementGetListQuery {
    return {
      id: this.id,
      title: this.title,
      text: this.text,
      date: this.date,
      status: this.status,
    }
  }
}
