import { Provider, oAuthService } from 'services'
import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'

export default class oAuthProvider extends Provider {
  configService = inject(DI_TOKENS.configService)

  boot(app) {
    app.bind('oauth', () => new oAuthService(this.config))
  }

  get config() {
    return {
      Auth: this.configService.get().auth,
    }
  }
}
