import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'
import { ImageOrientation } from 'shared/models/image/orientation'
import { AI_MOBILE_MODAL_FOOTER_HEIGHT, AI_MODAL_FOOTER_HEIGHT, AI_SCROLLBAR_WIDTH } from '../../search-modal.content'

const landscapePosterWidth = 80
const landscapeActionsWidth = 201
const mobileActionsWidth = 80
const portraitPosterWidth = 40
const posterMargin = 12

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    root: {},
    listItem: {
      // height: LIST_ITEM_HEIGHT,
      height: 'auto',
      padding: spacing(0, 3),
      borderRadius: 12,
      margin: spacing(3, 0),
      '&:hover': {
        backgroundColor: appFade(palette.common.white, 0.05),
      },
    },
    posterWrapper: {
      width: landscapePosterWidth,
      marginRight: posterMargin,
    },
    [ImageOrientation.portrait]: {
      height: 60,
      width: portraitPosterWidth,
      cursor: 'pointer',
    },
    [ImageOrientation.landscape]: {
      height: 40,
      width: landscapePosterWidth,
      cursor: 'pointer',
    },
    [`info-${ImageOrientation.portrait}`]: {
      width: `calc(100% - ${landscapePosterWidth + posterMargin + landscapeActionsWidth}px)`,
      '@media only screen and (min-width: 320px) and (max-width: 980px)':
        {
          width: `calc(100% - ${landscapePosterWidth + posterMargin + mobileActionsWidth}px)`,
        },
    },
    [`info-${ImageOrientation.landscape}`]: {
      width: `calc(100% - ${landscapePosterWidth + posterMargin + landscapeActionsWidth}px)`,
      '@media only screen and (min-width: 320px) and (max-width: 980px)':
        {
          width: `calc(100% - ${landscapePosterWidth + posterMargin + mobileActionsWidth}px)`,
        },
    },
    itemTitle: {
      color: '#B2B1B2',
      fontWeight: 700,
      fontSize: 16,
      cursor: 'pointer',
    },
    itemDescription: {
      paddingRight: 16,
      fontWeight: 600,
      color: appFade(palette.grey[100], 0.4),
      fontSize: 13,
    },
    itemMetadata: {
      marginRight: spacing(2),
      color: '#B5B5B5',
      fontSize: 11,
      fontWeight: 400,
      margin: spacing(2, 0),
    },
    footer: {
      borderTop: '1px solid #5A5A5A',
      height: AI_MODAL_FOOTER_HEIGHT,
      '@media only screen and (min-width: 320px) and (max-width: 980px)':
        {
          height: AI_MOBILE_MODAL_FOOTER_HEIGHT,
        },
      paddingRight: AI_SCROLLBAR_WIDTH,
      justifyContent: 'flex-end',
      alignContent: 'center',
    },
  })
}
