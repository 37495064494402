import { Model } from 'services'

export default class GridDepartmentModel extends Model {
  get fillable() {
    return {
      id: 'string',
      name: 'string',
      genre: 'string',
      logoUrl: 'null',
      images: 'array',
    }
  }

  getType() {
    return 'DEPARTMENT'
  }

  getLogo() {
    return (
      (this.images.filter(image => image.type === 'LOGO')[0] &&
        this.images.filter(image => image.type === 'LOGO')[0].url) ||
      null
    )
  }
}
