import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ custom }: AppTheme) {
  return appCreateStyles({
    root: {
      textTransform: 'none',
      borderRadius: custom.borderRadius.primary,
      fontSize: 14,
      fontWeight: 700,
    },
    text: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
  })
}
