import React from 'react'
import cx from 'classnames'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './select-field.styles'

type SelectFieldProps = {
  onClose: () => void
  label: string
  value?: string
  startIcon?: React.ReactNode
  disabled?: boolean
  isMobile?: boolean
}

type Props = AppWithStyles<typeof styles> & SelectFieldProps

const _SelectField: React.FC<Props> = ({
  classes,
  onClose,
  label,
  value,
  startIcon,
  disabled,
  isMobile,
}) => {
  return (
    <div
      className={cx(classes.container, disabled && classes.disabled)}
      onClick={onClose}
    >
      <span className={cx(classes.text, isMobile && classes.mobileText)}>
        {startIcon && <span className={classes.icon}>{startIcon}</span>}
        {!value && label}
        {value && (
          <>
            {label && (
              <span className={classes.label}>{`${label}:`}&ensp;</span>
            )}
            {value}
          </>
        )}
      </span>
      <KeyboardArrowDownIcon classes={{ root: classes.iconRoot }} />
    </div>
  )
}

export const SelectField = appWithStyles(styles)(_SelectField)
