import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'

import { OnboardingViewModel } from './onboarding.vm'
import { Stepper } from './components/stepper'
import { FirstStep } from './components/first-step'
import { SecondStep } from './components/second-step'
import { ThirdStep } from './components/third-step'
import { FourthStep } from './components/fourth-step'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { appObserver } from 'core/state-management/utils'
import { OnboardingStatus } from 'shared/models/user/onboading-status'
import { Dialog } from 'shared/components/dialog'
import {
  NotificationType,
  showNotification,
} from 'shared/components/notification/notification'
import { Loading } from 'shared/components/loading'
import { GridType } from 'shared/models/grid/type'
import { GridGroupItem } from 'shared/models/grid-group/GridGroupItem'
import { CompleteMessage } from './components/complete-message'

import { styles } from './onboarding.styles'
import { logAnalyticsOnboarding } from '../../../services/analytics.service'

const STEPS = [
  {
    id: OnboardingStatus.step0,
    label: 'Movies & TV Shows',
    grids: [GridType.genre, GridType.person, GridType.content],
  },
  {
    id: OnboardingStatus.step1,
    label: 'Sports',
    grids: [GridType.sport, GridType.team],
  },
  {
    id: OnboardingStatus.step2,
    label: 'Add Services',
    grids: [GridType.freeProvider, GridType.paidProvider],
  },
  {
    id: OnboardingStatus.step3,
    label: 'Follow Friends',
    grids: [],
  },
]

export type OnboardingProps = AppWithStyles<typeof styles> & {
  open: boolean
  status: OnboardingStatus
  onClose?: () => void
}

const OnboardingComponent: React.FC<OnboardingProps> = ({
  classes,
  open,
  status,
  onClose,
}) => {
  const $vm = useMemo(() => new OnboardingViewModel(), [])
  const [currentStep, setCurrentStep] = useState<OnboardingStatus>(status)
  const [initializing, setInitializing] = useState(false)
  const [grids, setGrids] = useState<Array<GridGroupItem>>([])

  const initialize = useCallback(async () => {
    try {
      setInitializing(true)

      const group = await $vm.getGroup()

      setGrids(group.asJson.items)
    } catch (err) {
      showNotification(
        'Something went wrong while initializing onboarding',
        NotificationType.error,
      )

      onClose()

      console.error(err)
    } finally {
      setInitializing(false)
    }
    // eslint-disable-next-line
  }, [])

  const proceedStep = useCallback(
    async (callback: () => Promise<void>) => {
      const currentStepIndex = STEPS.findIndex(({ id }) => id === currentStep)
      const nextStep =
        STEPS.find((step, i) => i > currentStepIndex)?.id ||
        OnboardingStatus.finished

      try {
        await callback()

        await $vm.updateOnboardingStatus(nextStep)

        setCurrentStep(nextStep)
      } catch (err) {
        showNotification(
          'Something went wrong while completing previous step, please reload the page',
          NotificationType.error,
        )

        console.error(err)
      }
    },
    // eslint-disable-next-line
    [currentStep],
  )

  const stepContent = useMemo(() => {
    const _currentStep = STEPS.find(({ id }) => id === currentStep)
    if (grids && _currentStep) {
      const stepGrids = grids.filter(grid =>
        _currentStep.grids.includes(grid.type),
      )
      const commonProps = {
        proceedStep,
        grids: stepGrids,
      }
      const config = {
        [OnboardingStatus.step0]: <FirstStep {...commonProps} />,
        [OnboardingStatus.step1]: <SecondStep {...commonProps} />,
        [OnboardingStatus.step2]: <ThirdStep {...commonProps} />,
        [OnboardingStatus.step3]: <FourthStep {...commonProps} />,
      }

      return config[currentStep]
    }
    // eslint-disable-next-line
  }, [grids, currentStep])

  useEffect(() => {
    initialize()
    // eslint-disable-next-line
  }, [])

  const processedSteps = useMemo(() => {
    const currentStepIndex = STEPS.findIndex(({ id }) => id === currentStep)
    logAnalyticsOnboarding(currentStep)

    return STEPS.map(({ id, label }, i) => {
      return {
        id,
        isCompleted: i < currentStepIndex,
        label: `${i + 1}. ${label}`,
      }
    })
  }, [currentStep])

  const content = useMemo(() => {
    if (initializing) {
      return <Loading height="100%" classes={{ root: classes.body }} />
    }

    if (currentStep === OnboardingStatus.finished) {
      return (
        <CompleteMessage
          classes={{ root: classes.body }}
          onProceedForward={onClose}
        />
      )
    }

    return (
      <div className={classes.body}>
        <Stepper activeStep={currentStep} steps={processedSteps} />
        <div className={classes.content}>{stepContent}</div>
      </div>
    )
  }, [initializing, classes, currentStep, onClose, processedSteps, stepContent])

  return (
    <Dialog
      withCloseButton
      open={open}
      classes={{
        root: classes.root,
        paper: cx({
          [classes.rootCompleted]: currentStep === OnboardingStatus.finished,
        }),
      }}
      onClose={onClose}
    >
      {content}
    </Dialog>
  )
}

export const Onboarding = appWithStyles(styles)(
  appObserver(OnboardingComponent),
)
