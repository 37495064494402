import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ palette, spacing, custom }: AppTheme) {
    return appCreateStyles({
        root: {
        },
        content: {
            height: '228px',
            width: '480px',
            zIndex: 1,
            backdropFilter: 'blur(16px)',
            borderRadius: spacing(6),
            padding: spacing(6,4,6,4),
            backgroundColor: custom.colors.gray[500],
        },
        buttonCancel: {
            height: '50px',
            minWidth: '50px !important',
            margin: '0 24px !important',
        },
        buttonAccept: {
            height: '50px',
            minWidth: '50px !important',
            margin: '0 !important',
        },
        title: {
            fontSize: 24,
            fontWeight: 700,
            color: custom.colors.gray[200],
            lineHeight: '30px',
        },
        text: {
            fontSize: 16,
            fontWeight: 400,
            color: appFade(custom.colors.gray[200], 0.8),
            lineHeight: '22px',
            marginTop: spacing(4),
            marginBottom: spacing(10),
        },
        link: {
            fontSize: 16,
            fontWeight: 400,
            color: appFade(custom.colors.gray[200], 0.8),
            lineHeight: '22px',
            textDecoration: 'underline',
        },
    })
}
