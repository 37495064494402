import React from 'react'

import { Slider as MuiSlider } from '@material-ui/core'
import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './select-slider.styles'

export enum SliderLabelDisplay {
  AUTO = 'auto',
  ON = 'on',
  OFF = 'off',
}

type SliderComponentProps = {
  onChange: (yearFrom: number, yearTo: number) => void
  min: number
  max: number
  value: number[]
  children?: React.ReactNode
  valueLabelDisplay?: SliderLabelDisplay
}

type Props = AppWithStyles<typeof styles> & SliderComponentProps

const _Slider: React.FC<Props> = ({
  classes,
  value,
  onChange,
  min = 0,
  max = 100,
  valueLabelDisplay = SliderLabelDisplay.OFF,
  children,
}) => {
  const handleOnChange = (event: React.ChangeEvent, newValue: number[]) => {
    const [yearFrom, yearTo] = newValue
    onChange(yearFrom, yearTo)
  }

  const [from, to] = value
  const hasValue = Boolean(from && to)

  return (
    <div className={classes.container}>
      <div className={classes.values}>
        {hasValue && (
          <>
            <span>{from}</span>
            <span>{to}</span>
          </>
        )}
        {!hasValue && (
          <>
            <span>{min}</span>
            <span>{max}</span>
          </>
        )}
      </div>
      <MuiSlider
        classes={{
          root: classes.sliderRoot,
          track: classes.sliderTrack,
          thumb: classes.sliderThumb,
        }}
        min={min}
        max={max}
        value={hasValue ? value : [min, max]}
        onChange={handleOnChange}
        valueLabelDisplay={valueLabelDisplay}
      />
      {children}
    </div>
  )
}

export const SelectSlider = appWithStyles(styles)(_Slider)
