import { configure, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { LiveModel } from 'models'

configure({ enforceActions: 'observed' })
export default class LiveStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      promo: new Collection(),
      collection: new Collection(),
    })
    this.model = LiveModel
  }

  // getListPromo() {
  // 	this.loading = true;
  // 	PromoModel.list(PromoCollection).then(
  // 		action(collection => {
  // 			this.promo = collection;
  // 		}),
  // 	);
  // }

  getList() {
    this.loading = true
    LiveModel.list()
      .then(
        action(collection => {
          this.collection = collection
        }),
      )
      .finally(action(() => (this.loading = false)))
  }
}
