import React, { useMemo } from 'react'
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Ellipsis } from 'shared/components/ellipsis'

import { styles } from './button.styles'

export type ButtonProps = MuiButtonProps &
  AppWithStyles<typeof styles> & {
    text?: string
    loading?: boolean
    error?: boolean
    component?: React.ComponentType<{ [key: string]: unknown }>
    componentProps?: { [key: string]: unknown }
  }

const ButtonComponent: React.FC<ButtonProps> = ({
  classes,
  text,
  loading,
  error,
  children,
  component: Component,
  componentProps,
  ...otherProps
}) => {
  const baseProps: ButtonProps = useMemo(() => {
    return {
      classes,
      variant: 'contained',
      size: 'small',
      disabled: loading,
      color: 'primary',
      ...otherProps,
    }
  }, [classes, loading, otherProps])

  const spinner = useMemo(() => {
    return loading ? (
      <CircularProgress size={15} style={{ marginLeft: 8 }} />
    ) : null
  }, [loading])

  const processedText = useMemo(() => {
    if (text) return text

    if (typeof children === 'string') {
      return children
    }
  }, [text, children])

  const content = useMemo(() => {
    return (
      <MuiButton {...baseProps}>
        {(processedText ? (
          <Ellipsis
            withTooltip={false}
            text={processedText}
            classes={{ root: classes.text }}
          />
        ) : undefined) || children}{' '}
        {spinner}
      </MuiButton>
    )
    // eslint-disable-next-line
  }, [baseProps, processedText, children, spinner])

  if (Component) {
    return <Component {...componentProps}>{content}</Component>
  }

  return content
}

export const Button = appWithStyles(styles)(ButtonComponent)
