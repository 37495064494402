export enum GridType {
  content = 'CONTENT',
  freeProvider = 'FREE_PROVIDER',
  genre = 'GENRE',
  grid = 'GRID',
  paidProvider = 'PAID_PROVIDER',
  person = 'PERSON',
  promo = 'PROMO',
  recommended = 'RECOMMENDED',
  similar = 'SIMILAR',
  sport = 'SPORT',
  team = 'TEAM',
  department = 'DEPARTMENT',
  user = 'USER',
  collection = 'COLLECTION',
}
