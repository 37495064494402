import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { SCROLL_STYLES } from 'shared/constants/global-styles'
import { CONTROLS_WRAPPER_HEIGHT } from '../../onboarding.constants'

export function styles({ spacing }: AppTheme) {
  return appCreateStyles({
    root: {
      height: '100%',
    },
    content: {
      height: `calc(100% - ${CONTROLS_WRAPPER_HEIGHT}px)`,
      overflow: 'auto',
      ...SCROLL_STYLES,
    },
    section: {
      padding: spacing(16, 6, 0),
    },
    controls: {
      height: CONTROLS_WRAPPER_HEIGHT,
      margin: spacing(0, 6),
    },
  })
}
