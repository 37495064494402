import React from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Flex } from 'shared/components/flex'
import { LogoIcon } from 'shared/icons/logo'
import { Button } from 'shared/components/button'

import { styles } from './complete-message.styles'

export type CompleteMessageProps = AppWithStyles<typeof styles> & {
  onProceedForward: () => void
}

const CompleteMessageComponent: React.FC<CompleteMessageProps> = ({
  classes,
  onProceedForward,
}) => {
  return (
    <Flex
      alignItems="center"
      justify="center"
      direction="column"
      classes={{ root: classes.root }}
    >
      <LogoIcon classes={{ root: classes.logo }} />
      <h5 className={classes.title}>Welcome to Skroote!</h5>
      <h5 className={classes.message}>
        Our mission is to connect movie, TV and sports lovers to the streaming
        content they want to watch. Just sit back and enjoy it all with no
        hassle.
      </h5>
      <Button
        text="Cool!"
        classes={{ root: classes.button }}
        onClick={onProceedForward}
      />
    </Flex>
  )
}

export const CompleteMessage = appWithStyles(styles)(CompleteMessageComponent)
