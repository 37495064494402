import React, { useCallback } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Flex } from 'shared/components/flex'
import { List } from 'shared/components/list'
import { LIST_ITEM_HEIGHT } from './users-list.constants'
import { Ellipsis } from 'shared/components/ellipsis'
import { ROUTES } from 'shared/constants/routes'
import { Avatar } from 'shared/components/avatar'
import { UserListQuery } from 'shared/models/user/list-model'
import { Link } from 'shared/components/link'
import { DEFAULT_ITEMS_PER_PAGE } from 'shared/constants/pagination'

import { styles } from './users-list.styles'

export type UsersListProps = AppWithStyles<typeof styles> & {
  data: Array<UserListQuery>
  width: number
  height: number
  query: string
  total: number
  getList: (query: string, page: number) => Promise<void>
}

const UsersListComponent: React.FC<UsersListProps> = ({
  classes,
  width,
  height,
  query,
  data,
  getList: _getList,
  total,
}) => {
  const getList = useCallback(
    (page: number) => {
      return _getList(query, page)
    },
    [query, _getList],
  )

  const renderItem = useCallback(
    (data: UserListQuery) => {
      return (
        <Flex alignItems="center" classes={{ root: classes.listItem }}>
          <Link
            href={ROUTES.private.profile.root(data.id)}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Avatar
              data-id={data.id}
              loadingSize={8}
              src={data.avatar}
              classes={{ root: classes.avatar }}
            />
          </Link>
          <div className={classes.info}>
            <Ellipsis
              component={Link}
              withTooltip={false}
              text={data.fullName}
              classes={{ root: classes.fullName }}
              componentProps={{
                href: ROUTES.private.profile.root(data.id),
                rel: 'noreferrer noopener',
                target: '_blank',
              }}
            />
          </div>
        </Flex>
      )
    },
    [classes],
  )

  return (
    <div className={classes.root}>
      <List
        idProperty="id"
        width={width}
        height={height}
        initialPage={data.length / DEFAULT_ITEMS_PER_PAGE - 1}
        itemSize={LIST_ITEM_HEIGHT}
        data={data}
        shimmerVerticalPadding={4}
        renderItem={renderItem}
        total={total}
        getData={getList}
      />
    </div>
  )
}

export const UsersList = appWithStyles(styles)(UsersListComponent)
