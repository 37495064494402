import React from 'react'
import cx from 'classnames'
import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './select-close.styles'

type ComponentProps = {
  hasValue: boolean
  onReset: () => void
  onClose: () => void
  showOnClose?: boolean
}

type Props = AppWithStyles<typeof styles> & ComponentProps

export const _SelectClose: React.FC<Props> = ({
  hasValue,
  classes,
  onReset,
  onClose,
  showOnClose = true,
}) => {
  const containerClasses = cx(
    hasValue && showOnClose ? classes.containerRight : classes.containerCenter,
  )
  return (
    <>
      <div className={classes.line} />
      <div className={containerClasses}>
        {hasValue && showOnClose && (
          <>
            <span className={cx(classes.hide, classes.text)}>empty</span>
            <span className={classes.text} onClick={onReset}>
              Reset
            </span>
          </>
        )}
        <span className={classes.text} onClick={onClose}>
          Close
        </span>
      </div>
    </>
  )
}

export const SelectClose = appWithStyles(styles)(_SelectClose)
