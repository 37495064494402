import { configure, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { SportModel } from 'models'

configure({ enforceActions: 'observed' })
export default class SportStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      promo: new Collection(),
      categories: new Collection(),
      collection: new Collection(),
      lastEvents: new Collection(),
      upcoming: new Collection(),
      collections: new Collection({}),
      listCollection: new Collection({}),
      categoryCollection: new Collection(),
      title: new SportModel(),
    })
    this.model = SportModel
  }

  // getListPromo() {
  // 	this.loading = true;
  // 	PromoModel.list(PromoCollection).then(
  // 		action(collection => {
  // 			this.promo = collection;
  // 		}),
  // 	);
  // }

  getCategories() {
    SportModel.categories()
      .then(
        action(collection => {
          this.categories.fill(collection)
        }),
      )
      .finally(action(() => (this.loading = false)))
  }

  getCategoryFilters(id, params) {
    return SportModel.categoryFilters(id, params)
  }

  getListFilters(id) {
    return SportModel.filters(id)
  }

  getCategoryCollection(id, params) {
    return SportModel.getCategoryContent(id, params)
      .then(
        action(collection => {
          this.categoryCollection = collection
        }),
      )
      .finally(action(() => (this.loading = false)))
  }

  getListCollection(id, params) {
    SportModel.getListContent(id, params)
      .then(
        action(collection => {
          this.listCollection = collection
        }),
      )
      .finally(action(() => (this.loading = false)))
  }

  getSportCollectionsContent() {
    SportModel.getListsContent()
      .then(
        action(collection => {
          this.collections = collection
        }),
      )
      .finally(action(() => (this.loading = false)))
  }

  getLastEvents() {
    this.loading = true
    SportModel.lastEvents()
      .then(
        action(collection => {
          this.lastEvents = collection
        }),
      )
      .finally(action(() => (this.loading = false)))
  }

  loadNext() {
    return this.categoryCollection.paginator.next().then(
      action(collection => {
        this.categoryCollection = this.categoryCollection.merge(collection)
      }),
    )
  }
}
