import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { IGenresService } from 'shared/types/services/genres'
import { GenreGetModel } from 'shared/models/genre/genre-get-model'
import { GenreGetListModel } from '../models/genre/genre-get-list-model'

@injectable()
export class GenresService extends BaseService implements IGenresService {
  private app = Application.instance()

  protected urlPrefix = '/genres'

  getList: IGenresService['getList'] = async (id: string, page: number) => {
    const {
      data: { items },
    } = await this.app.newrequest.get(`/grids/${encodeURIComponent(id)}`, {
      params: {
        page,
      },
    })

    const { data } = await this.app.newrequest.post(this.getUrl('list'), items)

    return data.map(item => new GenreGetModel(item))
  }

  async getAll(): Promise<GenreGetListModel> {
    const response = await this.app.newrequest.get('/genres?pageSize=1000')

    return new GenreGetListModel(response.data)
  }
}
