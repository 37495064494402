import React from 'react'
import cx from 'classnames'
import DoneIcon from '@material-ui/icons/Done'

import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'
import { SelectListItem } from '../../../filters/model/global-filter.types'

import { styles } from './select-single-list.styles'

type SingleListProps = {
  options: SelectListItem[]
  value: string
  onChange: (v: string) => void
  children: React.ReactNode
}

type Props = AppWithStyles<typeof styles> & SingleListProps

const _SelectSingleList: React.FC<Props> = ({
  classes,
  options,
  value,
  onChange,
  children,
}) => {
  const handleOnChange = (value: SelectListItem['value']) => () => {
    onChange(value)
  }
  return (
    <div className={classes.container}>
      <ul className={classes.list}>
        {options.map(option => {
          const isActive = option.value === value
          return (
            <li
              key={option.value}
              className={cx(classes.item, isActive && classes.active)}
              onClick={handleOnChange(option.value)}
            >
              {option.name}
              {isActive && <DoneIcon classes={{ root: classes.icon }} />}
            </li>
          )
        })}
        {children}
      </ul>
    </div>
  )
}

export const SelectSingleList = appWithStyles(styles)(_SelectSingleList)
