import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { observer } from 'mobx-react'
import { decorate, action, observable } from 'mobx'
import { Input, InputAdornment } from '@material-ui/core'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import cx from 'classnames'

import AdvancedButton from 'components/Buttons/Advanced'
import { Button } from 'shared/components/button'
import TitleActionButton from 'components/Buttons/TitleActionButton'
import addIcon from 'assets/images/ico/new-list.svg'
import cards from 'assets/images/ico/cards.png'
import clear from 'assets/images/ico/close.svg'
import { appWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './add-custom.styles'

class Custom extends Component {
  notAvailableLists = ['watchl<ist', 'seen', 'favourites', 'liked']
  newCustomName = ''
  status = false
  checkInList = []
  positionName = 'left'

  componentDidMount() {
    this.$app.event.$on('close.custom', this.reset)
  }

  componentWillUnmount() {
    this.reset()
  }

  handleClick = e => {
    if (this.props.isAiAction && e) {
      e.preventDefault();
    }
  }

  openCustom = e => {
    if (this.props.isAiAction && e) {
      e.preventDefault();
    }

    const position = e.target.getBoundingClientRect()
    this.positionName = position.left < 250 ? 'right' : 'left'
    this.status = true
  }

  closeCustom = () => {
    this.status = false
    this.$app.event.$emit('close.custom')
  }

  reset = () => {
    this.newCustomName = ''
  }

  close = () => {
    this.status = false
  }

  handleChange = e => {
    let wsRegex = /^\s*/g
    this.newCustomName = e.target.value.replace(wsRegex, '')
  }

  clearFiled = (e) => {
    if (this.props.isAiAction && e) {
      e.preventDefault();
    }

    this.newCustomName = ''
  }

  isExistsName = () => {
    return this.$app.store.UserCustomGridStore.isNameAlreadyExists(
      this.newCustomName,
    )
  }

  isDisabledButton = () => {
    return this.newCustomName === '' || this.isExistsName()
  }

  create = (e) => {
    if (this.props.isAiAction && e) {
      e.preventDefault();
    }

    if (!this.$app.auth.isLoggedIn()) {
      this.$app.event.$emit('overlay.activity.login', {
        status: true,
      })
      return
    }

    const isDisabled = this.isDisabledButton()

    if (isDisabled) return

    if (this.props.ids) {
      this.$app.store.UserCustomGridStore.createGridWithManyItems(
        this.newCustomName,
        this.props.ids,
      )
    } else {
      this.$app.store.UserCustomGridStore.createGrid(
        this.newCustomName,
        this.props.model.id,
      )
    }

    this.clearFiled()
  }

  renderCustomListItem = list => {
    const { ListStore } = this.$app.store
    const { model } = this.props
    const is_exist =
      ListStore.allListsItems[list.id] &&
      ListStore.allListsItems[list.id][model.id]
    return !this.notAvailableLists.includes(list.id) ? (
      <li
        key={list.id}
        className={`custom-list__item ${is_exist && 'marked'}`}
        onClick={() =>
          ListStore[
            !ListStore.allListsItems[list.id] ||
            !ListStore.allListsItems[list.id][model.id]
              ? 'addTitleToList'
              : 'removeTitleFromList'
          ]({
            model,
            listId: list.id,
          })
        }
      >
        <h6 className="ellipsis regular">{list.name}</h6>
      </li>
    ) : null
  }

  checkActivity = () => {
    const { ListStore } = this.$app.store
    const { model } = this.props
    const lists = []
    ListStore.listCollection.forEach(list => {
      if (
        !this.notAvailableLists.includes(list.id) &&
        ListStore.allListsItems[list.id] &&
        ListStore.allListsItems[list.id][model.id]
      ) {
        lists.push(list.id)
      }
    })
    this.checkInList = lists
  }

  handleKeyDown = e => {
    if (Number(e.keyCode) === 13) {
      this.create()
    }
  }

  addItemToList = (gridDetailsModel, item) => {
    this.$app.store.UserCustomGridStore.updateGridItems(gridDetailsModel, item)
  }

  removeItemFromList = (gridDetailsModel, item) => {
    this.$app.store.UserCustomGridStore.removeGridItems(gridDetailsModel, item)
  }

  addManyItemsToList = (gridDetailsModel, items) => {
    this.$app.store.UserCustomGridStore.updateManyGridItems(gridDetailsModel, items)
  }

  removeManyItemsFromList = (gridDetailsModel, items) => {
    this.$app.store.UserCustomGridStore.removeManyGridItems(gridDetailsModel, items)
  }

  handleChangeCustomStatus = (gridDetailsModel, id, ids) => {
    return (e) => {
      if (this.props.isAiAction && e) {
        e.preventDefault();
      }

      if (ids) {
        const selected =  ids.every(i => gridDetailsModel.items.includes(i));

        selected
          ? this.removeManyItemsFromList(gridDetailsModel, ids)
          : this.addManyItemsToList(gridDetailsModel, ids)
      } else {
        gridDetailsModel.items.includes(id)
          ? this.removeItemFromList(gridDetailsModel, id)
          : this.addItemToList(gridDetailsModel, id)
      }
    }
  }

  render() {
    const gridDetails = this.$app.store.UserCustomGridStore.getGridsDetails()
    const { model, ids, classes, details, isMobile } = this.props
    const buttonLabel = ids ? 'Save all' : 'Save';

    const isDisabled = this.isDisabledButton()
    const isExists = this.isExistsName()

    return (
      <div
        className="add-custom-wrapper"
        style={{fontSize: 'initial'}}
        onMouseLeave={this.closeCustom}
        onMouseEnter={this.openCustom}
      >
        {details ? (
          isMobile ? (
            <div className={classes.buttonWrapper}>
              <Button
                classes={{
                  root: cx(classes.button, classes.buttonMobile),
                }}
                active={this.checkInList.length}
                onClick={this.handleClick}
              >
                <PlaylistAddIcon />
              </Button>
              <p className={classes.buttonText}>{buttonLabel}</p>
            </div>
          ) : (
            <Button
              startIcon={<PlaylistAddIcon />}
              text={buttonLabel}
              classes={{ root: classes.button }}
              active={this.checkInList.length}
              onClick={this.handleClick}
            />
          )
        ) : (
          <TitleActionButton
            onClick={this.openCustom}
            icon={addIcon}
            active={this.checkInList.length}
            bgColor="transparent"
          />
        )}
        <div
          className={`custom-actions ${this.props.isBottomPosition && 'bottom'} ${this.status && 'opened'} ${
            this.positionName
          }`}
          style={this.props.isAiAction && !this.status ? {display: 'none'} : undefined}
        >
          <div className="custom-actions__header">
            <span className="gray-opacity-05 fz-16">Save to…</span>
          </div>
          <Scrollbars autoHeight autoHeightMin={50} autoHeightMax={110}>
            <ul className="custom-list">
              {gridDetails.map(grid => {
                const selected = ids
                  ? ids.every(id => grid.items.includes(id))
                  : grid.items.includes(model.id);

                return (
                  <li
                    key={grid.title}
                    className={`custom-list__item ${
                      selected && 'marked'
                    }`}
                    onClick={this.handleChangeCustomStatus(grid, model?.id, ids)}
                  >
                    <h6 className="ellipsis regular">{grid.title}</h6>
                  </li>
                )
              })}
            </ul>
          </Scrollbars>
          <div className="field">
            <Input
              disableUnderline
              error={isExists}
              onClick={this.handleClick}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              placeholder="Name your list"
              classes={{
                input: 'new-custom__field',
                root: 'new-custom__filed--wrapper',
              }}
              value={this.newCustomName}
              endAdornment={
                this.newCustomName ? (
                  <InputAdornment
                    classes={{ root: 'custom-icon-clear-wrapper' }}
                    onClick={this.clearFiled}
                    position="end"
                  >
                    <img
                      src={clear}
                      className="custom-icon-clear"
                      alt="Clear Field"
                    />
                  </InputAdornment>
                ) : (
                  ''
                )
              }
            />
            {isExists && (
              <span className="error_label">List name must be unique</span>
            )}
          </div>
          <AdvancedButton
            onClick={this.create}
            icon={<img src={cards} alt="Follow" />}
            type="rounded"
            size="middle"
            color="white-opacity-06"
            bgColor="dark-gray"
            customClass="button-custom"
            mainText="Create New List"
            disabled={isDisabled}
          />
        </div>
      </div>
    )
  }
}

decorate(Custom, {
  customLists: observable,
  is_active: observable,
  model: observable,
  params: observable,
  status: observable,
  newCustomName: observable,
  openCustom: action,
  checkActivity: action,
  closeCustom: action,
  handleChange: action,
  clearFiled: action,
  reset: action,
  generateCustomLists: action,
})

export default appWithStyles(styles)(observer(Custom))
