import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ custom, palette, spacing }: AppTheme) {
  return appCreateStyles({
    root: {},
    container: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      color: palette.common.white,
      fontWeight: 700,
      justifyContent: 'center',
      padding: spacing(3, 4),
      backgroundColor: appFade(palette.common.white, 0.1),
      borderRadius: custom.borderRadius.primary,
    },
    containerSelected: {
      backgroundColor: appFade(palette.primary.dark, 0.4),
    },
  })
}
