import { Model } from 'services'
import { Application } from 'services'
import { GRID_DETAILS } from 'const/api/grids'
import { processContentImages } from 'shared/utils/image'

export default class GridDetailsModel extends Model {
  get fillable() {
    return {
      id: 'string',
      title: 'string',
      promo: 'boolean',
      order: 'number',
      items: 'array',
      itemType: 'string',
      type: 'string',
      orientation: 'string',
      size: 'string',
      images: 'array',
    }
  }

  getType() {
    return 'SPORT'
  }

  getImage() {
    return processContentImages(this.images)?.POSTER?.url.large
  }

  update(items) {
    const app = Application.instance()

    return app.newrequest.patch(
      `${GRID_DETAILS}/${encodeURIComponent(this.id)}/items`,
      {
        itemType: this.itemType,
        items: items,
        order: this.order,
        title: this.title,
        type: this.type,
      },
    )
  }

  updateMovies(items) {
    const app = Application.instance()

    return app.newrequest.put(`${GRID_DETAILS}/likes`, {
      items: items,
    })
  }
}
