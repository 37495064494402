import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { IProvidersService } from 'shared/types/services/providers'
import { ProviderGetModel } from 'shared/models/provider/get-model'

@injectable()
export class ProvidersService extends BaseService implements IProvidersService {
  private app = Application.instance()

  protected urlPrefix = '/providers'

  getList: IProvidersService['getList'] = async (id, page) => {
    const {
      data: { items },
    } = await this.app.newrequest.get(`/grids/${encodeURIComponent(id)}`, {
      params: {
        page,
      },
    })

    const { data } = await this.app.newrequest.post(this.getUrl('list'), items)

    return data.map(item => new ProviderGetModel(item))
  }
}
