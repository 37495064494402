import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'
import { LIST_ITEM_HEIGHT } from './users-list.constants'

const avatarSize = 40
const avatarMargin = 12

export function styles({ spacing, palette, shadows }: AppTheme) {
  return appCreateStyles({
    root: {},
    listItem: {
      height: LIST_ITEM_HEIGHT,
      padding: spacing(0, 3),
      borderRadius: 12,

      '&:hover': {
        backgroundColor: appFade(palette.common.white, 0.05),
      },
    },
    avatar: {
      height: avatarSize,
      width: avatarSize,
      marginRight: avatarMargin,
      boxShadow: shadows[1],
      cursor: 'pointer',
    },
    info: {
      width: `calc(100% - ${avatarSize + avatarMargin}px)`,
    },
    fullName: {
      color: '#B2B1B2',
      fontWeight: 700,
      fontSize: 16,
      cursor: 'pointer',

      '&:hover': {
        color: palette.primary.main,
      },
    },
  })
}
