import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ shadows, shape }: AppTheme) {
  return appCreateStyles({
    root: {},
    item: {},
    skeleton: {
      boxShadow: shadows[1],
      borderRadius: shape.borderRadius,
    },
  })
}
