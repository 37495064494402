import React, { useCallback, useState } from 'react'
import MuiAvatar, {
  AvatarProps as MuiAvatarProps,
} from '@material-ui/core/Avatar'
import cx from 'classnames'

import emptyAvatarImage from 'assets/images/user.jpg'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Loading } from 'shared/components/loading'

import { styles } from './avatar.styles'

export type AvatarProps = Except<MuiAvatarProps, 'classes'> &
  AppWithStyles<typeof styles> & {
    loadingSize?: number
  }

const AvatarComponent: React.FC<AvatarProps> = ({
  src,
  classes,
  loadingSize,
  ...otherProps
}) => {
  const [loading, setLoading] = useState(true)

  const handleLoaded = useCallback(() => {
    setLoading(false)
  }, [])

  const handleError = useCallback(() => {
    setLoading(false)
  }, [])

  return (
    <>
      <MuiAvatar
        src={src || emptyAvatarImage}
        classes={{ root: cx(classes.root, { [classes.rootHidden]: loading }) }}
        onLoad={handleLoaded}
        onError={handleError}
        {...otherProps}
      />
      {loading && (
        <Loading
          size={loadingSize}
          classes={{ root: cx(classes.root, classes.loading) }}
          style={otherProps.style}
        />
      )}
    </>
  )
}

AvatarComponent.defaultProps = {
  loadingSize: 18,
}

export const Avatar = appWithStyles(styles)(AvatarComponent)
