import React, { useCallback, useMemo } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Carousel } from 'shared/components/carousel'
import { CONTENT_LIST_FIELDS } from 'shared/constants/content'
import { Item } from './components/item'
import { SectionTitle } from '../../../section-title'
import { DepartmentGetQuery } from 'shared/models/department/get-model'
import { Message } from 'shared/components/message'
import { GenreGetQuery } from 'shared/models/genre/genre-get-model'

import { styles } from './teams.styles'

export type TeamsProps = AppWithStyles<typeof styles> & {
  selectedItems: Array<DepartmentGetQuery['id']>
  sportName: GenreGetQuery['name']
  data: Array<DepartmentGetQuery>
  onItemChange: (id: DepartmentGetQuery['id']) => void
}

const TeamsComponent: React.FC<TeamsProps> = ({
  classes,
  selectedItems,
  sportName,
  data,
  onItemChange,
}) => {
  const renderItem = useCallback(
    (item: DepartmentGetQuery) => {
      return (
        <Item
          selected={selectedItems.includes(item.id)}
          id={item.id}
          name={item.name}
          images={item.images}
          classes={{ root: classes.item }}
          onChange={onItemChange}
        />
      )
    },
    [selectedItems, classes, onItemChange],
  )

  const title = useMemo(() => {
    return <SectionTitle title={sportName} classes={{ title: classes.title }} />
  }, [sportName, classes])

  if (!data) {
    return (
      <div className={classes.root}>
        {title}
        <Message heading="There are no related teams" />
      </div>
    )
  }

  return (
    <Carousel
      title={title}
      data={data}
      itemDimensions={{
        width: 100,
        height: 100,
      }}
      idProperty={CONTENT_LIST_FIELDS.id}
      renderItem={renderItem}
      classes={{ root: classes.root }}
    />
  )
}

export const Teams = appWithStyles(styles)(TeamsComponent)
