import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

type Props = SvgIconProps & {}

export const SelectedMarkIcon: React.FC<Props> = props => {
  return (
    <SvgIcon viewBox="0 0 67 67" fill="none" {...props}>
      <defs>
        <linearGradient
          id="prefix__c"
          x1="29.192%"
          x2="40.206%"
          y1="-5.273%"
          y2="143.141%"
        >
          <stop offset="0%" stopColor="#B8E986" />
          <stop offset="100%" stopColor="#489E3A" />
        </linearGradient>
        <filter
          id="prefix__a"
          width="342.9%"
          height="342.9%"
          x="-121.4%"
          y="-103.6%"
          filterUnits="objectBoundingBox"
        ></filter>
        <path id="prefix__b" d="M8 0h20L0 28V8c0-4.418 3.582-8 8-8z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(21 16)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="url(#prefix__c)" xlinkHref="#prefix__b" />
        <path stroke="#1A1A1A" strokeWidth="2" d="M5 8.348L8.429 12 15 5" />
      </g>
    </SvgIcon>
  )
}
