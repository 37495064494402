import ListStore from './ListStore'
import PromoStore from './PromoStore'
import MovieStore from './MovieStore'
import ShowStore from './ShowStore'
import SportStore from './SportStore'
import LiveStore from './LiveStore'
import SearchStore from './SearchStore'
import PersonStore from './PersonStore'
import HomeGridStore from './HomeGridStore'
import OnboardingStore from './OnboardingStore'
import LikeStore from './LikeStore'
import ShowGridStore from './ShowGridStore'
import MoviesGridStore from './MoviesGridStore'
import ContentStore from './ContentStore'
import FooterStore from './FooterSore'
import GridStore from './GridStore'
import SportsGridStore from './SportsGridStore'
import LiveGridStore from './LiveGridStore'
import UserCustomGridStore from './UserCustomGridStore'
import DefaultGridStore from './DefaultGridStore'
import FollowersGridStore from './FollowersGridStore'
import FollowingGridStore from './FollowingGridStore'
import NotifyStore from './NotifyStore'

export default {
  ListStore: new ListStore(),
  PromoStore: new PromoStore(),
  MovieStore: new MovieStore(),
  ShowStore: new ShowStore(),
  SportStore: new SportStore(),
  LiveStore: new LiveStore(),
  SearchStore: new SearchStore(),
  PersonStore: new PersonStore(),
  HomeGridStore: new HomeGridStore(),
  OnboardingStore: new OnboardingStore(),
  LikeStore: new LikeStore(),
  ShowGridStore: new ShowGridStore(),
  MoviesGridStore: new MoviesGridStore(),
  ContentStore: new ContentStore(),
  FooterStore: new FooterStore(),
  GridStore: new GridStore(),
  SportsGridStore: new SportsGridStore(),
  LiveGridStore: new LiveGridStore(),
  UserCustomGridStore: new UserCustomGridStore(),
  DefaultGridStore: new DefaultGridStore(),
  FollowersGridStore: new FollowersGridStore(),
  FollowingGridStore: new FollowingGridStore(),
  NotifyStore: new NotifyStore(),
}
