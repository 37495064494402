import { EventBus, Provider } from 'services'

export default class EventBusProvider extends Provider {
  boot(app) {
    app.bind('event', () => new EventBus())
  }

  get config() {
    return {}
  }
}
