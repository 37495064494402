import { appObservable } from 'core/state-management/utils'
import { injectable } from 'core/di/di-utils'
import { inject } from 'core/di/di-utils'

import { DI_TOKENS } from 'shared/constants/di'

import { UserGetModel } from 'shared/models/user/get-model'
import { IUsersService } from 'shared/types/services/users'
import { IUsersViewModel } from 'shared/types/view-models/users'

@injectable()
export class UsersViewModel implements IUsersViewModel {
  @appObservable private usersService = inject<IUsersService>(
    DI_TOKENS.usersService,
  )
  @appObservable private _user: UserGetModel | undefined = undefined
  @appObservable private _loading = {
    user: true,
  }
  @appObservable private _errors = {
    user: false,
  }

  get user() {
    return this._user
  }

  get loading() {
    return this._loading
  }

  get errors() {
    return this._errors
  }

  getUser: IUsersViewModel['getUser'] = async userId => {
    try {
      this._errors.user = false
      this._loading.user = true

      const response = await this.usersService.get(userId)

      this._user = response
    } catch (err) {
      this._errors.user = true
      console.error(err)
    } finally {
      this._loading.user = false
    }
  }
}
