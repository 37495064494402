import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { appObserver } from 'core/state-management/utils'
import { StepDescription } from '../step-description'
import { OnboardingStepProps } from '../../onboarding.types'
import { FourthStepViewModel } from './fourth-step.vm'
import { Controls } from '../controls'
import { Loading } from 'shared/components/loading'
import {
  NotificationType,
  showNotification,
} from 'shared/components/notification/notification'
import { UserListQuery } from 'shared/models/user/list-model'
import { Users } from './components/users'
import { logOnboardingResult } from "../../../../../services/analytics.service";
import { OnboardingStatus } from "../../../../models/user/onboading-status";

import { styles } from './fourth-step.styles'

export type FourthStepProps = AppWithStyles<typeof styles> & OnboardingStepProps

const FourthStepComponent: React.FC<FourthStepProps> = ({
  classes,
  proceedStep: _proceedStep,
}) => {
  const $vm = useMemo(() => new FourthStepViewModel(), [])
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [users, setUsers] = useState<Array<UserListQuery>>([])
  const [selectedUsers, setSelectedUsers] = useState<
    Array<UserListQuery['id']>
  >([])

  const initialize = useCallback(async () => {
    try {
      setLoading(true)

      const response = await $vm.getUsers()

      setUsers(response.map(({ asJson }) => asJson))
    } catch (err) {
      showNotification(
        'Something went wrong while fetching users',
        NotificationType.error,
      )

      console.error(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initialize()
    // eslint-disable-next-line
  }, [])

  const proceedStep = useCallback(async (skip: boolean) => {
    try {
      setSubmitting(true)
      logOnboardingResult(skip, OnboardingStatus.step3)
      await _proceedStep(async () => await $vm.updateList(selectedUsers))
    } finally {
      setSubmitting(false)
    }
  }, [$vm, _proceedStep, selectedUsers])

  const handleUserChange = useCallback(
    (id: any) => {
      setSelectedUsers(
        selectedUsers.includes(id)
          ? selectedUsers.filter(item => item !== id)
          : [...selectedUsers, id],
      )
    },
    [selectedUsers],
  )

  if (loading) {
    return <Loading classes={{ root: classes.root }} />
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <StepDescription
          heading="Follow Popular Skroote Users"
          subheading={
            <>
              <div>
                Do you want to know what the most popular Skroote users watch?
              </div>
              <div>Click to select users and stay up-to-date.</div>
            </>
          }
        />
        <Users
          data={users}
          selectedItems={selectedUsers}
          classes={{ root: classes.section }}
          onItemChange={handleUserChange}
        />
      </div>
      <Controls
        loading={submitting}
        isFinalStep={true}
        canProceedForward={true}
        onProceedForward={proceedStep}
        classes={{ root: classes.controls }}
      />
    </div>
  )
}

export const FourthStep = appWithStyles(styles)(
  appObserver(FourthStepComponent),
)
