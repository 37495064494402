import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'
import { ListData, ListDataItem } from './list.types'
import { Flex } from 'shared/components/flex'
import { Shimmer } from 'shared/components/shimmer'

import { styles } from './list.styles'

export type ListProps = AppWithStyles<typeof styles> & {
  idProperty: string
  itemSize: number
  autoInit?: boolean
  initialPage?: number
  shimmerVerticalPadding?: number
  height: number
  width: number
  total?: number
  errorMessage?: React.ReactNode
  data: ListData;
  overscanCount?: number;
  getData?: (page: number) => Promise<void>
  renderItem: (data: ListDataItem, i: number, array: ListData) => React.ReactNode
}

const ListComponent: React.FC<ListProps> = ({
  idProperty,
  classes,
  autoInit,
  initialPage,
  itemSize,
  shimmerVerticalPadding,
  width,
  height,
  total,
  data,
  renderItem,
  getData: _getData,
  errorMessage,
  overscanCount,
}) => {
  const [page, setPage] = useState(initialPage || 0)
  const [loading, setLoading] = useState(Boolean(_getData) && autoInit)
  const [error, setError] = useState(false)
  const listRef = useRef<HTMLDivElement>(null)
  const processedHeight = Math.abs(height)

  const getData = useCallback(
    async (_page: number) => {
      try {
        setLoading(true)

        await _getData(_page)
      } catch (err) {
        setError(true)

        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [_getData],
  )

  useEffect(() => {
    if (_getData && autoInit) {
      getData(page)
    }
    // eslint-disable-next-line
  }, [])

  const renderListItem = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const itemData = data[index]

      return (
        <div style={style} className={classes.item}>
          {itemData ? (
            renderItem(itemData, index, data)
          ) : (
            <Shimmer
              itemsAmount={1}
              containerProps={{
                height: '100%',
                width: '100%',
              }}
              itemProps={{
                width: width,
                height: itemSize - shimmerVerticalPadding * 2,
              }}
              classes={{ skeleton: classes.shimmer }}
            />
          )}
        </div>
      )
    },
    // eslint-disable-next-line
    [classes, data, renderItem, itemSize],
  )

  const getMore = useCallback(() => {
    if (_getData && !loading) {
      const threshold =
        data.length * itemSize - listRef.current.offsetHeight * 2

      if (listRef.current?.scrollTop >= threshold && data.length < total) {
        const nextPage = page + 1

        setPage(nextPage)

        getData(nextPage)
      }
    }
  }, [page, data, itemSize, _getData, getData, loading, total])

  const getItemKey = useCallback(
    index => {
      const item = data[index] as unknown as { [key: string]: unknown }

      return item?.[idProperty] || index
    },
    [data, idProperty],
  )

  const itemsCount = useMemo(() => {
    const config = [
      {
        condition: total === data.length,
        value: total,
      },
      {
        condition: Boolean(listRef.current?.offsetHeight),
        value:
          data.length +
          Math.round(listRef.current?.offsetHeight / itemSize) * 2,
      },
      {
        value: data.length,
      },
    ]
    const value = config.find(({ condition = true }) => condition)?.value

    return value || 0
    // eslint-disable-next-line
  }, [data, total, loading, itemSize])

  if (error) {
    return (
      <Flex
        alignItems="center"
        justify="center"
        classes={{ root: classes.errorState }}
      >
        {errorMessage}
      </Flex>
    )
  }

  return (
    <FixedSizeList
      itemKey={getItemKey}
      width={width}
      height={processedHeight}
      itemCount={itemsCount}
      itemSize={itemSize}
      overscanCount={overscanCount ?? Math.round(processedHeight / itemSize) * 2}
      outerRef={listRef}
      className={classes.root}
      onScroll={_getData && !loading ? getMore : undefined}
    >
      {renderListItem}
    </FixedSizeList>
  )
}

ListComponent.defaultProps = {
  errorMessage: 'Something went wrong',
}

export const List = appWithStyles(styles)(ListComponent)
