import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      margin: `0 ${spacing(6)}px`,
      paddingTop: spacing(1),
    },
    icon: {
      position: 'relative',
      cursor: 'pointer',
    },
    iconRoot: {
      color: palette.grey['400'],
    },
    count: {
      content: '',
      position: 'absolute',
      top: -4,
      right: -3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      backgroundColor: palette.primary.dark,
      color: palette.common.white,
      width: 18,
      height: 18,
      borderRadius: '50%',
    },
  })
}
