import { DI_TOKENS } from 'shared/constants/di'

import { ContentService } from './content'
import { DepartmentsService } from './departments'
import { FollowersService } from './followers'
import { GenresService } from './genres-service'
import { LayoutService } from './layout'
import { OnboardingService } from './onboarding'
import { PersonsService } from './persons'
import { ProvidersService } from './providers'
import { SearchService } from './search'
import { UsersService } from './users'
import { AnnouncementsService } from './announcements'
import { BannerService } from './banner'

export const services = [
  {
    diToken: DI_TOKENS.contentService,
    entity: ContentService,
  },
  {
    diToken: DI_TOKENS.followersService,
    entity: FollowersService,
  },
  {
    diToken: DI_TOKENS.genresService,
    entity: GenresService,
  },
  {
    diToken: DI_TOKENS.layoutService,
    entity: LayoutService,
  },
  {
    diToken: DI_TOKENS.personsService,
    entity: PersonsService,
  },
  {
    entity: SearchService,
    diToken: DI_TOKENS.searchService,
  },
  {
    diToken: DI_TOKENS.usersService,
    entity: UsersService,
  },
  {
    diToken: DI_TOKENS.onboardingService,
    entity: OnboardingService,
  },
  {
    diToken: DI_TOKENS.departmentsService,
    entity: DepartmentsService,
  },
  {
    diToken: DI_TOKENS.providersService,
    entity: ProvidersService,
  },
  {
    diToken: DI_TOKENS.announcementsService,
    entity: AnnouncementsService,
  },
  { diToken: DI_TOKENS.bannerService, entity: BannerService },
]
