import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { GridModel } from 'models'
import { SPORT } from 'const/api/grids'

configure({ enforceActions: 'observed' })
export default class SportsGridStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      grids: new Collection(),
      collection: new Collection(),
    })
    this.model = GridModel
  }

  /**
   * Set Collection
   *
   * @param payload
   */
  setCollection(payload) {
    this.collection = payload
  }

  getGrids() {
    GridModel.list(SPORT)
      .then(
        action(collection => {
          this.grids = collection
        }),
      )
      .catch(e => {})
  }
}

decorate(SportsGridStore, {
  setCollection: action,
})
