import React, { useState } from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { SelectSingleList } from './components/select-single-list/select-single-list'
import { SelectField } from './components/select-field/select-field'
import { SelectClose } from './components/select-close/select-close'
import { SelectListItem } from '../filters/model/global-filter.types'

import { styles } from './select.styles'

type ComponentProps = {
  options: SelectListItem[]
  value: SelectListItem['value'] | string
  onChange: (v: string) => void
  onReset: () => void
  label?: string
  isMobile?: boolean
  startIcon?: React.ReactNode
  showOnClose?: boolean
}

type Props = AppWithStyles<typeof styles> & ComponentProps

const _CustomSingleSelect: React.FC<Props> = ({
  classes,
  options,
  value,
  onChange,
  onReset,
  label,
  isMobile,
  startIcon,
  showOnClose = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnOpen = () => setIsOpen(true)
  const handleOnClose = () => setIsOpen(false)

  return (
    <ClickAwayListener onClickAway={handleOnClose}>
      <div className={classes.container}>
        <SelectField
          isMobile={isMobile}
          onClose={handleOnOpen}
          label={label}
          value={value}
          startIcon={startIcon}
        />
        {isOpen && (
          <SelectSingleList onChange={onChange} options={options} value={value}>
            <SelectClose
              onClose={handleOnClose}
              onReset={onReset}
              hasValue={Boolean(value)}
              showOnClose={showOnClose}
            />
          </SelectSingleList>
        )}
      </div>
    </ClickAwayListener>
  )
}

export const CustomSelectSingle = appWithStyles(styles)(
  React.memo(_CustomSingleSelect),
)
