import { Application } from './index'

export default class UserService {
  initUser() {
    Application.instance().store.NotifyStore.hide()
    this.getDefaultGrids()
  }

  getDefaultGrids() {
    Application.instance().store.DefaultGridStore.getCollection()
    Application.instance().store.UserCustomGridStore.getGrids()
  }
}
