import React from 'react'

import { ClickAwayListener } from '@material-ui/core'

import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'
import CloseIcon from '@material-ui/icons/Close'
import { AnnouncementItem } from '../announcement-item/announcement-item'
import { Button } from 'shared/components/button'
import {
  AnnouncementGetListModel,
  AnnouncementStatus,
} from 'shared/models/announcement/announcements-get-list'

import { styles } from './announcement-list.styles'

type NotificationListProps = {
  items: AnnouncementGetListModel[]
  onClose: () => void
  onMarkAllAsRead: () => void
  isLogged: boolean
  hasUnread: boolean
}

export type Props = AppWithStyles<typeof styles> & NotificationListProps

const NotificationListComponent: React.FC<Props> = ({
  classes,
  items,
  onClose,
  onMarkAllAsRead,
  isLogged,
  hasUnread,
}) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.container}>
        <div className={classes.header}>
          <span className={classes.title}>Notifications</span>
          <div className={classes.close} onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className={classes.list}>
          {items.map(item => (
            <AnnouncementItem
              key={item.asJson.id}
              item={item}
              isNewMessage={
                isLogged ? item.asJson.status === AnnouncementStatus.NEW : false
              }
            />
          ))}
        </div>
        {isLogged && hasUnread && (
          <div className={classes.button}>
            <Button
              variant="outlined"
              className={classes.buttonMark}
              onClick={onMarkAllAsRead}
            >
              Mark all as read
            </Button>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export const NotificationList = appWithStyles(styles)(NotificationListComponent)
