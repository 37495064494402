import { Model } from 'services'
import { TMDB_IMAGE } from 'const/api/tmdb'

export default class CrewModel extends Model {
  get fillable() {
    return {
      id: 'number',
      gender: 'number',
      creditId: 'string',
      name: 'string',
      profilePath: 'string',
      department: 'number',
      job: 'string',
    }
  }

  /**
   * Get type of model
   *
   * @returns {string}
   */
  getType() {
    return 'PERSON'
  }

  /**
   * Get preview Image
   *
   * @returns {string}
   */
  getImage(size = 185) {
    return this.profilePath && `${TMDB_IMAGE}w${size}${this.profilePath}`
  }

  /**
   * Get movie link
   *
   * @returns {string}
   */
  getLink() {
    return `/person/${this.id}`
  }

  /**
   * Get person list
   *
   * @param list
   * @returns {Promise<any>}
   */
  static list(list) {
    return this.createFromResponse({
      meta: {},
      type: 'COLLECTION',
      results: list.toArray(),
    })
  }
}
