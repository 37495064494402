import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { ContentGetQuery } from 'shared/models/content/get-model'
import { GenreGetQuery } from 'shared/models/genre/genre-get-model'
import { GridRequestType } from 'shared/models/grid/request-type'
import { GridType } from 'shared/models/grid/type'
import { PersonGetQuery } from 'shared/models/person/get-model'
import { IContentService } from 'shared/types/services/content'
import { IGenresService } from 'shared/types/services/genres'
import { IOnboardingService } from 'shared/types/services/onboarding'
import { IPersonsService } from 'shared/types/services/persons'

export class FirstStepViewModel {
  private contentService = inject<IContentService>(DI_TOKENS.contentService)
  private genresService = inject<IGenresService>(DI_TOKENS.genresService)
  private personsService = inject<IPersonsService>(DI_TOKENS.personsService)
  private onboardingService = inject<IOnboardingService>(
    DI_TOKENS.onboardingService,
  )

  getContentGridItems: IContentService['getGridItems'] = (...args) => {
    return this.contentService.getGridItems(...args)
  }

  getGenres: IGenresService['getList'] = (...args) => {
    return this.genresService.getList(...args)
  }

  getPersons: IPersonsService['getList'] = (...args) => {
    return this.personsService.getList(...args)
  }

  updateList = async (
    skip: boolean,
    selectedItems: {
      content: Array<ContentGetQuery['id']>
      people: Array<PersonGetQuery['id']>
      genres: Array<GenreGetQuery['id']>
    },
  ) => {
    try {
      await this.contentService.addLikedContent(
        skip ? [] : selectedItems.content,
      )
      await this.onboardingService.updateList(
        GridRequestType.like,
        GridType.person,
        skip ? [] : selectedItems.people,
      )
      await this.onboardingService.updateList(
        GridRequestType.like,
        GridType.genre,
        skip ? [] : selectedItems.genres,
      )
    } catch (err) {
      throw err
    }
  }
}
