import { configure, decorate, extendObservable, action } from 'mobx'

configure({ enforceActions: 'observed' })

export enum NotifyType {
  RESEND, CONFIRM_SUCCESS, CONFIRM_FAIL
}

export default class NotifyStore {
  public type?: NotifyType;
  public username: any;

  constructor() {
    // Initialize observable store
    extendObservable(this, {
      type: undefined,
      username: '',
    })
  }

  setVisible(type: NotifyType) {
    this.type = type
  }
  hide() {
    this.type = undefined
  }
  setUserName(username) {
    this.username = username
  }
}

decorate(NotifyStore, {
  setVisible: action,
  hide: action,
  setUserName: action,
})