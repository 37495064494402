import React, { Component } from 'react'
import { Application } from 'services'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom'
import { convertToFlatArray } from 'utils'
import registerModules from 'modules'
import AsyncComponent from 'components/AsyncComponent'
import AnimationContainer from 'AnimationContainer'
import NotFound from 'views/App/NotFound'
import { observer } from 'mobx-react'
import { Loading } from 'shared/components/loading'

const LoginTrigger = ({ canInitializeLogin, eventName }) => {
  React.useEffect(() => {
    const $app = Application.instance();

    if (canInitializeLogin) {
      $app.event.$emit(eventName, {
        status: true,
      });
    }
  }, [canInitializeLogin]);

  return <Loading />;
};

class Routes extends Component {
  get routes() {
    const arr = convertToFlatArray(this.props.data);
    return arr.map(this.renderRouting);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  get isAuthenticationInProgress() {
    const $app = Application.instance();
    return $app.auth.checkLoading || $app.auth.isAuthInProgressProperty;
  }

  renderRoute = ({ route, module, exact, auth }) => {
    const $app = Application.instance();
  
    const routeComponent = (
      <Route
        key={route}
        path={route}
        exact={exact ?? true}
        children={<AsyncComponent moduleProvider={module} />}
      />
    );
  
    if (auth) {
      if ($app.auth.isLoggedInProperty) {
        return routeComponent;
      } 
      
      if (this.isAuthenticationInProgress) {
        return <Loading key={route} />;
      }

      const loginEvent = 'overlay.activity.login';

      return (
        <Route
          key={route}
          path={route}
          exact={exact ?? true}
          children={(
            <LoginTrigger 
              canInitializeLogin={$app.event.isEventInitialized(loginEvent)}
              eventName={loginEvent}
            />
          )}
        />
      );
    }
  
    return routeComponent;
  }

  renderRouting = item => {
    const moduleComponent = registerModules[item.url]
    if (!moduleComponent) {
      return <React.Fragment key="nothing">{null}</React.Fragment>
    }
    return this.renderRoute(moduleComponent)
  }

  render() {
    return (
      <AnimationContainer animation={true}>
        <Switch>
          {this.routes}
          <Route component={NotFound} />
        </Switch>
      </AnimationContainer>
    )
  }
}

Routes.defaultProps = {
  data: {},
}

Routes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default withRouter(observer(Routes))
