import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { DefaultUserList } from 'shared/constants/grid'
import { GridItemType } from 'shared/models/grid/item-type'
import { ISearchService, SearchResponse } from 'shared/types/services/search'
import { IUsersService } from 'shared/types/services/users'

export class SearchModalViewModel {
  private searchService = inject<ISearchService>(DI_TOKENS.searchService)
  private usersService = inject<IUsersService>(DI_TOKENS.usersService)

  searchContent: ISearchService['searchContent'] = (...args) => {
    return this.searchService.searchContent(...args)
  }

  searchPersons: ISearchService['searchPersons'] = (...args) => {
    return this.searchService.searchPersons(...args)
  }

  searchUsers: ISearchService['searchUsers'] = (...args) => {
    return this.searchService.searchUsers(...args)
  }

  searchAI: ISearchService['searchAI'] = (...args) => {
    return this.searchService.searchAI(...args)
  }

  getUserPreferences = async () => {
    const defaultGrids = await this.usersService.getDefaultGrids();
    const gridHasItems = (gridTitle: DefaultUserList) => {
      return defaultGrids.find((x) => x.asJson.title === gridTitle)?.asJson.items.length > 0;
    }

    const labels = [
      {
        text: 'Seen',
        exists: gridHasItems(DefaultUserList.seen),
      },
      {
        text: 'Favourites',
        exists: gridHasItems(DefaultUserList.favorites),
      },
      {
        text: 'Like',
        exists: gridHasItems(DefaultUserList.like),
      },
    ];

    const existingLabels = labels
      .filter(label => label.exists)
      .map(label => label.text);

    const amount = existingLabels.length;
    let label: string | undefined;

    if (existingLabels.length === 1) {
      label = existingLabels[0];
    }

    if (existingLabels.length > 1) {
      const lastLabel = existingLabels.pop();
      label = `${existingLabels.join(', ')} and ${lastLabel}`;
    }

    return {
      label,
      amount,
    };
  }
}

export type { SearchResponse }
