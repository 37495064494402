import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ shadows }: AppTheme) {
  return appCreateStyles({
    root: {
      position: 'relative',
    },
    poster: {
      width: '100%',
      height: '100%',
      boxShadow: shadows[1],
    },
    selectedIcon: {
      position: 'absolute',
      top: -7.2,
      left: -9.5,
      width: 30,
      height: 30,
    },
  })
}
