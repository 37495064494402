import { appCreateTheme } from 'core/theme/theme'
import { appResponsiveFontSizes } from 'core/theme/utils/font'

const spacing = 4

export default appResponsiveFontSizes(
  // TODO rework since it has wrong structure according to interface
  appCreateTheme({
    palette: {
      background: {
        default: '#2E2E32',
      },
      colors: {
        mainGray: '#2e2e32',
      },
      primary: {
        main: '#B8E986',
        dark: '#75B952',
      },
      secondary: {
        main: '#cdddcc',
      },
      facebook: {
        main: '#EF0FFF',
      },
    },
    shadows: [
      '0 15px 20px 0 rgba(0, 0, 0, 0.25)',
      '0px 10px 20px rgba(0, 0, 0, 0.3)',
    ],
    status: {
      danger: 'orange',
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: 'Mulish',
    },
    custom: {
      borderRadius: {
        primary: 25,
        secondary: 4,
        round: '50%',
      },
      colors: {
        black: {
          100: '#212124',
        },
        green: '#76BA52',
        gray: {
          100: '#C2C2C2',
          200: '#B2B2B2',
          300: '#78787A',
          400: '#DADADA',
          450: '#4F4F51',
          500: '#414144',
          600: '#EBEBEB1A',
        },
        whiteOpacity: {
          1: 'rgba(235, 235, 235, 0.1)',
          2: 'rgba(235, 235, 235, 0.2)',
          3: 'rgba(235, 235, 235, 0.3)',
          4: 'rgba(235, 235, 235, 0.4)',
          5: 'rgba(235, 235, 235, 0.5)',
          6: 'rgba(235, 235, 235, 0.6)',
          7: 'rgba(235, 235, 235, 0.7)',
          8: 'rgba(235, 235, 235, 0.8)',
          9: 'rgba(235, 235, 235, 0.9)',
        },
      },
    },
    props: {
      MuiTooltip: {
        arrow: true,
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          padding: spacing * 2,
          fontSize: 14,
          lineHeight: 1.4,
          wordSpacing: 1.4,
        },
      },
    },
  }),
)
