import { Application, Model, Collection } from 'services'
import { GENRES, SPORTS, PROVIDERS } from 'const/api/onboarding'
import GridDetailsModel from './gridDetails.model'
import GridPersonModel from './gridPerson.model'
import GridMovieModel from './gridMovie.model'
import GridGenreModel from './gridGenre.model'
import GridProviderModel from './gridProvider.model'
import GridDepartmentModel from './gridDepartment.model'
import { GRID_DETAILS } from 'const/api/grids'
import { PromoModel } from 'models'

export default class GridModel extends Model {
  get fillable() {
    return {
      order: 'number',
      type: 'string',
      id: 'string',
    }
  }

  get relations() {
    return {
      listData: {
        class: GridPersonModel,
        type: 'COLLECTION',
      },

      details: {
        class: GridDetailsModel,
        type: 'single',
      },
    }
  }

  /**
   *
   * @param params
   * @returns {Promise<any>}
   */
  static list(endpoint) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.newrequest
        .get(endpoint)
        .then(response => {
          resolve(self.createGridFromResponse(response.data.items))
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get credits (Cast && Crew)
   *
   * @returns {Promise<any>}
   */
  getDetails(page) {
    const app = Application.instance()
    return new Promise((resolve, reject) => {
      const endpoint = `${GRID_DETAILS}/${encodeURIComponent(this.id)}${
        page ? `?page=${page}` : ''
      }`
      app.newrequest
        .get(endpoint)
        .then(response => {
          this.details = response.data
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  update(items) {
    this.details && this.details.update(items)
  }

  updateMovies(items) {
    this.details && this.details.updateMovies(items)
  }

  async getSubGrids() {
    await this.getDetails()
    const grids = this.details.items.map((item, i) =>
      new GridModel().fillFromResponse({ id: item, order: i, type: 'GRID' }),
    )

    const promises = grids.map(async grid => await grid.getDetails())
    await Promise.all(promises)

    return new Collection(grids)
  }

  getItems(items) {
    const path = {
      PERSON: `persons/list`,
      CONTENT: `contents/list`,
      SPORT_GENRE: `genres`,
      PROVIDER: `providers/list`,
      GENRE: `genres/list`,
      DEPARTMENT: `departments/list`,
      PROMO: `contents/promo/list`,
    }
    const customPath = path[this.details.itemType]
    const app = Application.instance()

    return app.newrequest
      .get(customPath, {
        params: {
          ids: items.slice(0, 100).join(),
        },
      })
      .then(response => {
        const array = []
        response.data.forEach(item => {
          const newModel = this.getItemModelByType(
            this.details.itemType,
          ).fillFromResponse(item)
          array.push(newModel)
        })
        return new Collection(array)
      })
      .catch(console.error)
  }

  static getContentItems(items = []) {
    const app = Application.instance()

    return app.newrequest
      .get(`contents/list`, {
        params: {
          ids: items.join(),
        },
      })
      .then(response => {
        const array = []
        response.data.forEach(item => {
          const newModel = new GridMovieModel().fillFromResponse(item)
          array.push(newModel)
        })
        return new Collection(array)
      })
      .catch(console.error)
  }

  getItemModelByType(type) {
    if (type === 'GRID') {
      return new GridModel()
    }
    if (type === 'PERSON') {
      return new GridPersonModel()
    }
    if (type === 'CONTENT') {
      return new GridMovieModel()
    }
    if (type === 'GENRE') {
      return new GridGenreModel()
    }
    //sport  genre?
    if (type === 'PROVIDER') {
      return new GridProviderModel()
    }
    if (type === 'DEPARTMENT') {
      return new GridDepartmentModel()
    }
    if (type === 'PROMO') {
      return new PromoModel()
    }
    //deparment
    return new GridPersonModel()
  }

  static getGenres() {
    const app = Application.instance()
    return new Promise((resolve, reject) => {
      const endpoint = `${GENRES}`
      app.newrequest
        .get(endpoint)
        .then(response => {
          resolve(new Collection(response.data))
        })
        .catch(error => reject(error))
    })
  }

  static getSports() {
    const app = Application.instance()
    return new Promise((resolve, reject) => {
      const endpoint = `${SPORTS}`
      app.newrequest
        .get(endpoint)
        .then(response => {
          resolve(new Collection(response.data))
        })
        .catch(error => reject(error))
    })
  }

  static getProviders() {
    const app = Application.instance()
    return new Promise((resolve, reject) => {
      const endpoint = `${PROVIDERS}`
      app.newrequest
        .get(endpoint)
        .then(response => {
          resolve(new Collection(response.data))
        })
        .catch(error => reject(error))
    })
  }
}
