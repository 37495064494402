import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, custom }: AppTheme) {
  return appCreateStyles({
    root: {
      padding: spacing(0, 6),
    },
    heading: {
      fontSize: 24,
      fontWeight: 700,
      color: custom.colors.gray[200],
      marginBottom: spacing(2),
    },
    subheading: {
      fontSize: 16,
      color: custom.colors.gray[200],
    },
  })
}
