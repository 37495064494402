import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ custom, palette, spacing }: AppTheme) {
  return appCreateStyles({
    root: {},
    container: {
      cursor: 'pointer',
      color: palette.common.white,
      fontWeight: 700,
      padding: spacing(3, 4),
      backgroundColor: appFade(palette.common.white, 0.1),
      borderRadius: custom.borderRadius.secondary,
    },
    containerSelected: {
      backgroundColor: appFade(palette.primary.dark, 0.4),
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      borderRadius: custom.borderRadius.secondary,
      width: 48,
      height: 24,
      marginRight: spacing(2),
    },
    name: {},
    checkIcon: {
      '& > path': {
        fill: palette.primary.main,
      },
    },
  })
}
