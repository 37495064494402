import React from 'react'
import Grid from '@material-ui/core/Grid'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Item } from './components/item'
import { UserListQuery } from 'shared/models/user/list-model'

import { styles } from './users.styles'

export type UsersProps = AppWithStyles<typeof styles> & {
  selectedItems: Array<UserListQuery['id']>
  data: Array<UserListQuery>
  onItemChange: (id: UserListQuery['id']) => void
}

const UsersComponent: React.FC<UsersProps> = ({
  classes,
  selectedItems,
  data,
  onItemChange,
}) => {
  return (
    <Grid container spacing={4} classes={{ root: classes.root }}>
      {data.map(({ id, fullName, avatar }) => {
        return (
          <Item
            key={id}
            selected={selectedItems.includes(id)}
            id={id}
            avatar={avatar}
            fullName={fullName}
            classes={{ container: classes.item }}
            onChange={onItemChange}
          />
        )
      })}
    </Grid>
  )
}

export const Users = appWithStyles(styles)(UsersComponent)
