import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = ({ spacing }: AppTheme) => {
  return appCreateStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: spacing(6, 6, 4, 6),

      '& > div': {
        marginRight: 20,
        marginBottom: 20,
        '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
          {
            marginRight: 5,
            marginBottom: 5,
          },
      },
    },
    arrowIcon: {
      transform: 'rotate(90deg)',
    },
    mobileSorting: {
      display: 'flex',
      borderTop: '1px solid #5A5A5A',
      padding: spacing(2),
    },
  })
}
