import { inject } from 'core/di/di-utils'
import { Application, Collection, Url } from '.'
import { DI_TOKENS } from 'shared/constants/di'

export default class Paginator {
  configService = inject(DI_TOKENS.configService)

  constructor(data = {}) {
    const collection = new Collection(data.pagination || data)
    this.url = collection.get('url', new Url())
    this.pivot = collection.get('pivot', null)
    this.total = collection.get('total', 0)
    this.limit = collection.get('limit', 50)
    this.offset = collection.get('offset', 0)
    this.current_page = collection.get('current_page', 1)
    this.offset_limit = 2000
  }

  getTotal() {
    return this.total
  }

  getCurrentPage() {
    return this.current_page
  }

  getNextPage() {
    this.offset += this.limit
    return this.current_page + 1
  }

  getPrevPage() {
    this.offset -= this.limit
    return this.current_page - 1
  }

  getNextPageUrl() {
    return `${this.configService.get().apiUrl}${this.url.pathname}?limit=${this.limit}&offset=${this.offset}`
  }

  setUrl(url) {
    this.url = new Url(url)
    return this
  }

  setPivot(pivot) {
    this.pivot = pivot
    return this
  }

  next() {
    this.offset += this.limit

    const app = Application.instance()
    const url = new Url(this.getNextPageUrl())
    const query = new Url()
      .setQuery({ ...this.url.query.data, ...url.query.data })
      .queryToString()
    const endpoint = `${this.url.pathname}${query}`

    return new Promise((resolve, reject) => {
      app.request
        .get(endpoint)
        .then(response => {
          response.data.results.length &&
            resolve(
              this.pivot.constructor.createFromResponse(
                response.data,
                endpoint,
              ),
            )
        })
        .catch(error => reject(error))
    })
  }
}
