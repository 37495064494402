import moment from 'moment'

export const formatTime = (data: Date) => {
  const currentTime = new Date()
  const utcDate = moment.utc(data).toDate()
  const localDate = moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss')
  const hours = moment(currentTime).diff(moment(localDate).local(), 'h')

  if (hours < 1) {
    const minutes = moment(currentTime).diff(localDate, 'm')

    return `${minutes} minutes ago`
  }

  if (hours >= 24 && hours < 730) {
    const days = moment(currentTime).diff(localDate, 'd')

    return `${days} days ago`
  }

  if (hours > 730) {
    const months = moment(currentTime).diff(localDate, 'M')

    return `${months} months ago`
  }

  return `${hours} hours ago`
}

const addZero = (num: number) => {
  return num < 10 ? '0' + num : num
}

export const getDDMMYYYYHHMMTimeFormat = (date: string) => {
  // e.g. 02/06/2020 5:09
  const d = new Date(date)

  return `${addZero(d.getDate())}/${addZero(
    d.getMonth() + 1,
  )}/${d.getFullYear()} ${addZero(d.getHours())}:${addZero(d.getMinutes())}`
}
