import { BaseModel } from 'core/models/base'
import { GenreSort } from './genre.types'
import { GenreGetQuery } from './genre-get-model'

export interface GenreGetListQuery {
  content: GenreGetQuery[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: string
  size: number
  sort: GenreSort
  totalElements: number
  totalPages: number
}

export class GenreGetListModel extends BaseModel<GenreGetListQuery> {
  private content: GenreGetQuery[]
  private empty: boolean
  private first: boolean
  private last: boolean
  private number: number
  private numberOfElements: number
  private pageable: string
  private size: number
  private sort: GenreSort
  private totalElements: number
  private totalPages: number

  constructor(data: GenreGetListQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): GenreGetListQuery {
    return {
      content: this.content,
      empty: this.empty,
      first: this.first,
      last: this.last,
      number: this.number,
      numberOfElements: this.numberOfElements,
      pageable: this.pageable,
      size: this.size,
      sort: this.sort,
      totalElements: this.totalElements,
      totalPages: this.totalPages,
    }
  }
}
