import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing }: AppTheme) {
  return appCreateStyles({
    root: {},
    content: {
      marginTop: spacing(7),
      width: '100%',
    },
    item: {},
  })
}
