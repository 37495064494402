import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'
import { CONTENT_PADDING, COUNTER_HEIGHT } from './search-modal.content'

const searchInputHorizontalMargin = 24
const searchInputBottomMargin = 32
const searchInputTopMargin = 24
const searchInputHeight = 56
const tabsHeight = 50

export function styles({ palette, spacing, custom }: AppTheme) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: '100%',
      minWidth: 320,
      zIndex: 3,
      backdropFilter: 'blur(16px)',
      padding: spacing(12, 50),
      backgroundColor: appFade(palette.common.black, 0.6),
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          padding: spacing(1, 1),
        },
      '@media only screen and (orientation: landscape) and (max-height: 480px)':
        {
          padding: spacing(12, 2),
        },
    },
    body: {
      backgroundColor: appFade(custom.colors.gray[500], 0.9),
      height: '100%',
      width: '100%',
      borderRadius: 20,
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          marginTop: '35px',
          height: '90%',
        },
      '@media only screen and (orientation: landscape) and (max-height: 480px)':
        {
          height: '90vh',
          width: '85vw',
          overflow: 'auto',
        },
    },
    closeIconWrapper: {
      width: 40,
      height: 40,
      position: 'absolute',
      zIndex: 2,
      right: 132,
      top: 12,
      backgroundColor: appFade(custom.colors.gray[500], 0.9),
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          right: 2,
          top: 10,
        },
      '@media only screen  and (max-height: 550px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          top: 2,
        },
      '@media only screen and (orientation: landscape) and (max-height: 480px)':
        {
          right: 2,
        },
      '&:hover': {
        backgroundColor: appFade(custom.colors.gray[500], 0.6),
      },
    },
    closeIcon: {
      color: custom.colors.gray[400],
    },
    searchWrapper: {
      position: 'relative',
      paddingTop: spacing(4),
    },
    selectedTemplateWrapper: {
      height: 24,
      position: 'absolute',
      top: -24,
      left: 42,
      zIndex: 100,
      background: custom.colors.gray[600],
      borderRadius: '4px 4px 0px 0px',
      padding: '4px 8px',
      display: 'inline-flex',
      marginRight: 10,
      alignItems: 'center',
    },
    selectedTemplateWrapperAi: {
      background: palette.primary.dark,

      '& $selectedTemplate': {
        color: palette.common.white,
      },
    },
    selectedTemplate: {
      fontSize: 14,
      lineHeight: '16px',
      color: '#B2B1B2',
      fontWeight: 400,
    },
    searchInput: {
      minHeight: searchInputHeight,
      width: `calc(100% - ${searchInputHorizontalMargin * 2}px)`,
      margin: `${searchInputTopMargin}px ${searchInputHorizontalMargin}px ${searchInputBottomMargin}px`,
      zIndex: 11,
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          margin: `${searchInputTopMargin}px ${searchInputHorizontalMargin}px 24px`,
        },
    },
    searchInputBase: {
      borderRadius: 36,
      borderBottom: 'none',
    },
    searchIcon: {
      marginRight: spacing(2),
      fill: custom.colors.gray[200],
    },
    searchHint: {
      color: appFade(palette.common.white, 0.4),
      position: 'absolute',
      right: 28,
      top: 102,
      fontSize: 12,
    },
    tabs: {
      height: tabsHeight,
    },
    content: {
      height: `calc(100% - ${
        searchInputHeight +
        searchInputTopMargin +
        searchInputBottomMargin +
        tabsHeight
      }px)`,
      width: '100%',
      padding: `${CONTENT_PADDING}px ${CONTENT_PADDING}px 0px`,
      overflow: 'hidden',
    },
    content2: {
      height: `calc(100% - ${
        searchInputHeight + searchInputTopMargin + searchInputBottomMargin
      }px)`,
      width: '100%',
      padding: `${CONTENT_PADDING}px 0 0px`,
      overflow: 'hidden',
    },
    emptyState: {
      height: '70%',
    },
    emptyStateIcon: {
      fill: '#929293',
      width: 70,
      height: 70,
    },
    sectionDivider: {
      backgroundColor: '#5A5A5A',
    },
    sectionLabelDiver: {
      color: '#5A5A5A',
      fontSize: 14,
      margin: spacing(5, 0, 4)
    },
    aiCategories: {
      margin: spacing(0, 4),
    },
    counter: {
      height: COUNTER_HEIGHT,
      fontSize: 12,
      color: palette.primary.main,
    },
    summary: {
      width: 'calc(100% - 50px)',
      marginLeft: spacing(7.5),
      fontSize: 13,
      color: 'rgba(245, 245, 245, 0.4)',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      padding: spacing(2),
      borderRadius: 8,
    },
    personalizationLink: {
      color: palette.primary.dark,
      textDecoration: 'underline',
    },
    hidePersonalizationBtn: {
      color: palette.primary.dark,
      height: 24,
    },
    titleAi: {
      color: 'rgba(245, 245, 245, 0.4)',
      fontSize: 13,
      fontWeight: 700,
      marginLeft: spacing(10),
      marginBottom: spacing(1.5),
    },
  })
}
