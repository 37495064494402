import { BaseModel } from 'core/models/base'
import { ProviderPrice } from './price'

export interface ProviderGetQuery {
  id: string
  currency: string
  images: Array<{ url: string }>
  liked: boolean
  minPrice: ProviderPrice
  name: string
  prices: Array<ProviderPrice>
  promo: boolean
}

export class ProviderGetModel extends BaseModel<ProviderGetQuery> {
  private id: string
  private currency: string
  private images: Array<{ url: string }>
  private liked: boolean
  private minPrice: ProviderPrice
  private name: string
  private prices: Array<ProviderPrice>
  private promo: boolean

  constructor(data: ProviderGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): ProviderGetQuery {
    return {
      id: this.id,
      currency: this.currency,
      images: this.images,
      liked: this.liked,
      minPrice: this.minPrice,
      name: this.name,
      prices: this.prices,
      promo: this.promo,
    }
  }
}
