import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, palette, custom }: AppTheme) {
  return appCreateStyles({
    root: {},
    step: {
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(3, 3),
      borderBottom: '2px solid #5A5A5A',
    },
    stepActive: {
      borderColor: palette.primary.main,
    },
    label: {
      fontWeight: 700,
      fontSize: 16,
      color: custom.colors.gray[200],
    },
    labelActive: {
      color: palette.primary.main,
    },
  })
}
