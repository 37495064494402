import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { DepartmentGetQuery } from 'shared/models/department/get-model'
import { GenreGetQuery } from 'shared/models/genre/genre-get-model'
import { GridRequestType } from 'shared/models/grid/request-type'
import { GridType } from 'shared/models/grid/type'
import { IDepartmentsService } from 'shared/types/services/departments'
import { IGenresService } from 'shared/types/services/genres'
import { IOnboardingService } from 'shared/types/services/onboarding'

export class SecondStepViewModel {
  private genresService = inject<IGenresService>(DI_TOKENS.genresService)
  private departmentsService = inject<IDepartmentsService>(
    DI_TOKENS.departmentsService,
  )
  private onboardingService = inject<IOnboardingService>(
    DI_TOKENS.onboardingService,
  )

  getGenres: IGenresService['getList'] = (...args) => {
    return this.genresService.getList(...args)
  }

  getTeams: IDepartmentsService['getList'] = (...args) => {
    return this.departmentsService.getList(...args)
  }

  updateList = async (
    skip: boolean,
    selectedItems: {
      teams: Array<DepartmentGetQuery['id']>
      sports: Array<GenreGetQuery['id']>
    },
  ) => {
    try {
      await this.onboardingService.updateList(
        GridRequestType.like,
        GridType.team,
        skip ? [] : selectedItems.teams,
      )
      await this.onboardingService.updateList(
        GridRequestType.like,
        GridType.sport,
        skip ? [] : selectedItems.sports,
      )
    } catch (err) {
      throw err
    }
  }
}
