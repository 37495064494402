export const abbreviateNumber = (num: number) => {
  let value = Math.abs(Number(num))
  let prefix = ''

  if (value >= 1.0e9) {
    value /= 1.0e9
    prefix = 'B'
  }

  if (value >= 1.0e6) {
    value /= 1.0e6
    prefix = 'M'
  }

  if (value >= 1.0e3) {
    value /= 1.0e3
    prefix = 'k'
  }

  return `${value.toString().length > 3 ? value.toFixed(1) : value}${prefix}`
}
