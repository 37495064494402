import React, { useCallback } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { ContentGetQuery } from 'shared/models/content/get-model'
import { Carousel } from 'shared/components/carousel'
import { CONTENT_LIST_FIELDS } from 'shared/constants/content'
import { Item } from './components/item'
import { SectionTitle } from '../../../section-title'

import { styles } from './content.styles'

export type ContentProps = AppWithStyles<typeof styles> & {
  selectedItems: Array<ContentGetQuery['id']>
  requiredItemsAmount: number
  data: Array<ContentGetQuery>
  onItemChange: (id: ContentGetQuery['id']) => void
}

const ContentComponent: React.FC<ContentProps> = ({
  classes,
  selectedItems,
  requiredItemsAmount,
  data,
  onItemChange,
}) => {
  const renderItem = useCallback(
    (item: ContentGetQuery) => {
      return (
        <Item
          selected={selectedItems.includes(item.id)}
          id={item.id}
          images={item.images}
          classes={{ root: classes.item }}
          onChange={onItemChange}
        />
      )
    },
    [selectedItems, classes, onItemChange],
  )

  return (
    <Carousel
      title={
        <SectionTitle
          title="Movies & TV Shows"
          hint={`Select at least ${requiredItemsAmount} items`}
          counter={selectedItems.length}
        />
      }
      data={data}
      itemDimensions={{
        width: 98,
        height: 148,
      }}
      idProperty={CONTENT_LIST_FIELDS.id}
      renderItem={renderItem}
      classes={{ root: classes.root }}
    />
  )
}

export const Content = appWithStyles(styles)(ContentComponent)
