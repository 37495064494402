export const CONTENT_PADDING = 12
export const COUNTER_HEIGHT = 40
export const AI_MODAL_FOOTER_HEIGHT = 64;
export const AI_MOBILE_MODAL_FOOTER_HEIGHT = 80;
export const AI_SCROLLBAR_WIDTH = 10;
export const SUMMARY_MARGIN_BOTTOM = 16;

export interface ISearchAiTemplate {
  text: string,
  width: number,
  hint: string | null,
  value: string,
}

export const similarToAiTemplate: ISearchAiTemplate = { text: 'Similar to ...', width: 102, hint: 'e.g. Star Wars', value: 'similar to' };

export const templatesList: ISearchAiTemplate[] = [
  similarToAiTemplate,
  { text: 'About ...', width: 78, hint: 'e.g. car racing', value: 'about' },
  { text: 'With ...', width: 68, hint: 'e.g. Margot Robbie', value: 'with' },
  {
    text: 'Where ...',
    width: 80,
    hint: 'e.g. a boy becomes a superhero',
    value: 'where',
  },
  { text: 'In Genre ...', width: 58, hint: 'e.g. Science Fiction', value: 'in genre' },
  { text: 'For ...', width: 58, hint: 'e.g. a family movie night', value: 'for' },
  {
    text: 'Something else',
    width: 36,
    hint: 'Tell us more',
    value: '',
  },
];
