import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { IAnnouncementsService } from 'shared/types/services/notifications'
import { Application } from 'services'
import { AnnouncementGetListModel } from '../models/announcement/announcements-get-list'

@injectable()
export class AnnouncementsService
  extends BaseService
  implements IAnnouncementsService
{
  private app = Application.instance()

  async getList(): Promise<AnnouncementGetListModel[]> {
    const { data } = await this.app.newrequest.get(`/announcements`)

    return data.map(item => new AnnouncementGetListModel(item))
  }

  async markAllAsRead(): Promise<void> {
    await this.app.newrequest.post(`/announcements`)
  }
}
