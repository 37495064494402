import React, { Suspense, lazy } from 'react'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import theme from 'theme'

import { AppThemeProvider } from 'core/theme/provider'
import App from './views/App'
import { HISTORY_INSTANCE } from 'configs/router.config'

const Notify = lazy(() => import('components/Notify'))
const Confirmation = lazy(
  () => import('shared/components/confirmation/confirmation'),
)
const Notification = lazy(
  () => import('shared/components/notification/notification'),
)
const Overlay = lazy(() => import('components/Overlay'))
const CompleteUserRegistration = lazy(
  () =>
    import('views/App/complete-user-registration/complete-user-registration'),
)
const LoginView = lazy(() => import('views/App/LoginView/LoginView'))
const RestorePasswordView = lazy(() => import('views/App/RestorePasswordView'))
const RestorePasswordConfirmView = lazy(
  () => import('views/App/RestorePasswordConfirmView'),
)

export const renderFunc = app => (
  <Suspense fallback={<div />}>
    <Router history={HISTORY_INSTANCE}>
      <AppThemeProvider theme={theme}>
        <Notification />
        <Confirmation />
        <ToastContainer {...app.notify.configs} />
        <Overlay type="login">
          <LoginView />
        </Overlay>
        <Overlay type="restorepassword">
          <RestorePasswordView />
        </Overlay>
        <Overlay type="restorepasswordconfirm">
          <RestorePasswordConfirmView />
        </Overlay>
        <Overlay type="completeuserregistration" showCloseIcon={false}>
          <CompleteUserRegistration />
        </Overlay>
        <Notify />
        <App />
      </AppThemeProvider>
    </Router>
  </Suspense>
)
