import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = ({ custom, spacing, palette }: AppTheme) =>
  appCreateStyles({
    container: {
      position: 'absolute',
      top: 48,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      backgroundColor: custom.colors.gray[450],
      padding: spacing(5, 5, 3, 5),
      borderRadius: 20,
      zIndex: 2,
    },
    list: {
      display: 'flex',
      gap: spacing(4),
      flexDirection: 'column',
      width: '100%',
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: palette.common.white,
      opacity: '0.6',
      fontSize: 14,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      transition: '.1s',
      height: 22,
      overflow: 'hidden',

      '&:hover': {
        opacity: 1,
      },
    },
    active: {
      opacity: 1,
    },
    icon: {
      color: palette.primary.main,
      fontWeight: 'bold',
    },
  })
