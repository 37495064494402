import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles() {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    item: {
      width: 94,
      height: 106,
    },
  })
}
