import { BaseModel } from 'core/models/base'
import { ContentType } from '../content/content-type'
import { Image } from '../image/model'

export interface DepartmentGetQuery {
  contentType: ContentType
  genre: string
  id: string
  images: Array<Image>
  name: string
}

export class DepartmentGetModel extends BaseModel<DepartmentGetQuery> {
  private contentType: ContentType
  private genre: string
  private id: string
  private images: Array<Image>
  private name: string

  constructor(data: DepartmentGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): DepartmentGetQuery {
    return {
      id: this.id,
      images: this.images,
      contentType: this.contentType,
      name: this.name,
      genre: this.genre,
    }
  }
}
