import React from 'react'

export const LiveIcon = props => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Combined-Shape"
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4505 2.65075L13.6364 4.76136V2.377C13.6364 1.33861 12.8225 0.5 11.8185 0.5H1.81785C0.812179 0.5 0 1.34036 0 2.377V13.623C0 14.6614 0.81388 15.5 1.81785 15.5H11.8185C12.8242 15.5 13.6364 14.6596 13.6364 13.623V11.2386L16.4505 13.3493C16.6518 13.5002 17.0223 13.625 17.2741 13.625H19.0882C19.597 13.625 20 13.2042 20 12.6852V3.31482C20 2.79548 19.5918 2.375 19.0882 2.375H17.2741C17.0172 2.375 16.6536 2.49846 16.4505 2.65075ZM1.81818 13.625V2.375H11.8182V13.625H1.81818ZM3.63636 10.8125C3.63636 10.2947 4.04412 9.875 4.54812 9.875H9.08825C9.59179 9.875 10 10.2911 10 10.8125C10 11.3303 9.59225 11.75 9.08825 11.75H4.54812C4.04457 11.75 3.63636 11.3339 3.63636 10.8125ZM17.4154 11.7499L13.6474 8.92847L13.6476 7.07135L17.4128 4.24745L18.1818 4.24863V11.7491L17.4154 11.7499Z"
        fill="white"
      />
    </svg>
  )
}
