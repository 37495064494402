import { Model } from 'services'
import { processContentImages } from 'shared/utils/image'

export default class GridMovieModel extends Model {
  get fillable() {
    return {
      adult: 'boolean',
      budget: 'string',
      description: 'string',
      genres: 'array',
      id: 'string',
      imageUrls: 'array',
      releaseDate: 'string',
      revenue: 'string',
      title: 'string',
      type: 'string',
      images: 'array',
      contentType: 'string',
      hideTitle: 'boolean',
      hideItemsTitle: 'boolean',
    }
  }

  getType() {
    return this.contentType
  }

  getImage() {
    return processContentImages(this.images)?.POSTER?.url.large
  }

  getLink() {
    return `/content/${encodeURIComponent(this.id)}`
  }
}
