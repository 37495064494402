import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { FooterGridModel } from 'models'

configure({ enforceActions: 'observed' })
export default class FooterStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      collection: new Collection(),
    })
    this.model = FooterGridModel
  }

  getCollection() {
    FooterGridModel.list('grids/footer').then(
      action(collection => {
        this.collection = collection
      }),
    )
  }
}

decorate(FooterStore, {
  setCollection: action,
})
