import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ shape, palette, spacing }: AppTheme) {
  return appCreateStyles({
    root: {
      cursor: 'pointer',
      padding: spacing(2),
      borderRadius: shape.borderRadius,
    },
    rootSelected: {
      backgroundColor: appFade(palette.primary.dark, 0.4),
    },
    icon: {
      maxWidth: 80,
      maxHeight: 50,
      marginBottom: spacing(3),
    },
    name: {
      color: palette.common.white,
      fontWeight: 700,
    },
  })
}
