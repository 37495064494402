import {
  ContentTypeFilter,
  DescendingSort,
  GlobalFilters,
  SelectListItem,
  ServicesFilter,
  SortByFilter,
  YearFilter,
} from './global-filter.types'

export const DEFAULT_YEARS_FILTER: Readonly<YearFilter> = {
  yearFrom: 1874,
  yearTo: new Date().getFullYear(),
}

export const CONTENT_TYPE_DEFAULT_SELECT_LIST: SelectListItem[] = [
  {
    name: ContentTypeFilter.MOVIES,
    value: ContentTypeFilter.MOVIES,
  },
  {
    name: ContentTypeFilter.TV_SHOWS,
    value: ContentTypeFilter.TV_SHOWS,
  },
  {
    name: ContentTypeFilter.SPORTS,
    value: ContentTypeFilter.SPORTS,
  },
]

export const SERVICE_DEFAULT_SELECT_LIST: SelectListItem[] = [
  {
    name: ServicesFilter.ALL_SERVICES,
    value: ServicesFilter.ALL_SERVICES,
  },
  {
    name: ServicesFilter.MY_SERVICES,
    value: ServicesFilter.MY_SERVICES,
  },
]

export const SORT_BY_DEFAULT_SELECT_LIST: SelectListItem[] = [
  {
    name: SortByFilter.POPULARITY,
    value: SortByFilter.POPULARITY,
  },
  {
    name: SortByFilter.YEAR,
    value: SortByFilter.YEAR,
  },
  {
    name: SortByFilter.ALPHABETICALLY,
    value: SortByFilter.ALPHABETICALLY,
  },
]

export const DESCENDING_DEFAULT_SELECT_LIST: SelectListItem[] = [
  {
    name: DescendingSort.ASC,
    value: DescendingSort.ASC,
  },
  {
    name: DescendingSort.DESC,
    value: DescendingSort.DESC,
  },
]

export const DEFAULT_GLOBAL_FILTERS: GlobalFilters = {
  contentType: null,
  genres: [],
  departments: [],
  service: null,
  sortBy: SortByFilter.POPULARITY,
  descending: DescendingSort.DESC,
  yearsFilter: {
    yearFrom: null,
    yearTo: null,
  },
}
