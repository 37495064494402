import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { IDepartmentsService } from 'shared/types/services/departments'
import { DepartmentGetModel } from 'shared/models/department/get-model'

@injectable()
export class DepartmentsService
  extends BaseService
  implements IDepartmentsService
{
  private app = Application.instance()

  protected urlPrefix = '/departments'

  getList: IDepartmentsService['getList'] = async (id, page) => {
    const {
      data: { items },
    } = await this.app.newrequest.get(`/grids/${encodeURIComponent(id)}`, {
      params: {
        page,
      },
    })

    const { data } = await this.app.newrequest.post(this.getUrl('list'), items)

    return data.map(item => new DepartmentGetModel(item))
  }

  getDepartmentList = async () => {
    const response = await this.app.newrequest.get('/departments?size=1000')

    return response.data.content.map(item => new DepartmentGetModel(item))
  }
}
