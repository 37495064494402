import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, custom }: AppTheme) {
  return appCreateStyles({
    root: {
      padding: spacing(3, 0),
      minHeight: 100,
    },
    iconWrapper: {},
    heading: {
      fontSize: 20,
      fontWeight: 600,
      textAlign: 'center',
      color: custom.colors.gray[200],
    },
    subheading: {
      margin: 0,
      fontSize: 16,
      fontWeight: 600,
      textAlign: 'center',
      color: custom.colors.gray[300],
    },
    button: {
      marginTop: spacing(3),
    },
  })
}
