import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ palette, spacing, custom }) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: '100%',
      zIndex: 5,
      background: 'rgba(33, 33, 36, 0.3)',
      backdropFilter: 'blur(4px)',
    },
    verticalWrapper: {
      width: '328px',
      height: '492px',
      position: 'relative',
      '@media only screen and (orientation: landscape) and (max-height: 480px)':
        {
          width: '200px',
          height: '300px',
        },
    },
    horizontalWrapper: {
      width: '492px',
      height: '328px',
      position: 'relative',
    },
    image: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
    },
    link: {
      textDecoration: 'none',
    },
    bannerText: {
      width: '100%',
      color: palette.common.white,
      fontSize: '16px',
      fontWeight: 700,
      marginBottom: spacing(4),
      textAlign: 'center',
    },
    footer: {
      position: 'absolute',
      bottom: 32,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      width: '146px',
      color: palette.common.white,
      background: '#75B952',
    },
    closeIconWrapper: {
      width: 40,
      height: 40,
      position: 'absolute',
      zIndex: 2,
      right: -42,
      top: -45,
      backgroundColor: appFade(custom.colors.gray[500], 0.9),
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          right: 2,
        },
      '@media only screen  and (max-height: 550px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          top: 2,
        },

      '&:hover': {
        backgroundColor: appFade(custom.colors.gray[500], 0.6),
      },
    },
    horizontalCloseIconWrapper: {
      width: 40,
      height: 40,
      position: 'absolute',
      zIndex: 2,
      right: -42,
      top: -45,
      backgroundColor: appFade(custom.colors.gray[500], 0.9),
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          right: 2,
        },

      '&:hover': {
        backgroundColor: appFade(custom.colors.gray[500], 0.6),
      },
    },
    closeIcon: {
      color: custom.colors.gray[400],
    },
  })
}
