import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { processContentImages } from 'shared/utils/image'
import { SelectedMarkRoundedIcon } from 'shared/icons/selected-mark-rounded'
import { Avatar } from 'shared/components/avatar'
import { Ellipsis } from 'shared/components/ellipsis'
import { PersonGetQuery } from 'shared/models/person/get-model'
import { Flex } from 'shared/components/flex'

import { styles } from './item.styles'

export type ItemProps = AppWithStyles<typeof styles> &
  Required<Pick<PersonGetQuery, 'id' | 'images' | 'name'>> & {
    selected?: boolean
    onChange: (id: PersonGetQuery['id']) => void
  }

const ItemComponent: React.FC<ItemProps> = ({
  classes,
  id,
  images,
  name,
  selected,
  onChange,
}) => {
  const image = useMemo(() => {
    return processContentImages(images)?.PROFILE?.url?.large
  }, [images])

  const handleChange = useCallback(() => {
    onChange(id)
  }, [id, onChange])

  return (
    <Flex
      autoWidth={false}
      direction="column"
      role="button"
      alignItems="center"
      justify="center"
      className={cx(classes.root, { [classes.rootSelected]: selected })}
      onClick={handleChange}
    >
      {selected && (
        <SelectedMarkRoundedIcon classes={{ root: classes.selectedIcon }} />
      )}
      <Avatar src={image} classes={{ root: classes.avatar }} />
      <Ellipsis maxWidth="100%" text={name} classes={{ root: classes.name }} />
    </Flex>
  )
}

export const Item = appWithStyles(styles)(React.memo(ItemComponent))
