import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import { Application } from '.'

const HEADER_KEY = 'authorization'

export default class RequestService {
  constructor(configs = {}) {
    this.configs = { ...this.defaults, ...configs }
    this.driver = axios.create(this.configs)
    this.errorsHandler()
  }

  /**
   * Returns default configurations
   *
   * @returns {Object}
   */
  get defaults() {
    return {
      baseURL: window.location.origin,
      headers: {
        'Cache-Control': 'no-cache',
        'X-Requested-With': 'XMLHttpRequest',
      },
      responseType: 'json',
      maxContentLength: 6000,
      adapter: throttleAdapterEnhancer(
        cacheAdapterEnhancer(axios.defaults.adapter, {
          enabledByDefault: false,
        }),
        {
          threshold: 300,
        },
      ),
    }
  }

  /**
   * Handler of errors
   */
  errorsHandler() {
    const app = Application.instance()
    this.driver.interceptors.response.use(
      response => response,
      error => {
        const status = error.response ? error.response.status : null
        if (app.auth.isLoggedIn() && status === 401) {
          return app.auth
            .check()
            .then(() => {
              error.config.headers[HEADER_KEY] = `Bearer ${app.auth.token}`
              return this.request(error.config)
            })
            .catch(e => {
              console.error(e)
            })
        }
        return Promise.reject(error)
      },
    )
  }

  /**
   * Set headers
   * @param headers
   */
  setHeaders(headers) {
    if (headers) {
      Object.keys(headers).forEach(key => {
        const header = headers[key]
        this.addHeader(key, header)
      })
    }
  }

  /**
   * Set header
   *
   * @param key
   * @param value
   *
   * @return this
   */
  addHeader(key, value) {
    this.driver.defaults.headers.common[key] = value
    return this
  }

  /**
   * Request
   *
   * @param config
   * @returns {*}
   */
  request(config) {
    return this.driver.request(config)
  }

  /**
   * Get request
   *
   * @param url
   * @param config
   */
  get(url, config) {
    return this.driver.get(url, config)
  }

  /**
   * Delete request
   *
   * @param url
   * @param config
   * @returns {*}
   */
  delete(url, config) {
    return this.driver.delete(url, config)
  }

  /**
   * Head request
   *
   * @param url
   * @param config
   * @returns {*}
   */
  head(url, config) {
    return this.driver.head(url, config)
  }

  /**
   * Options request
   *
   * @param url
   * @param config
   */
  options(url, config) {
    return this.driver.options(url, config)
  }

  /**
   * Post request
   *
   * @param url
   * @param data
   * @param config
   * @returns {*}
   */
  post(url, data, config) {
    return this.driver.post(url, data, config)
  }

  /**
   * Put request
   *
   * @param url
   * @param data
   * @param config
   * @returns {*}
   */
  put(url, data, config) {
    return this.driver.put(url, data, config)
  }

  /**
   * Path request
   *
   * @param url
   * @param data
   * @param config
   * @returns {*}
   */
  patch(url, data, config) {
    return this.driver.patch(url, data, config)
  }
}
