import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

import {COOKIES_CONSENT_KEY, CookiesConsentResult} from "./analytics.service";
import { inject } from 'core/di/di-utils';
import { DI_TOKENS } from 'shared/constants/di';
import { IConfigService } from 'core/types/services/config';

type FirebaseConfig = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

export class Firebase {
  static init() {
    try {
      const app = initializeApp(this.getConfig())
      if (process.env.NODE_ENV === 'production') {
        getAnalytics(app)
        app.automaticDataCollectionEnabled = localStorage.getItem(COOKIES_CONSENT_KEY) === CookiesConsentResult.ACCEPTED
      } else {
        app.automaticDataCollectionEnabled = false
      }
    } catch (e) {
      console.error(e)
    }
  }

  private static getConfig(): FirebaseConfig {
    const configService = inject<IConfigService>(DI_TOKENS.configService);
    
    return configService.get().firebase;
  }
}
