import { configure, decorate, extendObservable, action, observable } from 'mobx'
import { Application, Collection } from 'services'
import { ListModel } from 'models'
import { LIST } from 'const/api/user'
import { SHOW, EPISODE } from 'const/mediaTypes'

configure({ enforceActions: 'observed' })
export default class ListStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      listCollection: new Collection(),
      allListsItems: {},
    })
    this.model = ListModel
  }

  generateHashMapListItems = () => {
    this.listCollection.forEach(list => {
      !list.items && list.getListItemsContent()
      list.getListItems().then(
        action(res => {
          this.allListsItems[list.id] = {}
          res.results.forEach(item => {
            this.allListsItems[list.id][item.id] = {
              content: item.content,
            }
          })
        }),
      )
    })
  }

  addTitleToList({ model, listId }) {
    const app = Application.instance()
    const endpoint = `${LIST}/${listId}/item`
    const body = {
      id: model.id,
      type: model.getType(),
      content:
        this.allListsItems[listId] && this.allListsItems[listId][model.showId]
          ? this.allListsItems[[listId]][model.showId].content
          : [],
    }
    if (model.getType() === SHOW && listId === 'seen') {
      for (let i = 0; i <= model.seasonsNumber; i++) {
        body.content.push(`${i}.*`)
      }
    }
    if (model.getType() === EPISODE) {
      if (
        this.allListsItems[listId] &&
        this.allListsItems[listId][model.showId]
      ) {
        if (body.content.includes(`${model.seasonNumber || 0}.*`)) {
          body.content.splice(
            body.content.indexOf(`${model.seasonNumber || 0}.*`),
            1,
          )
          const season = app.store.ShowStore.title.seasons
            .where('id', `se_${model.showId}_${model.seasonNumber || 0}`)
            .first()

          for (let i = 1; i <= season.episodeCount; i++) {
            if (i !== model.number) {
              body.content.push(`${model.seasonNumber || 0}.${i}`)
            }
          }
        } else {
          body.content.includes(`${model.seasonNumber || 0}.${model.number}`)
            ? body.content.splice(
                body.content.indexOf(
                  `${model.seasonNumber || 0}.${model.number}`,
                ),
                1,
              )
            : body.content.push(`${model.seasonNumber || 0}.${model.number}`)
        }
      } else {
        body.content.push(`${model.seasonNumber || 0}.${model.number}`)
      }

      body.id = model.showId
      body.type = SHOW

      if (!body.content.length) {
        return this.removeTitleFromList({
          model: this.listCollection
            .where('id', listId)
            .first()
            .items.where('id', model.showId)
            .first(),
          listId,
        })
      }
    }
    return app.request
      .post(endpoint, body)
      .then(
        action(response => {
          this.listCollection.where('id', listId).first().getListItemsContent()
          if (!this.allListsItems[listId]) {
            this.allListsItems[listId] = {}
          }
          this.allListsItems[listId][response.data.id] = {
            content: response.data.content,
          }

          return true
        }),
      )
      .catch(error => {
        if (app.auth.isGuest() && error.response.status === 401) {
          app.event.$emit('overlay.activity.login', { status: true })
        }
      })
  }

  removeTitleFromList({ model, listId }) {
    const app = Application.instance()
    const endpoint = `${LIST}/${listId}/item/${
      model.id
    }?media-type=${model.getType()}`
    return app.request
      .delete(endpoint)
      .then(
        action(response => {
          delete this.allListsItems[listId][model.id]
          this.listCollection.where('id', listId).first().getListItemsContent()
          return true
        }),
      )
      .catch(error => {
        if (app.auth.isGuest() && error.response.status === 401) {
          app.event.$emit('overlay.activity.login', { status: true })
        }
      })
  }

  /**
   * Create list
   */
  create({ name = null, model }) {
    const app = Application.instance()
    const endpoint = LIST
    return app.request
      .post(endpoint, { name })
      .then(response => {
        const list = new ListModel().fill(response.data, true)
        if (!this.allListsItems[list.id]) {
          this.allListsItems[list.id] = {}
        }
        this.listCollection.splice(2, 0, false, list)
        this.addTitleToList({ model, listId: list.id })
      })
      .catch(error => {
        if (app.auth.isGuest() && error.response.status === 401) {
          app.event.$emit('overlay.activity.login', { status: true })
        }
      })
  }

  /**
   * Edit list
   *
   * @param name
   * @param listId
   * @returns {Promise<any> | Promise<postcss.Result> | undefined}
   */
  edit(name, listId) {
    const app = Application.instance()
    const endpoint = `${LIST}/${listId}`
    return app.request
      .put(endpoint, { name })
      .then(response => {
        this.listCollection.where('id', listId).first().set('name', name)
        return true
      })
      .catch(error => {
        if (app.auth.isGuest() && error.response.status === 401) {
          app.event.$emit('overlay.activity.login', { status: true })
        }
      })
  }

  /**
   * Remove list
   */
  remove(id) {
    const app = Application.instance()
    const endpoint = `${LIST}/${id}`
    return app.request
      .delete(endpoint)
      .then(response => {
        this.listCollection.removeById(id)
        this.allListsItems = delete this.allListsItems[id]
      })
      .catch(error => {
        if (app.auth.isGuest() && error.response.status === 401) {
          app.event.$emit('overlay.activity.login', { status: true })
        }
      })
  }

  getLists() {
    const app = Application.instance()
    this.loading = true
    ListModel.list()
      .then(
        action(collection => {
          this.listCollection = collection
          this.generateHashMapListItems()
        }),
      )
      .catch(error => {
        if (app.auth.isGuest() && error.response.status === 401) {
          app.event.$emit('overlay.activity.login', { status: true })
        }
      })
      .finally(action(() => (this.loading = false)))
  }
}

decorate(ListStore, {
  listCollection: observable,
  remove: action,
  addTitleToList: action,
  getLists: action,
})
