import React, { ImgHTMLAttributes } from 'react'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import posterEmptyState from 'shared/assets/poster-empty-state.png'

import { styles } from './poster.styles'

type PosterComponent = {
  onLoad?: () => void
  onError?: () => void
}

export type PosterProps = AppWithStyles<typeof styles> &
  ImgHTMLAttributes<HTMLImageElement> &
  PosterComponent

const _Poster: React.FC<PosterProps> = ({
  classes,
  src,
  className,
  onLoad,
  onError,
}) => {
  return (
    <img
      src={src || posterEmptyState}
      className={cx(classes.root, className)}
      alt="poster"
      onLoad={onLoad}
      onError={onError}
    />
  )
}

export const Poster = appWithStyles(styles)(_Poster)
