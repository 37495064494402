import { Model } from 'services'
import { processContentImages } from 'shared/utils/image'

export default class CastModel extends Model {
  get fillable() {
    return {
      id: 'number',
      castId: 'string',
      character: 'string',
      gender: 'number',
      creditId: 'string',
      name: 'string',
      profilePath: 'string',
      biography: 'string',
      birthday: 'sring',
      images: 'array',
    }
  }

  /**
   * Get type of model
   *
   * @returns {string}
   */
  getType() {
    return 'PERSON'
  }

  /**
   * Get preview Image
   *
   * @returns {string}
   */
  getImage() {
    return processContentImages(this.images)?.PROFILE?.url.large
  }

  /**
   * Get movie link
   *
   * @returns {string}
   */
  getLink() {
    return `/person/${this.id}`
  }

  /**
   * Get actor list
   *
   * @param list
   * @returns {Promise<any>}
   */
  static list(list) {
    return this.createFromResponse({
      meta: {},
      type: 'COLLECTION',
      results: list.toArray(),
    })
  }
}
