import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { SelectedMarkRoundedIcon } from 'shared/icons/selected-mark-rounded'
import { Avatar } from 'shared/components/avatar'
import { Ellipsis } from 'shared/components/ellipsis'
import { UserListQuery } from 'shared/models/user/list-model'
import { Flex } from 'shared/components/flex'

import { styles } from './item.styles'

export type ItemProps = AppWithStyles<typeof styles> &
  Required<Pick<UserListQuery, 'id' | 'fullName' | 'avatar'>> & {
    selected?: boolean
    onChange: (id: UserListQuery['id']) => void
  }

const ItemComponent: React.FC<ItemProps> = ({
  classes,
  id,
  avatar,
  fullName,
  selected,
  onChange,
}) => {
  const handleChange = useCallback(() => {
    onChange(id)
  }, [id, onChange])

  return (
    <Grid item role="button" classes={{ root: classes.root }}>
      <Flex
        autoWidth={false}
        direction="column"
        role="button"
        alignItems="center"
        justify="center"
        className={cx(classes.container, {
          [classes.containerSelected]: selected,
        })}
        onClick={handleChange}
      >
        {selected && (
          <SelectedMarkRoundedIcon classes={{ root: classes.selectedIcon }} />
        )}
        <Avatar src={avatar} classes={{ root: classes.avatar }} />
        <Ellipsis
          maxWidth="100%"
          text={fullName}
          classes={{ root: classes.fullName }}
        />
      </Flex>
    </Grid>
  )
}

export const Item = appWithStyles(styles)(React.memo(ItemComponent))
