import { Application, Model } from 'services'
import EpisodeModel from './episode.model'
import { TMDB_IMAGE } from 'const/api/tmdb'
import { SHOW } from 'const/api/show'
import moment from 'moment'
import SourceModel from '../source.model'
import DepartmentModel from '../departments.model'

export default class SeasonModel extends Model {
  get relations() {
    return {
      children: {
        class: EpisodeModel,
        type: 'collection',
      },
      departments: {
        class: DepartmentModel,
        type: 'collection',
      },
    }
  }

  get fillable() {
    return {
      id: 'string',
      adult: 'boolean',
      budget: 'string',
      contentType: 'string',
      description: 'string',
      episode: 'string',
      // genres: 'array',
      // grids: 'array',
      images: 'array',
      popularity: 'string',
      releaseDate: 'string',
      revenue: 'string',
      title: 'sring',
      url: 'string',
      videoProviders: 'string',
    }
  }

  /**
   * Get release date
   *
   * @returns {number}
   */
  getDate(type = 'start') {
    return moment(this.airDate).format('ll')
  }

  /**
   * Get type of model
   *
   * @returns {string}
   */
  getType() {
    return this.contentType
  }

  /**
   * Get season poster
   *
   * @returns {Promise<string | string>}
   */
  getPoster() {
    return this.posterPath && `${TMDB_IMAGE}original${this.posterPath}`
  }

  /**
   * Get season sources
   *
   * @returns {Promise<any> | Promise<postcss.Result> | undefined}
   */
  getSources() {
    const app = Application.instance()
    const endpoint = `${SHOW}/${this.showId}/season/${this.number}/content_source`
    return app.request
      .get(endpoint)
      .then(response => SourceModel.createFromResponse(response.data))
      .catch(error => error)
  }

  /**
   * Get season episodes
   *
   * @param params
   */
  getEpisodes(params) {
    const app = Application.instance()
    return app.request
      .get(`${SHOW}/${this.showId}/season/${this.number || 0}/episode/page`)
      .then(res => EpisodeModel.createFromResponse(res.data))
  }
}
