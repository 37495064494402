import { injectable } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'

@injectable()
export class BaseService {
  static diToken = DI_TOKENS.baseService

  protected urlPrefix = ''

  getUrl(path?: string | number) {
    if (!path) {
      return this.urlPrefix
    }

    return `${this.urlPrefix}/${path}`
  }
}
