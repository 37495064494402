import React from 'react'
import Grid, { GridProps } from '@material-ui/core/Grid'

export type FlexProps = GridProps & {
  autoWidth?: boolean
}

export const Flex: React.FC<FlexProps> = ({ autoWidth, ...otherProps }) => {
  return (
    <Grid
      container
      style={autoWidth ? { width: 'auto' } : {}}
      {...otherProps}
    />
  )
}

Flex.defaultProps = {
  autoWidth: true,
}
