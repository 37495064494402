import { ROUTES } from 'shared/constants/routes'
import module from 'store/module'

export const profile = () =>
  module('profile', import(/* webpackChunkName: "profile" */ './profile'))
export const users = () =>
  module('users', import(/* webpackChunkName: "users" */ './users'))
export const settings = () =>
  module('settings', import(/* webpackChunkName: "settings" */ './settings'))
export const titlePage = () =>
  module(
    'title-page',
    import(/* webpackChunkName: "title-page" */ './title-page'),
  )
export const homePage = () =>
  module(
    'home-page',
    import(/* webpackChunkName: "home-page" */ './home-page'),
  )
export const gridCollection = () =>
  module(
    'grid-collection',
    import(/* webpackChunkName: "grid-page" */ './grid-collection'),
  )
export const person = () =>
  module('person', import(/* webpackChunkName: "person" */ './person'))
export const movie = () =>
  module('movie', import(/* webpackChunkName: "movie" */ './Movie'))
export const tvShow = () =>
  module('tv-show', import(/* webpackChunkName: "tv-show" */ './Show'))
export const sports = () =>
  module('sports', import(/* webpackChunkName: "sports" */ './sports'))
export const live = () =>
  module('live', import(/* webpackChunkName: "live" */ './Live'))
export const faq = () =>
  module('faq', import(/* webpackChunkName: "search" */ './Faq/faq'))
export const policy = () =>
  module('policy', import(/* webpackChunkName: "search" */ './Policy'))
export const terms = () =>
  module('terms', import(/* webpackChunkName: "search" */ './terms/terms'))
export const partners = () =>
  module('partners', import(/* webpackChunkName: "search" */ './Partners'))
export const about = () =>
  module('about', import(/* webpackChunkName: "about" */ './about'))

export default {
  '/': {
    route: '/',
    module: homePage,
  },
  [ROUTES.private.profile.root()]: {
    route: ROUTES.private.profile.root(),
    module: profile,
    auth: true,
    exact: false,
  },
  [ROUTES.private.users.root]: {
    route: ROUTES.private.users.root,
    module: users,
    auth: true,
    exact: false,
  },
  [ROUTES.private.settings.root]: {
    route: ROUTES.private.settings.root,
    module: settings,
    auth: true,
    exact: false,
  },
  [ROUTES.public.content()]: {
    route: ROUTES.public.content(),
    module: titlePage,
    auth: false,
    exact: false,
  },
  [ROUTES.public.person()]: {
    route: ROUTES.public.person(),
    module: person,
    auth: false,
    exact: false,
  },
  [ROUTES.public.grid()]: {
    route: ROUTES.public.grid(),
    module: gridCollection,
    auth: false,
    exact: false,
  },
  [ROUTES.public.sports]: {
    route: ROUTES.public.sports,
    module: sports,
    auth: false,
    exact: false,
  },
  '/movies': {
    route: '/movies',
    module: movie,
    exact: true,
  },
  '/tv-shows': {
    route: '/tv-shows',
    module: tvShow,
    exact: true,
  },
  '/lives': {
    route: '/lives',
    module: live,
    exact: true,
  },
  '/faq': {
    route: '/faq',
    module: faq,
    exact: true,
  },
  '/privacy-policy': {
    route: '/privacy-policy',
    module: policy,
    exact: true,
  },
  '/terms-of-use': {
    route: '/terms-of-use',
    module: terms,
    exact: true,
  },
  '/partners': {
    route: '/partners',
    module: partners,
    exact: true,
  },
  [ROUTES.public.about]: {
    route: ROUTES.public.about,
    module: about,
    exact: false,
  },
}
