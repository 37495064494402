import React, { useEffect } from 'react'

import { Backdrop, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Button } from 'shared/components/button'
import { BannerModel } from 'shared/models/banner/banner'
import { showBanner } from '../../services/analytics.service'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './banner.styles'

type Props = AppWithStyles<typeof styles> & {
  banner: BannerModel
  handleClose: () => void
}
const BannerComponent: React.FC<Props> = ({ banner, handleClose, classes }) => {
  const userAgent = window.navigator.userAgent

  useEffect(() => {
    showBanner(banner.id)
  }, [])

  const setLink = () => {
    if (/Android/i.test(userAgent)) {
      return banner.androidLink
    }
    if (/iPhone/i.test(userAgent)) {
      return banner.iosLink
    }
    return banner.webLink
  }

  return (
    <Backdrop open={true} className={classes.root}>
      <div
        className={
          banner.orientation === 'VERTICAL'
            ? classes.verticalWrapper
            : classes.horizontalWrapper
        }
      >
        <IconButton
          onClick={handleClose}
          classes={{
            root:
              banner.orientation === 'VERTICAL'
                ? classes.closeIconWrapper
                : classes.horizontalCloseIconWrapper,
          }}
        >
          <CloseIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
        <div
          className={classes.image}
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(${banner.poster?.largeUrl})`,
          }}
        ></div>
        <div className={classes.footer}>
          <p className={classes.bannerText}>{banner.bannerText}</p>
          <a href={setLink()} rel="noopener noreferrer" target="_blank">
            <Button className={classes.button}>{banner.buttonText}</Button>
          </a>
        </div>
      </div>
    </Backdrop>
  )
}

export const Banner = appWithStyles(styles)(BannerComponent)
