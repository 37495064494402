import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ palette, custom, spacing }: AppTheme) {
  return appCreateStyles({
    root: {
      justifyContent: 'space-between',
      '@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          justifyContent: 'start',
        },
    },
    title: {},
    outerActionsWrapper: {
      marginRight: spacing(2),
    },
    actions: {},
    chevronIcon: {
      borderRadius: custom.borderRadius.primary,
      color: appFade(palette.common.white, 0.4),
      backgroundColor: appFade(palette.common.white, 0.1),
      height: 28,
      width: 28,
      marginRight: spacing(2),

      '&:last-of-type': {
        marginRight: 0,
      },
    },
    chevronIconDisabled: {
      opacity: 0.4,
    },
  })
}
