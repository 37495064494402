import { Application, Model } from 'services'
import {
  COLLECTION,
  COLLECTION_SHOWS,
  COLLECTION_MOVIES,
} from 'const/api/collection'
import { SHOW, MOVIE } from 'const/mediaTypes'
import { MovieModel, ShowModel } from './'

export default class List extends Model {
  constructor() {
    super()
    this.titleType = {
      [MOVIE]: MovieModel,
      [SHOW]: ShowModel,
    }
  }
  get fillable() {
    return {
      id: 'string',
      name: 'string',
      contentType: 'string',
      order: 'number',
      items: 'collection',
    }
  }

  /**
   * Get list items content
   */
  getListItemsContent() {
    const app = Application.instance()
    return new Promise((resolve, reject) => {
      const endpoint = `${COLLECTION}/${this.id}/content`
      app.request
        .get(endpoint)
        .then(response => {
          this.items = this.titleType[this.contentType].createFromResponse(
            response.data,
          )
          resolve(this.items)
        })
        .catch(error => reject(error))
    })
  }

  static getRoute(type) {
    const routes = {
      [SHOW]: COLLECTION_SHOWS,
      [MOVIE]: COLLECTION_MOVIES,
    }
    return routes[type]
  }

  /**
   * Get all lists
   *
   * @param contentType
   * @returns {Promise<unknown>}
   */
  static list(contentType = MOVIE) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      const endpoint = self.getRoute(contentType)
      app.request
        .get(endpoint)
        .then(response => {
          resolve(self.createFromResponse(response.data, endpoint))
        })
        .catch(error => reject(error))
    })
  }
}
