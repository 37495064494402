import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { appObserver } from 'core/state-management/utils'
import { StepDescription } from '../step-description'
import { ContentGetQuery } from 'shared/models/content/get-model'
import { FirstStepViewModel } from './first-step.vm'
import { GridType } from 'shared/models/grid/type'
import { Loading } from 'shared/components/loading'
import {
  NotificationType,
  showNotification,
} from 'shared/components/notification/notification'
import { OnboardingStepProps } from '../../onboarding.types'
import { Content } from './components/content'
import { Genres } from './components/genres'
import { PersonGetQuery } from 'shared/models/person/get-model'
import { People } from './components/people'
import { GenreGetQuery } from 'shared/models/genre/genre-get-model'
import { Controls } from '../controls'
import { IConfigService } from 'core/types/services/config'
import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { logOnboardingResult } from '../../../../../services/analytics.service'
import { OnboardingStatus } from '../../../../models/user/onboading-status'

import { styles } from './first-step.styles'

export type FirstStepProps = AppWithStyles<typeof styles> & OnboardingStepProps

const FirstStepComponent: React.FC<FirstStepProps> = ({
  classes,
  grids,
  proceedStep: _proceedStep,
}) => {
  const configService = inject<IConfigService>(DI_TOKENS.configService).get()
  const $vm = useMemo(() => new FirstStepViewModel(), [])
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [contentItems, setContentItems] = useState<Array<ContentGetQuery>>([])
  const [people, setPeople] = useState<Array<PersonGetQuery>>([])
  const [genres, setGenres] = useState<Array<GenreGetQuery>>([])
  const [selectedContent, setSelectedContent] = useState<
    Array<ContentGetQuery['id']>
  >([])
  const [selectedPeople, setSelectedPeople] = useState<
    Array<PersonGetQuery['id']>
  >([])
  const [selectedGenres, setSelectedGenres] = useState<
    Array<GenreGetQuery['id']>
  >([])

  const initialize = useCallback(async () => {
    try {
      setLoading(true)

      for (let gridIndex in grids) {
        const currentGrid = grids[gridIndex]
        const config = {
          [GridType.content]: async () => {
            const response = await $vm.getContentGridItems(currentGrid.id)

            setContentItems(response.map(({ asJson }) => asJson))
          },
          [GridType.person]: async () => {
            const response = await $vm.getPersons(currentGrid.id)

            setPeople(
              response
                .map(({ asJson }) => asJson)
                .sort((a, b) => a.name && a.name.localeCompare(b.name)),
            )
          },
          [GridType.genre]: async () => {
            const response = await $vm.getGenres(currentGrid.id)

            setGenres(response.map(({ asJson }) => asJson))
          },
        }

        await config[currentGrid.type]()
      }
    } catch (err) {
      showNotification(
        'Something went wrong while fetching content',
        NotificationType.error,
      )

      console.error(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [grids])

  const proceedStep = useCallback(
    async (skip: boolean) => {
      try {
        setSubmitting(true)
        logOnboardingResult(skip, OnboardingStatus.step0)
        await _proceedStep(
          async () =>
            await $vm.updateList(skip, {
              content: selectedContent,
              people: selectedPeople,
              genres: selectedGenres,
            }),
        )
      } finally {
        setSubmitting(false)
      }
    },
    [_proceedStep, $vm, selectedContent, selectedPeople, selectedGenres],
  )

  const handleContentItemChange = useCallback(
    (id: ContentGetQuery['id']) => {
      setSelectedContent(
        selectedContent.includes(id)
          ? selectedContent.filter(item => item !== id)
          : [...selectedContent, id],
      )
    },
    [selectedContent],
  )

  const handleGenresChange = useCallback(
    (id: GenreGetQuery['id']) => {
      setSelectedGenres(
        selectedGenres.includes(id)
          ? selectedGenres.filter(item => item !== id)
          : [...selectedGenres, id],
      )
    },
    [selectedGenres],
  )

  const handlePeopleChange = useCallback(
    (id: PersonGetQuery['id']) => {
      setSelectedPeople(
        selectedPeople.includes(id)
          ? selectedPeople.filter(item => item !== id)
          : [...selectedPeople, id],
      )
    },
    [selectedPeople],
  )

  const canProceedForward = useMemo(() => {
    const { content, genres } = configService.onboardingRequiredItemsAmount

    return selectedContent.length >= content && selectedGenres.length >= genres
  }, [
    configService.onboardingRequiredItemsAmount,
    selectedContent.length,
    selectedGenres.length,
  ])

  useEffect(() => {
    initialize()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return <Loading classes={{ root: classes.root }} />
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <StepDescription
          heading="What are you into?"
          subheading="Tell us what you like so we can show you content that we know you will enjoy."
        />
        <Genres
          selectedItems={selectedGenres}
          requiredItemsAmount={
            configService.onboardingRequiredItemsAmount.genres
          }
          data={genres}
          classes={{ root: classes.section }}
          onItemChange={handleGenresChange}
        />
        <People
          selectedItems={selectedPeople}
          data={people}
          classes={{ root: classes.section }}
          onItemChange={handlePeopleChange}
        />
        <Content
          selectedItems={selectedContent}
          requiredItemsAmount={
            configService.onboardingRequiredItemsAmount.content
          }
          data={contentItems}
          classes={{ root: classes.section }}
          onItemChange={handleContentItemChange}
        />
      </div>
      <Controls
        loading={submitting}
        canProceedForward={canProceedForward}
        onProceedForward={proceedStep}
        classes={{ root: classes.controls }}
      />
    </div>
  )
}

export const FirstStep = appWithStyles(styles)(appObserver(FirstStepComponent))
