import React from 'react'

export const ShowsIcon = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66667 16.9458H18.3333V5.29371H1.66667V16.9458ZM19.1667 3.62703H9.7175L11.7896 1.40119C12.1033 1.06411 12.0842 0.537024 11.7475 0.223273C11.4113 -0.0892276 10.8833 -0.0713109 10.5696 0.26494L7.91583 3.11578L5.26167 0.26494C4.94792 -0.0717276 4.42042 -0.0896443 4.08375 0.223273C3.74708 0.537024 3.72792 1.06411 4.04167 1.40119L6.11375 3.62703H0.833333C0.373333 3.62703 0 3.99995 0 4.46037V17.7792C0 18.2392 0.373333 18.6125 0.833333 18.6125H2.55208V19.1667C2.55208 19.6267 2.92542 20 3.38542 20C3.84583 20 4.21875 19.6267 4.21875 19.1667V18.6125H15.7812V19.1667C15.7812 19.6267 16.1546 20 16.6146 20C17.075 20 17.4479 19.6267 17.4479 19.1667V18.6125H19.1667C19.6271 18.6125 20 18.2392 20 17.7792V4.46037C20 3.99995 19.6271 3.62703 19.1667 3.62703ZM15.6454 9.83218C16.1054 9.83218 16.4788 9.45884 16.4788 8.99884V7.79717C16.4788 7.33717 16.1054 6.96384 15.6454 6.96384C15.1854 6.96384 14.8121 7.33717 14.8121 7.79717V8.99884C14.8121 9.45884 15.1854 9.83218 15.6454 9.83218ZM16.4788 14.4419C16.4788 14.9019 16.1054 15.2753 15.6454 15.2753C15.1854 15.2753 14.8121 14.9019 14.8121 14.4419V14.1603C14.8121 13.7003 15.1854 13.3269 15.6454 13.3269C16.1054 13.3269 16.4788 13.7003 16.4788 14.1603V14.4419Z"
        fill="white"
      />
    </svg>
  )
}
