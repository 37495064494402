import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { GenreGetQuery } from 'shared/models/genre/genre-get-model'

import { styles } from './item.styles'

export type ItemProps = AppWithStyles<typeof styles> &
  Required<Pick<GenreGetQuery, 'id' | 'name'>> & {
    selected?: boolean
    onChange: (id: GenreGetQuery['id']) => void
  }

const ItemComponent: React.FC<ItemProps> = ({
  classes,
  id,
  name,
  selected,
  onChange,
}) => {
  const handleChange = useCallback(() => {
    onChange(id)
  }, [id, onChange])

  return (
    <Grid item role="button" classes={{ root: classes.root }}>
      <div
        className={cx(classes.container, {
          [classes.containerSelected]: selected,
        })}
        onClick={handleChange}
      >
        {name}
      </div>
    </Grid>
  )
}

export const Item = appWithStyles(styles)(React.memo(ItemComponent))
