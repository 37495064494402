import { Provider, Url } from 'services'
import store from 'store'

export default class ApplicationProvider extends Provider {
  boot(app) {
    app.bind('url', () => new Url())
    // Init Mobx store in application wrapper
    app.bind('store', () => store)
  }
}
