import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ palette }: AppTheme) {
  return appCreateStyles({
    root: {},
    item: {},
    errorState: {
      fontWeight: 700,
      color: palette.common.white,
    },
    shimmer: {
      boxShadow: 'none',
    },
  })
}
