import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Close, Search as SearchIcon } from '@material-ui/icons'
import { Application } from 'services'
import cx from 'classnames';

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Button } from 'shared/components/button'
import { appObserver } from 'core/state-management/utils'
import { GlobalSearchViewModel } from '../model/global-search.vm'
import { SearchModal } from 'components/search-modal/search-modal'
import { Tooltip } from '@material-ui/core';
import { Flex } from 'shared/components/flex';

import { styles } from './global-search.styles'

export type SearchProps = AppWithStyles<typeof styles> & {
  withHint?: boolean;
  hintHighlighted?: boolean;
  hint?: React.ReactNode;
  placeholder?: string;
}

const GlobalSearchComponent: React.FC<SearchProps> = appObserver(
  ({ classes, withHint, hintHighlighted, hint = 'AI powered', placeholder }) => {
    const $app = Application.instance()
    const $vm = useMemo(() => new GlobalSearchViewModel(), [])
    const [isModalOpen, setModalOpen] = useState(false)
    enum StorageAiHintValue {
      skipAiHint = '1'
    }
    const storageAiHintKey = 'skipAiHint';
    const [skipAiHintMsg, setSkipAiHintMsg] = useState(
      localStorage.getItem(storageAiHintKey) === StorageAiHintValue.skipAiHint
    )
    const [hintOpen, setHintOpen] = useState(false)

    useEffect(() => {
      if (!skipAiHintMsg) {
        const timeToWaitUntilBtnIsMounted = 500;

        setTimeout(() => {
          setHintOpen(true)
        }, timeToWaitUntilBtnIsMounted);
      }
    }, [skipAiHintMsg]);

    const closeAiHintMsg = React.useCallback(() => {
      setSkipAiHintMsg(true)
      setHintOpen(false)
      localStorage.setItem(storageAiHintKey, StorageAiHintValue.skipAiHint)
    }, []);

    const searchParams = new URLSearchParams(window.location.search)
    const searchValue = searchParams.get('query')

    useEffect(() => {
      if (searchValue) {
        setModalOpen(true)
      }
    }, [searchValue])

    const handleModelOpenStateChange = useCallback(() => {
      setModalOpen(!isModalOpen)
      const scrollArea = document.querySelector<HTMLElement>('.app-container')
      isModalOpen
        ? (scrollArea.style.overflowY = 'auto')
        : (scrollArea.style.overflowY = 'hidden')
    }, [isModalOpen])

    const trigger = (
      <div className={classes.searchWrapper}>
        <Flex alignItems="center" className={cx(classes.aiOption, { [classes.aiOptionLoggedInUser]: $vm.isLoggedIn || hintHighlighted })}>{hint}</Flex>
        <Button
          fullWidth
          startIcon={<SearchIcon />}
          text={placeholder || `Search content, people${$vm.isLoggedIn ? ', users' : ''}`}
          classes={{ root: classes.root }}
          onClick={handleModelOpenStateChange}
        />
      </div>
    );

    const tooltipOpened = withHint && !skipAiHintMsg && hintOpen;

    return (
      <>
        {tooltipOpened && (
          <Tooltip 
            title={(
              <>
                Try AI powered search
                <Button className={classes.closeHintBtn} onClick={closeAiHintMsg}><Close /></Button>
              </>
            )}
            arrow
            classes={{
              popper: classes.hintPopper,
              tooltip: classes.hintPopperInner,
              arrow: classes.hintPopperArrow,
            }}
            open
            placement="bottom"
          >
            {trigger}
          </Tooltip>
        )}
        {!tooltipOpened && trigger}
        {isModalOpen && (
          <SearchModal
            isLoggedIn={$vm.isLoggedIn}
            onClose={handleModelOpenStateChange}
            paramsValue={searchValue}
          />
        )}
      </>
    )
  },
)

export const GlobalSearch = appWithStyles(styles)(GlobalSearchComponent)
