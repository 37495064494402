import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { GridModel } from 'models'

configure({ enforceActions: 'observed' })
export default class GridStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      collection: new Collection(),
      grid: new GridModel(),
    })
    this.model = GridModel
  }

  /**
   * Set Collection
   *
   * @param payload
   */
  setCollection(payload) {
    this.collection = payload
  }

  getGridById(id) {
    this.grid = new GridModel().fillFromResponse({
      id: id,
      order: 0,
      type: 'CONTENT',
    })
  }

  clear() {
    this.grid = new GridModel()
  }
}

decorate(GridStore, {
  setCollection: action,
  getGridById: action,
  clear: action,
})
