import { FOLLOWING } from 'const/api/grids'
import { configure, decorate, extendObservable, action } from 'mobx'
import { FollowingGridModel } from 'models'
import { abbreviateNumber } from 'utils/abbreviateNumber'

configure({ enforceActions: 'observed' })
export default class FollowingGridStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      ids: [],
      displayedCount: '-',
    })
    this.model = FollowingGridModel
  }

  getFollowing() {
    this.loading = true
    FollowingGridModel.list(FOLLOWING)
      .then(
        action(collection => {
          this.ids = collection.toArray()
          this.displayedCount = abbreviateNumber(collection.length, {
            style: 'uppercase',
          })
        }),
      )
      .finally(action(() => (this.loading = false)))
  }
}

decorate(FollowingGridStore, {})
