import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(4),
    },
    headerPart: {
      display: 'flex',
      alignItems: 'center',
    },
    menuIcon: {
      width: '36px',
      height: '24px',
    },
    logo: {
      width: '90px',
      height: '20px',
    },
    headerButton: {
      padding: spacing(2),
      color: '#fff',
    },
    searchIcon: {
      width: '26px',
      height: '26px',
    },
    signInButton: {
      margin: spacing(2),
    },
    aiBtn: {
      position: 'relative',
    },
    aiOption: {
      top: -12,
      borderRadius: 24,
      right: 4,
      position: 'absolute',
      padding: spacing(0.5, 2),
      background: palette.primary.dark,
      color: palette.common.white,
      fontSize: 12,
    },
  })
}
