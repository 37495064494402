import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { IContentService } from 'shared/types/services/content'
import { ContentGetModel } from 'shared/models/content/get-model'
import { GridGetModel } from 'shared/models/grid/get-model'
import { GridGroupGetModel } from 'shared/models/grid-group/get-model'
import { SharedLinkModel } from 'shared/models/content/shared-link-model'

@injectable()
export class ContentService extends BaseService implements IContentService {
  private app = Application.instance()

  protected urlPrefix = '/contents'

  get: IContentService['get'] = async (id: string) => {
    const { data } = await this.app.newrequest.get(this.getUrl(id))

    return new ContentGetModel(data)
  }

  getGrid: IContentService['getGrid'] = async (id, page) => {
    const { data } = await this.app.newrequest.get(
      `/grids/${encodeURIComponent(id)}`,
      {
        params: {
          page,
        },
      },
    )

    return new GridGetModel(data)
  }

  getGrids: IContentService['getGrids'] = async ids => {
    const { data } = await this.app.newrequest.post('/grids/list', ids)

    return data.map(item => new GridGetModel(item))
  }

  getGridItems: IContentService['getGridItems'] = async (id, page) => {
    const {
      data: { items },
    } = await this.app.newrequest.get(`/grids/${encodeURIComponent(id)}`, {
      params: {
        page,
      },
    })

    const { data } = await this.app.newrequest.post(this.getUrl('list'), items)

    return data.map(item => new ContentGetModel(item))
  }

  getItems: IContentService['getItems'] = async items => {
    const { data } = await this.app.newrequest.post(this.getUrl('list'), items)

    return data.map(item => new ContentGetModel(item))
  }

  getGroup: IContentService['getGroup'] = async type => {
    const { data } = await this.app.newrequest.get(`/groups/${type}`)

    return new GridGroupGetModel(data)
  }

  addLikedContent: IContentService['addLikedContent'] = items => {
    return this.app.newrequest.put('/grids/likes', { items })
  }

  deleteCustomList: IContentService['deleteCustomList'] = id => {
    return this.app.newrequest.delete(`grids/custom/${encodeURIComponent(id)}`)
  }

  getContentsSharedLink: IContentService['getContentsSharedLink'] = async (
    encodedId: string,
  ) => {
    const { data } = await this.app.newrequest.get(
      `/contents/${encodedId}/sharedlink`,
    )

    return new SharedLinkModel(data)
  }

  getGridSharedLink: IContentService['getGridSharedLink'] = async (
    encodedId: string,
  ) => {
    const { data } = await this.app.newrequest.get(
      `/grids/${encodedId}/sharedlink`,
    )

    return new SharedLinkModel(data)
  }
}
