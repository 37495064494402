import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { IPersonsService } from 'shared/types/services/persons'
import { PersonGetModel } from 'shared/models/person/get-model'

@injectable()
export class PersonsService extends BaseService implements IPersonsService {
  private app = Application.instance()

  protected urlPrefix = '/persons'

  get: IPersonsService['get'] = async id => {
    const { data } = await this.app.newrequest.get(this.getUrl(id))

    return new PersonGetModel(data)
  }

  getList: IPersonsService['getList'] = async (id, page) => {
    const {
      data: { items },
    } = await this.app.newrequest.get(`/grids/${encodeURIComponent(id)}`, {
      params: {
        page,
      },
    })

    const { data } = await this.app.newrequest.post(this.getUrl('list'), items)

    return data.map(item => new PersonGetModel(item))
  }
}
