import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ spacing, palette, custom }: AppTheme) {
  return appCreateStyles({
    root: {},
    title: {
      fontSize: '20px !important',
      fontWeight: 700,
      color: appFade(palette.common.white, 0.7),
      marginBottom: -2,
      marginRight: spacing(4),
    },
    hint: {
      color: custom.colors.gray[200],
      fontSize: '14px !important',
      fontWeight: 400,
      marginRight: spacing(4),
    },
    counter: {
      color: '#8FA984',
      fontSize: '14px !important',
      fontWeight: 400,
    },
  })
}
