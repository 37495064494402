import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { GridRequestType } from 'shared/models/grid/request-type'
import { IContentService } from 'shared/types/services/content'
import { IOnboardingService } from 'shared/types/services/onboarding'

export class OnboardingViewModel {
  private contentService = inject<IContentService>(DI_TOKENS.contentService)
  private onboardingService = inject<IOnboardingService>(
    DI_TOKENS.onboardingService,
  )

  getGroup = () => {
    return this.contentService.getGroup(GridRequestType.onboarding)
  }

  getGrid: IContentService['getGrid'] = (...args) => {
    return this.contentService.getGrid(...args)
  }

  getGridItems: IContentService['getGridItems'] = (...args) => {
    return this.contentService.getGridItems(...args)
  }

  updateOnboardingStatus: IOnboardingService['updateStatus'] = (...args) => {
    return this.onboardingService.updateStatus(...args)
  }
}
