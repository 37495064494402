import React from 'react'
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom'
import cx from 'classnames'

import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './link.styles'

export type LinkProps = AppWithStyles<typeof styles> &
  Partial<ReactRouterLinkProps> & {
    native?: boolean
  }

const LinkComponent: React.FC<LinkProps> = ({
  native,
  classes,
  className,
  children,
  to,
  ...otherProps
}) => {
  if (native || !to) {
    return (
      <a className={cx(classes.root, className)} {...otherProps}>
        {children}
      </a>
    )
  }

  return (
    <ReactRouterLink
      className={cx(classes.root, className)}
      to={to}
      {...otherProps}
    >
      {children}
    </ReactRouterLink>
  )
}

export const Link = appWithStyles(styles)(LinkComponent)
