import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    root: {},
    action: {
      marginRight: spacing(2),
      backgroundColor: appFade(palette.common.white, 0.1),
      color: palette.common.white,
      height: 34,
    },
    actionActive: {
      backgroundColor: palette.primary.dark,
    },
    actionMobile: {
      minWidth: 0,
      width: 34,
      marginRight: 0,
      borderRadius: '50%',
      padding: spacing(2, 1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonWrapperMobile: {
      marginLeft: 8,
    },
    buttonText: {
      color: palette.grey[600],
      fontSize: '10px',
      fontWeight: 700,
      zIndex: 2,
      textAlign: 'center',
      marginBottom: 0,
    },
  })
}
