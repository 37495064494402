import { BaseModel } from 'core/models/base'
import { Image } from 'shared/models/image/model'
import { ContentType } from './content-type'
import { ContentDepartment } from './department/model'
import { GridGetQuery } from '../grid/get-model'
import { Providers } from './providers'

export interface ContentGetQuery {
  contentType: ContentType
  description?: string
  genres?: Array<string>
  id: string
  images?: Array<Image>
  releaseDate?: string
  seasonYears?: string
  score?: number
  title: string
  trailer?: string
  videoProviders: Providers
  departments: Array<ContentDepartment>
  grids: Array<GridGetQuery['id']>
  children: Array<ContentGetQuery>
  episode?: string
}

export class ContentGetModel extends BaseModel<ContentGetQuery> {
  private contentType: ContentType
  private description?: string
  private genres?: Array<string>
  private id: string
  private images: Array<Image>
  private releaseDate?: string
  private seasonYears?: string
  private score?: number
  private title: string
  private trailer?: string
  private videoProviders: Providers
  private departments: Array<ContentDepartment>
  private grids: Array<GridGetQuery['id']>
  private children: Array<ContentGetQuery>
  private episode?: string

  constructor(data: ContentGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): ContentGetQuery {
    return {
      contentType: this.contentType,
      description: this.description,
      genres: this.genres,
      id: this.id,
      images: this.images,
      releaseDate: this.releaseDate,
      seasonYears: this.seasonYears,
      score: this.score,
      title: this.title,
      trailer: this.trailer,
      videoProviders: this.videoProviders,
      departments: this.departments,
      grids: this.grids,
      children: this.children,
      episode: this.episode,
    }
  }
}
