import {
  ANALYTICS_EVENT,
  ANALYTICS_PARAMETER,
  ANALYTICS_ONBOARDING_RESULT,
} from '../shared/constants/analytics'
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics'
import { OnboardingStatus } from '../shared/models/user/onboading-status'

export const COOKIES_CONSENT_KEY = 'COOKIES_CONSENT_ACCEPTED_KEY'
export enum CookiesConsentResult {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}
function logFirebaseEvent(event, eventParams?: { [key: string]: any }) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(
      `Logging Firebase event ${event} skipped for non-production (${process.env.NODE_ENV}) environment`,
    )
    return
  }
  if (
    !getAnalytics().app.automaticDataCollectionEnabled &&
    eventParams?.hasOwnProperty(ANALYTICS_PARAMETER.EMAIL)
  ) {
    delete eventParams[ANALYTICS_PARAMETER.EMAIL]
  }
  logEvent(getAnalytics(), event, eventParams)
}

function linkUserId(userId: string) {
  if (getAnalytics().app.automaticDataCollectionEnabled) {
    setUserId(getAnalytics(), userId, { global: true })
  }
}

export function setAutomaticDataCollectionEnabled(value: boolean) {
  getAnalytics().app.automaticDataCollectionEnabled = value
}

export function logHomePage() {
  logFirebaseEvent(ANALYTICS_EVENT.HOME_PAGE)
}

export function logSectionTvShows() {
  logFirebaseEvent(ANALYTICS_EVENT.SECTION_TV_SHOWS)
}

export function logSectionMovies() {
  logFirebaseEvent(ANALYTICS_EVENT.SECTION_MOVIES)
}

export function logSectionLive() {
  logFirebaseEvent(ANALYTICS_EVENT.SECTION_LIVE)
}

export function logSectionSports() {
  logFirebaseEvent(ANALYTICS_EVENT.SECTION_SPORTS)
}

export function logGridCollection(
  id: string,
  title: string,
  itemType: string,
  type: string,
) {
  logFirebaseEvent(ANALYTICS_EVENT.GRID_COLLECTION, {
    [ANALYTICS_PARAMETER.ID]: id,
    [ANALYTICS_PARAMETER.TITLE]: title,
    [ANALYTICS_PARAMETER.GRID_ITEM_TYPE]: itemType,
    [ANALYTICS_PARAMETER.GRID_TYPE]: type,
  })
}

export function logTitlePage(id: string, title: string, contentType: string) {
  logFirebaseEvent(ANALYTICS_EVENT.TITLE_PAGE, {
    [ANALYTICS_PARAMETER.ID]: id,
    [ANALYTICS_PARAMETER.TITLE]: title,
    [ANALYTICS_PARAMETER.CONTENT_TYPE]: contentType,
  })
}

export function logSignUp(sub: string, email: string, provider: string) {
  linkUserId(sub)
  logFirebaseEvent(ANALYTICS_EVENT.SIGN_UP, {
    [ANALYTICS_PARAMETER.EMAIL]: email,
    [ANALYTICS_PARAMETER.PROVIDER]: provider,
  })
}

export function logSignUpFailure(email: string, provider: string) {
  logFirebaseEvent(ANALYTICS_EVENT.SIGN_UP_FAILURE, {
    [ANALYTICS_PARAMETER.EMAIL]: email,
    [ANALYTICS_PARAMETER.PROVIDER]: provider,
  })
}

export function logSignIn(sub: string, email: string, provider: string) {
  linkUserId(sub)
  logFirebaseEvent(ANALYTICS_EVENT.SIGN_IN, {
    [ANALYTICS_PARAMETER.EMAIL]: email,
    [ANALYTICS_PARAMETER.PROVIDER]: provider,
  })
}

export function logSignInFailure(email: string, provider: string) {
  logFirebaseEvent(ANALYTICS_EVENT.SIGN_IN_FAILURE, {
    [ANALYTICS_PARAMETER.EMAIL]: email,
    [ANALYTICS_PARAMETER.PROVIDER]: provider,
  })
}

export function logSignOut(email: string, provider: string) {
  logFirebaseEvent(ANALYTICS_EVENT.SIGN_OUT, {
    [ANALYTICS_PARAMETER.EMAIL]: email,
    [ANALYTICS_PARAMETER.PROVIDER]: provider,
  })
  setTimeout(() => {
    setUserId(getAnalytics(), null, { global: true })
  }, 200)
}

export function showBanner(bannerId: string) {
  logFirebaseEvent(ANALYTICS_EVENT.SHOW_BANNER, {
    [ANALYTICS_PARAMETER.BANNER_ID]: bannerId,
  })
}

export function notificationsOpen() {
  logFirebaseEvent(ANALYTICS_EVENT.NOTIFICATIONS_OPEN)
}

function convertOnboardingStatusToAnalyticsEvent(
  onboardingStatus: OnboardingStatus,
) {
  return {
    [OnboardingStatus.step0]: ANALYTICS_EVENT.ONBOARDING_MOVIES_TV_SHOWS,
    [OnboardingStatus.step1]: ANALYTICS_EVENT.ONBOARDING_SPORTS,
    [OnboardingStatus.step2]: ANALYTICS_EVENT.ONBOARDING_ADD_SERVICES,
    [OnboardingStatus.step3]: ANALYTICS_EVENT.ONBOARDING_FOLLOW_FRIENDS,
  }[onboardingStatus]
}

export function logAnalyticsOnboarding(status: OnboardingStatus) {
  logFirebaseEvent(convertOnboardingStatusToAnalyticsEvent(status))
}

export function logOnboardingResult(skip: boolean, status: OnboardingStatus) {
  logFirebaseEvent(ANALYTICS_EVENT.ONBOARDING_RESULT, {
    [ANALYTICS_PARAMETER.RESULT]: skip
      ? ANALYTICS_ONBOARDING_RESULT.SKIP
      : ANALYTICS_ONBOARDING_RESULT.NEXT,
    [ANALYTICS_PARAMETER.ONBOARDING_SECTION]:
      convertOnboardingStatusToAnalyticsEvent(status),
  })
}
