export const ALL_FILTER = 'ALL'

export enum ContentTypeFilter {
  MOVIES = 'Movies',
  TV_SHOWS = 'TV Shows',
  SPORTS = 'Sports',
}

export const ContentTypesFilterValue: Record<ContentTypeFilter, string> = {
  [ContentTypeFilter.MOVIES]: 'MOVIE',
  [ContentTypeFilter.TV_SHOWS]: 'SHOW',
  [ContentTypeFilter.SPORTS]: 'SPORT',
}

export enum SortByFilter {
  POPULARITY = 'Popularity',
  YEAR = 'Year',
  ALPHABETICALLY = 'Alphabetically',
}

export const SortByFilterValue: Record<SortByFilter, string> = {
  [SortByFilter.POPULARITY]: 'popularity',
  [SortByFilter.YEAR]: 'releaseDate',
  [SortByFilter.ALPHABETICALLY]: 'title',
}

export enum ServicesFilter {
  ALL_SERVICES = 'All services',
  MY_SERVICES = 'My services',
}

export const ServicesFilterValue: Record<ServicesFilter, string> = {
  [ServicesFilter.ALL_SERVICES]: ALL_FILTER,
  [ServicesFilter.MY_SERVICES]: 'MY',
}

export enum DescendingSort {
  ASC = 'Ascending',
  DESC = 'Descending',
}

export const DescendingSortFilterValue: Record<DescendingSort, string> = {
  [DescendingSort.ASC]: 'asc',
  [DescendingSort.DESC]: 'desc',
}

export type SelectListItem = {
  name: string
  value: string
}

export type YearFilter = {
  yearFrom: number
  yearTo: number
}

export type GlobalFilters = {
  contentType: ContentTypeFilter | null
  genres: string[]
  yearsFilter: YearFilter
  service: ServicesFilter | null
  sortBy: SortByFilter
  descending: DescendingSort
  departments: string[]
}
