import React, { useCallback } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Carousel } from 'shared/components/carousel'
import { CONTENT_LIST_FIELDS } from 'shared/constants/content'
import { Item } from './components/item'
import { SectionTitle } from '../../../section-title'
import { PersonGetQuery } from 'shared/models/person/get-model'

import { styles } from './people.styles'

export type PeopleProps = AppWithStyles<typeof styles> & {
  selectedItems: Array<PersonGetQuery['id']>
  data: Array<PersonGetQuery>
  onItemChange: (id: PersonGetQuery['id']) => void
}

const PeopleComponent: React.FC<PeopleProps> = ({
  classes,
  selectedItems,
  data,
  onItemChange,
}) => {
  const renderItem = useCallback(
    (person: PersonGetQuery) => {
      return (
        <Item
          selected={selectedItems.includes(person.id)}
          id={person.id}
          name={person.name}
          images={person.images}
          classes={{ root: classes.item }}
          onChange={onItemChange}
        />
      )
    },
    [selectedItems, classes, onItemChange],
  )

  return (
    <Carousel
      title={<SectionTitle title="People" />}
      data={data}
      idProperty={CONTENT_LIST_FIELDS.id}
      itemDimensions={{
        width: 94,
        height: 106,
      }}
      renderItem={renderItem}
      classes={{ root: classes.root }}
    />
  )
}

export const People = appWithStyles(styles)(PeopleComponent)
