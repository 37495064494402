import React from 'react'

export const EmailIcon = props => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Mail">
        <circle id="Oval" cx="24" cy="24" r="24" fill="#F4CD45" />
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8 17.1753C12.8 17.1753 22.4596 22.6529 24.3344 22.6529C26.2996 22.6529 35.8544 17.2799 35.8544 17.2799L35.8698 16.3199C35.8698 15.2601 35.0096 14.3999 33.9469 14.3999H14.722C13.6602 14.3999 12.8 15.2601 12.8 16.3199V17.1753ZM35.8544 20.1599C35.8544 20.1599 26.3898 25.2929 24.3344 25.2929C22.4596 25.2929 12.8144 20.1599 12.8154 20.1599L12.8 31.6799C12.8 32.7397 13.6612 33.5999 14.722 33.5999H33.9469C35.0096 33.5999 35.8698 32.7397 35.8698 31.6799L35.8544 20.1599Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
