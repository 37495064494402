import React, { useCallback, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { ProviderGetQuery } from 'shared/models/provider/get-model'
import { Ellipsis } from 'shared/components/ellipsis'
import { CheckIcon } from 'shared/icons/check'
import { Flex } from 'shared/components/flex'
import { PlusIcon } from 'shared/icons/plus'

import { styles } from './item.styles'

export type ItemProps = AppWithStyles<typeof styles> &
  Required<Pick<ProviderGetQuery, 'id' | 'name' | 'images'>> & {
    selected?: boolean
    onChange: (id: ProviderGetQuery['id']) => void
  }

const ItemComponent: React.FC<ItemProps> = ({
  classes,
  id,
  name,
  selected,
  images,
  onChange,
}) => {
  const handleChange = useCallback(() => {
    onChange(id)
  }, [id, onChange])

  const icon = useMemo(() => {
    if (selected) {
      return <CheckIcon classes={{ root: classes.checkIcon }} />
    }

    return <PlusIcon />
    // eslint-disable-next-line
  }, [selected])

  return (
    <Grid item xs={6} role="button" classes={{ root: classes.root }}>
      <Flex
        alignItems="center"
        justify="space-between"
        className={cx(classes.container, {
          [classes.containerSelected]: selected,
        })}
        onClick={handleChange}
      >
        <Grid item xs={10} classes={{ root: classes.logoWrapper }}>
          <img src={images?.[0]?.url} alt={name} className={classes.logo} />
          <Ellipsis
            withTooltip={false}
            text={name}
            classes={{ root: classes.name }}
          />
        </Grid>
        {icon}
      </Flex>
    </Grid>
  )
}

export const Item = appWithStyles(styles)(React.memo(ItemComponent))
