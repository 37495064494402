import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

type Props = SvgIconProps & {}

export const SelectedMarkRoundedIcon: React.FC<Props> = props => {
  return (
    <SvgIcon viewBox="0 0 34 34" fill="none" {...props}>
      <g filter="url(#filter0_b_4550_7318)">
        <circle cx="17" cy="17" r="17" fill="#75B952" fillOpacity="0.4" />
      </g>
      <path
        d="M24.7681 11.3658L25.6386 12.2342C26.124 12.7185 26.1199 13.4997 25.6295 13.9791L16.331 23.0664C15.6617 23.7205 14.6136 23.7749 13.8813 23.2203L13.7393 23.1013L8.39506 18.1555C7.89154 17.6896 7.86592 16.9088 8.33782 16.4116L9.18399 15.5201C9.62216 15.0584 10.3353 15.0036 10.838 15.3712L10.9501 15.4636L14.9801 19.1933L23.0009 11.3567C23.4916 10.8776 24.2826 10.8817 24.7681 11.3658Z"
        fill="#75B952"
      />
      <defs>
        <filter
          id="filter0_b_4550_7318"
          x="-24.4645"
          y="-24.4645"
          width="82.9291"
          height="82.9291"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="12.2323" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_4550_7318"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_4550_7318"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  )
}
