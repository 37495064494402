import React from 'react'
import ButtonDefault from './Default'

const AdvancedButton = props => {
  const types = {
    rounded: 'rounded',
    rectangular: 'rectangular',
  }
  const {
    mainText,
    icon = null,
    secondaryText,
    secondaryTextValue = '',
    customClass = '',
  } = props
  return (
    <ButtonDefault
      {...props}
      customClass={`advanced-button ${customClass}`}
      type={types[props.type]}
      icon={null}
    >
      <div className="label">
        {icon}
        <p className="text fz-14">{mainText}</p>
      </div>
      <div className="info">
        <p className="text secondary">{secondaryText}</p>
        <p className="text value">{secondaryTextValue}</p>
      </div>
    </ButtonDefault>
  )
}

export default AdvancedButton
