import { configure, decorate, extendObservable, action } from 'mobx'
import { FollowersGridModel } from 'models'
import { FOLLOWERS } from 'const/api/grids'
import { abbreviateNumber } from 'utils/abbreviateNumber'

configure({ enforceActions: 'observed' })
export default class FollowersGridStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      ids: [],
      displayedCount: 0,
      followersActivity: [],
    })
    this.model = FollowersGridModel
  }

  getFollowers() {
    this.loading = true
    FollowersGridModel.list(FOLLOWERS)
      .then(
        action(collection => {
          this.ids = collection.toArray()
          this.displayedCount = abbreviateNumber(collection.length, {
            style: 'uppercase',
          })
        }),
      )
      .finally(action(() => (this.loading = false)))
  }
}

decorate(FollowersGridStore, {})
