import { Model } from 'services'
import PersonModel from './person.model'

export default class DepartmentModel extends Model {
  get relations() {
    return {
      persons: {
        class: PersonModel,
        type: 'collection',
      },
    }
  }

  get fillable() {
    return {
      name: 'string',
      images: 'array',
    }
  }
}
