import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = ({ custom, spacing }: AppTheme) => {
  return appCreateStyles({
    container: {
      paddingTop: spacing(2),
    },
    line: {
      height: 1,
      color: custom.colors.gray[450],
      background: 'rgba(216, 216, 216, 0.1)',
    },
    block: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: spacing(3),
    },
    count: {
      color: custom.colors.gray[100],
      fontSize: 14,
      fontWeight: 700,
    },
    reset: {},
    control: {
      '& > button + button': {
        marginLeft: 5,
      },
      '& .MuiButton-startIcon': {
        marginLeft: 4,
        marginRight: -3,
      },
      '& .MuiButton-endIcon': {
        marginLeft: 1,
        marginRight: 1,
      },
      '& svg': {
        fontSize: 20,
      },
    },
  })
}
