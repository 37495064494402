import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

const stepperHeight = 46
const contentMargin = 16

export function styles({ custom }: AppTheme) {
  return appCreateStyles({
    root: {},
    rootCompleted: {
      width: 500,
      height: 476,
    },
    completedStepCloseIcon: {
      display: 'none',
    },
    body: {
      backgroundColor: appFade(custom.colors.gray[500], 0.9),
      height: '100%',
      width: '100%',
    },
    content: {
      height: `calc(100% - ${stepperHeight + contentMargin}px)`,
      padding: '0px 1px',
      width: '100%',
      marginTop: contentMargin,
    },
  })
}
