import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Application } from 'services'
import cx from 'classnames'

import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons'
import { IconButton, Backdrop, Divider } from '@material-ui/core'

import { debounce } from 'lodash-es'

import { SearchModalViewModel, SearchResponse } from './search-modal.vm'
import { Tab, Tabs } from './components/tabs/tabs'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { TextField } from 'shared/components/text-field'
import { Message } from 'shared/components/message'
import {
  NotificationType,
  showNotification,
} from 'shared/components/notification/notification'
import { getEmptyState } from './search-modal.utils'
import { ContentList } from './components/content-list'
import { AIList } from './components/ai-list'
import { UsersList } from './components/users-list'
import { ContentGetQuery } from 'shared/models/content/get-model'
import { Loading } from 'shared/components/loading'
import {
  AI_SCROLLBAR_WIDTH,
  CONTENT_PADDING,
  COUNTER_HEIGHT,
  AI_MODAL_FOOTER_HEIGHT,
  AI_MOBILE_MODAL_FOOTER_HEIGHT,
  templatesList,
  ISearchAiTemplate,
  SUMMARY_MARGIN_BOTTOM,
} from './search-modal.content'
import { Flex } from 'shared/components/flex'
import { abbreviateNumber } from 'utils/abbreviateNumber'
import { UserListQuery } from 'shared/models/user/list-model'
import { PersonGetQuery } from 'shared/models/person/get-model'
import { PersonList } from './components/person-list'

import { GlobalFilters } from './components/filters/global-filters/global-filters'
import { GlobalSearchFilter } from '../../shared/types/services/search'
import { AiCategories } from './components/ai-categories'
import { AiIcon } from 'shared/icons/ai'

import { styles } from './search-modal.styles'
import { Link } from 'shared/components/link'
import { Button } from 'shared/components/button'
import { ROUTES } from 'shared/constants/routes'

type SearchData = Record<
  Tab,
  SearchResponse<ContentGetQuery | UserListQuery | PersonGetQuery>
>

const defaultData: SearchData = [Tab.content, Tab.people, Tab.users].reduce(
  (acc, type) => {
    acc[type] = {
      items: [],
      total: 0,
    }
    return acc
  },
  {} as SearchData,
)

type FetchListConfig = Record<
  Tab,
  (
    query: string,
    filter: GlobalSearchFilter,
    page: number,
  ) => Promise<{ items: Array<unknown>; total: number }>
>

export type ModalProps = AppWithStyles<typeof styles> & {
  isLoggedIn: boolean
  onClose: () => void
  paramsValue: string
  initialSearchAiTemplate?: ISearchAiTemplate;
}

const SearchModalComponent: React.FC<ModalProps> = ({
  classes,
  isLoggedIn,
  onClose,
  paramsValue,
  initialSearchAiTemplate,
}) => {
  const isMobile = window.innerWidth < 980;
  const $app = Application.instance()
  const $vm = useMemo(() => new SearchModalViewModel(), [])

  const fetchListConfig: FetchListConfig = useMemo(() => {
    return {
      [Tab.content]: $vm.searchContent,
      [Tab.people]: $vm.searchPersons,
      [Tab.users]: $vm.searchUsers,
    }
  }, [$vm])

  const inputRef = useRef<HTMLInputElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const filterRef = useRef<HTMLDivElement>(null)
  const summaryRef = useRef<HTMLDivElement>(null)
  const isMadeInitialSearchRef = useRef<boolean>(false)

  const [userPreferences, setUserPreferences] = useState({ label: '', amount: 0 })
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<SearchData>(defaultData)
  const [AIData, setAIData] = useState<any>(null)
  const [searchValue, setSearchValue] = useState(paramsValue || '')
  const [aiSearchValueSubmitted, setAiSearchValueSubmitted] = useState(paramsValue || '')
  const [searchAITemplate, setSearchAITemplate] = useState<ISearchAiTemplate | null>(initialSearchAiTemplate ?? null)
  const [filter, setFilter] = useState<GlobalSearchFilter>(null)
  const [visited, setVisited] = useState<Record<Tab, boolean>>({
    [Tab.content]: false,
    [Tab.people]: false,
    [Tab.users]: false,
  })
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.content)
  enum StoragePersonalizationValue {
    dontShowAgain = '1'
  }
  const storagePersonalizationKey = 'dontShowAgainPersonalization';
  const [hideAiPersonalizationMsg, setHideAiPersonalizationMsg] = useState(
    localStorage.getItem(storagePersonalizationKey) === StoragePersonalizationValue.dontShowAgain
  )

  const history = useHistory()

  const fetchList = useCallback(
    async (query: string, page: number, increment = true) => {
      try {
        if (!hasFilterValue() && query === '') {
          setData(defaultData)
          return
        }

        const filterValue = currentTab === Tab.content ? filter : null

        const { items, total } = await fetchListConfig[currentTab](
          query,
          filterValue,
          page,
        )

        setData(prevState => ({
          ...prevState,
          [currentTab]: {
            total,
            items: increment
              ? [
                  ...prevState[currentTab].items,
                  ...items.map(({ asJson }) => asJson),
                ]
              : items.map(({ asJson }) => asJson),
          },
        }))
      } catch (err) {
        showNotification(
          'Something went wrong while searching',
          NotificationType.error,
        )
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line
    [currentTab, filter, fetchListConfig],
  )

  const updateDontShowAgainPersonalization = React.useCallback(() => {
    localStorage.setItem(storagePersonalizationKey, StoragePersonalizationValue.dontShowAgain);
    setHideAiPersonalizationMsg(true);
  }, []);

  useEffect(() => {
    if (!visited[currentTab] && searchValue && !Boolean(searchAITemplate)) {
      setLoading(true)
      fetchList(searchValue, 0, false)
    }
    // eslint-disable-next-line
  }, [fetchList, searchValue, visited])

  useEffect(() => {
    inputRef.current.focus({ preventScroll: true })
    $vm.getUserPreferences().then(x => setUserPreferences(x));
  }, [])

  const debouncedHandleSearch = useMemo(() => {
    return debounce((query: string) => {
      fetchList(query, 0, false)
    }, 800)
  }, [fetchList])

  useEffect(() => {
    if (
      !filter ||
      Object.keys(filter).length === 0 ||
      Boolean(searchAITemplate)
    )
      return

    debouncedHandleSearch(searchValue)
    // eslint-disable-next-line
  }, [debouncedHandleSearch, filter, searchAITemplate])

  useEffect(() => {
    if (currentTab !== Tab.content) {
      setFilter(null)
    }
  }, [currentTab])

  const hasFilterValue = (): boolean => {
    if (!filter) return false

    return !!Object.entries(filter).find(([key, value]) => {
      if (key === 'sort') return false
      return Boolean(value)
    })
  }

  const applyCustomFilters = useCallback((newFilter: GlobalSearchFilter) => {
    setFilter(newFilter)
  }, [])

  const handleSearch = useCallback(
    (query: string) => {
      setSearchValue(query)

      const updatedVisitedState = Object.keys(visited).reduce<
        Record<Tab, boolean>
      >((acc, tab) => {
        acc[tab] = tab === currentTab

        return acc
      }, {} as Record<Tab, boolean>)

      setVisited(updatedVisitedState)

      if (!query) {
        setAiSearchValueSubmitted('');
        setData(prevState => ({
          ...prevState,
          [currentTab]: {
            items: [],
            total: 0,
          },
        }))
        setSearchAITemplate(null)
        setAIData(null)
      }

      if (query && !Boolean(searchAITemplate)) {
        setLoading(true)
        debouncedHandleSearch(query)
      }
    },
    [currentTab, visited, debouncedHandleSearch, searchAITemplate],
  )

  const makeAiSearch = async (val: string) => {
    setAiSearchValueSubmitted(val);
    setLoading(true)
    setAIData(null)

    const prohibitedSymbols = ['[', ']', '{', '}']
    const value = val
      .split('')
      .filter(item => !prohibitedSymbols.includes(item))
      .join('')

    const query = `movies, tv series ${searchAITemplate.value} ${value}`
    try {
      const { entries, summary } = await $vm.searchAI(query)
      const entriesUpdate = entries
        .filter(item => Boolean(item.content))
        .map(item => {
          return {
            ...item.content,
            title: item.title,
            description: item.description,
          }
        })

      setAIData({ entries: entriesUpdate, summary })
    } catch (err) {
      showNotification(
        'Something went wrong while searching',
        NotificationType.error,
      )
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleAISearch = async e => {
    if (e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault();

      await makeAiSearch(e.target.value);
    }
  }

  useEffect(() => {
    if (initialSearchAiTemplate && !isMadeInitialSearchRef.current) {
      makeAiSearch(searchValue);
      isMadeInitialSearchRef.current = true;
    }
  }, [searchValue, initialSearchAiTemplate]);

  const handleTabChange = useCallback(
    (e, value: Tab) => {
      setVisited(prevState => ({
        ...prevState,
        [currentTab]: true,
      }))
      setCurrentTab(value)
    },
    [currentTab],
  )

  const [contentWidth, setContentWidth] = useState(contentRef.current?.offsetWidth)

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      setContentWidth(entries[0].contentRect.width)
    })
    observer.observe(contentRef.current)

    return () => contentRef.current && observer.unobserve(contentRef.current)
  }, [])

  const content = useMemo(() => {
    const contentHeight = contentRef.current?.offsetHeight

    const commonListProps = {
      getList: fetchList,
      total: data[currentTab].total,
      query: searchValue,
      width: contentWidth - CONTENT_PADDING * 2,
      classes: {},
    }

    const height = contentHeight - CONTENT_PADDING * 2 - (AIData ? 0 : COUNTER_HEIGHT)

    const getHeightForContent = (): number => {
      const filterHeight = filterRef.current?.offsetHeight

      return height - filterHeight
    }

    if (isLoading) {
      return <Loading height={70} />
    }

    if (Boolean(searchAITemplate) && AIData) {
      const heightWithSummary = height
        - summaryRef.current?.offsetHeight
        - AI_MODAL_FOOTER_HEIGHT
        - CONTENT_PADDING
        - SUMMARY_MARGIN_BOTTOM;

      const heightWithSummaryMobile = height
        - summaryRef.current?.offsetHeight
        - AI_MOBILE_MODAL_FOOTER_HEIGHT
        - SUMMARY_MARGIN_BOTTOM;

      return AIData?.entries.length ? (
        <AIList
          data={AIData.entries}
          height={isMobile ? heightWithSummaryMobile : heightWithSummary}
          {...commonListProps}
          width={contentWidth + AI_SCROLLBAR_WIDTH}
        />
      ) : (
        <Message
          icon={<SearchIcon classes={{ root: classes.emptyStateIcon }} />}
          heading={'Sorry, there is no result to display'}
          classes={{ root: classes.emptyState }}
        />
      )
    }

    const message = getEmptyState(isLoggedIn, data[currentTab].total)

    if (message) {
      return (
        <Message
          icon={<SearchIcon classes={{ root: classes.emptyStateIcon }} />}
          heading={message}
          classes={{ root: classes.emptyState }}
        />
      )
    }

    const config: Record<Tab, () => JSX.Element> = {
      [Tab.content]: () => (
        <ContentList
          data={data[currentTab].items as Array<ContentGetQuery>}
          height={getHeightForContent()}
          {...commonListProps}
        />
      ),
      [Tab.people]: () => (
        <PersonList
          data={data[currentTab].items as Array<PersonGetQuery>}
          height={height}
          {...commonListProps}
        />
      ),
      [Tab.users]: () => (
        <UsersList
          data={data[currentTab].items as Array<UserListQuery>}
          height={height}
          {...commonListProps}
        />
      ),
    }

    return (
      <>
        {config[currentTab]()}
        <Flex
          justify="center"
          alignItems="center"
          classes={{ root: classes.counter }}
        >
          {abbreviateNumber(data[currentTab].items.length)} of{' '}
          {abbreviateNumber(data[currentTab].total)}
        </Flex>
      </>
    )
    // eslint-disable-next-line
  }, [currentTab, isLoading, data, isLoggedIn, summaryRef, searchAITemplate, contentWidth])

  const handleOnClose = () => {
    setCurrentTab(Tab.content)
    setData(defaultData)
    setSearchValue('')
    setAiSearchValueSubmitted('')
    history.push(window.location.pathname)
    onClose()
  }

  const handleAiTemplateChange = React.useCallback((template: ISearchAiTemplate) => {
    setSearchAITemplate(template)
    setSearchValue('')
  }, []);

  const searchValueProcessed = searchValue.trim();

  return (
    <Backdrop open={true} className={classes.root}>
      <IconButton
        classes={{ root: classes.closeIconWrapper }}
        onClick={handleOnClose}
      >
        <CloseIcon classes={{ root: classes.closeIcon }} />
      </IconButton>
      <div className={classes.body}>
        <div className={classes.searchWrapper}>
          <TextField
            classes={{
              root: classes.searchInput,
              border2: classes.searchInputBase,
            }}
            clearable={Boolean(searchValue) || Boolean(searchAITemplate)}
            multiline
            inputRef={inputRef}
            value={searchValue}
            placeholder={searchAITemplate 
              ? searchAITemplate.hint
              : `Search content, people${isLoggedIn ? ', users' : ''}`}
            variant="outlined"
            onChange={handleSearch}
            onKeyDown={handleAISearch}
            InputProps={{
              startAdornment: (
                <>
                  <SearchIcon classes={{ root: classes.searchIcon }} />
                  <div className={cx(classes.selectedTemplateWrapper, { [classes.selectedTemplateWrapperAi]: Boolean(searchAITemplate) })}>
                    {searchAITemplate && <AiIcon />}
                    <div className={classes.selectedTemplate}>
                      {searchAITemplate ? searchAITemplate.text : 'Search for a Title or Person'}
                    </div>
                  </div>
                </>
              ),
            }}
            allBorder={false}
          />
          {searchAITemplate && searchValueProcessed && !isLoading && <span className={classes.searchHint}>Press <b>Enter ↵</b></span>}
          {!searchAITemplate && !searchValueProcessed  && (
            <>
              <Divider className={classes.sectionDivider} />
              <Flex justify="center" className={classes.sectionLabelDiver}>or</Flex>
            </>
          )}
          {((!searchAITemplate && !searchValueProcessed) || (searchAITemplate && !aiSearchValueSubmitted)) && (
            <Flex justify="center">
              <AiCategories
                selectedItem={searchAITemplate}
                data={templatesList}
                onChange={handleAiTemplateChange}
                classes={{
                  root: classes.aiCategories,
                }}
              />
            </Flex>
          )}
        </div>
        {!searchAITemplate && searchValueProcessed && (
          <>
            <Tabs
              classes={{ root: classes.tabs }}
              isLoggedIn={isLoggedIn}
              value={currentTab}
              onChange={handleTabChange}
            />

            {currentTab === Tab.content && (
              <div ref={filterRef}>
                <GlobalFilters
                  onChangeFilter={applyCustomFilters}
                  hasQueryString={searchValueProcessed.length > 0}
                />
              </div>
            )}
          </>
        )}
        <div
          ref={contentRef}
          className={
            Boolean(searchAITemplate) ? classes.content2 : classes.content
          }
        >
          {Boolean(searchAITemplate) && AIData?.entries.length ? (
            <div ref={summaryRef} style={{ marginBottom: SUMMARY_MARGIN_BOTTOM }}>
              <div className={classes.titleAi}>Your Skroote AI assistant says:</div>
              <div className={classes.summary} style={{ marginBottom: 8 }}>{AIData?.summary}</div>
              {!hideAiPersonalizationMsg && userPreferences.label && (
                <Flex autoWidth={false} className={classes.summary} justify="space-between" alignItems="center">
                  <div>Your <Link className={classes.personalizationLink} to={ROUTES.private.profile.children.lists($app.auth.user.id)}>{userPreferences.label} content</Link> {userPreferences.amount > 1 ? 'lists were' : 'list was'} used to help personalise these recommendations.</div>
                  <Button 
                    className={classes.hidePersonalizationBtn} 
                    variant="text" 
                    text="Don't show again" 
                    onClick={updateDontShowAgainPersonalization} 
                  />
                </Flex>
              )}
            </div>
          ) : null}

          {(searchAITemplate 
            ? aiSearchValueSubmitted
            : searchValueProcessed
          ) && content}
        </div>
      </div>
    </Backdrop>
  )
}

export const SearchModal = appWithStyles(styles)(SearchModalComponent)
