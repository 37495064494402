import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Application } from 'services'
import cx from 'classnames'

import { Search as SearchIcon } from '@material-ui/icons'
import { Menu as MenuIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import Logo from 'components/Logo'
import ButtonDefault from 'components/Buttons/Default'
import { MenuModal } from './components/menu-modal'
import { Avatar } from 'shared/components/avatar'
import { SearchModal } from 'components/search-modal/search-modal'
import { ROUTES } from 'shared/constants/routes'

import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './mobile-header.styles'

type Props = AppWithStyles<typeof styles>

const MobileHeaderComponent: React.FC<Props> = ({ classes }) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)

  const searchParams = new URLSearchParams(window.location.search)
  const searchValue = searchParams.get('query')

  useEffect(() => {
    if (searchValue) {
      setOpenSearch(true)
    }
  }, [searchValue])

  const $app = Application.instance()
  const loggedIn = $app.auth.isLoggedIn()

  const handleMenuClick = () => {
    setOpenMenu(!openMenu)
  }

  const handleSearchClick = () => {
    setOpenSearch(!openSearch)
  }

  const handleSignInClick = () =>
    $app.event.$emit('overlay.activity.login', {
      status: true,
      mobile: true,
    })

  return (
    <div className={classes.root}>
      <div className={classes.headerPart}>
        {!openMenu && (
          <IconButton
            onClick={handleMenuClick}
            className={classes.headerButton}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        )}

        {openMenu && (
          <IconButton
            onClick={handleMenuClick}
            className={classes.headerButton}
          >
            <CloseIcon />
          </IconButton>
        )}

        {openMenu && <MenuModal handleNavClick={handleMenuClick} />}
        <Logo />
      </div>

      <div className={classes.headerPart}>
        <IconButton
          onClick={handleSearchClick}
          className={cx(classes.headerButton, classes.aiBtn)}
        >
          <div className={classes.aiOption}>AI</div>
          <SearchIcon className={classes.searchIcon} />
        </IconButton>
        {!loggedIn && (
          <ButtonDefault
            onClick={handleSignInClick}
            bgColor="green"
            type="rounded"
            style={{ margin: '0', color: '#fff' }}
            disabled={$app.auth.isAuthInProgress()}
          >
            <div>Sign In</div>
          </ButtonDefault>
        )}
        {loggedIn && (
          <>
            <Link to={ROUTES.private.profile.root($app.auth.user.id)}>
              <Avatar src={$app.auth.user.avatar} />
            </Link>
            <ButtonDefault
              type="link"
              color="gray"
              customClass="more"
              style={{ marginRight: 0 }}
              onClick={$app.auth.signOut}
            >
              <span className="fz-14 white-opacity-04">Sign Out</span>
            </ButtonDefault>
          </>
        )}
      </div>

      {openSearch && (
        <SearchModal
          isLoggedIn={true}
          onClose={handleSearchClick}
          paramsValue={searchValue}
        />
      )}
    </div>
  )
}

export const MobileHeader = appWithStyles(styles)(MobileHeaderComponent)
