import { Application, Model, Url } from 'services'
import { PERSON, GRID_CONTENT } from 'const/api/search'

export default class SearchModel extends Model {
  get fillable() {
    return {}
  }

  /**
   * Get type of model
   * @returns {string}
   */
  getType() {
    return 'SEARCH'
  }

  /**
   * Get persons
   *
   * @param params
   * @param typeahead
   * @returns {Promise<any>}
   */
  static listPerson(params, typeahead = '') {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery({ ...params }).queryToString() : ''
      const endpoint = `${PERSON}${typeahead}${query}`
      app.newrequest
        .get(endpoint)
        .then(response => {
          resolve(self.createFromResponse(response.data, endpoint))
        })
        .catch(error => reject(error))
    })
  }

  // static listUsers(params) {
  // 	const app = Application.instance();
  // 	const self = this;
  // 	const url = new Url();
  // 	return new Promise((resolve, reject) => {
  // 		const query = params
  // 			? url
  // 				.setQuery({ ...params })
  // 				.queryToString()
  // 			: '';
  // 		const endpoint = `${USERS}${query}`;
  // 		app.newrequest
  // 			.get(endpoint)
  // 			.then(response => {
  // 				resolve(self.createFromResponse(response.data, endpoint));
  // 			})
  // 			.catch(error => reject(error));
  // 	});
  // }

  static listUsers(params) {
    const self = this

    return new Promise((resolve, reject) => {
      resolve(
        self.createFromResponse([
          {
            avatar: 'string',
            email: 'string',
            fullName: 'string',
            id: 'string',
          },
        ]),
      )
    })

    // const app = Application.instance();
    // const self = this;
    // const url = new Url();
    // return new Promise((resolve, reject) => {
    // 	const query = params
    // 		? url
    // 			.setQuery({ ...params })
    // 			.queryToString()
    // 		: '';
    // 	const endpoint = `${USERS}${query}`;
    // 	app.newrequest
    // 		.get(endpoint)
    // 		.then(response => {
    // 			resolve(self.createFromResponse(response.data, endpoint));
    // 		})
    // 		.catch(error => reject(error));
    // });
  }

  /**
   * Get content
   *
   * @param params
   * @param typeahead
   * @returns {Promise<any>}
   */
  static listContent(params, typeahead = '') {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery({ ...params }).queryToString() : ''
      const endpoint = `${GRID_CONTENT}${typeahead}${query}`
      app.newrequest
        .get(endpoint)
        .then(response => {
          resolve(self.createFromResponse(response.data, endpoint))
        })
        .catch(error => reject(error))
    })
  }
  // static listContent(params, typeahead = '') {
  // 	const app = Application.instance();
  // 	const self = this;
  // 	const url = new Url();
  // 	return new Promise((resolve, reject) => {
  // 		const query = params
  // 			? url
  // 					.setQuery({ limit: 50, 'sort.attribute': 'POPULARITY', ...params })
  // 					.queryToString()
  // 			: '';
  // 		const endpoint = `${CONTENT}${typeahead}${query}`;
  // 		app.request
  // 			.get(endpoint)
  // 			.then(response => {
  // 				resolve(self.createFromResponse(response.data, endpoint));
  // 			})
  // 			.catch(error => reject(error));
  // 	});
  // }
}
