import { inject } from 'core/di/di-utils'
import { extendObservable, observable } from 'mobx'
import { DI_TOKENS } from 'shared/constants/di'
import { BannerModel } from 'shared/models/banner/banner'
import { IBannerService } from 'shared/types/services/banner'

export class HeaderViewModel {
  private readonly _bannerService = inject<IBannerService>(
    DI_TOKENS.bannerService,
  )

  private _banner: BannerModel | null = null
  private _isBannerOpen: boolean = false

  private readonly _navigationListData = [
    {
      name: 'Home',
      to: '/',
    },
    {
      name: 'TV Shows',
      to: '/tv-shows',
    },
    {
      name: 'Sports',
      to: '/sports',
    },
    {
      name: 'Movies',
      to: '/movies',
    },
    {
      name: 'Live',
      to: '/lives',
    },
  ]

  constructor() {
    extendObservable(this, {
      _banner: observable,
      _isBannerOpen: observable,
    })
    this.handleBannerOpen()
  }

  get navigationListData() {
    return this._navigationListData
  }

  get banner() {
    return this._banner
  }

  get isBannerOpen() {
    return this._isBannerOpen
  }

  handleBannerOpen = async () => {
    try {
      const response = await this._bannerService.getBanner()
      this._banner = response[0]

      const viewedBanners: Array<string> =
        JSON.parse(localStorage.getItem('banners')) || []

      const alreadyViewed = Boolean(
        viewedBanners.find(banner => banner === this._banner?.id),
      )

      this._isBannerOpen = !alreadyViewed

      const updatedViewedBanners = alreadyViewed && this._banner
        ? viewedBanners
        : [...viewedBanners, this._banner?.id]

      localStorage.setItem('banners', JSON.stringify(updatedViewedBanners))
    } catch (e) {
      console.error(e)
    }
  }

  handleBannerClose() {
    this._isBannerOpen = false
  }
}
