/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  }
  return str
}

/**
 * Text toLowerCase
 */
export function lowerCase(value) {
  if (value && typeof value !== 'string') return
  return value.toLowerCase().trim()
}

/**
 * Text toUpperCase
 */
export function upperCase(value) {
  if (value && typeof value !== 'string') return
  return value.toUpperCase().trim()
}

export const capitalize = value =>
  value && value.replace(/(?:^|\s)\S/g, a => a.toUpperCase())
