import React from 'react'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { SelectButton, SelectButtonView } from '../select-button/select-button'

import { styles } from './select-multiply-pagination.styles'

type ComponentProps = {
  label: string
  onReset: () => void
  onPaginate: (page: number) => void
  selectedCount: number
  page: number
  maxPage: number
}

type Props = AppWithStyles<typeof styles> & ComponentProps

const _SelectMultiplyPagination: React.FC<Props> = ({
  classes,
  label,
  onReset,
  onPaginate,
  selectedCount,
  page,
  maxPage,
}) => {
  const isFirstPage = page === 1
  const isLastPage = page === maxPage

  const handleBackPaginate = () => {
    if (!isFirstPage) {
      onPaginate(page - 1)
    }
  }
  const handleForwardPaginate = () => {
    if (!isLastPage) {
      onPaginate(page + 1)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.line} />
      <div className={classes.block}>
        {Boolean(selectedCount) ? (
          <>
            <div className={classes.reset}>
              <SelectButton text={'Reset'} onClick={onReset} />
            </div>
            <div className={classes.count}>
              {selectedCount} {label} Selected
            </div>
          </>
        ) : (
          <div />
        )}
        <div className={classes.control}>
          <SelectButton
            view={SelectButtonView.FILL}
            onClick={handleBackPaginate}
            startIcon={<ArrowBackIosIcon />}
            disabled={isFirstPage}
          />
          <SelectButton
            view={SelectButtonView.FILL}
            onClick={handleForwardPaginate}
            endIcon={<ArrowForwardIosIcon />}
            disabled={isLastPage}
          />
        </div>
      </div>
    </div>
  )
}

export const SelectMultiplyPagination = appWithStyles(styles)(
  _SelectMultiplyPagination,
)
