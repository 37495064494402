import React from 'react'

export const HomeIcon = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Combined-Shape"
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.55192 11.0921C1.19689 11.4455 0.621289 11.4455 0.266267 11.0921C-0.0887555 10.7388 -0.0887555 10.1659 0.266267 9.81262L9.35718 0.764999C9.7122 0.411667 10.2878 0.411667 10.6428 0.764999L19.7337 9.81262C20.0888 10.1659 20.0888 10.7388 19.7337 11.0921C19.3787 11.4455 18.8031 11.4455 18.4481 11.0921L18.1818 10.8274V17.6945C18.1818 18.6933 17.3689 19.5 16.3628 19.5H3.63716C2.63348 19.5 1.81818 18.6934 1.81818 17.6945V10.8274L1.55192 11.0921ZM3.63716 17.6905L6.36364 17.691V12.2597C6.36364 11.7612 6.77023 11.3571 7.27003 11.3571H12.73C13.2306 11.3571 13.6364 11.7609 13.6364 12.2597V17.6929L16.3636 17.6945V9.01944L10 2.69166L3.63632 9.01948C3.63613 10.7308 3.63716 17.6905 3.63716 17.6905ZM11.8182 13.1653V17.6923L8.18182 17.6914V13.1667L11.8182 13.1653Z"
        fill="white"
      />
    </svg>
  )
}
