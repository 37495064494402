import React, { useState } from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { SelectMultiplyList } from './components/select-multiply-list/select-multiply-list'
import { SelectField } from './components/select-field/select-field'
import { SelectMultiplyPagination } from './components/select-multiply-pagination/select-multiply-pagination'
import { SelectListItem } from '../filters/model/global-filter.types'

import { styles } from './select.styles'

type ComponentProps = {
  options: SelectListItem[]
  value: string[]
  onChange: (v: string) => void
  onPaginate: (page: number) => void
  onReset: () => void
  label?: string
  page: number
  maxPage: number
  disabled?: boolean
}

type Props = AppWithStyles<typeof styles> & ComponentProps

const _CustomMultiSelect: React.FC<Props> = ({
  classes,
  options,
  value,
  onChange,
  onPaginate,
  onReset,
  label,
  page,
  maxPage,
  disabled = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnOpen = () => {
    if (!disabled) {
      setIsOpen(true)
    }
  }
  const handleOnClose = () => setIsOpen(false)

  const getCountSelected = (): number => {
    const ALL_KEY = 'ALL'
    const hasAllValue = Boolean(value.find(item => item === ALL_KEY))

    if (hasAllValue) {
      return value.length - 1
    }
    return value.length
  }
  const selectedCount = getCountSelected()

  const getTitle = (): string => {
    const showMaxCount = 2
    const ALL_KEY = 'ALL'
    const hasAllValue = Boolean(value.find(item => item === ALL_KEY))

    if (hasAllValue) {
      return `${ALL_KEY} +${selectedCount}`
    }
    const title = [...value]
      .slice(0, showMaxCount)
      .map(i => i)
      .join(', ')

    if (value.length > showMaxCount) {
      return `${title} +${selectedCount - showMaxCount}`
    }
    return value.length > 0 ? title : ''
  }
  const valueTitle = getTitle()

  const getLabel = (): string => {
    const lastCharS = label[label.length - 1] === 's'

    if (valueTitle) {
      return lastCharS ? label : `${label}s`
    }
    return label
  }

  const formattedLabel = getLabel()

  return (
    <ClickAwayListener onClickAway={handleOnClose}>
      <div className={classes.container}>
        <SelectField
          onClose={handleOnOpen}
          label={formattedLabel}
          value={valueTitle}
          disabled={disabled}
        />
        {isOpen && (
          <SelectMultiplyList
            onChange={onChange}
            options={options}
            value={value}
          >
            <SelectMultiplyPagination
              label={formattedLabel}
              selectedCount={selectedCount}
              onReset={onReset}
              onPaginate={onPaginate}
              page={page}
              maxPage={maxPage}
            />
          </SelectMultiplyList>
        )}
      </div>
    </ClickAwayListener>
  )
}

export const CustomMultiSelect = appWithStyles(styles)(
  React.memo(_CustomMultiSelect),
)
