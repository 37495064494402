import React from 'react'
import Grid from '@material-ui/core/Grid'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Item } from './components/item'
import { SectionTitle } from '../../../section-title'
import { GenreGetQuery } from 'shared/models/genre/genre-get-model'

import { styles } from './genres.styles'

export type GenresProps = AppWithStyles<typeof styles> & {
  selectedItems: Array<GenreGetQuery['id']>
  data: Array<GenreGetQuery>
  requiredItemsAmount: number
  onItemChange: (id: GenreGetQuery['id']) => void
}

const GenresComponent: React.FC<GenresProps> = ({
  classes,
  selectedItems,
  requiredItemsAmount,
  data,
  onItemChange,
}) => {
  return (
    <div className={classes.root}>
      <SectionTitle
        title="Genres"
        hint={`Select at least ${requiredItemsAmount} items`}
        counter={selectedItems.length}
      />
      <Grid container spacing={4} classes={{ root: classes.content }}>
        {data.map(({ id, name }) => {
          return (
            <Item
              key={id}
              selected={selectedItems.includes(id)}
              id={id}
              name={name}
              onChange={onItemChange}
            />
          )
        })}
      </Grid>
    </div>
  )
}

export const Genres = appWithStyles(styles)(GenresComponent)
