import React, { useCallback, useMemo } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Poster } from 'shared/components/poster'
import { ContentGetQuery } from 'shared/models/content/get-model'
import { processContentImages } from 'shared/utils/image'
import { SelectedMarkRoundedIcon } from 'shared/icons/selected-mark-rounded'

import { styles } from './item.styles'

export type ItemProps = AppWithStyles<typeof styles> &
  Required<Pick<ContentGetQuery, 'id' | 'images'>> & {
    selected?: boolean
    onChange: (id: ContentGetQuery['id']) => void
  }

const ItemComponent: React.FC<ItemProps> = ({
  classes,
  id,
  images,
  selected,
  onChange,
}) => {
  const image = useMemo(() => {
    return processContentImages(images)?.POSTER?.url?.medium
  }, [images])

  const handleChange = useCallback(() => {
    onChange(id)
  }, [id, onChange])

  return (
    <div role="button" className={classes.root} onClick={handleChange}>
      {selected && (
        <SelectedMarkRoundedIcon classes={{ root: classes.selectedIcon }} />
      )}
      <Poster src={image} classes={{ root: classes.poster }} />
      {selected && <div className={classes.overlay} />}
    </div>
  )
}

export const Item = appWithStyles(styles)(React.memo(ItemComponent))
