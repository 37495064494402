import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Grid, { GridSize, GridSpacing } from '@material-ui/core/Grid'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { appUseTheme } from 'core/theme/utils/use-theme'

import { styles } from './shimmer.styles'

export type ShimmerProps = AppWithStyles<typeof styles> & {
  itemsAmount: number
  itemProps: {
    height: number | string
    width: number | string
    xs?: GridSize
  }
  containerProps?: {
    height?: number | string
    width?: number | string
    spacing?: GridSpacing
  }
  animationDuration?: number
}

const ShimmerComponent: React.FC<ShimmerProps> = ({
  classes,
  itemsAmount,
  itemProps,
  containerProps,
  animationDuration,
}) => {
  const $theme = appUseTheme()

  return (
    <SkeletonTheme
      baseColor={$theme.palette.background.default}
      highlightColor="#3A3A3E"
    >
      <Grid
        container
        style={{
          height: containerProps.height,
          width: containerProps.width,
        }}
        spacing={containerProps.spacing}
        classes={{ root: classes.root }}
      >
        {Array(itemsAmount)
          .fill('')
          .map((x, i) => {
            return (
              <Grid
                item
                key={i}
                xs={itemProps?.xs}
                classes={{ root: classes.item }}
              >
                <Skeleton
                  className={classes.skeleton}
                  height={itemProps.height}
                  width={itemProps.width}
                  duration={animationDuration}
                />
              </Grid>
            )
          })}
      </Grid>
    </SkeletonTheme>
  )
}

ShimmerComponent.defaultProps = {
  containerProps: {
    spacing: 10,
  },
  animationDuration: 3,
}

export const Shimmer = appWithStyles(styles)(ShimmerComponent)
