import { Provider, UserService } from 'services'

export default class UserProvider extends Provider {
  boot(app) {
    app.bind('user', () => new UserService())
  }

  get config() {
    return {}
  }
}
