import { Model } from 'services'

export default class ProvidersModel extends Model {
  get fillable() {
    return {
      buy: 'array',
      free: 'array',
      paid: 'array',
      rent: 'array',
    }
  }
}
