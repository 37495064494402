import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, custom }: AppTheme) {
  return appCreateStyles({
    root: {},
    subtitle: {
      color: custom.colors.gray[200],
      marginTop: spacing(2),
      fontWeight: 400,
    },
    content: {
      marginTop: spacing(7),
      width: '100%',
    },
    item: {},
  })
}
