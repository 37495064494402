export const MOVIE = 'MOVIE'
export const SHOW = 'SHOW'
export const PERSON = 'PERSON'
export const SPORT = 'SPORT'
export const LIVE = 'LIVE'
export const PROMO = 'PROMO'
export const SEASON = 'SEASON'
export const EPISODE = 'EPISODE'
export const EMPTY = 'EMPTY'
export const GENRE = 'GENRE'
export const PROVIDER = 'PROVIDER'
export const USER = 'USER'
