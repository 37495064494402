import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ shape, palette }: AppTheme) {
  return appCreateStyles({
    root: {
      position: 'relative',
      cursor: 'pointer',
    },
    selectedIcon: {
      position: 'absolute',
      top: -8,
      right: -8,
    },
    poster: {
      width: '100%',
      height: '100%',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      borderRadius: shape.borderRadius,
      width: '100%',
      height: '100%',
      backgroundColor: appFade(palette.primary.dark, 0.4),
    },
  })
}
