import { Application, Collection, Model, Url } from 'services'
import { LIST } from 'const/api/user'
import { MOVIE, SHOW } from 'const/mediaTypes'
import { ShowModel, MovieModel } from '../'

export default class List extends Model {
  constructor() {
    super()
    this.titleType = {
      [MOVIE]: MovieModel,
      [SHOW]: ShowModel,
    }
  }
  get fillable() {
    return {
      id: 'string',
      name: 'string',
      listType: 'string',
      items: 'collection',
    }
  }

  /**
   * Transform model by type of model {Movie, Show}
   *
   * @param collection
   * @returns {*}
   */
  mutateCollectionModels(collection) {
    const mutatedCollection = new Collection().setPagination(
      collection.paginator,
    )
    collection.forEach((title, index) =>
      mutatedCollection.push(
        new this.titleType[title.mediaType]().fill(title.getAttributes(), true),
      ),
    )
    return mutatedCollection
  }

  /**
   * Get list items content
   */
  getListItemsContent() {
    const app = Application.instance()
    return new Promise((resolve, reject) => {
      const endpoint = `${LIST}/${this.id}/item/content`
      app.request
        .get(endpoint)
        .then(response => {
          this.items = this.mutateCollectionModels(
            List.createFromResponse(response.data),
          )
          resolve(this.items)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get list items
   */
  getListItems() {
    const app = Application.instance()
    return new Promise((resolve, reject) => {
      const endpoint = `${LIST}/${this.id}/item/all`
      app.request
        .get(endpoint)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  /**
   * Get all lists
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  static list(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${LIST}/all${query}`
      app.request
        .get(endpoint)
        .then(response =>
          resolve(self.createFromResponse(response.data, endpoint)),
        )
        .catch(error => reject(error))
    })
  }
}
