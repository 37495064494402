import { Container } from 'inversify'

import { DiEntityIdentifier } from './types'
import { services } from 'shared/services'
import { viewModels } from 'shared/view-models'
import { DI_TOKENS } from 'shared/constants/di'
import { ConfigService } from 'core/services/config'

const diContainer = new Container()

export function initializeDi() {
  const entities: Array<{ diToken: DiEntityIdentifier; entity: any }> = [
    {
      diToken: DI_TOKENS.configService,
      entity: ConfigService,
    },
    ...services,
    ...viewModels,
  ]
  
  entities.forEach(({ diToken, entity }) => {
    diContainer.bind(diToken).to(entity).inSingletonScope()
  })
}


export { diContainer }
