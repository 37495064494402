import { ContentType } from 'shared/models/content/content-type'
import { GridType } from 'shared/models/grid/type'
import { GridOrientation } from 'shared/models/grid/orientation'
import { ImageOrientation } from 'shared/models/image/orientation'
import { GridSize } from 'shared/models/grid/size'

export enum DefaultUserList {
  favorites = 'FAVORITES',
  watchLater = 'WATCH_LATER',
  seen = 'SEEN',
  like = 'LIKE',
}

export const DEFAULT_USER_LISTS_LABELS: Record<DefaultUserList, string> = {
  [DefaultUserList.favorites]: 'Favorites',
  [DefaultUserList.watchLater]: 'Watch later',
  [DefaultUserList.seen]: 'Seen',
  [DefaultUserList.like]: 'Like',
}

export const CONTENT_ITEMS_DIMENSIONS: Record<
  Exclude<ContentType, ContentType.season>,
  Record<ImageOrientation, { width: number; height: number }>
> = {
  [ContentType.movie]: {
    [ImageOrientation.landscape]: {
      height: 234,
      width: 360,
    },
    [ImageOrientation.portrait]: {
      height: 274,
      width: 160,
    },
  },
  [ContentType.show]: {
    [ImageOrientation.landscape]: {
      height: 249,
      width: 360,
    },
    [ImageOrientation.portrait]: {
      height: 289,
      width: 160,
    },
  },
  [ContentType.sport]: {
    [ImageOrientation.landscape]: {
      height: 234,
      width: 360,
    },
    [ImageOrientation.portrait]: {
      height: 270,
      width: 160,
    },
  },
}

export const MOBILE_CONTENT_ITEMS_DIMENSIONS: Record<
  Exclude<ContentType, ContentType.season>,
  Record<ImageOrientation, { width: number; height: number }>
> = {
  [ContentType.movie]: {
    [ImageOrientation.landscape]: {
      height: 181,
      width: 278,
    },
    [ImageOrientation.portrait]: {
      height: 183,
      width: 99,
    },
  },
  [ContentType.show]: {
    [ImageOrientation.landscape]: {
      height: 196,
      width: 278,
    },
    [ImageOrientation.portrait]: {
      height: 196,
      width: 99,
    },
  },
  [ContentType.sport]: {
    [ImageOrientation.landscape]: {
      height: 181,
      width: 278,
    },
    [ImageOrientation.portrait]: {
      height: 183,
      width: 99,
    },
  },
}

export const MAX_GRID_ITEM_DIMENSIONS = {
  [GridType.promo]: {
    [GridOrientation.vertical]: {
      [GridSize.large]: {
        height: 380,
        width: 560,
      },
      [GridSize.regular]: {
        height: 200,
        width: 360,
      },
    },
    [GridOrientation.horizontal]: {
      [GridSize.large]: {
        height: 380,
        width: 560,
      },
      [GridSize.regular]: {
        height: 200,
        width: 360,
      },
    },
    [GridOrientation.mixed]: {
      [GridSize.large]: {
        height: 380,
        width: 560,
      },
      [GridSize.regular]: {
        height: 200,
        width: 360,
      },
    },
  },
  [GridType.content]: {
    [GridOrientation.vertical]: {
      [GridSize.large]: {
        height: 249,
        width: 160,
      },
      [GridSize.regular]: {
        height: 249,
        width: 160,
      },
    },
    [GridOrientation.horizontal]: {
      [GridSize.large]: {
        height: 249,
        width: 360,
      },
      [GridSize.regular]: {
        height: 249,
        width: 360,
      },
    },
    [GridOrientation.mixed]: {
      [GridSize.large]: {
        height: 249,
        width: 360,
      },
      [GridSize.regular]: {
        height: 249,
        width: 360,
      },
    },
  },
  [GridType.grid]: {
    [GridOrientation.vertical]: {
      [GridSize.large]: {
        height: 380,
        width: 560,
      },
      [GridSize.regular]: {
        height: 200,
        width: 360,
      },
    },
    [GridOrientation.horizontal]: {
      [GridSize.large]: {
        height: 380,
        width: 560,
      },
      [GridSize.regular]: {
        height: 200,
        width: 360,
      },
    },
    [GridOrientation.mixed]: {
      [GridSize.large]: {
        height: 380,
        width: 560,
      },
      [GridSize.regular]: {
        height: 200,
        width: 360,
      },
    },
  },
  [GridType.genre]: {
    [GridOrientation.vertical]: {
      [GridSize.large]: {
        height: 160,
        width: 160,
      },
      [GridSize.regular]: {
        height: 160,
        width: 160,
      },
    },
    [GridOrientation.horizontal]: {
      [GridSize.large]: {
        height: 160,
        width: 160,
      },
      [GridSize.regular]: {
        height: 160,
        width: 160,
      },
    },
    [GridOrientation.mixed]: {
      [GridSize.large]: {
        height: 160,
        width: 160,
      },
      [GridSize.regular]: {
        height: 160,
        width: 160,
      },
    },
  },
}

export const MAX_MOBILE_GRID_ITEM_DIMENSIONS = {
  [GridType.promo]: {
    [GridOrientation.vertical]: {
      [GridSize.large]: {
        height: 196,
        width: 99,
      },
      [GridSize.regular]: {
        height: 196,
        width: 99,
      },
    },
    [GridOrientation.horizontal]: {
      [GridSize.large]: {
        height: 200,
        width: 294,
      },
      [GridSize.regular]: {
        height: 162,
        width: 278,
      },
    },
    [GridOrientation.mixed]: {
      [GridSize.large]: {
        height: 200,
        width: 294,
      },
      [GridSize.regular]: {
        height: 162,
        width: 278,
      },
    },
  },
  [GridType.content]: {
    [GridOrientation.vertical]: {
      [GridSize.large]: {
        height: 196,
        width: 99,
      },
      [GridSize.regular]: {
        height: 196,
        width: 99,
      },
    },
    [GridOrientation.horizontal]: {
      [GridSize.large]: {
        height: 200,
        width: 294,
      },
      [GridSize.regular]: {
        height: 162,
        width: 278,
      },
    },
    [GridOrientation.mixed]: {
      [GridSize.large]: {
        height: 200,
        width: 294,
      },
      [GridSize.regular]: {
        height: 162,
        width: 278,
      },
    },
  },
  [GridType.grid]: {
    [GridOrientation.vertical]: {
      [GridSize.large]: {
        height: 196,
        width: 99,
      },
      [GridSize.regular]: {
        height: 196,
        width: 99,
      },
    },
    [GridOrientation.horizontal]: {
      [GridSize.large]: {
        height: 200,
        width: 326,
      },
      [GridSize.regular]: {
        height: 173,
        width: 310,
      },
    },
    [GridOrientation.mixed]: {
      [GridSize.large]: {
        height: 200,
        width: 294,
      },
      [GridSize.regular]: {
        height: 162,
        width: 278,
      },
    },
  },
  [GridType.genre]: {
    [GridOrientation.vertical]: {
      [GridSize.large]: {
        height: 160,
        width: 160,
      },
      [GridSize.regular]: {
        height: 160,
        width: 160,
      },
    },
    [GridOrientation.horizontal]: {
      [GridSize.large]: {
        height: 160,
        width: 160,
      },
      [GridSize.regular]: {
        height: 160,
        width: 160,
      },
    },
    [GridOrientation.mixed]: {
      [GridSize.large]: {
        height: 160,
        width: 160,
      },
      [GridSize.regular]: {
        height: 160,
        width: 160,
      },
    },
  },
}

export const SEASON_EPISODE_ITEM_DIMENSIONS = {
  height: 260,
  width: 360,
}

export const PERSON_ITEM_DIMENSIONS = {
  height: 150,
  width: 140,
}

export const PROMO_ITEM_DIMENSIONS = {
  height: 380,
  width: 560,
}

export const GENRE_ITEM_DIMENSIONS = {
  height: 160,
  width: 160,
}

export const ITEMS_WRAPPER_DIMENSIONS = {
  sport: {
    height: 300,
    width: 390,
  },
  movie: {
    height: 300,
    width: 200,
  },
}

export const MOBILE_ITEMS_WRAPPER_DIMENSIONS = {
  sport: {
    height: 300,
    width: 390,
  },
  movie: {
    height: 183,
    width: 99,
  },
}
