import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles() {
  return appCreateStyles({
    avatar: {
      height: 40,
      width: 40,
    },
    search: {
      height: 40,
    },
  })
}
