import { Application, Model } from 'services'

export default class ContentModel extends Model {
  get fillable() {
    return {
      adult: 'boolean',
      contentType: 'string',
      id: 'string',
      attribute: 'string',
      mediaType: 'string',
      popularity: 'float',
      score: 'float',
      title: 'string',
      overview: 'string',
      tagline: 'string',
      runtime: 'number',
      posterPath: 'string',
      backdropPath: 'string',
      homepage: 'string',
      budget: 'number',
      status: 'string',
      revenue: 'number',
      imdbId: 'string',
      originalTitle: 'string',
      originalLanguage: 'string',
      releaseDate: 'data',
      mediaTrailers: 'collection',
      images: 'array',
      description: 'string',
      genres: 'array',
      url: 'string',
    }
  }

  clear() {
    return new this.constructor()
  }

  /**
   * Get type of model
   *
   * @returns {string}
   */
  getType() {
    return this.mediaType
  }

  getById(id) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.newrequest
        .get(`contents/${id}`)
        .then(response => {
          response
            ? resolve(self.fillFromResponse(response.data))
            : app.router.push('/not-found')
        })
        .catch(error => reject(error))
    })
  }

  static getContentList(ids) {
    const app = Application.instance()

    return new Promise((resolve, reject) => {
      app.newrequest
        .post('contents/list', ids)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
}
