import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { Application } from 'services'
import { extendObservable, observable } from 'mobx'
import { IAnnouncementsService } from 'shared/types/services/notifications'
import {
  AnnouncementGetListModel,
  AnnouncementStatus,
} from 'shared/models/announcement/announcements-get-list'

export class AnnouncementViewModel {
  private $app = Application.instance()
  private announcementsService = inject<IAnnouncementsService>(
    DI_TOKENS.announcementsService,
  )

  private _announcements: AnnouncementGetListModel[] = []

  constructor() {
    extendObservable(this, {
      _announcements: observable,
    })
  }

  get isLoggedIn(): boolean {
    return this.$app.auth.isLoggedIn()
  }

  get isAuthLoading(): boolean {
    return this.$app.auth.checkLoading
  }

  get announcements(): AnnouncementGetListModel[] {
    return this._announcements
  }

  get hasOfNewAnnouncements(): boolean {
    return this.isLoggedIn ? Boolean(this.countOfNewAnnouncements) : false
  }

  get countOfNewAnnouncements(): number {
    if (!Array.isArray(this.announcements) || !this.isLoggedIn) return 0

    return this.announcements.reduce((acc, item) => {
      acc = item.asJson.status === AnnouncementStatus.NEW ? ++acc : acc
      return acc
    }, 0)
  }

  async getNotificationList(): Promise<void> {
    try {
      this._announcements = await this.announcementsService.getList()
    } catch (e) {
      console.error(e)
    }
  }

  async markAllAsRead(): Promise<void> {
    try {
      await this.announcementsService.markAllAsRead()
      await this.getNotificationList()
    } catch (e) {
      console.error(e)
    }
  }
}
