const { toast } = require('react-toastify')

export default {
  driver: toast,
  options: {
    position: 'top-right',
    autoClose: 15000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
  },
}
