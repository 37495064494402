import { injectable } from 'core/di/di-utils'
import { IConfigService, Config } from 'core/types/services/config'
import { merge } from 'lodash-es'
import { DI_TOKENS } from 'shared/constants/di'

@injectable()
export class ConfigService implements IConfigService {
  static diToken = DI_TOKENS.configService
  private config: Config = {} as Config

  async initialize() {
    const commonConfig = await fetch('/config.common.json').then(res =>
      res.json(),
    )
    await fetch('/config.env.json')
      .then(res => res.json())
      .then(config => {
        this.config = merge(commonConfig, config)
      })
  }

  get() {
    return this.config
  }
}
