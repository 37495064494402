import { Model } from 'services'

export default class TrailerModel extends Model {
  get fillable() {
    return {
      site: 'string',
      size: 'number',
      name: 'string',
      isoCountry: 'string',
      id: 'string',
      type: 'string',
      isoLanguage: 'string',
      key: 'string',
    }
  }

  /**
   * Get Origin Url trailer
   * @param site
   * @returns {string}
   */
  getUrl(site = 'youtube') {
    return `https://www.youtube.com/watch?v=${this.key}`
  }
}
