import { Application } from 'services'
import { inject } from 'core/di/di-utils'
import { IGenresService } from 'shared/types/services/genres'
import { DI_TOKENS } from 'shared/constants/di'
import { extendObservable, observable } from 'mobx'
import { GenreGetListQuery } from 'shared/models/genre/genre-get-list-model'
import { IDepartmentsService } from 'shared/types/services/departments'
import {
  ALL_FILTER,
  ContentTypeFilter,
  ContentTypesFilterValue,
  DescendingSort,
  DescendingSortFilterValue,
  GlobalFilters,
  SelectListItem,
  ServicesFilterValue,
  SortByFilter,
  SortByFilterValue,
  YearFilter,
} from './global-filter.types'
import { DepartmentGetModel } from 'shared/models/department/get-model'
import { GlobalSearchFilter } from 'shared/types/services/search'
import {
  CONTENT_TYPE_DEFAULT_SELECT_LIST,
  DEFAULT_GLOBAL_FILTERS,
  DEFAULT_YEARS_FILTER,
  DESCENDING_DEFAULT_SELECT_LIST,
  SERVICE_DEFAULT_SELECT_LIST,
  SORT_BY_DEFAULT_SELECT_LIST,
} from './global-filter.constants'

export class GlobalSearchViewModel {
  private _genresService = inject<IGenresService>(DI_TOKENS.genresService)
  private _departmentsService = inject<IDepartmentsService>(
    DI_TOKENS.departmentsService,
  )
  private $app = Application.instance()

  private readonly _defaultPaginationPerPage: number = 27

  private _genreList: GenreGetListQuery['content']
  private _genrePaginationPage: number

  private _departmentsList: DepartmentGetModel[]
  private _departmentsPaginationPage: number

  private _globalFilters: GlobalFilters

  constructor() {
    extendObservable(this, {
      _genreList: observable,
      _genrePaginationPage: observable,

      _departmentsList: observable,
      _departmentsPaginationPage: observable,

      _globalFilters: observable,
    })

    this._genreList = []
    this._genrePaginationPage = 1

    this._departmentsList = []
    this._departmentsPaginationPage = 1

    this._globalFilters = DEFAULT_GLOBAL_FILTERS
  }

  get isLoggedIn(): boolean {
    return this.$app.auth.isLoggedIn()
  }

  get isMobile(): boolean {
    return window.innerWidth < 980
  }

  get isShowSortingFilters(): boolean {
    const { contentType, yearsFilter, service } = this._globalFilters

    return Boolean(
      contentType || yearsFilter.yearTo || yearsFilter.yearFrom || service,
    )
  }

  get contentTypeSelectList(): SelectListItem[] {
    return CONTENT_TYPE_DEFAULT_SELECT_LIST
  }

  get currentContentTypeSport(): boolean {
    return this.globalFilters.contentType === ContentTypeFilter.SPORTS
  }

  get genresSelectList(): SelectListItem[] {
    return this._createGenreSelectList(this._genreList)
  }

  get genresPaginationPage(): number {
    return this._genrePaginationPage
  }

  get genresPaginateMaxPage(): number {
    return Math.ceil(
      this.genresSelectList.length / this._defaultPaginationPerPage,
    )
  }

  get genresPaginatedData(): SelectListItem[] {
    const page = this.genresPaginationPage
    const perPage = this._defaultPaginationPerPage
    const start = page === 1 ? 0 : page - 2 + (page - 1) * perPage
    const end = start + perPage

    return [...this.genresSelectList].slice(start, end)
  }

  get yearSelectData(): YearFilter {
    return DEFAULT_YEARS_FILTER
  }

  get servicesSelectData(): SelectListItem[] {
    return SERVICE_DEFAULT_SELECT_LIST
  }

  get descendingSelectData(): SelectListItem[] {
    return DESCENDING_DEFAULT_SELECT_LIST
  }

  get sortBySelectData(): SelectListItem[] {
    return SORT_BY_DEFAULT_SELECT_LIST
  }

  get departmentsPaginationPage(): number {
    return this._departmentsPaginationPage
  }

  get departmentsPaginateMaxPage(): number {
    return Math.ceil(
      this.departmentsSelectList.length / this._defaultPaginationPerPage,
    )
  }

  get departmentsSelectList(): SelectListItem[] {
    return this._createDepartmentsSelectList(this._departmentsList)
  }

  get departmentsPaginatedData(): SelectListItem[] {
    const page = this.departmentsPaginationPage
    const perPage = this._defaultPaginationPerPage
    const start = page === 1 ? 0 : page - 2 + (page - 1) * perPage
    const end = start + perPage

    return [...this.departmentsSelectList].slice(start, end)
  }

  get globalFilters(): GlobalFilters {
    return this._globalFilters
  }

  get hasSetFilter(): boolean {
    const {
      contentType,
      sortBy,
      yearsFilter,
      service,
      genres,
      descending,
      departments,
    } = this._globalFilters

    const isSetSortBy = sortBy !== SortByFilter.POPULARITY
    const isSetDescending = descending !== DescendingSort.DESC

    return Boolean(
      contentType ||
        genres.length > 0 ||
        isSetSortBy ||
        (yearsFilter.yearFrom && yearsFilter.yearTo) ||
        service ||
        departments.length > 0 ||
        isSetDescending,
    )
  }

  get resultGlobalFilters(): GlobalSearchFilter {
    const {
      contentType,
      genres,
      departments,
      yearsFilter,
      service,
      sortBy,
      descending,
    } = this._globalFilters

    const genresHasAll = Boolean(genres.find(i => i === ALL_FILTER))
    const departmentsHasAll = Boolean(departments.find(i => i === ALL_FILTER))

    const sortFieldsStr = sortBy
      ? SortByFilterValue[sortBy]
      : SortByFilterValue[SortByFilter.POPULARITY]

    const sortByStr = descending
      ? DescendingSortFilterValue[descending]
      : DescendingSortFilterValue[DescendingSort.DESC]

    return {
      contentTypes: contentType ? [ContentTypesFilterValue[contentType]] : null,
      genres: genresHasAll ? [ALL_FILTER] : genres.length > 0 ? genres : null,
      departments: departmentsHasAll
        ? [ALL_FILTER]
        : departments.length > 0
        ? departments
        : null,
      providers: service ? [ServicesFilterValue[service]] : null,
      yearFrom: yearsFilter.yearFrom || null,
      yearTo: yearsFilter.yearTo || null,

      sort: `${sortFieldsStr},${sortByStr}`,
    }
  }

  updateGenresPaginationPage(page: number) {
    this._genrePaginationPage = page
  }

  updateDepartmentsPaginationPage(page: number) {
    this._departmentsPaginationPage = page
  }

  updateGlobalFilters<T extends keyof GlobalFilters>(
    key: T,
    value: GlobalFilters[T],
  ) {
    this._globalFilters = {
      ...this._globalFilters,
      [key]: value,
    }
  }

  updateContentTypeFilter(value: SelectListItem['value']) {
    this._globalFilters = {
      ...this._globalFilters,
      contentType: value as ContentTypeFilter,
      genres: [],
      departments: [],
    }
    this.updateGenresPaginationPage(1)
    this.updateDepartmentsPaginationPage(1)
  }

  updateGenreListFilter(value: SelectListItem['value'] | null) {
    if (value === null) {
      this._globalFilters = {
        ...this._globalFilters,
        genres: [],
        departments: [],
      }
    }

    if (value) {
      const selectedItems = this._globalFilters.genres
      const allListItems = this.genresSelectList

      const updatedList = this._getUpdatedMultipleList(
        selectedItems,
        allListItems,
        value,
      )
      this._globalFilters = {
        ...this._globalFilters,
        genres: updatedList,
        departments: [],
      }
    }
    this.updateGenresPaginationPage(1)
    this.updateDepartmentsPaginationPage(1)
  }

  updateDepartmentListFilter(value: SelectListItem['value'] | null) {
    if (value === null) {
      this._globalFilters = {
        ...this._globalFilters,
        departments: [],
      }
      return
    }

    const selectedItems = this._globalFilters.departments
    const allListItems = this.departmentsSelectList

    const updatedList = this._getUpdatedMultipleList(
      selectedItems,
      allListItems,
      value,
    )
    this._globalFilters = {
      ...this._globalFilters,
      departments: updatedList,
    }
  }

  updateYearsFilter(yearsFilter: YearFilter) {
    this._globalFilters = {
      ...this._globalFilters,
      yearsFilter: {
        yearFrom: yearsFilter.yearFrom,
        yearTo: yearsFilter.yearTo,
      },
    }
  }

  updateSortByFilter(val: SortByFilter) {
    const getDescendingValue = (val: SortByFilter) => {
      switch (val) {
        case SortByFilter.POPULARITY:
          return DescendingSort.DESC
        case SortByFilter.YEAR:
          return DescendingSort.DESC
        case SortByFilter.ALPHABETICALLY:
          return DescendingSort.ASC
        default:
          return DescendingSort.ASC
      }
    }
    this._globalFilters = {
      ...this._globalFilters,
      sortBy: val,
      descending: getDescendingValue(val),
    }
  }

  resetGlobalFilters() {
    this._globalFilters = { ...DEFAULT_GLOBAL_FILTERS }
  }

  async fetchGenresList() {
    try {
      const genres = await this._genresService.getAll()
      const json = genres.asJson

      this._genreList = json.content
    } catch (e) {
      console.error(e)
    }
  }

  async fetchDepartmentsList() {
    try {
      this._departmentsList = await this._departmentsService.getDepartmentList()
    } catch (e) {
      console.error(e)
    }
  }

  private _shortFormatName = (name: string): string => {
    return name.length > 15 ? `${name.slice(0, 15).trim()}...` : name
  }

  private _createGenreSelectList(
    list: GenreGetListQuery['content'],
  ): SelectListItem[] {
    const createList = (list: GenreGetListQuery['content']) => {
      return list
        .map(({ name }) => ({
          name: this._shortFormatName(name),
          value: name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value))
    }
    const selectContentType = this._globalFilters.contentType
    if (!selectContentType) {
      return []
    }

    // FOR TV_SHOWS SHOULD BE MOVIES
    const filterValueContentType =
      ContentTypesFilterValue[selectContentType] ===
      ContentTypesFilterValue['TV Shows']
        ? ContentTypesFilterValue['Movies']
        : ContentTypesFilterValue[selectContentType]

    const resultList = selectContentType
      ? createList(
          list.filter(item => item.contentType === filterValueContentType),
        )
      : createList(list)

    if (resultList.length > 0) {
      return [{ name: ALL_FILTER, value: ALL_FILTER }, ...resultList]
    }
    return []
  }

  private _createDepartmentsSelectList(list: DepartmentGetModel[]) {
    const createList = (list: DepartmentGetModel[]): SelectListItem[] => {
      const uniqNames = [...new Set(list.map(item => item.asJson.name))]
      return uniqNames
        .map(name => ({
          name: this._shortFormatName(name),
          value: name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    }

    const currentGenres = this._globalFilters.genres
    const result =
      currentGenres.length > 0
        ? createList(list.filter(i => currentGenres.includes(i.asJson.genre)))
        : createList(list)

    if (result.length === 0) {
      return []
    }
    return [{ name: ALL_FILTER, value: ALL_FILTER }, ...result]
  }

  private _getUpdatedMultipleList(
    selectedItems: string[],
    list: SelectListItem[],
    value: string,
  ) {
    const hasCurrentValueInList = selectedItems.includes(value)
    const hasAll = Boolean(selectedItems.find(name => name === ALL_FILTER))

    let newList = []

    if (value === ALL_FILTER) {
      if (hasCurrentValueInList) {
        newList = []
      }
      const hasAllInList = Boolean(list.find(item => item.name === ALL_FILTER))
      const resultList = list.map(i => i.value)

      newList = hasAllInList ? resultList : [ALL_FILTER, ...resultList]
    } else {
      if (hasCurrentValueInList && hasAll) {
        newList = selectedItems.filter(
          name => name !== value && name !== ALL_FILTER,
        )
      }
      if (hasCurrentValueInList && !hasAll) {
        newList = selectedItems.filter(name => name !== value)
      }
      if (!hasCurrentValueInList && !hasAll) {
        newList = [...selectedItems, value]
      }
    }

    return newList
  }
}
