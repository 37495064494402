import { Application, Model } from 'services'

export default class FooerGridModel extends Model {
  get fillable() {
    return {
      type: 'title',
      id: 'string',
    }
  }

  /**
   *
   * @param params
   * @returns {Promise<any>}
   */
  static list(endpoint) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.newrequest
        .get(endpoint)
        .then(response => resolve(self.createGridFromResponse(response.data)))
        .catch(error => reject(error))
    })
  }
}
