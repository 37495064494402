import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { PromoModel } from 'models'

configure({ enforceActions: 'observed' })
export default class PromoStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      collection: new Collection(),
    })
    this.model = PromoModel
  }

  getList() {
    this.collection.isEmpty() &&
      PromoModel.list().then(
        action(collection => {
          this.collection = collection
        }),
      )
  }

  // getListPerson(params, typeOfCollection = 'collectionPerson', typeahead) {
  // 	this.loading = true;
  // 	this.model
  // 		.listPerson(params, typeahead)
  // 		.then(
  // 			action(collection => {
  // 				this[typeOfCollection] = this.mutateCollectionModels(
  // 					collection,
  // 					typeOfCollection,
  // 				);
  // 			}),
  // 		)
  // 		.finally(action(() => (this.loading = false)));
  // }
}

decorate(PromoStore, {
  getList: action,
})
