import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ palette, custom }: AppTheme) {
  return appCreateStyles({
    root: {},
    inputFormControl: {},
    input: {
      color: palette.common.white,
      fontWeight: 700,
      backgroundColor: custom.colors.gray[600],

      '&:hover:not($focused) $notchedOutline': {
        // https://github.com/mui-org/material-ui/issues/13347
        // border: 'none',
      },
    },
    border1: {
      borderRadius: 36,
      border: '2px solid transparent',
    },
    border2: {
      borderRadius: '36px 36px 0px 0px',
      border: 'none',
    },
    focused: {
      borderBottom: '2px solid #B8E986',
    },
    notchedOutline: {
      border: '2px solid transparent',
    },
    borderNone: {
      border: 'none',
    },
    clearButton: {
      padding: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clearIcon: {
      fill: custom.colors.gray[200],
    },
  })
}
