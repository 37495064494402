import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    rootAbsolute: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 3,
      backgroundColor: appFade(palette.common.white, 0.5),
    },
    none: {
      margin: 0,
    },
    small: {
      margin: spacing(2),
    },
    normal: {
      margin: spacing(4),
    },
    big: {
      margin: spacing(6),
    },
    svg: {},
  })
}
