import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    container: {
      display: 'flex',

      '& + &': {
        marginTop: spacing(6),
      }
    },
    icon: {
      marginRight: spacing(2),
    },
    readRootIcon: {
      color: palette.grey['500'],
    },
    newRootIcon: {
      color: palette.primary.main,
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: 17,
      fontWeight: 700,
      color: '#B2B2B2',
      marginBottom: spacing(2),
    },
    text: {
      fontSize: 14,
      fontWeight: 300,
      color: '#B2B2B2',
      lineHeight: '20px',
    },
    date: {
      fontSize: 14,
      fontWeight: 500,
      color: '#78787A',
      marginTop: spacing(3),
    },
    show: {
      fontSize: 14,
      color: palette.primary.dark,
      fontWeight: 600,
      cursor: 'pointer',
    },
  })
}
