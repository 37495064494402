import { BaseService } from 'core/services/base'
import { injectable } from 'inversify/lib/annotation/injectable'
import { Application } from 'services'
import { IBannerService } from 'shared/types/services/banner'

@injectable()
export class BannerService extends BaseService implements IBannerService {
  private app = Application.instance()

  protected urlPrefix = '/banners'

  getBanner = async () => {
    const { data } = await this.app.newrequest.get('/banners')
    return data
  }
}
