import { Collection } from '../'

export default class CookieStorage {
  /**
   * Set value to the storage
   *
   * @returns {LocalStorage}
   * @param args
   */
  set(...args) {
    const [key, value, end, path, domain, secure] = args
    if (key instanceof Array) {
      for (const [key, value] of Object.entries(this.configs)) {
        this.set(key, value)
      }
    } else {
      if (!key || /^(?:expires|max\/-age|path|domain|secure)$/i.test(key)) {
        return this
      }
      let expires = ''
      if (end) {
        switch (end.constructor) {
          case Number:
            expires =
              end === Infinity
                ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                : `; max-age=${end}`
            break
          case String:
            expires = `; expires=${end}`
            break
          case Date:
            expires = `; expires=${end.toUTCString()}`
            break
          default:
            return ''
        }
      }
      document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
        value,
      )}${expires}${
        domain ? `; domain=${domain}` : `;domain=.${window.location.host}`
      }${path ? `; path=${path}` : ''}${secure ? '; secure' : ''}`
      return this
    }
    return this
  }

  /**
   * Return the record or list of records
   *
   * @param key
   * @param defaultValue
   * @returns {*}
   */
  get(key = false, defaultValue = false) {
    if (key) {
      return (
        decodeURIComponent(
          document.cookie.replace(
            new RegExp(
              `(?:(?:^|.*;)\\s*${encodeURIComponent(key).replace(
                // eslint-disable-next-line
                /[\-\.\+\*]/g,
                '\\$&',
              )}\\s*\\=\\s*([^;]*).*$)|^.*$`,
            ),
            '$1',
          ),
        ) || null
      )
    }
    return this.list()
  }

  /**
   * Check if exists the record in the storage
   *
   * @param key
   * @returns {boolean}
   */
  has(key) {
    // eslint-disable-next-line
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
      return false
    }
    return new RegExp(
      `(?:^|;\\s*)${encodeURIComponent(key).replace(
        // eslint-disable-next-line
        /[\-\.\+\*]/g,
        '\\$&',
      )}\\s*\\=`,
    ).test(document.cookie)
  }

  /**
   * Remove the record
   *
   * @param key
   * @returns {boolean}
   */
  remove(key, path, domain) {
    if (!this.has(key)) {
      return false
    }
    document.cookie = `${encodeURIComponent(
      key,
    )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
      domain ? `; domain=${domain}` : ''
    }${path ? `; path=${path}` : ''}`
    return true
  }

  /**
   * Clear the storage
   *
   * @returns {string}
   */
  clear() {
    document.cookie = ''
  }

  /**
   * Returns keys
   *
   * @returns {Array}
   */
  keys() {
    const keys = document.cookie
      // eslint-disable-next-line
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
      // eslint-disable-next-line
      .split(/\s*(?:\=[^;]*)?;\s*/)
    for (let { length } = keys, idx = 0; idx < length; idx++) {
      keys[idx] = decodeURIComponent(keys[idx])
    }
    return keys
  }

  /**
   * Get list of the records
   *
   * @returns {*}
   */
  list() {
    const collection = new Collection({})
    this.keys().forEach(key => {
      collection.set(key, this.get(key))
    })
    return collection
  }
}
