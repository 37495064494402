import { extendObservable, action, decorate, configure } from 'mobx'
import { Model, Paginator } from '.'
// import { runInAction, makeAutoObservable } from "mobx"
configure({ enforceActions: 'observed' })
export default class Collection {
  constructor(data = []) {
    extendObservable(this, {
      data: [],
    })

    this.model = null
    this.fill(data)
    this.hack()
  }

  /**
   * Set data of collection
   *
   * @returns {Collection}
   * @param data
   */
  fill(data) {
    this.data = data
    return this
  }

  hack() {
    this.timestamp = Date.now()
    return this
  }

  /**
   * Get the data of collection
   *
   * @returns Array
   */
  values() {
    return this.data
  }

  /**
   * Get number of items in collection
   *
   * @returns {Number}
   */
  get length() {
    return Array.isArray(this.data) ? this.data.length : this.keys().length
  }

  getByIndex(index) {
    return this.data[index]
  }

  /**
   * Returns true if data is empty
   *
   * @returns {boolean}
   */
  isEmpty() {
    return !this.length
  }

  /**
   * Returns true if data is not empty
   *
   * @returns {boolean}
   */
  isNotEmpty() {
    return !this.isEmpty()
  }

  /**
   * Get item by key
   *
   * @param key
   * @param defaultValue
   * @returns {*}
   */
  get(key, defaultValue = null) {
    return key in this.data ? this.data[key] : defaultValue
  }

  /**
   * Get item by object key
   *
   * @param key
   * @returns {*}
   */
  findByKey(key) {
    const result = this.data.filter(item => item.key === key)

    return result ? result[0] : null
  }

  /**
   * Get item by object key
   *
   * @param key
   * @returns {*}
   */
  findByProperty(value, property) {
    const result = this.data.filter(item => item[property] === value)

    return result ? result[0] : null
  }

  /**
   * Set item by index
   *
   * @param key
   * @param value
   */
  set(key, value) {
    this.data[key] = value
  }

  /**
   * Set default model
   *
   * @param model
   * @returns {Collection}
   */
  setModel(model = Model) {
    const NewModel = model
    this.model = new NewModel()
    return this
  }

  /**
   * Returns default model of collection
   *
   * @returns {null}
   */
  getModel() {
    return this.model
  }

  /**
   * Returns all of the collection's keys
   *
   * @returns {Array}
   */
  keys() {
    return Array.isArray(this.data)
      ? [...this.data.keys()]
      : Object.keys(this.data)
  }

  /**
   * Add new item to the collection
   *
   * @param value
   * @returns {Collection}
   */
  push(value) {
    if (Array.isArray(this.data)) {
      this.data.push(value)
    } else {
      this.data = Object.assign(this.data, value)
    }
    return this
  }

  /**
   * Add new item to first position in collection
   *
   * @param value
   * @returns {Collection}
   */
  unshift(value) {
    if (Array.isArray(this.data)) {
      this.data.unshift(value)
    } else {
      this.data = Object.assign(this.data, value)
    }
    return this
  }

  /**
   * Add new item to the collection
   *
   * @param value
   * @returns {Collection}
   */
  add(value) {
    return this.push(value)
  }

  /**
   * Clone collection
   *
   * @returns {Object}
   */
  clone() {
    return new this.constructor()
  }

  /**
   * Get first key of the collection
   *
   * @returns {Collection}
   */
  firstKey() {
    return this.keys()[0]
  }

  /**
   * Get last key of the collection
   *
   * @returns {Collection}
   */
  lastKey() {
    return this.keys()[this.length]
  }

  /**
   * Get first item of the collection
   *
   * @param defaultValue
   * @returns {String|Number}
   */
  first(defaultValue = null) {
    return this.get(this.firstKey(), defaultValue)
  }

  /**
   * Get last item of the collection
   *
   * @param defaultValue
   * @returns {Collection}
   */
  last(defaultValue = null) {
    return this.get(this.lastKey(), defaultValue)
  }

  /**
   * Clear the collection
   *
   * @returns {Collection}
   */
  clear() {
    if (Array.isArray(this.data)) {
      this.data = []
    } else {
      this.data = {}
    }
    return this
  }

  /**
   * Remove the item by index
   *
   * @param key
   * @returns {Collection}
   */
  remove(key) {
    if (Array.isArray(this.data)) {
      this.data.splice(key, 1)
    } else {
      delete this.data[key]
    }
    return this
  }

  /**
   * Remove the item by key, value pairs
   *
   * @param key
   * @param value
   * @returns {Collection}
   */
  removeBy(key, value) {
    this.forEach((item, index, instance) => {
      if (item[key] === value) {
        instance.remove(index)
      }
    })
    return this
  }

  /**
   * Remove the item by id
   *
   * @param id
   * @returns {Collection}
   */
  removeById(id) {
    for (let i = 0; i < this.length; i++) {
      if (this.data[i].id === id) {
        this.remove(i)
        break
      }
    }
    return this
  }

  /**
   * Removes and returns the first item from the collection
   *
   * @returns {*}
   */
  shift() {
    const key = this.firstKey()
    const item = this.get(key)
    this.remove(key)
    return item
  }

  /**
   * Calls a function for each collection items
   *
   * @param callback
   * @returns {Collection}
   */
  forEach(callback) {
    Object.keys(this.data).forEach(index => {
      if (typeof callback === 'function') {
        callback(this.data[index], index, this)
      }
    })
    return this
  }

  /**
   * Joins two or more collections
   *
   * @returns {Collection}
   * @param value
   * @param overwrite
   */
  merge(value, overwrite = false) {
    let collection = {}
    const getValue = value instanceof Collection ? value.data : value

    // if (value.paginator) this.setPagination(value.paginator);

    if (Array.isArray(this.data)) {
      collection = this.data.concat(getValue)
    } else {
      collection = { ...this.data, ...getValue }
    }
    return this.overwrite(collection, overwrite)
  }

  /**
   * Filter the collection
   * @param callback
   * @param overwrite
   * @returns {Collection}
   */
  filter(callback, overwrite = false) {
    const collection = this.clone()
    this.forEach((...args) => {
      if ((typeof callback !== 'function' && args[0]) || callback(...args)) {
        collection.set(args[1], args[0])
      }
    })
    return this.overwrite(collection.data, overwrite)
  }

  /**
   * Calls a function for each collection items
   *
   * @param callback
   * @returns {Collection}
   */
  map(callback) {
    this.forEach(callback)
    return this
  }

  /**
   * Retrieves all of the values for a given key
   *
   * @param attributes
   * @param overwrite
   * @returns {Collection}
   */
  pluck(attributes, overwrite = false) {
    const collection = []
    if (typeof attributes !== 'undefined') {
      this.forEach(item => {
        let value = null
        if (attributes instanceof Array) {
          value = {}
          attributes.forEach(key => {
            value[key] = item[key]
          })
        } else {
          value = item[attributes]
        }
        collection.push(value)
      })
    }
    return this.overwrite(collection, overwrite)
  }

  /**
   * Filters the collection by a given key / opertor / value
   *
   * @returns {Collection}
   */
  where(...args) {
    let key = null
    let operator = null
    let value = null
    let overwrite = false
    if (args.length > 2) {
      ;[key, operator, value, overwrite = false] = args
    } else {
      ;[key, value, overwrite = false] = args
      operator = '=='
    }
    const collection = []
    this.forEach((item, index) => {
      const a =
        item instanceof Array || item instanceof Object ? item[key] : item
      if (this.whereOperators(operator, a, value)) {
        collection.push(this.data[index])
      }
    })
    return this.overwrite(collection, overwrite)
  }

  /**
   * Checks if an items exists by index
   * @returns {boolean}
   * @param args
   */
  has(...args) {
    let key = null
    let operator = null
    let value = null
    if (args.length === 1) {
      return Array.isArray(this.data)
        ? this.data.includes(args[0])
        : args[0] in this.data
    }
    if (args.length > 2) {
      ;[key, operator, value] = args
    } else {
      ;[key, value] = args
      operator = '=='
    }
    let result = false
    this.forEach(item => {
      const a =
        item instanceof Array || item instanceof Object ? item[key] : item
      if (this.whereOperators(operator, a, value)) {
        result = true
      }
    })
    return result
  }

  /**
   * Filters the collection by a given key
   *
   * @returns {Collection}
   */
  whereNotNull(key) {
    const collection = []
    this.forEach((item, index) => {
      if (item.get(key, null) !== null) {
        collection.push(this.get(index))
      }
    })
    return collection
  }

  /**
   * Filters the collection by a given key
   *
   * @returns {Collection}
   */
  whereNull(key) {
    const collection = []
    this.forEach((item, index) => {
      if (item.get(key, null) === null) {
        collection.push(this.get(index))
      }
    })
    return collection
  }

  /**
   * Returns the clone of instance or change current instance
   *
   * @param collection
   * @param overwrite
   * @returns {*}
   */
  overwrite(collection, overwrite = false) {
    if (!overwrite) {
      return this.clone().fill(collection).setPagination(this.paginator)
    }
    this.data = collection
    return this.hack()
  }

  /**
   * Compare values by operator
   *
   * @param operator
   * @param a
   * @param b
   * @returns {boolean}
   */
  whereOperators = (operator, a, b) => {
    switch (operator) {
      case '==':
        return a === b
      case '===':
        return a === b
      case '!=':
        return a !== b
      case '!==':
        return a !== b
      case '>':
        return a > b
      case '>=':
        return a >= b
      case '<':
        return a < b
      case '<=':
        return a <= b
      default:
        return false
    }
  }

  /**
   * Reverses the order of the collection's items, preserving the original keys
   * @param overwrite
   * @returns {Collection}
   */
  reverse(overwrite = true) {
    let collection = {}
    if (Array.isArray(this.data)) {
      collection = this.data.reverse()
    } else {
      Object.keys(this.data)
        .reverse()
        .forEach(key => {
          collection[key] = this.get(key)
        })
    }
    return this.overwrite(collection, overwrite)
  }

  /**
   * Returns a shallow copy of a portion of an array into a new array
   * object selected from begin to end (end not included).
   * The original array will not be modified.
   *
   * @param begin
   * @param end
   * @param overwrite
   * @returns {Collection}
   */
  slice(begin, end, overwrite = false) {
    let collection = {}
    if (Array.isArray(this.data)) {
      collection = this.data.slice(begin, end)
    } else {
      const keys = this.keys().slice(begin, end)
      this.forEach((item, key) => {
        if (keys.includes(key)) {
          collection[key] = this.get(key)
        }
      })
    }
    return this.overwrite(collection, overwrite)
  }

  /**
   * Removes and returns a slice of items starting at the specified index:
   *
   * @param begin
   * @param deleteCount
   * @param overwrite
   * @returns {Collection}
   */
  splice(begin, deleteCount, overwrite, ...items) {
    let collection = {}
    if (Array.isArray(this.data)) {
      collection = this.data.splice(begin, deleteCount, ...items)
    } else {
      this.keys().forEach((value, key) => {
        if (!(begin <= key && begin + deleteCount > key)) {
          collection[value] = this.get(value)
          if (begin + deleteCount === key && items.length) {
            items.forEach(item => {
              if (item instanceof Object || item instanceof Array) {
                collection = Object.assign(collection, item)
              } else {
                collection[item] = item
              }
            })
          }
        }
      })
    }
    return this.overwrite(collection, overwrite)
  }

  /**
   * Sorts the items of an collection in place and returns the collection.
   * The sort is not necessarily stable.
   * The default sort order is according to string Unicode code points.
   *
   * @returns {Collection}
   * @param args
   */
  sort(...args) {
    if (Array.isArray(this.data)) {
      this.data = this.data.slice().sort(...args)
    } else {
      const keys = this.keys()
      keys.sort(...args)
      keys.forEach(key => {
        this.set(key, this.get(key))
      })
    }
    return this
  }

  /**
   * Groups the collection's items by a given key
   *
   * @param key
   * @param overwrite
   * @returns {Collection}
   */
  groupBy(key, overwrite = false) {
    const collection = {}
    this.forEach(item => {
      if (!collection[item[key]]) {
        collection[item[key]] = this.clone().fill(
          Array.isArray(this.data) ? [] : {},
        )
      }
      collection[item[key]].push(item)
    })

    return this.overwrite(collection, overwrite)
  }

  /**
   * Get the item by key, value pairs
   *
   * @param key
   * @param value
   * @param defaultValue
   * @returns {*}
   */
  find(key, value, defaultValue = false) {
    const result = defaultValue
    Object.keys(this.data).forEach(index => {
      const item =
        this.data[index] instanceof Object
          ? this.data[index][key]
          : this.data[index]
      if (item === value) {
        return this.data[index]
      }
    })
    return result
  }

  /**
   * Removes any values from the original collection that are not present in the given array
   *
   * @param keys
   * @param overwrite
   * @returns {Collection}
   */
  intersect(keys, overwrite = false) {
    const collection = []
    if (keys instanceof Array) {
      this.forEach((item, key) => {
        if (keys.includes(key)) {
          collection[key] = item
        }
      })
    }
    return this.overwrite(collection, overwrite)
  }

  /**
   * Returns the items in the collection with the specified keys
   *
   * @param keys
   * @param overwrite
   * @returns {Collection}
   */
  only(keys, overwrite = false) {
    const collection = []
    if (keys instanceof Array) {
      keys.forEach(key => {
        collection[key] = this.get(key)
      })
    }
    return this.overwrite(collection, overwrite)
  }

  /**
   * Returns all items in the collection except for those with the specified keys
   *
   * @param keys
   * @param overwrite
   * @returns {*}
   */
  except(keys, overwrite = false) {
    const collection = []
    if (keys instanceof Array) {
      this.forEach((item, key) => {
        if (!keys.includes(key)) {
          collection[key] = item
        }
      })
    }
    return this.overwrite(collection, overwrite)
  }

  /**
   * Dumps the collection's items
   *
   * @returns {Collection}
   */
  dump() {
    return this
  }

  /**
   * Sorts the collection by the given key
   *
   * @param key
   * @param order
   * @returns {Collection}
   */
  sortBy(key, order = 'asc') {
    if (Array.isArray(this.data)) {
      this.data.sort((a, b) =>
        this.whereOperators(order === 'asc' ? '>' : '<', a[key], b[key]),
      )
    }
    return this
  }

  /**
   * Sorts the collection by the given key
   *
   * @returns {Collection}
   * @param args
   */
  orderBy(...args) {
    this.sortBy(...args)
    return this
  }

  /**
   * Returns the average value of a given key
   *
   * @param key
   * @returns {number}
   */
  avg(key) {
    return this.sum(key) / this.length
  }

  /**
   * Returns the min value of a given key
   *
   * @param key
   * @returns {*}
   */
  min(key) {
    if (!Array.isArray(this.data)) {
      return 0
    }
    return this.data.reduce((a, b) => {
      const newA = key && a[key] ? a[key] : a
      const newB = key && b[key] ? b[key] : b
      return Math.min(newA, newB)
    })
  }

  /**
   * Returns the max value of a given key
   *
   * @param key
   * @returns {*}
   */
  max(key) {
    if (!Array.isArray(this.data)) {
      return 0
    }
    return this.data.reduce((a, b) => {
      const newA = key && a[key] ? a[key] : a
      const newB = key && b[key] ? b[key] : b
      return Math.max(newA, newB)
    })
  }

  /**
   * Returns the sum of value of a given key
   *
   * @param key
   * @returns {*}
   */
  sum(key) {
    let sum = 0
    this.forEach(item => {
      if (key && item[key]) {
        sum += item[key]
      }
    })
    return sum
  }

  /**
   * Returns a random item from the collection
   *
   * @returns {*}
   */
  random() {
    const min = 0
    const max = this.count()
    const index = Math.floor(Math.random() * (max - min) + min)
    return this.get(index)
  }

  /**
   * Randomly shuffles the items in the collection
   *
   * @returns {Collection}
   */
  shuffle() {
    const a = this.data
    let j
    let x
    let i
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = a[i]
      a[i] = a[j]
      a[j] = x
    }
    return this
  }

  /**
   * Convert collection to string
   *
   * @returns {string}
   */
  toString() {
    return this.toJSON()
  }

  toObject() {
    const result = {
      kind: this.first().kind,
      type: 'collection',
      items: [],
    }
    this.data.forEach(item => {
      result.items.push(
        typeof item.toObject === 'function' ? item.toObject() : item,
      )
    })
    return result
  }

  /**
   * Convert collection to json
   *
   * @returns {string}
   */
  toJSON() {
    return JSON.stringify(this.toObject())
  }

  /**
   * Convert collection to array
   *
   * @returns {string}
   */
  toArray() {
    return Array.isArray(this.data) ? this.data : Array.from(this.data)
  }

  setPagination(meta) {
    this.paginator = meta instanceof Paginator ? meta : new Paginator(meta)
    return this
  }

  /**
   * Generate simple static Collection
   * @param collection
   * @returns {Collection}
   */
  generateStatic(collection = [], model = Model) {
    const arr =
      collection instanceof Collection ? collection.toArray() : collection
    for (let i = 0; arr.length > i; i++) {
      this.push(new model(arr[i]))
    }
    return this
  }
}

decorate(Collection, {
  overwrite: action,
  clear: action,
  set: action,
  merge: action,
  push: action,
  fill: action,
  sortBy: action,
  splice: action,
  add: action,
  shuffle: action,
  unshift: action,
  remove: action,
  removeBy: action,
  removeById: action,
})
