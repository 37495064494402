import React, { Dispatch, SetStateAction } from 'react'
import { NavLink } from 'react-router-dom'

import { Backdrop } from '@material-ui/core'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './menu-modal.styles'
import {
  MoviesIcon,
  ShowsIcon,
  SportsIcon,
  LiveIcon,
  HomeIcon,
} from 'components/Icons'

type Props = AppWithStyles<typeof styles> & {
  handleNavClick: Dispatch<SetStateAction<boolean>>
}

const MenuModalComponent: React.FC<Props> = ({ classes, handleNavClick }) => {
  const handleCloseModal = () => {
    handleNavClick(false)
  }
  const navigationListData = [
    {
      icon: <HomeIcon className={classes.navIcon} />,
      name: 'Home',
      to: '/',
    },
    {
      icon: <MoviesIcon className={classes.navIcon} />,
      name: 'Movies',
      to: '/movies',
    },
    {
      icon: <ShowsIcon className={classes.navIcon} />,
      name: 'TV Shows',
      to: '/tv-shows',
    },
    {
      icon: <SportsIcon className={classes.navIcon} />,
      name: 'Sports',
      to: '/sports',
    },
    {
      icon: <LiveIcon className={classes.navIcon} />,
      name: 'Live',
      to: '/lives',
    },
  ]

  const headerNavigationList = navigationListData.map(item => (
    <li key={item.name} className={classes.navItem}>
      <NavLink
        exact
        to={item.to}
        className={classes.navLink}
        activeClassName="active"
        onClick={handleCloseModal}
      >
        {item.icon}
        {item.name}
      </NavLink>
    </li>
  ))
  return (
    <Backdrop open={true} className={classes.root}>
      <div className={classes.navigation}>
        <ul className="header-nav">{headerNavigationList}</ul>
      </div>
    </Backdrop>
  )
}

export const MenuModal = appWithStyles(styles)(MenuModalComponent)
