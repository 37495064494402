import { Collection } from '.'

const { location } = window

export default class Url {
  constructor(url = location.href) {
    this.url = url
    this.protocol = 'http:'
    this.host = location.host
    this.hostname = location.hostname
    this.port = location.port
    this.hash = location.hash
    this.pathname = location.pathname
    this.search = location.search
    this.query = {}
    this.parse()
  }

  parse() {
    const element = document.createElement('a')
    element.href = this.url
    this.protocol = element.protocol
    this.host = element.host
    this.hostname = element.hostname
    this.port = element.port
    this.hash = element.hash
    this.pathname = element.pathname
    this.search = element.search
    this.parseSearch()
  }

  get queryData() {
    return this.query.data
  }

  parseSearch() {
    const matches =
      !!this.search && decodeURI(this.search).replace(/^\?/, '').split('&')
    const results = new Collection()
    matches &&
      matches.forEach(match => {
        const pairs = new Collection(match.replace(/\[(.*)\]/, '').split('='))
        if (results.has(pairs.get(0))) {
          const value = results.get(pairs.get(0))
          results.set(
            pairs.get(0),
            (Array.isArray(value) ? value : [value]).concat([
              pairs.get(1, null),
            ]),
          )
        } else {
          if (pairs.get(1, null) && pairs.get(1, null).match(',')) {
            results.set(pairs.get(0), pairs.get(1, null).split(','))
          } else {
            results.set(pairs.get(0), pairs.get(1, null))
          }
        }
      })
    this.query = results
    return results
  }

  setQuery(params) {
    this.query = new Collection(params)
    this.search = this.queryToString()
    return this
  }

  queryToString() {
    const string = []
    this.query.forEach((value, key) => {
      if (
        value instanceof Collection ||
        typeof value === 'object' ||
        Array.isArray(value)
      ) {
        let validValue = value
        if (typeof value === 'object' || Array.isArray(value)) {
          validValue = new Collection(value)
        }
        let substring = `${key}=`
        validValue.forEach((subvalue, index) => {
          if (subvalue) {
            substring += `${subvalue}${
              Number(index) + 1 !== validValue.length ? ',' : ''
            }`
          }
        })
        string.push(substring)
      } else {
        if (typeof value === 'string' && value.match('&')) {
          string.push(key + (value ? `=${value.replace('&', '%26')}` : ''))
        } else {
          string.push(key + (value ? `=${value}` : ''))
        }
      }
    })
    return `?${string.join('&')}`
  }

  isValid() {
    const regexp =
      // eslint-disable-next-line
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(this.url)
  }

  baseUrl() {
    return `${this.protocol}//${this.host}`
  }

  static isValid(url) {
    return new Url(url).isValid()
  }

  toJSON() {
    return JSON.stringify(this.toString())
  }

  toString() {
    let string = this.baseUrl() + this.pathname
    string += this.queryToString() + this.hash
    return string
  }
}
