import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { appObserver } from 'core/state-management/utils'
import { StepDescription } from '../step-description'
import { OnboardingStepProps } from '../../onboarding.types'
import { GridType } from 'shared/models/grid/type'
import {
  NotificationType,
  showNotification,
} from 'shared/components/notification/notification'
import { GenreGetQuery } from 'shared/models/genre/genre-get-model'
import { Controls } from '../controls'
import { SecondStepViewModel } from './second-step.vm'
import { Genres } from './components/genres'
import { Loading } from 'shared/components/loading'
import { DepartmentGetQuery } from 'shared/models/department/get-model'
import { processTeams } from './second-step.utils'
import { SectionTitle } from '../section-title'
import { Teams } from './components/teams'
import { logOnboardingResult } from "../../../../../services/analytics.service";
import { OnboardingStatus } from "../../../../models/user/onboading-status";

import { styles } from './second-step.styles'

export type SecondStepProps = AppWithStyles<typeof styles> & OnboardingStepProps

const SecondStepComponent: React.FC<SecondStepProps> = ({
  classes,
  grids,
  proceedStep: _proceedStep,
}) => {
  const $vm = useMemo(() => new SecondStepViewModel(), [])
  const [loading, setLoading] = useState(false)
  const [sports, setSports] = useState<Array<GenreGetQuery>>([])
  const [teams, setTeams] = useState<{
    [key: string]: Array<DepartmentGetQuery>
  }>({})
  const [submitting, setSubmitting] = useState(false)
  const [selectedSports, setSelectedSports] = useState<
    Array<GenreGetQuery['id']>
  >([])
  const [selectedTeams, setSelectedTeams] = useState<
    Array<DepartmentGetQuery['id']>
  >([])

  const initialize = useCallback(async () => {
    try {
      setLoading(true)

      for (let gridIndex in grids) {
        const currentGrid = grids[gridIndex]
        const config = {
          [GridType.team]: async () => {
            const response = await $vm.getTeams(currentGrid.id)

            setTeams(processTeams(response.map(({ asJson }) => asJson)))
          },
          [GridType.sport]: async () => {
            const response = await $vm.getGenres(currentGrid.id)

            setSports(response.map(({ asJson }) => asJson))
          },
        }

        await config[currentGrid.type]()
      }
    } catch (err) {
      showNotification(
        'Something went wrong while fetching content',
        NotificationType.error,
      )

      console.error(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [grids])

  useEffect(() => {
    initialize()
    // eslint-disable-next-line
  }, [])

  const proceedStep = useCallback(
    async (skip: boolean) => {
      try {
        setSubmitting(true)
        logOnboardingResult(skip, OnboardingStatus.step1)
        await _proceedStep(
          async () =>
            await $vm.updateList(skip, {
              teams: selectedTeams,
              sports: selectedSports,
            }),
        )
      } finally {
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [_proceedStep, $vm, selectedTeams, selectedSports],
  )

  const handleGenresChange = useCallback(
    (id: GenreGetQuery['id']) => {
      const currentSport = sports.find(genre => genre.id === id)
      const sportTeams = teams[currentSport.name]

      if (selectedSports.includes(id)) {
        setSelectedTeams(
          selectedTeams.filter(
            teamId => !sportTeams?.some(team => team.id === teamId),
          ),
        )
      }

      setSelectedSports(
        selectedSports.includes(id)
          ? selectedSports.filter(item => item !== id)
          : [id, ...selectedSports],
      )
    },
    // eslint-disable-next-line
    [teams, selectedSports, selectedTeams],
  )

  const handleTeamsChange = useCallback(
    (id: GenreGetQuery['id']) => {
      setSelectedTeams(
        selectedTeams.includes(id)
          ? selectedTeams.filter(item => item !== id)
          : [...selectedTeams, id],
      )
    },
    // eslint-disable-next-line
    [selectedTeams],
  )

  const selectedFullSports = useMemo(() => {
    const acc: Array<GenreGetQuery> = []

    selectedSports.forEach(genreId => {
      sports.forEach(genre => {
        if (genre.id === genreId) {
          acc.push(genre)
        }
      })
    })

    return acc
  }, [selectedSports, sports])

  const teamsContent = useMemo(() => {
    return selectedFullSports.map(genre => {
      return (
        <Teams
          key={genre.id}
          sportName={genre.name}
          data={teams[genre.name]}
          selectedItems={selectedTeams}
          onItemChange={handleTeamsChange}
          classes={{ root: classes.teamRow }}
        />
      )
    })
    // eslint-disable-next-line
  }, [selectedFullSports, teams, selectedTeams, handleTeamsChange])

  if (loading) {
    return <Loading classes={{ root: classes.root }} />
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <StepDescription
          heading="What are you into?"
          subheading="Tell us what you like so we can show you content that we know you will enjoy."
        />
        <Genres
          selectedItems={selectedSports}
          data={sports}
          classes={{ root: classes.section }}
          onItemChange={handleGenresChange}
        />
        {Boolean(selectedSports.length) && (
          <SectionTitle
            title="Favourite teams"
            classes={{ root: classes.teamsHeading }}
          />
        )}
        {teamsContent}
      </div>
      <Controls
        loading={submitting}
        canProceedForward={true}
        onProceedForward={proceedStep}
        classes={{ root: classes.controls }}
      />
    </div>
  )
}

export const SecondStep = appWithStyles(styles)(
  appObserver(SecondStepComponent),
)
