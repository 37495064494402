import React from 'react'
import ChevronLeft from '@material-ui/icons/ChevronLeftRounded'
import ChevronRight from '@material-ui/icons/ChevronRightRounded'
import IconButton from '@material-ui/core/IconButton'
import cx from 'classnames'

import { Flex } from 'shared/components/flex'
import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './header.styles'

export type HeaderProps = AppWithStyles<typeof styles> & {
  title?: React.ReactNode
  actions?: React.ReactNode
  disabledScrollControls: {
    left: boolean
    right: boolean
  }
  onScrollLeft: () => void
  onScrollRight: () => void
}

const HeaderComponent: React.FC<HeaderProps> = ({
  classes,
  title,
  disabledScrollControls,
  onScrollLeft,
  actions,
  onScrollRight,
}) => {
  return (
    <Flex alignItems="center" classes={{ root: classes.root }}>
      <Flex alignItems="center" classes={{ root: classes.title }}>
        {title}
      </Flex>
      <Flex alignItems="center" classes={{ root: classes.actions }}>
        <div className={classes.outerActionsWrapper}>{actions}</div>
        <IconButton
          disabled={disabledScrollControls.left}
          onClick={onScrollLeft}
        >
          <ChevronLeft
            classes={{
              root: cx(classes.chevronIcon, {
                [classes.chevronIconDisabled]: disabledScrollControls.left,
              }),
            }}
          />
        </IconButton>
        <IconButton
          disabled={disabledScrollControls.right}
          onClick={onScrollRight}
        >
          <ChevronRight
            classes={{
              root: cx(classes.chevronIcon, {
                [classes.chevronIconDisabled]: disabledScrollControls.right,
              }),
            }}
          />
        </IconButton>
      </Flex>
    </Flex>
  )
}

export const Header = appWithStyles(styles)(HeaderComponent)
