import { BaseModel } from 'core/models/base'
import { LoginSources } from 'shared/models/user/login-sources'
import { UserStatuses } from 'shared/models/user/user-statuses'
import { OnboardingStatus } from 'shared/models/user/onboading-status'
import { UserListVisibility } from 'shared/models/user/list-visibility'
import { ContentGetQuery } from '../content/get-model'

export interface UserGetQuery {
  adultContent: boolean
  avatar?: string
  blocked: boolean
  country?: string
  email: string
  favoriteList: UserListVisibility
  favorites: Array<ContentGetQuery['id']>
  firstName?: string
  followed: boolean
  followers: number
  following: boolean
  follows: number
  hidden: boolean
  id: string
  language: string
  lastName?: string
  like: Array<ContentGetQuery['id']>
  likeList: UserListVisibility
  loginSource: LoginSources
  onboardingStatus: OnboardingStatus
  seen: Array<ContentGetQuery['id']>
  seenList: UserListVisibility
  userStatus: UserStatuses
  watchLater: Array<ContentGetQuery['id']>
  watchLaterList: UserListVisibility
  subscriptionStatus: string | null
}

export class UserGetModel extends BaseModel<UserGetQuery> {
  private adultContent: boolean
  private avatar?: string
  private blocked: boolean
  private country?: string
  private email: string
  private favoriteList: UserListVisibility
  private favorites: Array<ContentGetQuery['id']>
  private firstName?: string
  private followed: boolean
  private followers: number
  private following: boolean
  private follows: number
  private hidden: boolean
  private id: string
  private language: string
  private lastName?: string
  private like: Array<ContentGetQuery['id']>
  private likeList: UserListVisibility
  private loginSource: LoginSources
  private onboardingStatus: OnboardingStatus
  private seen: Array<ContentGetQuery['id']>
  private seenList: UserListVisibility
  private userStatus: UserStatuses
  private watchLater: Array<ContentGetQuery['id']>
  private watchLaterList: UserListVisibility
  private subscriptionStatus: string | null

  constructor(data: UserGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): UserGetQuery {
    return {
      adultContent: this.adultContent,
      avatar: this.avatar,
      blocked: this.blocked,
      country: this.country,
      email: this.email,
      favoriteList: this.favoriteList,
      favorites: this.favorites,
      firstName: this.firstName,
      followed: this.followed,
      followers: this.followers,
      following: this.following,
      follows: this.follows,
      hidden: this.hidden,
      id: this.id,
      language: this.language,
      lastName: this.lastName,
      like: this.like,
      likeList: this.likeList,
      loginSource: this.loginSource,
      onboardingStatus: this.onboardingStatus,
      seen: this.seen,
      seenList: this.seenList,
      userStatus: this.userStatus,
      watchLater: this.watchLater,
      watchLaterList: this.watchLaterList,
      subscriptionStatus: this.subscriptionStatus,
    }
  }
}
