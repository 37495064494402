import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles({ shape }: AppTheme) {
  return appCreateStyles({
    root: {
      borderRadius: shape.borderRadius,
      objectFit: 'cover',
    },
  })
}
