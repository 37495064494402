import { BaseModel } from 'core/models/base'

type Warning = {
  warningCode: string
  warningMessage: string
}

export interface SharedLinkQuery {
  shortLink: string
  previewLink: string
  warning: Array<Warning>
}

export class SharedLinkModel extends BaseModel<SharedLinkQuery> {
  private shortLink: string
  private previewLink: string
  private warning: Array<Warning>

  constructor(data: SharedLinkQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): SharedLinkQuery {
    return {
      shortLink: this.shortLink,
      previewLink: this.previewLink,
      warning: this.warning,
    }
  }
}
