import { Application } from 'services'
import GridDeailsModel from './gridDetails.model'

export default class DefaultGridModel extends GridDeailsModel {
  get fillable() {
    return {
      title: 'sring',
      type: 'string',
      itemType: 'string',
      orientation: 'string',
      size: 'sring',
      items: 'array',
    }
  }

  /**
   *
   * @param params
   * @returns {Promise<any>}
   */
  static list(endpoint) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      app.newrequest
        .get(endpoint)
        .then(response => resolve(self.createGridFromResponse(response.data)))
        .catch(error => reject(error))
    })
  }

  static addToGrid(contentId, listType) {
    const app = Application.instance()

    return new Promise((resolve, reject) => {
      app.newrequest
        .post('/user/mark', {
          contentIds: [contentId],
          listType,
        })
        .then(response => resolve(response))
    })
  }

  static removeFromGrid(contentId, listType) {
    const app = Application.instance()

    return new Promise((resolve, reject) => {
      app.newrequest
        .post('/user/unmark', {
          contentIds: [contentId],
          listType,
        })
        .then(response => resolve(response))
        .catch(e => reject(e))
    })
  }

  static addManyToGrid(contentIds, listType) {
    const app = Application.instance()

    return new Promise((resolve, reject) => {
      app.newrequest
        .post('/user/mark', {
          contentIds,
          listType,
        })
        .then(response => resolve(response))
    })
  }

  static removeManyFromGrid(contentIds, listType) {
    const app = Application.instance()

    return new Promise((resolve, reject) => {
      app.newrequest
        .post('/user/unmark', {
          contentIds,
          listType,
        })
        .then(response => resolve(response))
        .catch(e => reject(e))
    })
  }
}
