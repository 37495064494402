import Amplify from 'aws-amplify'
import { Provider, AuthService } from 'services'
import { auth } from 'configs'
import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'

export default class AuthProvider extends Provider {
  configService = inject(DI_TOKENS.configService)

  boot(app) {
    app.bind('auth', () => new AuthService(this.config.auth, this.config.amplify))
    // Init Amplify configuration
    Amplify.configure(this.config.amplify)
  }

  get config() {
    return {
      auth,
      amplify: {
        Auth: this.configService.get().auth,
      },
    }
  }
}
