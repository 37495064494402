import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = ({ custom, spacing, palette }: AppTheme) => {
  return appCreateStyles({
    root: {
      color: palette.common.white,
      opacity: '0.6',
      borderRadius: 20,
      border: '2px solid rgba(226, 229, 233, 0.1)',
      transition: '0.2s',
      boxSizing: 'border-box',
      height: 41,
      minWidth: 'unset',

      '&.Mui-disabled': {
        boxShadow: 'none',
        backgroundColor: custom.colors.gray[200],
      },
    },
    outline: {
      backgroundColor: 'transparent',
      padding: spacing(2.5, 4),

      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: palette.common.white,
      },
    },
    fill: {
      backgroundColor: '#525255',
      padding: spacing(2, 2),

      '&:hover': {
        backgroundColor: custom.colors.gray[200],
      },
    },
  })
}
