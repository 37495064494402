import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = ({ custom, spacing, palette }: AppTheme) =>
  appCreateStyles({
    container: {
      position: 'relative',
      cursor: 'pointer',
    },
    disabled: {
      cursor: 'default',

      '& $text': {
        color: custom.colors.gray[300],
      },
    },
    icon: {
      height: 20,
      marginRight: spacing(1),
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 20,
      backgroundColor: custom.colors.gray[450],
      padding: spacing(3, 8, 3, 5),
      color: '#B3B3B4',
      fontSize: 14,
      border: 'none',
      outline: 'none',
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          padding: spacing(2, 8, 2, 5),
        },
    },
    mobileText: {
      padding: spacing(2, 8, 2, 5),
      backgroundColor: 'transparent',
    },
    label: {
      fontWeight: 300,
    },
    iconRoot: {
      position: 'absolute',
      top: 11,
      right: 10,
      color: palette.common.white,
      opacity: '0.6',
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          top: 7,
        },
    },
  })
