import React, { Component } from 'react'

export default class NotFound extends Component {
  render() {
    return (
      <div className="not-found 404">
        <div className="container">
          <h2 className="white-opacity-07">
            <span className="green">404</span> Page Not Found (!!!
          </h2>
        </div>
      </div>
    )
  }
}
