import 'reflect-metadata'

import React from 'react'
import { hydrate, render } from 'react-dom'

import { setUp } from 'core/state-management/utils'
import { renderFunc } from './renderFunction'

import { Firebase } from './services/firebase.service'

// Import application service
import { Application } from 'services'
// Get list of providers
import providers from 'providers'
import { initializeDi } from 'core/di'
import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'

import 'react-toastify/dist/ReactToastify.css'
// Include all css files
import 'assets/scss/_style.scss'

initializeDi()

const configService = inject(DI_TOKENS.configService)

configService
  .initialize()
  .then(() => {
    // Create new instance of application and register the service providers
    const app = new Application().registerServiceProviders(providers)
    // Inject app to React Component
    React.Component.prototype.$app = app
    // Create global app variable
    window.$app = app

    Firebase.init()

    setUp()

    const ROOT = document.getElementById('root')

    app.auth.check().finally(() => {
      app.run(() => {
        ROOT.hasChildNodes()
          ? hydrate(renderFunc(app), ROOT)
          : render(renderFunc(app), ROOT)
      })
    })
  })
  .catch(err => {
    console.error('INITIALIZATION_ERROR', err)
    alert(
      'An error occurred while launching the application. Please try again later.',
    )
  })
