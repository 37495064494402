import React from 'react'
import { Helmet } from 'react-helmet'
import logo from 'assets/images/logo.png'
import { Application } from '../../services'

export const Meta: React.FC = () => {
  const $app = Application.instance()

  return (
    <Helmet>
      {/* General tags */}
      <title>Skroote | Streaming TV, Sports, Movies</title>
      <meta
        name="description"
        content="Skroote | Streaming TV, Sports, Movies"
      />
      {/* OpenGraph tags */}
      <meta property="og:url" content={$app.url.url} />
      <meta
        property="og:title"
        content="Skroote | Streaming TV, Sports, Movies"
      />
      <meta
        property="og:description"
        content="Skroote | Streaming TV, Sports, Movies"
      />
      <meta property="og:image" content={`${$app.url.url}${logo}`} />
      <meta property="og:type" content="website" />
      {/* Twitter Card tags */}
      <meta
        property="twitter:title"
        content="Skroote | Streaming TV, Sports, Movies"
      />
      <meta
        property="twitter:description"
        content="Skroote | Streaming TV, Sports, Movies"
      />
      <meta property="twitter:image" content={`${$app.url.url}${logo}`} />
      <meta
        name="viewport"
        content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
      />

      <meta property="twitter:card" content="summary" />
      <link rel="canonical" href={$app.url.url} />
    </Helmet>
  )
}
