import LocalStorage from 'services/storages/localStorage.service'
import CookieStorage from 'services/storages/cookieStorage.service'

export default {
  default: 'local',
  drivers: {
    local: {
      Driver: LocalStorage,
    },
    cookie: {
      Driver: CookieStorage,
    },
  },
}
