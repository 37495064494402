import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import {
  MovieModel,
  ShowModel,
  SearchModel,
  CastModel,
  UserModel,
  SportModel,
} from 'models'
import { MOVIE, SHOW, PERSON, USER, SPORT } from 'const/mediaTypes'

configure({ enforceActions: 'observed' })
export default class SearchStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      is_focused: false,
      value: '',
      shortValue: '',
      collectionContent: new Collection(),
      collectionPerson: new Collection(),
      collectionUsers: new Collection(),
      shortListTitles: new Collection(),
      shortListPersons: new Collection(),
      collapsed: false,
    })
    this.model = SearchModel
    this.titleType = {
      [MOVIE]: MovieModel,
      [SHOW]: ShowModel,
      [PERSON]: CastModel,
      [USER]: UserModel,
      [SPORT]: SportModel,
    }
  }

  setCollapsed() {
    // if(this.collapsed ==  false) {
    // 	this.collectionContent =  new Collection(this.collectionContent.toArray().slice(0,5));
    // }
    this.collapsed = !this.collapsed
  }
  /**
   * Reset all store data
   *
   */
  reset() {
    this.value = ''
    this.shortValue = ''
    this.collectionContent = this.collectionContent.clear()
    this.collectionPerson = this.collectionPerson.clear()
    this.shortListTitles = this.shortListTitles.clear()
    this.shortListPersons = this.shortListPersons.clear()
  }

  /**
   * Set Value by type ()
   * @param val
   * @param typeOfValue
   */
  setValue(val, typeOfValue) {
    this[typeOfValue] = val
  }

  /**
   * Focus functionality for field
   * @param val
   */
  setFocus(val) {
    this.is_focused = val
  }

  /**
   * Load next collection of titles
   */
  loadNext() {
    return this.collectionContent.paginator.next().then(
      action(collection => {
        this.collectionContent = this.collectionContent.merge(
          this.mutateCollectionModels(
            collection.setPagination(this.collectionContent.paginator),
          ),
        )
      }),
    )
  }

  /**
   * Transform model by type of model {Movie, Show, Person}
   *
   * @param collection
   * @returns {*}
   */
  mutateCollectionModels(collection, modelType) {
    const mutatedCollection = new Collection().setPagination(
      collection.paginator,
    )
    collection.forEach((title, index) => {
      if (modelType && this.titleType[modelType]) {
        mutatedCollection.push(
          new this.titleType[modelType]().fill(title.getAttributes(), true),
        )
        return
      }
      if (!title.contentType) {
        mutatedCollection.push(
          new this.titleType[PERSON]().fill(title.getAttributes(), true),
        )
      }

      if (title.contentType && this.titleType[title.contentType]) {
        mutatedCollection.push(
          new this.titleType[title.contentType]().fill(
            title.getAttributes(),
            true,
          ),
        )
      }

      // 	return mutatedCollection.push(
      // 	!title.type
      // 		? new this.titleType[PERSON]().fill(title.getAttributes(), true)
      // 		: new this.titleType[title.type]().fill(
      // 				title.getAttributes(),
      // 				true,
      // 		  ),
      // )
    })
    return mutatedCollection
  }

  /**
   * Get list of search people
   *
   * @param params
   * @param typeOfCollection
   * @param typeahead
   */
  getListPerson(params, typeOfCollection = 'collectionPerson', typeahead) {
    this.loading = true
    this.model
      .listPerson(params, typeahead)
      .then(
        action(collection => {
          this[typeOfCollection] = this.mutateCollectionModels(
            collection,
            typeOfCollection,
          )
        }),
      )
      .finally(action(() => (this.loading = false)))
  }

  getListUsers(params, typeOfCollection = 'collectionUsers', typeahead) {
    this.loading = true
    UserModel.listUsers(params, typeahead)
      .then(
        action(collection => {
          this[typeOfCollection] = this.mutateCollectionModels(collection, USER)
        }),
      )
      .finally(action(() => (this.loading = false)))
  }

  /**
   * Get list of search content
   *
   * @param params
   * @param typeOfCollection
   * @param typeahead
   */
  getListContent(params, typeOfCollection = 'collectionContent', typeahead) {
    this.loading = true
    this.model
      .listContent(params, typeahead)
      .then(
        action(collection => {
          this[typeOfCollection] = this.mutateCollectionModels(
            collection,
            typeOfCollection,
          )
        }),
      )
      .finally(action(() => (this.loading = false)))
  }
}

decorate(SearchStore, {
  reset: action,
  setValue: action,
  setFocus: action,
  getListContent: action,
  getListUsers: action,
  getListPerson: action,
  prepareCollection: action,
  setCollapsed: action,
})
