import { BaseModel } from 'core/models/base'
import { GridRequestType } from '../grid/request-type'
import { GridGroupItem } from './GridGroupItem'

export interface GridGroupGetQuery {
  id: string
  items: Array<GridGroupItem>
  title: string
  type: GridRequestType
}

export class GridGroupGetModel extends BaseModel<GridGroupGetQuery> {
  id: string
  items: Array<GridGroupItem>
  title: string
  type: GridRequestType

  constructor(data: GridGroupGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): GridGroupGetQuery {
    return {
      id: this.id,
      items: this.items,
      title: this.title,
      type: this.type,
    }
  }
}
