import { BaseModel } from 'core/models/base'
import { ContentGetQuery } from '../content/get-model'
import { DefaultUserList } from 'shared/constants/grid'
import { UserGetQuery } from '../user/get-model'

export interface ActivityGetQuery {
  activity: Array<{
    contentId: ContentGetQuery['id']
    date: Date
    listType: DefaultUserList
    userId: UserGetQuery['id']
  }>
  contentInfo: Record<
    DefaultUserList,
    Array<{ count: number; contentId: ContentGetQuery['id'] }>
  >
}

export class ActivityGetModel extends BaseModel<ActivityGetQuery> {
  private activity: Array<{
    contentId: ContentGetQuery['id']
    date: Date
    listType: DefaultUserList
    userId: UserGetQuery['id']
  }>
  private contentInfo: Record<
    DefaultUserList,
    Array<{ count: number; contentId: ContentGetQuery['id'] }>
  >

  constructor(data: ActivityGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): ActivityGetQuery {
    return {
      activity: this.activity,
      contentInfo: this.contentInfo,
    }
  }
}
