import React from 'react'
import cx from 'classnames'
import { Button } from 'shared/components/button'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './select-button.styles'

export enum SelectButtonView {
  OUTLINE = 'outline',
  FILL = 'fill',
}

type ComponentProps = {
  onClick: () => void
  text?: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  view?: SelectButtonView
  disabled?: boolean
}

type Props = AppWithStyles<typeof styles> & ComponentProps

const _SelectButton: React.FC<Props> = ({
  classes,
  onClick,
  text,
  startIcon,
  endIcon,
  view = SelectButtonView.OUTLINE,
  disabled = false,
}) => {
  const styles = cx(
    classes.root,
    view === SelectButtonView.OUTLINE && classes.outline,
    view === SelectButtonView.FILL && classes.fill,
  )

  return (
    <Button
      classes={{ root: styles }}
      startIcon={startIcon}
      endIcon={endIcon}
      text={text}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

export const SelectButton = appWithStyles(styles)(_SelectButton)
