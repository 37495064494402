import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { MovieModel, ShowModel, ContentModel, SportModel } from 'models'
import { MOVIE, SHOW, SPORT } from 'const/mediaTypes'
import { GridModel } from 'models'

configure({ enforceActions: 'observed' })
export default class ContentStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      title: new MovieModel(), // MovieModel ||  ShowModel
      content: new ContentModel(),
      items: new Collection(),
    })
    this.model = ContentModel
    this.titleType = {
      [MOVIE]: MovieModel,
      [SHOW]: ShowModel,
      [SPORT]: SportModel,
    }
  }

  /**
   * Set Collection
   *
   * @param payload
   */
  setCollection(payload) {
    this.collection = payload
  }

  getById(id) {
    this.loading = true
    this.content.getById(id).then(
      action(item => {
        this.title = new this.titleType[item.contentType]().fill(
          item.getAttributes(),
          true,
        )
        this.loading = false
      }),
    )
  }

  getItems(items) {
    return GridModel.getContentItems(items)
  }
}

decorate(ContentStore, {
  setCollection: action,
  getById: action,
})
