import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';


export const useAppWidth = () => {
  const theme = useTheme();

  const isUp = (breakpoint: Breakpoint | number) => {
    // eslint-disable-next-line
    return useMediaQuery(theme.breakpoints.up(breakpoint));
  };

  const isDown = (breakpoint: Breakpoint | number) => {
    // eslint-disable-next-line
    return useMediaQuery(theme.breakpoints.down(breakpoint));
  };

  return { isUp, isDown };
};
