import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { UserListModel } from 'shared/models/user/list-model'
import { UserGetModel } from 'shared/models/user/get-model'
import { IUsersService } from 'shared/types/services/users'
import { GridRequestType } from 'shared/models/grid/request-type'
import { GridGroupGetModel } from 'shared/models/grid-group/get-model'
import { GridGetModel } from 'shared/models/grid/get-model'
import { ActivityGetModel } from 'shared/models/activity/get-model'

@injectable()
export class UsersService extends BaseService implements IUsersService {
  private app = Application.instance()

  protected urlPrefix = '/user'

  getList: IUsersService['getList'] = async ids => {
    const { data } = await this.app.newrequest.post('/users', ids)

    return data.map(user => new UserListModel(user))
  }

  get: IUsersService['get'] = async id => {
    const { data } = await this.app.newrequest.get(this.getUrl(id))

    return new UserGetModel(data)
  }

  getCustomGridsGroup: IUsersService['getCustomGridsGroup'] = async () => {
    const { data } = await this.app.newrequest.get(
      `/groups/${GridRequestType.userCustom}`,
    )

    return new GridGroupGetModel(data)
  }

  getDefaultGrids: IUsersService['getDefaultGrids'] = async () => {
    const { data } = await this.app.newrequest.get('grids/default')

    return data.map(item => new GridGetModel(item))
  }

  getActivity: IUsersService['getActivity'] = async () => {
    const { data } = await this.app.newrequest.get(
      this.getUrl('follow/content'),
    )

    return new ActivityGetModel(data)
  }
}
