import React from 'react'
import cx from 'classnames'
import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'

import { SelectListItem } from '../../../filters/model/global-filter.types'

import { styles } from './selec-multiply-list.styles'

type MultiplyListProps = {
  options: SelectListItem[]
  value: string[]
  onChange: (v: string) => void
  children?: React.ReactNode
}

type Props = AppWithStyles<typeof styles> & MultiplyListProps

const _SelectMultiplyList: React.FC<Props> = ({
  classes,
  options,
  value,
  onChange,
  children,
}) => {
  const handleOnChange = (value: SelectListItem['value']) => () => {
    onChange(value)
  }
  return (
    <>
      {options.length === 0 && (
        <div className={classes.empty}>
          <span className={classes.item}>Options list is empty</span>
        </div>
      )}
      {options.length > 0 && (
        <div className={classes.container}>
          <ul className={classes.list}>
            {options.map(option => {
              const isActive = value.includes(option.value)
              const styles = cx(classes.item, isActive && classes.itemChecked)
              return (
                <li
                  className={styles}
                  key={option.value}
                  onClick={handleOnChange(option.value)}
                >
                  <span className={classes.shortText}>{option.name}</span>
                  <span className={classes.extraText}>{option.value}</span>
                </li>
              )
            })}
          </ul>
          {children}
        </div>
      )}
    </>
  )
}

export const SelectMultiplyList = appWithStyles(styles)(_SelectMultiplyList)
