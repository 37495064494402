import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { GridRequestType } from 'shared/models/grid/request-type'
import { GridType } from 'shared/models/grid/type'
import { ProviderGetQuery } from 'shared/models/provider/get-model'
import { IOnboardingService } from 'shared/types/services/onboarding'
import { IProvidersService } from 'shared/types/services/providers'

export class ThirdStepViewModel {
  private onboardingService = inject<IOnboardingService>(
    DI_TOKENS.onboardingService,
  )
  private providersService = inject<IProvidersService>(
    DI_TOKENS.providersService,
  )

  getList: IProvidersService['getList'] = (...args) => {
    return this.providersService.getList(...args)
  }

  updateList = async (
    skip: boolean,
    selectedProviders: {
      free: Array<ProviderGetQuery['id']>
      paid: Array<ProviderGetQuery['id']>
    },
  ) => {
    try {
      await this.onboardingService.updateList(
        GridRequestType.like,
        GridType.freeProvider,
        skip ? [] : selectedProviders.free,
      )
      await this.onboardingService.updateList(
        GridRequestType.like,
        GridType.paidProvider,
        skip ? [] : selectedProviders.paid,
      )
    } catch (err) {
      throw err
    }
  }
}
