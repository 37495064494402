import { extendObservable } from "mobx"

export default class EventBus {
  constructor() {
    this.bus = {}

    extendObservable(this, {
      initializedEvents: [],
    })
  }

  isEventInitialized(id) {
    return this.initializedEvents.includes(id)
  }

  $on(id, callback) {
    this.bus[id] = callback

    if (!this.initializedEvents.includes(id)) {
      this.initializedEvents.push(id);
    }
  }

  $emit(id, ...rest) {
    this.bus[id](...rest)
  }

  clear(id, all = false) {
    !all ? delete this.bus[id] : (this.bus = {})
    this.bus = {}

    if (all) {
      this.initializedEvents = [];
    } else {
      this.initializedEvents = this.initializedEvents.filter((event) => event !== id);
    }

    return this
  }
}
