export enum GridRequestType {
  onboarding = 'ONBOARDING',
  like = 'LIKE',
  content = 'CONTENT',
  followers = 'FOLLOWERS',
  following = 'FOLLOWING',
  footer = 'FOOTER',
  home = 'HOME',
  live = 'LIVE',
  movie = 'MOVIE',
  none = 'NONE',
  show = 'SHOW',
  sport = 'SPORT',
  userCustom = 'USER_CUSTOM',
}
