import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { GridModel } from 'models'
import { LIKE } from 'const/api/grids'
import { Application } from 'services'

configure({ enforceActions: 'observed' })
export default class LikeStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      grids: new Collection(),
    })
    this.model = GridModel
  }

  /**
   * Set Collection
   *
   * @param payload
   */
  setCollection(payload) {
    this.collection = payload
  }

  getGrids() {
    return GridModel.list(LIKE)
      .then(
        action(collection => {
          this.grids = collection
          this.fetchDetails()
        }),
      )
      .catch(e => {
        console.error(e)
      })
  }

  fetchDetails() {
    this.grids.toArray().forEach(grid => {
      const app = Application.instance()

      grid.getDetails().then(details => {
        if (details.itemType === 'GENRE') {
          app.store.OnboardingStore.selectMultipleGenre(details.items)
        }

        if (details.type === 'CONTENT') {
          app.store.OnboardingStore.selectMultipleMovie(details.items)
        }

        if (details.type === 'PERSON') {
          app.store.OnboardingStore.selectMultipleselectPerson(details.items)
        }

        if (details.type === 'SPORT') {
          app.store.OnboardingStore.selectMultipleSportGenre(details.items)
        }

        if (details.type === 'TEAM') {
          app.store.OnboardingStore.selectMultipleTeam(details.items)
        }

        if (details.type === 'FREE_PROVIDER') {
          app.store.OnboardingStore.selectMultipleFreeProviders(details.items)
        }

        if (details.type === 'PAID_PROVIDER') {
          app.store.OnboardingStore.selectMultiplePaidProviders(details.items)
        }
      })
    })
  }
}

decorate(LikeStore, {
  setCollection: action,
})
