import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { BREAKPOINT } from 'shared/constants/theme'

export function styles({ spacing, palette, breakpoints }: AppTheme) {
  return appCreateStyles({
    root: {
      padding: spacing(4),
      backgroundColor: palette.background.default,
      borderRadius: 12,
      maxWidth: 980,
    },
    title: {
      color: '#FFFFFF80',
      fontWeight: 700,
      marginBottom: spacing(2),
    },
    subtitle: {
      color: palette.common.white,
      fontWeight: 500,
      margin: spacing(0, 0, 2, 2),
    },
    buttons: {
      
    },
    btnWrapper: {
      [breakpoints.down(BREAKPOINT.mobile)]: {
        width: '50%',

        '&:last-of-type': {
          width: '100%',
        },
      },
    },
    button: {
      boxShadow: '2px 2px 4px 0px #00000040',
      height: 28,
      minWidth: 122,
      fontWeight: 400,
      margin: spacing(1),
      borderRadius: 4,
      backgroundColor: '#565659',
      color: palette.common.white,
    },
    buttonSelected: {
      backgroundColor: palette.primary.dark,
    },
  })
}
