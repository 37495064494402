import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ palette, custom, spacing }: AppTheme) {
  const hintZIndexToBeUnderAllModals = 1;
  return appCreateStyles({
    root: {
      justifyContent: 'flex-start',
      backgroundColor: custom.colors.gray[600],
      color: appFade(palette.common.white, 0.4),
      border: `1px solid transparent`,

      '&:hover': {
        backgroundColor: custom.colors.gray[600],
        borderColor: palette.primary.main,
      },
    },
    searchWrapper: {
      maxWidth: 400,
      position: 'relative',
      flex: 1,
      marginLeft: spacing(6),
    },
    aiOption: {
      position: 'absolute',
      color: appFade(palette.common.white, 0.4),
      background: custom.colors.gray[450],
      padding: '2px 8px',
      borderRadius: '4px 4px 0px 0px',
      top: '-14.5px',
      left: '30px',
      fontSize: 10,
    },
    aiOptionLoggedInUser: {
      background: palette.primary.dark,
      color: palette.common.white,
    },
    hintPopper: {
      pointerEvents: 'all',
      zIndex: hintZIndexToBeUnderAllModals,
    },
    hintPopperInner: {
      padding: spacing(4),
      background: palette.primary.dark,
    },
    hintPopperArrow: {
      '&::before': {
        background: palette.primary.dark,
      },
    },
    closeHintBtn: {
      position: 'absolute',
      top: '-12px',
      right: '-11px',
      width: 20,
      minWidth: 'auto',
      height: 20,
      background: '#EBEBEB1A',
      color: 'white',
      backdropFilter: 'blur(19.76932144165039px)',

      '& svg': {
        fontSize: 12,
      },
    },
  })
}
