import React from 'react'
import Grid from '@material-ui/core/Grid'

import { Button, ButtonProps } from 'shared/components/button'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './message.styles'

export type MessageProps = AppWithStyles<typeof styles> & {
  heading?: React.ReactNode
  subheading?: React.ReactNode
  icon?: React.ReactNode
  buttonProps?: Partial<ButtonProps>
}

const MessageComponent: React.FC<MessageProps> = ({
  classes,
  heading,
  icon,
  subheading,
  buttonProps,
  ...otherProps
}) => {
  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
      direction="column"
      {...otherProps}
    >
      {icon && (
        <Grid item classes={{ root: classes.iconWrapper }}>
          {icon}
        </Grid>
      )}
      {heading && (
        <Grid item>{<h4 className={classes.heading}>{heading}</h4>}</Grid>
      )}
      {subheading && (
        <Grid item>{<h4 className={classes.subheading}>{subheading}</h4>}</Grid>
      )}
      {buttonProps && (
        <Button classes={{ root: classes.button }} {...buttonProps} />
      )}
    </Grid>
  )
}

export const Message = appWithStyles(styles)(MessageComponent)
