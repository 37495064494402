import { mapValues } from 'lodash-es'

import { Image } from 'shared/models/image/model'
import { ImageOrientation } from 'shared/models/image/orientation'
import { ImageType } from 'shared/models/image/type'

export const processContentImages = (
  images: Array<Image>,
): Partial<
  Record<
    ImageType,
    {
      url: { large: string; medium: string; small: string }
      orientation: ImageOrientation
    }
  >
> => {
  const imagesByType = images.reduce(
    (acc, image) => ({ ...acc, [image.type]: image }),
    {},
  )

  return mapValues(
    imagesByType,
    ({ orientation, largeUrl, mediumUrl, smallUrl }) => ({
      orientation,
      url: { large: largeUrl, medium: mediumUrl, small: smallUrl },
    }),
  )
}
