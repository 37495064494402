import { extendObservable, toJS, action, configure, decorate } from 'mobx'
import { Collection, Mutator } from './'
import { EPISODE, SPORT } from 'const/mediaTypes'

configure({ enforceActions: 'observed' })
export default class Model {
  constructor(attributes = {}) {
    extendObservable(this, {
      attributes,
    })
    this.originals = {}
    this.pivot = null
    ;[...Object.keys(this.fillable), ...Object.keys(this.relations)].forEach(
      key => {
        this.observe(key)
      },
    )
    this.fill(
      attributes && typeof attributes === 'object' ? attributes : {},
      true,
    )
  }

  /**
   * Relations getter
   *
   * @returns {{}}
   */
  get relations() {
    return {}
  }

  /**
   * Fillable getter
   *
   * @returns {{}}
   */
  get fillable() {
    return {}
  }

  /**
   * Returns the model attributes
   *
   * @returns {{}|*}
   */
  getAttributes() {
    return toJS(this.attributes)
  }

  /**
   * Returns the model originals
   *
   * @returns {{}|*}
   */
  getOriginals() {
    return this.originals
  }

  /**
   * Set original value of attribute
   *
   * @param key
   * @param value
   * @returns {Model}
   */
  setOriginal(key, value) {
    this.originals[key] = this.mutate(key, value)
    return this
  }

  setPivot(pivot) {
    this.pivot = pivot
    return this
  }

  getPivot() {
    return this.pivot
  }

  /**
   * Set attributes of model
   *
   * @param attributes
   * @param initial
   * @returns {Model}
   */
  fill(attributes = {}, initial = false) {
    Object.keys(attributes || {}).forEach(key => {
      this.set(key, attributes[key])
      if (initial) {
        this.setOriginal(key, this.get(key))
      }
    })
    return this
  }

  /**
   * Set attribute by key
   *
   * @param key
   * @param value
   * @returns {Model}
   */
  set(key, value) {
    if (key in this.relations && key) {
      if (
        !this.attributes[key] &&
        (this.relations[key].class || this.relations[key].morph)
      ) {
        this.attributes[key] = this.addRelation(key)
      }
      this.fillRelation(key, value)
    } else {
      this.attributes[key] = this.mutate(key, value)
    }
    if (!this.hasOwnProperty(key)) {
      this.observe(key)
    }
    return this
  }

  /**
   * Reset attributes to original values
   *
   * @returns {Model}
   */
  reset() {
    this.fill(this.getOriginals())
  }

  /**
   * Returns the attribute by key
   *
   * @param key
   * @param defaultValue
   * @returns {boolean}
   */
  get(key, defaultValue = null) {
    return key in this.attributes ? this.attributes[key] : defaultValue
  }

  /**
   * Check if the model is empty
   *
   * @returns {boolean}
   */
  isEmpty() {
    const values = Object.values(this.attributes).filter(item => !!item)
    return !values.length
  }

  /**
   * Check if the model is not empty
   *
   * @returns {boolean}
   */
  isNotEmpty() {
    return !this.isEmpty()
  }

  /**
   * Returns original value of attribute
   *
   * @param key
   * @param defaultValue
   * @returns {boolean}
   */
  original(key, defaultValue = null) {
    return key in this.originals && this.originals[key]
      ? this.originals[key]
      : defaultValue
  }

  /**
   * Mutates the attribute
   *
   * @param key
   * @param value
   * @returns {*}
   */
  mutate(key, value) {
    if (key in this.fillable) {
      return new Mutator(value, this.fillable[key]).run()
    }
    return value
  }

  /**
   * Check if the attribute has been changed
   *
   * @param key
   * @returns {boolean}
   */
  isDirty(key = false) {
    if (key) {
      if (
        typeof this.relations[key] !== 'undefined' ||
        typeof this.attributes[key] === 'undefined' ||
        this.attributes[key] instanceof Array
      ) {
        return false
      }
      const current = JSON.parse(JSON.stringify(this.get(key)))
      const original = JSON.parse(JSON.stringify(this.original(key)))
      return current !== original
    }
    let result = false
    Object.keys(this.getAttributes()).forEach(key => {
      if (this.isDirty(key)) {
        result = true
      }
    })
    return result
  }

  /**
   * Remove attributes by key
   * @param keys
   * @returns {boolean}
   */
  removeAttributeByKeys(keys) {
    keys.forEach(key => {
      delete this.attributes[key]
    })
  }

  /**
   * Fills the model relation
   *
   * @param key
   * @param value
   */
  fillRelation(key, value) {
    const relation = this.attributes[key]
    if (value) {
      if (typeof this.relations[key] === 'function') {
        this.attributes[key] = this.relations[key](value.data || value)
        if (this.attributes[key] instanceof Model) {
          this.attributes[key].setPivot(this)
        }
      } else if (this.relations[key].morph) {
        this.fillMorphRelation(key, value)
      } else if (this.relations[key].class.prototype instanceof Model) {
        if (this.relations[key].type !== 'collection') {
          relation.fillFromResponse(value).setPivot(this)
        } else {
          let data = []
          data = value.map(item =>
            this.relations[key].class.createFromResponse(item),
          )
          relation.fill(data)
          if (value && value.meta) {
            relation.setPagination(value.meta)
          }
        }
      }
    }
  }

  fillMorphRelation(key, value) {
    const typeKey = `${key}_type`
    if (this.relations[key].type !== 'collection') {
      const model = this.relations[key].map[this.attributes[typeKey]]
      const _value = value instanceof Model ? value.toObject() : value
      this.attributes[key] = new model().fillFromResponse(_value).setPivot(this)
    } else {
      let items = []
      if (value && value.items) {
        items = value.items.map(item => {
          const model = this.relations[key].map[item.data[typeKey]]
          return new model().fillFromResponse(value)
        })
        this.attributes[key] = new Collection().fill({
          items,
        })
        if (value && value.meta) {
          this.attributes[key].setPagination(value.meta)
        }
      }
    }
  }

  /**
   * Adds the relation to current model
   *
   * @param key
   * @returns {null}
   */
  addRelation(key) {
    let relation = null
    if (this.relations[key].morph) {
      if (this.relations[key].type !== 'collection') {
        relation = Model.createEmpty()
      } else {
        relation = Model.createEmptyCollection()
      }
    } else if (this.relations[key].class.prototype instanceof Model) {
      if (this.relations[key].type !== 'collection') {
        relation = this.relations[key].class.createEmpty()
      } else {
        relation = this.relations[key].class.createEmptyCollection()
      }
    }
    if (relation instanceof Model) {
      relation.setPivot(this)
    }
    return relation
  }

  /**
   * Clear all attributes to default values
   *
   * @returns {Model}
   */
  clear() {
    Object.keys(this.attributes).forEach(key => {
      this.attributes[key] = null
    })
    return this
  }

  /**
   * Defines the attribute observer
   *
   * @param key
   * @returns {Model}
   */
  observe(key) {
    Object.defineProperty(this, key, {
      set: value => this.set(key, value),
      get: () => this.get(key),
    })
    return this
  }

  /**
   * Create a new instance
   *
   * @returns {Model}
   */
  static createEmpty() {
    return new this()
  }

  /**
   * Create empty collection
   *
   * @returns {Collection}
   */
  static createEmptyCollection() {
    return new Collection([])
  }

  /**
   * Create fill attributesF
   *
   * @param response
   * @returns {Model}
   */
  fillFromResponse(response) {
    this.fill(response, true)
    if (response.meta) this.setMeta(response.meta)
    return this
  }

  /**
   * Create a new instance of the response
   *
   * @param response
   * @param endpoint
   * @returns {Model}
   */
  static createFromResponse(response, endpoint = null) {
    if (response.type === 'COLLECTION') {
      const collection = []
      let res = null
      for (let i = 0; i < response.results.length; i++) {
        res = response.results[i]
        if (res.mediaType) {
          if (res.mediaType !== EPISODE && res.mediaType !== SPORT) {
            if (res.posterPath) {
              collection.push(this.createFromResponse(res, endpoint))
            }
          } else {
            collection.push(this.createFromResponse(res, endpoint))
          }
        } else {
          collection.push(this.createFromResponse(res, endpoint))
          // if (!res.listType && !res.site && !res.service && !res.contentType) {
          // 	if (res.profilePath) {
          // 		collection.push(this.createFromResponse(res, endpoint));
          // 	}
          // } else {
          // 	collection.push(this.createFromResponse(res, endpoint));
          // }
        }
      }
      res = null
      const instance = new Collection()
      if (response.meta) {
        instance.fill(collection).setPagination(response.meta)
        instance.paginator.setUrl(endpoint).setPivot(new this())
      } else {
        instance.fill(collection)
      }
      return instance
    }
    if (response.content) {
      return this.createGridFromResponse(response.content)
    }
    if (Array.isArray(response)) {
      return this.createGridFromResponse(response)
    }
    return new this().fillFromResponse(response)
  }

  static createGridFromResponse(response) {
    const array = []
    response.forEach(item => {
      const newModel = new this().fillFromResponse(item)
      array.push(newModel)
    })

    if (array.some(item => item.hasOwnProperty('order'))) {
      array.sort((a, b) => (a.order > b.order && 1) || -1)
    }

    return new Collection(array)
  }
}

decorate(Model, {
  set: action,
  reset: action,
  fillFromResponse: action,
  createFromResponse: action,
  createGridFromResponse: action,
})
