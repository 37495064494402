import React, { useState } from 'react'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { SelectSlider } from './components/select-slider/select-slider'
import { SelectField } from './components/select-field/select-field'
import { SelectClose } from './components/select-close/select-close'

import { styles } from './select.styles'

export type SelectValue = number[]

type ComponentProps = {
  value: SelectValue
  onChange: (yearFrom: number, yearTo: number) => void
  onReset: () => void
  label?: string
  min: number
  max: number
}

type Props = AppWithStyles<typeof styles> & ComponentProps

const _CustomSelectSlider: React.FC<Props> = ({
  classes,
  value,
  onChange,
  onReset,
  label,
  min,
  max,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnOpen = () => setIsOpen(true)
  const handleOnClose = () => setIsOpen(false)

  const getTitle = () => {
    const [from, to] = value
    if (!from || !to) return ''

    return `${from}-${to}`
  }

  const valueTitle = getTitle()

  const getLabel = (): string => {
    return valueTitle ? `${label}s` : label
  }

  return (
    <ClickAwayListener onClickAway={handleOnClose}>
      <div className={classes.container}>
        <SelectField
          onClose={handleOnOpen}
          label={getLabel()}
          value={valueTitle}
        />
        {isOpen && (
          <SelectSlider onChange={onChange} value={value} min={min} max={max}>
            <SelectClose
              onClose={handleOnClose}
              onReset={onReset}
              hasValue={Boolean(value[0])}
            />
          </SelectSlider>
        )}
      </div>
    </ClickAwayListener>
  )
}

export const CustomSelectSlider = appWithStyles(styles)(
  React.memo(_CustomSelectSlider),
)
