import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { IOnboardingService } from 'shared/types/services/onboarding'
import { UserListModel } from 'shared/models/user/list-model'

@injectable()
export class OnboardingService
  extends BaseService
  implements IOnboardingService
{
  private $app = Application.instance()

  updateStatus: IOnboardingService['updateStatus'] = status => {
    return this.$app.newrequest.put('/user/onboarding', {
      onboardingStatus: status,
    })
  }

  updateList: IOnboardingService['updateList'] = (
    requestType,
    gridType,
    items,
  ) => {
    const listId = encodeURIComponent(
      `grid#${requestType}#${gridType}#${this.$app.auth.user.id}`,
    )

    return this.$app.newrequest.patch(`/grids/${listId}/items`, { items })
  }

  getUsers: IOnboardingService['getUsers'] = async () => {
    const { data } = await this.$app.newrequest.get('/user/search/onboarding')

    return data.map(item => new UserListModel(item))
  }
}
