import { Provider, Request } from 'services'
import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'

export default class RequestProvider extends Provider {
  configService = inject(DI_TOKENS.configService)

  boot(app) {
    app.bind('request', () => new Request(this.config))
  }

  get config() {
    return {
      baseURL: this.configService.get().apiUrl,
    }
  }
}
