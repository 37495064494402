import { Auth } from 'aws-amplify'
import { Application } from './index'

export default class oAuthService {
  constructor(configs) {
    this.configs = { ...this.defaults, ...configs }
  }

  signInWithGoogle() {
    const app = Application.instance()

    app.auth.setAuthInProgress(true)
    app.auth.setSignedInFrom(app.router.current)

    Auth.federatedSignIn({ provider: 'Google' })
  }

  signInWithFacebook() {
    const app = Application.instance()
    //set auth in progress true
    app.auth.setAuthInProgress(true)
    app.auth.setSignedInFrom(app.router.current)

    Auth.federatedSignIn({ provider: 'Facebook' })
  }

  signInWithApple() {
    const app = Application.instance()

    app.auth.setAuthInProgress(true)
    app.auth.setSignedInFrom(app.router.current)

    Auth.federatedSignIn({ provider: 'SignInWithApple' })
  }

  get defaults() {
    return {}
  }
}
