import { DiEntityIdentifier } from 'core/di/types'

function createTokens<Key extends string>(x: Record<Key, DiEntityIdentifier>) {
  return x
}

export const DI_TOKENS = createTokens({
  baseService: Symbol('base-service'),
  configService: Symbol('config-service'),
  contentService: Symbol('content-service'),
  followersService: Symbol('followers-service'),
  genresService: Symbol('genres-service'),
  layoutService: Symbol('layout-service'),
  personsService: Symbol('person-service'),
  profileService: Symbol('profile-service'),
  searchService: Symbol('search-service'),
  usersService: Symbol('users-service'),
  onboardingService: Symbol('onboarding-service'),
  departmentsService: Symbol('departments-service'),
  providersService: Symbol('providers-service'),
  announcementsService: Symbol('announcements-service'),
  bannerService: Symbol('banner-service'),
  usersViewModel: Symbol('users-view-model'),
})
