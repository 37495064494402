import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

type Props = SvgIconProps & {}

export const AiIcon: React.FC<Props> = props => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="#B2B1B2" {...props}>
      <path d="M18 14C16.1435 14 14.363 14.7375 13.0502 16.0503C11.7375 17.363 11 19.1435 11 21C10.9984 19.144 10.2603 17.3645 8.94789 16.0521C7.63549 14.7397 5.85601 14.0016 4 14C5.85652 14 7.63694 13.2625 8.94969 11.9497C10.2624 10.637 11 8.85652 11 7C11 8.85652 11.7375 10.637 13.0502 11.9497C14.363 13.2625 16.1435 14 18 14Z"/>
      <path d="M20 7C18.9391 7 17.9217 7.42143 17.1715 8.17158C16.4214 8.92172 16 9.93913 16 11C15.9991 9.93942 15.5773 8.92255 14.8274 8.17261C14.0774 7.42267 13.0606 7.00094 12 7C13.0609 7 14.0783 6.57857 14.8284 5.82842C15.5785 5.07828 16 4.06087 16 3C16 4.06087 16.4214 5.07828 17.1715 5.82842C17.9217 6.57857 18.9391 7 20 7Z"/>
    </SvgIcon>
  )
}
