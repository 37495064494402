import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = ({ custom, spacing, palette }: AppTheme) => {
  return appCreateStyles({
    containerCenter: {
      display: 'flex',
      justifyContent: 'center',
    },
    containerRight: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    line: {
      height: 1,
      color: custom.colors.gray[450],
      background: 'rgba(216, 216, 216, 0.1)',
    },
    text: {
      color: palette.common.white,
      opacity: '0.6',
      paddingTop: spacing(2),
      fontSize: 14,
      cursor: 'pointer',

      '&:hover': {
        opacity: 1,
      },
    },
    hide: {
      opacity: 0,

      '&:hover': {
        opacity: 0,
      },
    },
  })
}
