import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const AnimationContainer = ({ animation, children }) =>
  animation ? (
    <TransitionGroup component="main">
      <CSSTransition timeout={1000} classNames="fade">
        {children}
      </CSSTransition>
    </TransitionGroup>
  ) : (
    { children }
  )

AnimationContainer.propTypes = {
  animation: PropTypes.bool,
  children: PropTypes.instanceOf(Object).isRequired,
}

AnimationContainer.defaultProps = {
  animation: false,
}

export default AnimationContainer
