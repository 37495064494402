import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = () => {
  return appCreateStyles({
    container: {
      position: 'relative',
      minWidth: 210,
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          minWidth: 20,
        },
      '@media only screen and (orientation: landscape) and (max-height: 480px)':
        {
          minWidth: 20,
        },
    },
  })
}
