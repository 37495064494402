import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { DefaultGridModel } from 'models'

configure({ enforceActions: 'observed' })
export default class DefaultGridStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      collection: new Collection(),
      favorites: [],
      seen: [],
      watchLater: [],
      liked: [],
    })
    this.model = DefaultGridModel
  }

  getCollection() {
    DefaultGridModel.list('grids/default').then(
      action(collection => {
        this.collection = collection
        this.favorites =
          collection.toArray().find(grid => grid.title === 'FAVORITES')
            ?.items || []
        this.seen =
          collection.toArray().find(grid => grid.title === 'SEEN')?.items || []
        this.watchLater =
          collection.toArray().find(grid => grid.title === 'WATCH_LATER')
            ?.items || []
        this.liked =
          collection.toArray().find(grid => grid.title === 'LIKE')?.items || []
      }),
    )
  }

  addToFavorites(contentId) {
    DefaultGridModel.addToGrid(contentId, 'FAVORITES').then(
      action(collection => {
        this.favorites.push(contentId)
      }),
    )
  }

  removeFromFavorites(contentId) {
    DefaultGridModel.removeFromGrid(contentId, 'FAVORITES').then(
      action(collection => {
        this.favorites = this.favorites.filter(item => item !== contentId)
      }),
    )
  }

  addToSeen(contentId) {
    DefaultGridModel.addToGrid(contentId, 'SEEN').then(
      action(collection => {
        this.seen.push(contentId)
      }),
    )
  }

  removeFromSeen(contentId) {
    DefaultGridModel.removeFromGrid(contentId, 'SEEN').then(
      action(collection => {
        this.seen = this.seen.filter(item => item !== contentId)
      }),
    )
  }

  addToLiked(contentId) {
    DefaultGridModel.addToGrid(contentId, 'LIKE').then(
      action(collection => {
        this.liked.push(contentId)
      }),
    )
  }

  removeFromLiked(contentId) {
    DefaultGridModel.removeFromGrid(contentId, 'LIKE').then(
      action(collection => {
        this.liked = this.liked.filter(item => item !== contentId)
      }),
    )
  }

  addToWatchLater(contentId) {
    DefaultGridModel.addToGrid(contentId, 'WATCH_LATER').then(
      action(collection => {
        this.watchLater.push(contentId)
      }),
    )
  }

  removeFromWatchLater(contentId) {
    DefaultGridModel.removeFromGrid(contentId, 'WATCH_LATER').then(
      action(collection => {
        this.watchLater = this.watchLater.filter(item => item !== contentId)
      }),
    )
  }

  addManyToWatchLater(contentIds) {
    const ids = contentIds.filter(id => !this.watchLater.includes(id));

    DefaultGridModel.addManyToGrid(ids, 'WATCH_LATER').then(
      action(collection => {
        this.watchLater.push(...ids)
      }),
    )
  }

  removeManyFromWatchLater(contentIds) {
    DefaultGridModel.removeManyFromGrid(contentIds, 'WATCH_LATER').then(
      action(collection => {
        this.watchLater = this.watchLater.filter(item => !contentIds.includes(item))
      }),
    )
  }

  flush() {
    this.collection = new Collection()
    this.favorites = []
    this.seen = []
    this.watchLater = []
    this.liked = []
  }
}

decorate(DefaultGridStore, {
  setCollection: action,
  addToFavorites: action,
  flush: action,
})
