import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

type Props = SvgIconProps & {}

export const PlusIcon: React.FC<Props> = props => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <rect opacity="0.01" x="3" y="3" width="18" height="18" fill="#B2B2B2" />
      <path
        opacity="0.8"
        d="M12.3398 6C12.8921 6 13.3398 6.44772 13.3398 7L13.339 10.349L16.6893 10.3495C17.2416 10.3495 17.6893 10.7972 17.6893 11.3495V12.3398C17.6893 12.8921 17.2416 13.3398 16.6893 13.3398L13.339 13.339L13.3398 16.6893C13.3398 17.2416 12.8921 17.6893 12.3398 17.6893H11.3495C10.7972 17.6893 10.3495 17.2416 10.3495 16.6893L10.349 13.339L7 13.3398C6.44772 13.3398 6 12.8921 6 12.3398V11.3495C6 10.7972 6.44772 10.3495 7 10.3495L10.349 10.349L10.3495 7C10.3495 6.44772 10.7972 6 11.3495 6H12.3398Z"
        fill="#B2B2B2"
      />
    </SvgIcon>
  )
}
