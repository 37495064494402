import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    root: {},
    skip: {
      backgroundColor: appFade(palette.common.white, 0.1),
      color: palette.common.white,
      width: 120,
    },
    next: {
      marginLeft: spacing(2),
      color: palette.common.white,
      width: 120,
    },
    nextDisabled: {
      backgroundColor: appFade(palette.common.white, 0.1),
      opacity: 0.6,
      pointerEvents: 'none',
    },
    finish: {
      width: 270,
      color: palette.common.white,
    },
  })
}
