import { configure, decorate, extendObservable, action, observable } from 'mobx'
import { Application, Url, Collection } from 'services'
import { MovieModel, ShowModel, CastModel } from 'models'
import { PEOPLE } from 'const/api/people'
import { MOVIE, SHOW } from 'const/mediaTypes'

configure({ enforceActions: 'observed' })
export default class PersonStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      person: new CastModel(),
      credits: new Collection(),
    })
    this.creditType = {
      [MOVIE]: MovieModel,
      [SHOW]: ShowModel,
    }
  }

  /**
   * Transform model by type of model {Movie, Show, Person}
   *
   * @param collection
   * @returns {*}
   */
  mutateCollectionModels(collection) {
    const mutatedCollection = new Collection()
    collection.forEach(
      (title, index) =>
        title.posterPath &&
        mutatedCollection.push(
          new this.creditType[title.mediaType]().fill(title, true),
        ),
    )
    return mutatedCollection
  }

  getById(id) {
    const app = Application.instance()
    const self = this
    return new Promise((resolve, reject) => {
      const endpoint = `${PEOPLE}/${id}`
      app.request
        .get(endpoint)
        .then(
          action(response => {
            response
              ? resolve(self.person.fill(response.data, true))
              : app.router.push('/not-found')
          }),
        )
        .catch(error => reject(error))
    })
  }

  getCredits() {
    const app = Application.instance()
    const url = new Url()
    const self = this
    return new Promise((resolve, reject) => {
      const query = url.setQuery({ limit: 50 }).queryToString()
      const endpoint = `${PEOPLE}/${self.person.id}/credits${query}`
      app.request
        .get(endpoint)
        .then(
          action(response => {
            self.credits = self.mutateCollectionModels(response.data.results)
          }),
        )
        .catch(error => reject(error))
    })
  }
}

decorate(PersonStore, {
  person: observable,
  credits: observable,
  getById: action,
  getList: action,
})
