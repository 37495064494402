import React from 'react'
import Grid from '@material-ui/core/Grid'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Item } from './components/item'
import { SectionTitle } from '../../../section-title'
import { ProviderGetQuery } from 'shared/models/provider/get-model'

import { styles } from './providers.styles'

export type ProvidersProps = AppWithStyles<typeof styles> & {
  selectedItems: Array<ProviderGetQuery['id']>
  data: Array<ProviderGetQuery>
  title: React.ReactNode
  subtitle: React.ReactNode
  onItemChange: (id: ProviderGetQuery['id']) => void
}

const ProvidersComponent: React.FC<ProvidersProps> = ({
  classes,
  title,
  subtitle,
  selectedItems,
  data,
  onItemChange,
}) => {
  return (
    <div className={classes.root}>
      <SectionTitle title={title} />
      <h5 className={classes.subtitle}>{subtitle}</h5>
      <Grid container spacing={4} classes={{ root: classes.content }}>
        {data.map(({ id, name, images }) => {
          return (
            <Item
              key={id}
              images={images}
              selected={selectedItems.includes(id)}
              id={id}
              name={name}
              onChange={onItemChange}
            />
          )
        })}
      </Grid>
    </div>
  )
}

export const Providers = appWithStyles(styles)(ProvidersComponent)
