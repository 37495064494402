import ApplicationProvider from './application.provider'
import NotifyProvider from './notify.provider'
import RequestProvider from './request.provider'
import StorageProvider from './storage.provider'
import oAuthProvider from './oauth.provider'
import AuthProvider from './auth.provider'
import EventBusProvider from './event.bus.provider'
import RouterProvider from './router.provider'
import UserProvider from './user.provider'
import NewRequestProvider from './newRequest.provider'

export default [
  ApplicationProvider,
  NotifyProvider,
  RequestProvider,
  StorageProvider,
  AuthProvider,
  oAuthProvider,
  UserProvider,
  EventBusProvider,
  RouterProvider,
  NewRequestProvider,
]
