import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { IFollowersService } from 'shared/types/services/followers'
import { GridRequestType } from 'shared/models/grid/request-type'

@injectable()
export class FollowersService extends BaseService implements IFollowersService {
  private app = Application.instance()

  getFollowers: IFollowersService['getFollowers'] = async () => {
    const { data } = await this.app.newrequest.get(
      `/groups/${GridRequestType.followers}`,
    )
    const {
      data: { items },
    } = await this.app.newrequest.get(
      `/grids/${encodeURIComponent(data.items[0].id)}`,
    )

    return items
  }

  getFollowing: IFollowersService['getFollowing'] = async () => {
    const { data } = await this.app.newrequest.get(
      `/groups/${GridRequestType.following}`,
    )
    const {
      data: { items },
    } = await this.app.newrequest.get(
      `/grids/${encodeURIComponent(data.items[0].id)}`,
    )

    return items
  }

  follow: IFollowersService['follow'] = async id => {
    await this.app.newrequest.post(`/user/follow/${id}`)
  }

  unfollow: IFollowersService['unfollow'] = async id => {
    await this.app.newrequest.post(`/user/unfollow/${id}`)
  }

  followBulk: IFollowersService['followBulk'] = async ids => {
    await this.app.newrequest.post('/user/follow/list', ids)
  }
}
