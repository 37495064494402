import React, { useCallback } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Carousel } from 'shared/components/carousel'
import { CONTENT_LIST_FIELDS } from 'shared/constants/content'
import { Item } from './components/item'
import { SectionTitle } from '../../../section-title'
import { GenreGetQuery } from 'shared/models/genre/genre-get-model'

import { styles } from './genres.styles'

export type GenresProps = AppWithStyles<typeof styles> & {
  selectedItems: Array<GenreGetQuery['id']>
  data: Array<GenreGetQuery>
  onItemChange: (id: GenreGetQuery['id']) => void
}

const GenresComponent: React.FC<GenresProps> = ({
  classes,
  selectedItems,
  data,
  onItemChange,
}) => {
  const renderItem = useCallback(
    (item: GenreGetQuery) => {
      return (
        <Item
          selected={selectedItems.includes(item.id)}
          id={item.id}
          name={item.name}
          images={item.images}
          classes={{ root: classes.item }}
          onChange={onItemChange}
        />
      )
    },
    [selectedItems, classes, onItemChange],
  )

  return (
    <Carousel
      title={<SectionTitle title="Favourite Sports" />}
      data={data}
      itemDimensions={{
        width: 100,
        height: 100,
      }}
      idProperty={CONTENT_LIST_FIELDS.id}
      renderItem={renderItem}
      classes={{ root: classes.root }}
    />
  )
}

export const Genres = appWithStyles(styles)(GenresComponent)
