const modules = {}
export default (name, moduleProvider, requirements) => {
  if (modules.hasOwnProperty(name)) {
    return Promise.resolve(modules[name])
  }

  modules[name] = moduleProvider

  return moduleProvider.then(mod => {
    if (requirements) {
      requirements.forEach(async item => item())
    }
    return mod
  })
}
