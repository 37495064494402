import React, { useCallback, useMemo, useState } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Poster as NativePoster } from 'shared/components/poster'
import { ContentGetQuery } from 'shared/models/content/get-model'
import { SelectedMarkIcon } from 'shared/icons/selected-mark'
import { Loading } from 'shared/components/loading'
import { PosterViewModel } from './poster.vm'
import { appObserver } from 'core/state-management/utils'

import { styles } from './poster.styles'

export type PosterProps = AppWithStyles<typeof styles> & {
  url?: string
  id: ContentGetQuery['id']
}

const PosterComponent: React.FC<PosterProps> = appObserver(
  ({ classes, url, id }) => {
    const $vm = useMemo(() => new PosterViewModel({ id }), [id])
    const [loading, setLoading] = useState(true)

    const handleLoaded = useCallback(() => {
      setLoading(false)
    }, [])

    const handleError = useCallback(() => {
      setLoading(false)
    }, [])

    return (
      <div className={classes.root}>
        <NativePoster
          src={url}
          classes={{ root: classes.poster }}
          onLoad={handleLoaded}
          onError={handleError}
        />
        {!loading && $vm.isPosterInUserLists && (
          <SelectedMarkIcon classes={{ root: classes.selectedIcon }} />
        )}
        {loading && <Loading size={8} classes={{ root: classes.poster }} />}
      </div>
    )
  },
)

export const Poster = appWithStyles(styles)(PosterComponent)
