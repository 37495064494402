import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ palette, spacing }: AppTheme) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: '100%',
      zIndex: 3,
      backdropFilter: 'blur(16px)',
      marginTop: spacing(16),
      backgroundColor: appFade(palette.common.black, 0.6),
      display: 'block',
    },
    navigation: {
      width: '80%',
      height: '100%',
      padding: spacing(9, 2),
      backgroundColor: palette.background.default,
    },
    navIcon: {
      marginRight: spacing(4.5),
    },
    navLink: {
      width: '100%',
      color: '#fff',
      opacity: '0.4',
      display: 'flex',
      alignItems: 'center',
    },
    navItem: {
      width: '100%',
      fontSize: '14px',
      padding: spacing(3.5, 4.5),
      border: '1px solid transparent',
      borderRadius: '20px',
      '&:focus, &:hover, &:active': {
        backgroundColor: appFade(palette.primary.main, 0.15),
      },
      '&:focus a, &:hover a, &:active a': {
        color: palette.primary.main,
        opacity: '1',
      },
      ' &:focus svg path, &:hover svg path, &:active svg path': {
        fill: palette.primary.main,
        opacity: '1',
      },
    },
  })
}
