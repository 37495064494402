import moment from 'moment'

export const formatDate = (
  date: string | Date,
  options?: { format?: string; utc?: boolean },
) => {
  const utc = options?.utc ?? false

  if (utc) {
    return moment.utc(date).format(options?.format)
  }

  return moment(date).format(options?.format)
}

export const convertDateToIso = (
  date: string | Date,
  options?: { inputFormat?: string; utc?: boolean },
) => {
  const utc = options?.utc ?? false

  if (utc) {
    return moment.utc(date, options.inputFormat).toISOString()
  }

  return moment(date, options.inputFormat).toISOString()
}
