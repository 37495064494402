import * as React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './ellipsis.styles'

export type EllipsisProps = AppWithStyles<typeof styles> & {
  text: string | number | React.ReactNode
  tooltipText?: string
  component?: React.ComponentType<any>
  componentProps?: any
  width?: string | number
  maxWidth?: string | number
  withTooltip?: boolean
}

const EllipsisComponent: React.FC<EllipsisProps> = ({
  classes,
  width,
  maxWidth,
  text,
  tooltipText,
  withTooltip,
  component: Component,
  componentProps,
}) => {
  const contentStyles = Component ? { maxWidth: '100%' } : { width, maxWidth }

  const content = (
    <>
      {withTooltip ? (
        <Tooltip placement="top" title={tooltipText || String(text) || ''}>
          <div style={contentStyles} className={classes.root}>
            {text}
          </div>
        </Tooltip>
      ) : (
        <div style={contentStyles} className={classes.root}>
          {text}
        </div>
      )}
    </>
  )

  if (Component) {
    return (
      <Component
        style={{ width, maxWidth: maxWidth || '100%' }}
        {...componentProps}
      >
        {content}
      </Component>
    )
  }

  return content
}

EllipsisComponent.defaultProps = {
  withTooltip: true,
}

export const Ellipsis = appWithStyles(styles)(EllipsisComponent)
