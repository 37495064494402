import React, { useMemo } from 'react'
import MuiTabs from '@material-ui/core/Tabs'
import MuiTab from '@material-ui/core/Tab'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './tabs.styles'

export type TabProps = AppWithStyles<typeof styles> & {
  value: Tab
  isLoggedIn: boolean
  onChange: (e, value: Tab) => void
}

export enum Tab {
  content = 'content',
  people = 'people',
  users = 'users',
}

const TabsComponent: React.FC<TabProps> = ({
  classes,
  value,
  isLoggedIn,
  onChange,
}) => {
  const tabs = useMemo(() => {
    return [
      {
        label: 'Content',
        value: Tab.content,
      },
      {
        label: 'People',
        value: Tab.people,
      },
      {
        label: 'Users',
        value: Tab.users,
        condition: isLoggedIn,
      },
    ].filter(({ condition = true }) => condition)
  }, [isLoggedIn])

  return (
    <MuiTabs
      indicatorColor="primary"
      value={value}
      classes={{
        root: classes.root,
        flexContainer: classes.flexContainer,
      }}
      onChange={onChange}
    >
      {tabs.map(tab => {
        return (
          <MuiTab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            classes={{
              root: classes.tab,
              selected: classes.selectedTab,
            }}
          />
        )
      })}
    </MuiTabs>
  )
}

export const Tabs = appWithStyles(styles)(TabsComponent)
