import React from 'react'
import ButtonDefault from 'components/Buttons/Default'

import NativeDialog, {
  DialogProps as NativeDialogProps,
} from '@material-ui/core/Dialog'
import close from 'assets/images/ico/close.svg'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './dialog.styles'

export type DialogProps = AppWithStyles<typeof styles> &
  NativeDialogProps & {
    withCloseButton?: boolean
  }

const DialogComponent: React.FC<DialogProps> = ({
  classes,
  open,
  withCloseButton,
  children,
  onClose,
  ...otherProps
}) => {
  return (
    <NativeDialog
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      onClose={onClose}
      {...otherProps}
    >
      {withCloseButton && (
        <ButtonDefault
          customClass="overlay-close"
          type="circle"
          bgColor="white"
          size="medium"
          onClick={onClose as any}
          icon={<img src={close} className="icon white" alt="close" />}
        />
      )}
      {children}
    </NativeDialog>
  )
}

export const Dialog = appWithStyles(styles)(DialogComponent)
