import React from 'react';
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles';
import { styles } from './ai-categories.styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from 'shared/components/button';
import { AiIcon } from 'shared/icons/ai';
import { ISearchAiTemplate } from 'components/search-modal/search-modal.content';
import cx from 'classnames';
import { BREAKPOINT } from 'shared/constants/theme';
import { useAppWidth } from 'shared/hooks/responsive';

export type AiCategoriesProps = AppWithStyles<typeof styles> & {
  selectedItem?: ISearchAiTemplate;
  data: Array<ISearchAiTemplate>;
  onChange: (item?: ISearchAiTemplate) => void;
};

const AiCategoriesComponent: React.FC<AiCategoriesProps> = (props) => {
  const { classes, data, onChange, selectedItem } = props;
  const width = useAppWidth()
  const isMobile = width.isDown(BREAKPOINT.mobile)

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="subtitle1">
        <AiIcon /> Choose one of the preset options below so Skroote's AI Assistant can help you find content recommendations
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        I am looking for content
      </Typography>
      <Grid container spacing={1} justify="center" className={classes.buttons}>
        {data.map((option, index) => {
          const isSelected = selectedItem?.text === option.text;

          return (
            <Grid item key={index} className={classes.btnWrapper}>
              <Button 
                style={{
                  width: isMobile ? '100%' : `${100 / data.length}%`
                }}
                variant="contained" 
                className={cx(classes.button, { [classes.buttonSelected]: isSelected })}
                onClick={() => onChange(isSelected ? undefined : option)}
              >
                {option.text}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export const AiCategories = appWithStyles(styles)(AiCategoriesComponent)