import React, { useCallback, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { debounce } from 'lodash-es'

import CloseIcon from '@material-ui/icons/Close'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'

import { GlobalSearchFilter } from 'shared/types/services/search'

import { CustomSelectSingle } from 'components/search-modal/components/select/select-single'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { SelectButton } from '../../select/components/select-button/select-button'
import { CustomSelectSlider } from '../../select/select-slider'
import { CustomMultiSelect } from '../../select/select-multi'
import { GlobalSearchViewModel } from '../model/global-search.vm'
import {
  ContentTypeFilter,
  DescendingSort,
  SelectListItem,
  ServicesFilter,
  SortByFilter,
} from '../model/global-filter.types'

import { styles } from './global-filters.styles'

type PropsComponent = {
  onChangeFilter: (filter: GlobalSearchFilter) => void
  hasQueryString: boolean
}

type Props = AppWithStyles<typeof styles> & PropsComponent

function _GlobalFilters({ classes, onChangeFilter, hasQueryString }: Props) {
  const $vm = useMemo(() => new GlobalSearchViewModel(), [])

  useEffect(() => {
    $vm.fetchGenresList()
  }, [$vm])

  useEffect(() => {
    if ($vm.currentContentTypeSport) {
      $vm.fetchDepartmentsList()
    }
  }, [$vm, $vm.currentContentTypeSport])

  const debouncedGlobalFilter = useCallback(
    debounce((filter: GlobalSearchFilter) => {
      onChangeFilter(filter)
    }, 500),
    [],
  )

  // LISTEN CHANGE FILTERS
  useEffect(() => {
    debouncedGlobalFilter($vm.resultGlobalFilters)
  }, [$vm.resultGlobalFilters, debouncedGlobalFilter])

  const handleAllResetFilters = useCallback(() => {
    $vm.resetGlobalFilters()
  }, [$vm])

  const handleOnChangeContentType = useCallback(
    (val: string) => {
      $vm.updateContentTypeFilter(val)
    },
    [$vm],
  )
  const handleOnResetContentType = useCallback(() => {
    $vm.updateContentTypeFilter(null)
  }, [$vm])

  const handleOnChangeGenre = useCallback(
    (val: SelectListItem['value']) => {
      $vm.updateGenreListFilter(val)
    },
    [$vm],
  )
  const handleOnResetGenre = useCallback(() => {
    $vm.updateGenreListFilter(null)
  }, [$vm])
  const handlePaginateGenre = useCallback(
    (page: number) => {
      $vm.updateGenresPaginationPage(page)
    },
    [$vm],
  )

  const handleOnChangeYear = useCallback(
    (yearFrom: number, yearTo: number) => {
      $vm.updateYearsFilter({ yearFrom, yearTo })
    },
    [$vm],
  )
  const handleOnResetYear = useCallback(() => {
    $vm.updateYearsFilter({ yearFrom: null, yearTo: null })
  }, [$vm])

  const handleOnChangeService = useCallback(
    (val: ServicesFilter) => {
      $vm.updateGlobalFilters('service', val)
    },
    [$vm],
  )
  const handleOnResetService = useCallback(() => {
    $vm.updateGlobalFilters('service', null)
  }, [$vm])

  const handleOnChangeSortBy = useCallback(
    (val: string) => {
      $vm.updateSortByFilter(val as SortByFilter)
    },
    [$vm],
  )
  const handleOnResetSortBy = useCallback(() => {
    $vm.updateGlobalFilters('sortBy', null)
  }, [$vm])

  const handleOnChangeDepartments = useCallback(
    (val: string) => {
      $vm.updateDepartmentListFilter(val)
    },
    [$vm],
  )
  const handleOnResetDepartments = useCallback(() => {
    $vm.updateDepartmentListFilter(null)
  }, [$vm])
  const handlePaginateDepartments = useCallback(
    (page: number) => {
      $vm.updateDepartmentsPaginationPage(page)
    },
    [$vm],
  )

  const handleOnChangeDescending = useCallback(
    (val: DescendingSort) => {
      $vm.updateGlobalFilters('descending', val)
    },
    [$vm],
  )
  const handleOnResetDescending = useCallback(() => {
    $vm.updateGlobalFilters('sortBy', null)
  }, [$vm])

  const yearSliderValue = useMemo(() => {
    return [
      $vm.globalFilters.yearsFilter.yearFrom,
      $vm.globalFilters.yearsFilter.yearTo,
    ]
  }, [
    $vm.globalFilters.yearsFilter.yearFrom,
    $vm.globalFilters.yearsFilter.yearTo,
  ])

  return (
    <>
      <div className={classes.container}>
        <CustomSelectSingle
          label="Content type"
          options={$vm.contentTypeSelectList}
          value={$vm.globalFilters.contentType}
          onChange={handleOnChangeContentType}
          onReset={handleOnResetContentType}
        />
        <CustomMultiSelect
          disabled={!Boolean($vm.globalFilters.contentType)}
          label={
            $vm.currentContentTypeSport ? ContentTypeFilter.SPORTS : 'Genre'
          }
          options={$vm.genresPaginatedData}
          value={$vm.globalFilters.genres}
          page={$vm.genresPaginationPage}
          maxPage={$vm.genresPaginateMaxPage}
          onChange={handleOnChangeGenre}
          onPaginate={handlePaginateGenre}
          onReset={handleOnResetGenre}
        />
        {$vm.currentContentTypeSport && (
          <CustomMultiSelect
            label="Teams"
            options={$vm.departmentsPaginatedData}
            value={$vm.globalFilters.departments}
            page={$vm.departmentsPaginationPage}
            maxPage={$vm.departmentsPaginateMaxPage}
            onChange={handleOnChangeDepartments}
            onPaginate={handlePaginateDepartments}
            onReset={handleOnResetDepartments}
          />
        )}
        <CustomSelectSlider
          label="Year"
          min={$vm.yearSelectData.yearFrom}
          max={$vm.yearSelectData.yearTo}
          value={yearSliderValue}
          onChange={handleOnChangeYear}
          onReset={handleOnResetYear}
        />
        {$vm.isLoggedIn && (
          <CustomSelectSingle
            label="Services"
            options={$vm.servicesSelectData}
            value={$vm.globalFilters.service}
            onChange={handleOnChangeService}
            onReset={handleOnResetService}
          />
        )}
        {($vm.isShowSortingFilters || hasQueryString) && !$vm.isMobile && (
          <>
            <CustomSelectSingle
              label="Sort by"
              isMobile={$vm.isMobile}
              options={$vm.sortBySelectData}
              value={$vm.globalFilters.sortBy}
              onChange={handleOnChangeSortBy}
              onReset={handleOnResetSortBy}
              showOnClose={false}
            />
            <CustomSelectSingle
              isMobile={$vm.isMobile}
              options={$vm.descendingSelectData}
              value={$vm.globalFilters.descending}
              onChange={handleOnChangeDescending}
              onReset={handleOnResetDescending}
              startIcon={
                <CompareArrowsIcon classes={{ root: classes.arrowIcon }} />
              }
              showOnClose={false}
            />
          </>
        )}
        {$vm.hasSetFilter && !$vm.isMobile && (
          <div>
            <SelectButton
              text={'Reset all filters'}
              onClick={handleAllResetFilters}
              startIcon={<CloseIcon />}
            />
          </div>
        )}
      </div>
      <div className={classes.mobileSorting}>
        {($vm.isShowSortingFilters || hasQueryString) && $vm.isMobile && (
          <>
            <CustomSelectSingle
              label="Sort by"
              isMobile={$vm.isMobile}
              options={$vm.sortBySelectData}
              value={$vm.globalFilters.sortBy}
              onChange={handleOnChangeSortBy}
              onReset={handleOnResetSortBy}
              showOnClose={false}
            />
            <CustomSelectSingle
              isMobile={$vm.isMobile}
              options={$vm.descendingSelectData}
              value={$vm.globalFilters.descending}
              onChange={handleOnChangeDescending}
              onReset={handleOnResetDescending}
              startIcon={
                <CompareArrowsIcon classes={{ root: classes.arrowIcon }} />
              }
              showOnClose={false}
            />
          </>
        )}
      </div>
    </>
  )
}

export const GlobalFilters = appWithStyles(styles)(observer(_GlobalFilters))
