import { configure } from 'mobx'

export { observable as appObservable } from 'mobx'
export { observer as appObserver } from 'mobx-react'

export const setUp = () => {
  configure({
    enforceActions: 'never',
  })
}
