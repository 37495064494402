import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = ({ custom, spacing, palette }: AppTheme) => {
  return appCreateStyles({
    container: {
      position: 'absolute',
      top: 48,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      backgroundColor: custom.colors.gray[450],
      padding: spacing(4, 4, 3, 4),
      borderRadius: 20,
      zIndex: 2,
      paddingTop: spacing(4),
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          position: 'relative',
          width: 200,
          top: 0,
        },
      '@media only screen and (orientation: landscape) and (max-height: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          position: 'relative',
          width: 200,
          top: 0,
        },
    },
    values: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: spacing(2),
      marginBottom: spacing(4),
      fontSize: 12,
      color: palette.common.white,
      opacity: '0.6',
    },
    slider: {
      paddingBottom: spacing(3),
    },
    sliderRoot: {
      color: palette.common.white,
    },
    sliderTrack: {
      color: palette.primary.dark,
      height: 4,
    },
    sliderThumb: {
      width: 17,
      height: 17,
      marginTop: -6,
    },
  })
}
