import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { GridModel } from 'models'
import { ONBOARDING } from 'const/api/grids'

configure({ enforceActions: 'observed' })
export default class OnboardingStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      grids: new Collection(),
      collection: new Collection(),
      genres: new Collection(),
      sports: new Collection(),
      providers: new Collection(),
      persons: new GridModel(),
      selectedMovies: [],
      selectedPersons: [],
      selectedGenres: [],
      selectedSportsGenre: [],
      selectedPaidProviders: [],
      selectedFreeProviders: [],
      freeProviders: [],
      selectedTeams: [],
      teams: [
        { name: 'Arizona Cardinals', id: '' },
        { name: 'Atlanta Falcons', id: '' },
        { name: 'Buffalo Bills', id: '' },
        { name: 'Chicago Bears', id: '' },
        { name: 'Dallas Cowboys', id: '' },
        { name: 'Denver Broncos', id: '' },
        { name: 'Green Bay Packers', id: '' },
        { name: 'Houston Texans', id: '' },
        { name: 'Kansas City Chiefs', id: '' },
        { name: 'Los Angeles Rams', id: '' },
        { name: 'Miami Dolphins', id: '' },
        { name: 'Pittsburgh Steelers', id: '' },
        { name: 'San Francisco 49ers', id: '' },
        { name: 'Seattle Seahawks', id: '' },
        { name: 'Tampa Bay Buccaneers', id: '' },
        { name: 'Boston Red Sox', id: '' },
        { name: 'Chicago Cubs', id: '' },
        { name: 'Houston Astros', id: '' },
        { name: 'New York Giants', id: '' },
        { name: 'New York Yankees', id: '' },
        { name: 'Tampa Bay Rays', id: '' },
        { name: 'Toronto Blue Jays', id: '' },
        { name: 'Washington Nationals', id: '' },
        { name: 'Chicago Bulls', id: '' },
        { name: 'Cleveland Cavaliers', id: '' },
        { name: 'Golden State Warriors', id: '' },
        { name: 'Los Angeles Lakers', id: '' },
        { name: 'Miami Heat', id: '' },
        { name: 'Orlando Magic', id: '' },
        { name: 'San Antonio Spurs', id: '' },
        { name: 'Toronto Raptors', id: '' },
        { name: 'Chennai Super Kings', id: '' },
        { name: 'Delhi Capitals', id: '' },
        { name: 'Kolkata Knight Riders', id: '' },
        { name: 'Mumbai Indians', id: '' },
        { name: 'Punjab Kings', id: '' },
        { name: 'Rajastan Royals', id: '' },
        { name: 'Royal Challengers Bangalore', id: '' },
        { name: 'Sunrisers Hyderabad', id: '' },
        { name: 'AC Milan', id: '' },
        { name: 'Arsenal', id: '' },
        { name: 'Athletico Madrid', id: '' },
        { name: 'Barcelona', id: '' },
        { name: 'Bayern Munich', id: '' },
        { name: 'Celtic', id: '' },
        { name: 'Chelsea', id: '' },
        { name: 'Inter Milan', id: '' },
        { name: 'Juventus', id: '' },
        { name: 'Liverpool', id: '' },
        { name: 'Manchester City', id: '' },
        { name: 'Manchester United', id: '' },
        { name: 'Paris Saint-Germain', id: '' },
        { name: 'Rangers', id: '' },
        { name: 'Real Madrid', id: '' },
        { name: 'Tottenham Hotspur', id: '' },
        { name: 'Castleford Tigers', id: '' },
        { name: 'Huddersfield Giants', id: '' },
        { name: 'Hull KR', id: '' },
        { name: 'Leeds Rhinos', id: '' },
        { name: 'St Helens', id: '' },
        { name: 'Wakefield Trinity', id: '' },
        { name: 'Warrington Wolves', id: '' },
        { name: 'Wigan Warriors', id: '' },
        { name: 'Bath Rugby', id: '' },
        { name: 'Bristol Bears', id: '' },
        { name: 'Exeter Chiefs', id: '' },
        { name: 'Harlequins', id: '' },
        { name: 'Leicester Tigers', id: '' },
        { name: 'Sale Sharks', id: '' },
        { name: 'Saracens', id: '' },
        { name: 'Wasps', id: '' },
      ],
    })
    this.model = GridModel
  }

  /**
   * Set Collection
   *
   * @param payload
   */
  setCollection(payload) {
    this.collection = payload
  }

  selectMovie = action(id => {
    if (this.selectedMovies.includes(id)) {
      // eslint-disable-next-line
      this.selectedMovies = this.selectedMovies.filter(item => item != id)
    } else {
      this.selectedMovies.push(id)
    }
  })

  selectMultipleMovie = action(items => {
    this.selectedMovies = this.selectedMovies.concat(items)
  })

  selectTeam = action(id => {
    if (this.selectedTeams.includes(id)) {
      // eslint-disable-next-line
      this.selectedTeams = this.selectedTeams.filter(item => item != id)
    } else {
      this.selectedTeams.push(id)
    }
  })

  selectMultipleTeam = action(items => {
    this.selectedTeams = this.selectedTeams.concat(items)
  })

  selectGenre = action(id => {
    if (this.selectedGenres.includes(id)) {
      // eslint-disable-next-line
      this.selectedGenres = this.selectedGenres.filter(item => item != id)
    } else {
      this.selectedGenres.push(id)
    }
  })

  selectMultipleGenre = action(items => {
    this.selectedGenres = this.selectedGenres.concat(items)
  })

  //Free providers

  selectFreeProvider = action((id, noCheck = false) => {
    if (this.selectedFreeProviders.includes(id) && noCheck === false) {
      this.selectedFreeProviders = this.selectedFreeProviders.filter(
        // eslint-disable-next-line
        item => item != id,
      )
    } else {
      this.selectedFreeProviders.push(id)
    }
  })

  selectMultipleFreeProviders = action(items => {
    this.selectedFreeProviders = this.selectedFreeProviders.concat(items)
  })

  deselectFreeProviders = action(items => {
    this.selectedFreeProviders = this.selectedFreeProviders.filter(function (
      el,
    ) {
      return items.indexOf(el) < 0
    })
  })

  //Paid

  selectPaidProvider = action(id => {
    if (this.selectedPaidProviders.includes(id)) {
      this.selectedPaidProviders = this.selectedPaidProviders.filter(
        // eslint-disable-next-line
        item => item != id,
      )
    } else {
      this.selectedPaidProviders.push(id)
    }

    if (this.freeProviders.toArray().some(provider => provider.name === id)) {
      this.selectFreeProvider(id, true)
    }
  })

  selectMultiplePaidProviders = action(items => {
    this.selectedPaidProviders = this.selectedPaidProviders.concat(items)
    //check the same in free

    items.forEach(id => {
      if (this.freeProviders.toArray().some(provider => provider.name === id)) {
        this.selectFreeProvider(id, true)
      }
    })
  })

  deselectPaidProviders = action(items => {
    this.selectedPaidProviders = this.selectedPaidProviders.filter(function (
      el,
    ) {
      return items.indexOf(el) < 0
    })
  })

  selectSportGenre = action(id => {
    if (this.selectedSportsGenre.includes(id)) {
      this.selectedSportsGenre = this.selectedSportsGenre.filter(
        // eslint-disable-next-line
        item => item != id,
      )
    } else {
      this.selectedSportsGenre.push(id)
    }
  })

  selectMultipleSportGenre = action(items => {
    this.selectedSportsGenre = this.selectedSportsGenre.concat(items)
  })

  selectPerson = action(id => {
    if (this.selectedPersons.includes(id)) {
      // eslint-disable-next-line
      this.selectedPersons = this.selectedPersons.filter(item => item != id)
    } else {
      this.selectedPersons.push(id)
    }
  })

  selectMultipleselectPerson = action(items => {
    this.selectedPersons = this.selectedPersons.concat(items)
  })

  setFreeProviders = action(items => {
    this.freeProviders = items
  })

  getGrids() {
    GridModel.list(ONBOARDING)
      .then(
        action(collection => {
          this.grids = collection
        }),
      )
      .catch(e => {})
  }

  getGenres() {
    return GridModel.getGenres()
      .then(
        action(collection => {
          this.genres = collection
        }),
      )
      .catch(e => {})
  }

  getPersons() {
    return this.genres.toArray().filter(model => model.type === 'PERSON')
  }

  getSports() {
    return GridModel.getSports()
      .then(
        action(collection => {
          this.sports = collection
        }),
      )
      .catch(e => {})
  }

  getProviders() {
    return GridModel.getProviders()
      .then(
        action(collection => {
          this.providers = collection
        }),
      )
      .catch(e => {})
  }
}

decorate(OnboardingStore, {
  setCollection: action,
})
