import { Application, Model } from 'services'
import moment from 'moment'
import { SHOW } from 'const/api/show'
import SourceModel from '../source.model'
import { processContentImages } from 'shared/utils/image'

export default class EpisodeModel extends Model {
  get fillable() {
    return {
      id: 'string',
      adult: 'boolean',
      budget: 'string',
      contentType: 'string',
      description: 'string',
      episode: 'string',
      // genres: 'array',
      // grids: 'array',
      images: 'array',
      popularity: 'string',
      releaseDate: 'string',
      revenue: 'string',
      title: 'sring',
      url: 'string',
      // videoProviders: 'string',
    }
  }

  /**
   * Get type of model
   *
   * @returns {string}
   */
  getType() {
    return this.contentType
  }

  /**
   * Get release date
   *
   * @returns {number}
   */
  getDate(type = 'start') {
    return moment(this.releaseDate).format('ll')
  }

  getImage(size = 500) {
    return processContentImages(this.images)?.POSTER?.url.large
  }

  /**
   * Get season sources
   *
   * @returns {Promise<any> | Promise<postcss.Result> | undefined}
   */
  getSources() {
    const app = Application.instance()
    const endpoint = `${SHOW}/${this.showId}/season/${this.seasonNumber}/episode/${this.number}/content_source`
    return app.request
      .get(endpoint)
      .then(response => SourceModel.createFromResponse(response.data))
      .catch(error => error)
  }
}
