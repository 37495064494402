import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { appObserver } from 'core/state-management/utils'
import { StepDescription } from '../step-description'
import { OnboardingStepProps } from '../../onboarding.types'
import { ThirdStepViewModel } from './third-step.vm'
import { Controls } from '../controls'
import { GridType } from 'shared/models/grid/type'
import {
  NotificationType,
  showNotification,
} from 'shared/components/notification/notification'
import { Loading } from 'shared/components/loading'
import { Providers } from './components/providers'
import { ProviderGetQuery } from 'shared/models/provider/get-model'
import { logOnboardingResult } from "../../../../../services/analytics.service";
import { OnboardingStatus } from "../../../../models/user/onboading-status";

import { styles } from './third-step.styles'

export type ThirdStepProps = AppWithStyles<typeof styles> & OnboardingStepProps

const ThirdStepComponent: React.FC<ThirdStepProps> = ({
  classes,
  grids,
  proceedStep: _proceedStep,
}) => {
  const $vm = useMemo(() => new ThirdStepViewModel(), [])
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [freeProviders, setFreeProviders] = useState<Array<ProviderGetQuery>>(
    [],
  )
  const [paidProviders, setPaidProviders] = useState<Array<ProviderGetQuery>>(
    [],
  )
  const [selectedFreeProviders, setSelectedFreeProviders] = useState<
    Array<ProviderGetQuery['id']>
  >([])
  const [selectedPaidProviders, setSelectedPaidProviders] = useState<
    Array<ProviderGetQuery['id']>
  >([])

  const initialize = useCallback(async () => {
    try {
      setLoading(true)

      for (let gridIndex in grids) {
        const currentGrid = grids[gridIndex]
        const config = {
          [GridType.freeProvider]: async () => {
            const response = await $vm.getList(currentGrid.id)

            setFreeProviders(response.map(({ asJson }) => asJson))
          },
          [GridType.paidProvider]: async () => {
            const response = await $vm.getList(currentGrid.id)

            setPaidProviders(response.map(({ asJson }) => asJson))
          },
        }

        await config[currentGrid.type]()
      }
    } catch (err) {
      showNotification(
        'Something went wrong while fetching providers',
        NotificationType.error,
      )

      console.error(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [grids])

  useEffect(() => {
    initialize()
    // eslint-disable-next-line
  }, [])

  const handleFreeProviderChange = useCallback(
    (id: ProviderGetQuery['id']) => {
      setSelectedFreeProviders(
        selectedFreeProviders.includes(id)
          ? selectedFreeProviders.filter(item => item !== id)
          : [...selectedFreeProviders, id],
      )
    },
    [selectedFreeProviders],
  )

  const handlePaidProviderChange = useCallback(
    (id: ProviderGetQuery['id']) => {
      setSelectedPaidProviders(
        selectedPaidProviders.includes(id)
          ? selectedPaidProviders.filter(item => item !== id)
          : [...selectedPaidProviders, id],
      )
    },
    [selectedPaidProviders],
  )

  const proceedStep = useCallback(
    async (skip: boolean) => {
      try {
        setSubmitting(true)
        logOnboardingResult(skip, OnboardingStatus.step2)
        await _proceedStep(
          async () =>
            await $vm.updateList(skip, {
              free: selectedFreeProviders,
              paid: selectedPaidProviders,
            }),
        )
      } finally {
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [_proceedStep, selectedFreeProviders, selectedPaidProviders],
  )

  if (loading) {
    return <Loading classes={{ root: classes.root }} />
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <StepDescription
          heading="Add Your Subscriptions"
          subheading={
            <>
              <div>
                Hit the + to add each service you use. You won't need account
                info (like your Netflix login) to combine your services.
              </div>
              <div>
                Skroote will link you directly to the service to play your
                chosen content.
              </div>
            </>
          }
        />
        <Providers
          title="Free Services"
          subtitle="Although these are free, you may need to register to these services to access their content"
          data={freeProviders}
          selectedItems={selectedFreeProviders}
          classes={{ root: classes.section }}
          onItemChange={handleFreeProviderChange}
        />
        <Providers
          title="Paid Subscriptions"
          subtitle="You will need to sign up to a paid subscription to access content from these services"
          data={paidProviders}
          selectedItems={selectedPaidProviders}
          classes={{ root: classes.section }}
          onItemChange={handlePaidProviderChange}
        />
      </div>
      <Controls
        loading={submitting}
        canProceedForward={true}
        onProceedForward={proceedStep}
        classes={{ root: classes.controls }}
      />
    </div>
  )
}

export const ThirdStep = appWithStyles(styles)(appObserver(ThirdStepComponent))
