import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'

export const styles = ({ custom, spacing, palette }: AppTheme) => {
  return appCreateStyles({
    container: {
      position: 'absolute',
      top: 48,
      left: 0,
      padding: spacing(4, 4, 3, 4),
      backgroundColor: custom.colors.gray[450],
      width: 550,
      borderRadius: 20,
      zIndex: 2,
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          width: 320,
          position: 'relative',
          top: 0,
        },
      '@media only screen and (orientation: landscape) and (max-height: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          width: 320,
          position: 'relative',
          top: 0,
        },
    },
    empty: {
      position: 'absolute',
      top: 48,
      left: 0,
      paddingBottom: spacing(3),
      backgroundColor: custom.colors.gray[450],
      width: '100%',
      borderRadius: 20,
      zIndex: 2,
      textAlign: 'center',
    },
    list: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: spacing(1),
      '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          gridTemplateColumns: '1fr 1fr',
          height: '45vh',
          overflow: 'auto',
          overscrollBehavior: 'none',
        },
      '@media only screen and (orientation: landscape) and (max-height: 480px) and (-webkit-min-device-pixel-ratio: 2)':
        {
          gridTemplateColumns: '1fr 1fr',
          height: '45vh',
          overflow: 'auto',
          overscrollBehavior: 'none',
        },
    },
    item: {
      padding: spacing(2.2, 3),
      color: palette.common.white,
      opacity: '0.6',
      fontSize: 14,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      transition: 'opacity .2s',
      maxWidth: 155,

      '&:hover': {
        opacity: 1,
        '@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2)':
          {
            whiteSpace: 'pre-wrap',
            maxWidth: 100,
          },
        '@media only screen and (orientation: landscape) and (max-height: 480px) and (-webkit-min-device-pixel-ratio: 2)':
          {
            whiteSpace: 'pre-wrap',
            maxWidth: 100,
          },

        '& $shortText': {
          display: 'none',
        },
        '& $extraText': {
          display: 'block',
        },
      },
    },
    itemChecked: {
      opacity: 1,
      backgroundColor: custom.colors.green,
      borderRadius: 16,

      '&:hover': {
        '& $shortText': {
          display: 'block',
        },
        '& $extraText': {
          display: 'none',
        },
      },
    },
    shortText: {},
    extraText: {
      display: 'none',
    },
  })
}
