import React, { Component, createRef } from 'react'
import { Provider } from 'mobx-react'

import { Application } from 'services'
import Routes from 'routes'
import { ROUTES } from 'shared/constants/routes'
import { appObserver } from 'core/state-management/utils'
import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { Onboarding } from 'shared/components/onboarding'
import { OnboardingStatus } from 'shared/models/user/onboading-status'
import { Meta } from './meta'
import { Header } from '../../components/header'
import { Footer } from '../../components/Footer/footer'
import { CookiesConsent } from '../../components/CookiesConsent/cookeis-content'
import { COOKIES_CONSENT_KEY } from '../../services/analytics.service'

// TODO: test routes
const routerData = [
  {
    label: 'home-page',
    url: ROUTES.initial,
    children: [],
  },
  {
    label: 'profile',
    url: ROUTES.private.profile.root(),
    children: [],
  },
  {
    url: ROUTES.private.settings.root,
    children: [],
  },
  {
    label: 'users',
    url: ROUTES.private.users.root,
    children: [],
  },
  {
    label: 'title-page',
    url: ROUTES.public.content(),
    children: [],
  },
  {
    label: 'person',
    url: ROUTES.public.person(),
    children: [],
  },
  {
    label: 'grid-collection',
    url: ROUTES.public.grid(),
    children: [],
  },
  {
    label: 'sports',
    url: ROUTES.public.sports,
    children: [],
  },
  {
    label: 'Movies',
    url: '/movies',
    children: [],
  },
  {
    label: 'TV Shows',
    url: '/tv-shows',
    children: [],
  },
  {
    label: 'Live',
    url: '/lives',
    children: [],
  },
  {
    label: 'faq',
    url: '/faq',
    children: [],
  },
  {
    label: 'privacy-policy',
    url: '/privacy-policy',
    children: [],
  },
  {
    label: 'terms-of-use',
    url: '/terms-of-use',
    children: [],
  },
  {
    label: 'partners',
    url: '/partners',
    children: [],
  },
  {
    label: 'about',
    url: ROUTES.public.about,
    children: [],
  },
]

class App extends Component {
  $app = Application.instance()
  layoutService = inject(DI_TOKENS.layoutService)
  rootRef = createRef()

  state = { data: [], isOnboardingOpen: true }

  async componentDidMount() {
    this.layoutService.setConfig({ rootRef: this.rootRef })
    this.setState({ data: routerData })
  }

  handleCloseOnboarding = () => {
    this.setState(prevState => ({
      ...prevState,
      isOnboardingOpen: false,
    }))
  }

  render() {
    const stores = {
      SearchStore: this.$app.store.SearchStore,
      FooterStore: this.$app.store.FooterStore,
    }

    const isShowOnboarding =
      this.$app.auth.isLoggedIn() &&
      !this.$app.auth.user.hideOnboarding &&
      this.$app.auth.user.onboardingStatus !== OnboardingStatus.finished

    return (
      <div className="app-container">
        <Meta />
        <Provider {...stores}>
          {isShowOnboarding && (
            <Onboarding
              open={this.state.isOnboardingOpen}
              status={this.$app.auth.user.onboardingStatus}
              onClose={this.handleCloseOnboarding}
            />
          )}
          <Header />
          <div
            className="page"
            ref={this.rootRef}
            onScroll={this.layoutService.config.onScroll}
          >
            <div></div>
            <div className="content">
              <Routes data={this.state.data} location={this.props.location} />
            </div>
            <Footer />
            {!localStorage.getItem(COOKIES_CONSENT_KEY) && <CookiesConsent />}
          </div>
        </Provider>
      </div>
    )
  }
}

export default appObserver(App)
