import { Application } from 'services'
import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { ISearchService } from 'shared/types/services/search'
import { ContentGetModel } from 'shared/models/content/get-model'
import { PersonGetModel } from 'shared/models/person/get-model'
import { UserListModel } from 'shared/models/user/list-model'

@injectable()
export class SearchService extends BaseService implements ISearchService {
  private app = Application.instance()

  searchContent: ISearchService['searchContent'] = async (
    query,
    filter,
    page,
  ) => {
    const { data } = await this.app.newrequest.post(
      'contents/search',
      { query, filter },
      { params: { page, sort: filter?.sort } },
    )
    const { content, totalElements, totalPages } = data
    const items = content
      .filter(item => Boolean(item))
      .map(item => new ContentGetModel(item))
    const total = totalPages === 1 ? items.length : totalElements
    return {
      items: items,
      total: total,
    }
  }

  searchPersons: ISearchService['searchPersons'] = async (
    query,
    filter,
    page,
  ) => {
    const {
      data: { content, totalElements },
    } = await this.app.newrequest.post(
      'persons/search',
      { query, filter },
      { params: { page, sort: filter?.sort } },
    )

    return {
      items: content.map(item => new PersonGetModel(item)),
      total: totalElements,
    }
  }

  searchUsers: ISearchService['searchUsers'] = async (query, filter, page) => {
    const {
      data: { content, totalElements },
    } = await this.app.newrequest.post(
      'user/search',
      { query, filter },
      { params: { page, sort: filter?.sort } },
    )

    return {
      items: content.map(item => new UserListModel(item)),
      total: totalElements,
    }
  }

  searchAI: ISearchService['searchAI'] = async query => {
    const { data } = await this.app.newrequest.get(
      `contents/search/ai?query=${query}`,
    )

    return data
  }
}
