import { Model, Application, Url } from 'services'
import { USERS } from 'const/api/search'

export default class UserModel extends Model {
  get fillable() {
    return {
      id: 'string',
      username: 'string',
      firstName: 'string',
      fullName: 'string',
      lastName: 'string',
      email: 'string',
      dateOfBirth: 'string',
      avatar: 'string',
      picture: 'string',
      userStatus: 'string',
      onboardingStatus: 'string',
    }
  }

  /**
   * Get user avatar
   *
   * @returns {any}
   */
  getAvatar() {
    return this.avatar
  }
  getImage() {
    return this.avatar
  }

  /// ! type Person for grids carusel
  getType() {
    return 'USER'
  }

  static listUsers(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery({ ...params }).queryToString() : ''
      const endpoint = `${USERS}${query}`
      app.newrequest
        .get(endpoint)
        .then(response => {
          resolve(self.createFromResponse(response.data, endpoint))
        })
        .catch(error => reject(error))
    })
  }

  static getUsersByIds(ids) {
    const app = Application.instance()

    return new Promise((resolve, reject) => {
      app.newrequest
        .post('users', ids)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
}
