import { Collection } from '.'

let instance = false

export default class Application {
  /**
   * Application constructor
   */
  constructor() {
    this.providers = new Collection({})
    this.client = 'skroote'
    this.containers = {}
    this.resolved = {}
    instance = this
  }

  /**
   * Singleton
   *
   * @return {*}
   */
  static instance() {
    if (instance) {
      return instance
    }
    return new this()
  }

  /**
   * Defines service providers
   *
   * @param providers
   */
  registerServiceProviders(providers) {
    if (providers instanceof Array) {
      providers.forEach(Provider => {
        const provider = new Provider()
        provider.boot(this)
        this.providers.push(provider)
      }, this)
    }
    return this
  }

  get(container) {
    if (!this.resolved[container]) {
      const callback = this.containers[container]
      if (typeof callback !== 'function') {
        if (this.containers[container]) {
          this.resolved[container] = this.containers[container]
          return this.resolved[container]
        }
        return null
      }
      const resolved = callback(this)
      if (resolved instanceof Promise) {
        return resolved.then(data => {
          this.resolved[container] = data
          return data
        })
      }
      this.resolved[container] = resolved
    }
    return this.resolved[container]
  }

  resetter(container) {
    delete this.resolved[container]
    return this.get(container)
  }

  /**
   * Registers new singleton
   *
   * @param name String
   * @param callback Function
   * @param force Boolean
   */
  bind(name, callback, force = false) {
    if (this.containers[name] && !force) {
      return this
    }
    if (typeof callback !== 'function') {
      this.resolved[name] = callback
    }
    this.containers[name] = callback
    if (!this[name]) {
      Object.defineProperty(this, name, {
        get: () => this.get(name),
      })
    }
    return this
  }

  /**
   * Run the application
   */
  run(callback) {
    if (callback && typeof callback === 'function') {
      callback(this)
    }
    return this
  }
}
