import React from 'react'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import cx from 'classnames'

import { Application } from 'services'
import AddCustom from 'components/AddCustom'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Flex } from 'shared/components/flex'
import { ListType } from 'shared/models/content/list-type'
import { Button } from 'shared/components/button'
import { appObserver } from 'core/state-management/utils'

import { styles } from './actionsWithAll.styles'

export type ActionsProps = AppWithStyles<typeof styles> & {
  ids: string[];
  isMobile: boolean
}

const actionsWithAllComponent: React.FC<ActionsProps> = appObserver(
  ({ classes, ids, isMobile }) => {
    const $app = Application.instance()

    const getWatchlistState = () =>
      ids.every(id => $app.store.DefaultGridStore.watchLater.includes(id));

    const handleActionClick = e => {
      e.preventDefault();
      const { type } = e.currentTarget.dataset

      const isLoggedIn = $app.auth.isLoggedIn()

      const actionsConfig = {
        [ListType.watchLater]: () =>
          getWatchlistState()
            ? $app.store.DefaultGridStore.removeManyFromWatchLater(ids)
            : $app.store.DefaultGridStore.addManyToWatchLater(ids),
      }

      if (isLoggedIn) {
        actionsConfig[type]()

        return
      }

      $app.event.$emit('overlay.activity.login', {
        status: true,
      })
    }

    const getActionsConfig = () => {
      return [
        {
          listType: ListType.watchLater,
          icon: <PlayArrowIcon />,
          text: 'Watchlist all',
          active: getWatchlistState(),
        },
      ]
    }

    return (
      <Flex
        alignItems="center"
        alignContent={'center'}
        justify="flex-end"
        classes={{ root: classes.root }}
      >
        <AddCustom
          isAiAction
          isBottomPosition
          details
          ids={ids}
          isMobile={isMobile}
          classes={{
            buttonText: classes.buttonText,
          }}
        />
        {getActionsConfig().map(({ listType, icon, text, active }) => {
          return isMobile ? (
            <div key={listType} className={cx(classes.buttonWrapper, {[classes.buttonWrapperMobile]: isMobile})}>
              <Button
                data-type={listType}
                classes={{
                  root: cx(
                    classes.action,
                    { [classes.actionActive]: active },
                    { [classes.actionMobile]: isMobile },
                  ),
                }}
                onClick={handleActionClick}
              >
                {icon}
              </Button>
              <p className={classes.buttonText}>{text}</p>
            </div>
          ) : (
            <Button
              key={listType}
              startIcon={icon}
              text={text}
              data-type={listType}
              classes={{
                root: cx(classes.action, { [classes.actionActive]: active }),
              }}
              onClick={handleActionClick}
            />
          )
        })}
      </Flex>
    )
  },
)

export const ActionsWithAll = appWithStyles(styles)(actionsWithAllComponent)
