import React from 'react'

export const MoviesIcon = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3333 18.3334H2.59209V9.90265H18.3333V18.3334ZM3.99417 6.96018L5.48959 4.74729L1.83959 5.76478L2.26334 7.45018L3.98917 6.96935C3.99031 6.96776 3.99107 6.96618 3.99187 6.96452C3.99253 6.96314 3.99322 6.9617 3.99417 6.96018ZM12.8458 4.50063L16.2521 3.55147L15.8283 1.86565L14.3479 2.27815L12.8458 4.50063ZM10.3742 5.18187L11.8692 2.96939L7.96792 4.05646L6.46667 6.27894L10.3692 5.19145C10.3703 5.18989 10.371 5.18821 10.3718 5.18647C10.3725 5.18497 10.3732 5.18342 10.3742 5.18187ZM5.65376 8.23558H19.1667C19.6271 8.23558 20 8.60891 20 9.06891V19.1667C20 19.6267 19.6271 20 19.1667 20H1.75876C1.29834 20 0.925426 19.6267 0.925426 19.1667V9.06891C0.925426 9.00974 0.932093 8.95183 0.944176 8.89558C0.906259 8.82974 0.874176 8.76058 0.855426 8.68558L0.0254265 5.38312C-0.0849901 4.94479 0.174176 4.49854 0.609593 4.37688L16.2046 0.0306747C16.4208 -0.0297413 16.6529 -0.000158303 16.8471 0.112757C17.0413 0.226089 17.1817 0.412337 17.2363 0.630251L18.0667 3.93313C18.1767 4.37146 17.9175 4.8177 17.4821 4.93895L5.65376 8.23558ZM13.653 14.9511H7.27301C6.81301 14.9511 6.43967 14.5777 6.43967 14.1177C6.43967 13.6577 6.81301 13.2844 7.27301 13.2844H13.653C14.113 13.2844 14.4863 13.6577 14.4863 14.1177C14.4863 14.5777 14.113 14.9511 13.653 14.9511Z"
        fill="white"
      />
    </svg>
  )
}
