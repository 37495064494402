import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { CustomGridModel } from 'models'
import { USER_CUSTOM } from 'const/api/grids'

configure({ enforceActions: 'observed' })
export default class UserCustomGridStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      grids: new Collection(),
      collection: new Collection(),
      details: [],
    })
    this.model = CustomGridModel
  }

  /**
   * Set Collection
   *
   * @param payload
   */
  setCollection(payload) {
    this.collection = payload
  }

  getGrids() {
    CustomGridModel.list(USER_CUSTOM)
      .then(collection => {
        this.grids = collection
        this._fetchDetails()
      })
      .catch(e => {
        throw e
      })
  }

  _fetchDetails() {
    const promises = this.grids.toArray().map(g => g.getDetails())
    try {
      Promise.all(promises).then(
        action(data => {
          this.details = data
        }),
      )
    } catch (e) {
      console.error(e)
    }
  }

  getGridsDetails() {
    return this.grids
      .toArray()
      .map(grid => grid.details)
      .filter(item => Boolean(item))
  }

  isNameAlreadyExists(name) {
    return Boolean(
      this.grids.toArray().find(item => item?.details?.title === name),
    )
  }

  createGrid(name, item) {
    CustomGridModel.create(name, [item])
      .then(() => {
        this.getGrids()
      })
      .catch(e => {
        console.error(e)
      })
  }

  createGridWithManyItems(name, items) {
    CustomGridModel.create(name, items)
      .then(() => {
        this.getGrids()
      })
      .catch(e => {
        console.error(e)
      })
  }

  updateGridItems(gridDetailsModel, item) {
    this.grids.forEach(grid => {
      if (grid.details.id === gridDetailsModel.id) {
        grid.details.items = [...grid.details.items, item]
        gridDetailsModel.update(grid.details.items)
      }
    })
  }

  removeGridItems(searchGrid, removeItem) {
    this.grids.forEach(grid => {
      if (grid.details.id === searchGrid.id) {
        grid.details.items = grid.details.items.filter(
          item => item !== removeItem,
        )
        searchGrid.update(grid.details.items)
      }
    })
  }

  updateManyGridItems(gridDetailsModel, items) {
    this.grids.forEach(grid => {
      if (grid.details.id === gridDetailsModel.id) {
        grid.details.items = Array.from(new Set([...grid.details.items, ...items]));
        gridDetailsModel.update(grid.details.items)
      }
    })
  }

  removeManyGridItems(searchGrid, removeItems) {
    this.grids.forEach(grid => {
      if (grid.details.id === searchGrid.id) {
        grid.details.items = grid.details.items.filter(
          item => !removeItems.includes(item),
        )
        searchGrid.update(grid.details.items)
      }
    })
  }

  removeGrid(gridId) {
    this.grids.removeById(gridId)
  }

  flush() {
    this.grids = new Collection()
    this.collection = new Collection()
  }
}

decorate(UserCustomGridStore, {
  setCollection: action,
  getGrids: action,
  updateGridItems: action,
  getDetails: action,
  flush: action,
})
