import { configure, decorate, extendObservable, action } from 'mobx'
import { Collection } from 'services'
import { ShowModel, TitleCollectionModel } from 'models'
import { SHOW } from 'const/mediaTypes'

configure({ enforceActions: 'observed' })
export default class ShowStore {
  constructor() {
    // Initialize observable store
    extendObservable(this, {
      loading: false,
      promo: new Collection(),
      collection: new Collection(),
      latest: new Collection(),
      trending: new Collection(),
      recommended: new Collection(),
      title: new ShowModel(),
      listCollection: new Collection(),
    })
    this.model = ShowModel
  }

  /**
   * Set Collection
   *
   * @param payload
   */
  setCollection(payload) {
    this.collection = payload
  }

  // getListPromo() {
  // 	this.loading = true;
  // 	PromoModel.list(PromoCollection).then(
  // 		action(collection => {
  // 			this.promo = collection;
  // 		}),
  // 	);
  // }

  /**
   * Get show page collection lists
   */
  getCollections() {
    TitleCollectionModel.list(SHOW).then(
      action(collection => {
        this.listCollection = collection
      }),
    )
  }

  /**
   * Get recommended collection
   *
   * @param id
   * @returns {Promise<U>}
   */
  getRecommended(id) {
    this.model.recommended(id).then(
      action(collection => {
        this.recommended = collection
      }),
    )
  }

  /**
   * Get latest collection
   *
   * @param params
   * @returns {Promise<U>}
   */
  getLatest(params) {
    this.model.latest(params).then(
      action(collection => {
        this.latest = collection
      }),
    )
  }

  /**
   * Get trending collection
   *
   * @param params
   * @returns {Promise<U>}
   */
  getTrending(params) {
    this.model.trending(params).then(
      action(collection => {
        this.trending = collection
      }),
    )
  }

  loadNext() {
    return this.collection.paginator.next().then(collection => {
      this.setCollection(this.collection.merge(collection))
    })
  }
}

decorate(ShowStore, {
  setCollection: action,
})
