import { Application, Model, Url } from 'services'
import { PROMO } from 'const/api/conents'
import { processContentImages } from 'shared/utils/image'

export default class PromoModel extends Model {
  get fillable() {
    return {
      id: 'string',
      icon: 'string',
      title: 'string',
      description: 'string',
      backdropPath: 'string',
      slides: 'collection',
      slideWidth: 'number',
      slideHeight: 'number',
      type: 'string',
      mediaType: 'string',
      images: 'array',
      url: 'string',
    }
  }

  /**
   * Get type of model
   * @returns {string}
   */
  getType() {
    return this.mediaType || 'PROMO'
  }

  /**
   * Get preview image
   *
   * @returns {string}
   */

  getImage() {
    return processContentImages(this.images)?.PROMO?.url.large
  }

  // 	/**
  // 	 * Get recommended list
  // 	 *
  // 	 * @param collection
  // 	 * @returns {Promise<any>}
  // 	 */
  // 	static list(collection) {
  // 	return new Promise((resolve, reject) => {
  // 		resolve(new Collection().generateStatic(collection, PromoModel));
  // 	});
  // }

  static list(params, typeahead = '') {
    const app = Application.instance()
    const self = this
    const url = new Url()

    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery({ ...params }).queryToString() : ''
      const endpoint = `${PROMO}${typeahead}${query}`
      app.newrequest
        .get(endpoint)
        .then(response => {
          resolve(self.createFromResponse(response.data, endpoint))
        })
        .catch(error => reject(error))
    })
  }
}
