import { extendObservable, action, configure, decorate } from 'mobx'
import { Application } from '../services'
import { Auth, Hub } from 'aws-amplify'
import { USER, USER_DELETE, USER_RESTORE } from 'const/api/user'
import {
  logSignIn,
  logSignInFailure,
  logSignOut,
  logSignUp,
  logSignUpFailure,
} from './analytics.service'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import { ROUTES } from 'shared/constants/routes'

const TOKEN_KEY = 'token'
const HEADER_KEY = 'authorization'
const SIGN_IN_FROM = 'sign_In_from'
const IS_AUTH_IN_PROGRESS_KEY = 'is_auth_in_progress_key'

const UserRoles = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_MODERATOR: 'ROLE_MODERATOR',
}

configure({ enforceActions: 'observed' })
export default class AuthService {
  constructor(config = {}, amplifyConfig = {}) {
    this.config = config
    extendObservable(this, {
      _user: new this.config.model(),
      _isAuthInProgress: true,
      checkLoading: false,
    })
    this.level = 0
    this.checkLoggedIn()
    this.userPool = new CognitoUserPool({
      UserPoolId: amplifyConfig.Auth.userPoolId,
      ClientId: amplifyConfig.Auth.userPoolWebClientId,
    })

    Hub.listen('auth', ({ payload: { event, data } }) => {
      action(() => {
        event === 'signIn' && this.loggedIn(data)
        event === 'signOut' && this.handleSignOut()
      })()

      // log analytic events
      setTimeout(() => {
        const payload = data?.signInUserSession?.idToken?.payload
        const sub = data?.userSub || payload?.sub
        const email = data?.user?.username || payload?.email
        let provider =
          payload?.identities?.[0]?.providerName || 'password_email'
        action((sub, email, provider) => {
          event === 'signIn' && logSignIn(sub, email, provider)
          event === 'signUp' && logSignUp(sub, email, provider)
          event === 'signOut' && logSignOut(email, provider)
          event === 'signIn_failure' && logSignInFailure(email, provider)
          event === 'signUp_failure' && logSignUpFailure(email, provider)
        })(sub, email, provider)
      }, 200)
    })
  }

  /**
   * Set current user
   *
   * @param attributes
   */
  set user(attributes) {
    if (this.config.model) {
      this._user =
        attributes instanceof this.config.model
          ? attributes
          : Object.keys(attributes).length
          ? this.config.model.createFromResponse(attributes)
          : new this.config.model()
    }
  }

  get isAdminRole() {
    const userData = this.user.getOriginals()
    if (userData) {
      const authorities = userData?.authorities || []

      return authorities.includes(UserRoles.ROLE_ADMIN)
    }
    return false
  }

  get isModeratorRole() {
    const userData = this.user.getOriginals()
    if (userData) {
      const authorities = userData?.authorities || []

      return authorities.includes(UserRoles.ROLE_MODERATOR)
    }
    return false
  }

  /**
   * Return current user
   *
   * @returns {*|{}}
   */
  get user() {
    return this._user
  }

  /**
   * Set user token
   *
   * @param token
   */
  set token(token) {
    this._token = token
    if (token) {
      this.storage.set(TOKEN_KEY, token)
    }
    this.setAuthHeader()
  }

  /**
   * Return user token
   *
   * @returns {*|{}}
   */
  get token() {
    return this._token
  }

  /**
   * Return true if user is logged in
   *
   * @returns {boolean}
   */
  isLoggedIn() {
    return this.user instanceof this.config.model
      ? !this.user.isEmpty()
      : this.user
  }

  // having isLoggedIn as method above is a wrong approach and should not be used
  // removing isLoggedIn() is out of scope of the current task https://isportal.atlassian.net/browse/SKRT-2805
  get isLoggedInProperty() {
    return this.user instanceof this.config.model
      ? !this.user.isEmpty()
      : this.user
  }

  /**
   * Return true if user is in the middle of the auth process
   *
   * @returns {boolean}
   */
  isAuthInProgress() {
    return this._isAuthInProgress
  }

  // having isAuthInProgress as method above is a wrong approach and should not be used
  // removing isAuthInProgress() is out of scope of the current task https://isportal.atlassian.net/browse/SKRT-2805
  get isAuthInProgressProperty() { 
    return this._isAuthInProgress
  }

  /**
   * Check if auth was skip
   * @returns {*}
   */
  isSkipped() {
    return !!this.storage.get('login_skipped')
  }

  /**
   * Return true if user is guest
   *
   * @returns {boolean}
   */
  isGuest() {
    return !this.isLoggedIn()
  }

  /**
   * Set if auth is in progress
   * @param {boolean} isInProgress
   */
  setAuthInProgress(isInProgress) {
    //change state
    this._isAuthInProgress = isInProgress
    //true/false stored as string "+" will convert true/false to 1/0
    this.storage.set(IS_AUTH_IN_PROGRESS_KEY, +isInProgress)
  }

  /**
   * Set url for redirect after the login is finished
   * @param {string} url
   */
  setSignedInFrom(url) {
    this.storage.set(SIGN_IN_FROM, url)
  }

  /**
   *
   * @returns {AuthService}
   */
  setAuthHeader() {
    if (this.token) {
      Application.instance().request.addHeader(
        HEADER_KEY,
        `Bearer ${this.token}`,
      )
      Application.instance().newrequest.addHeader(
        HEADER_KEY,
        `Bearer ${this.token}`,
      )
    }

    return this
  }

  /**
   *
   * @returns {*|never}
   */
  get storage() {
    return Application.instance().storage.driver(this.config.storage)
  }

  /**
   * User logged in
   *
   * @param response
   */
  async loggedIn() {
    this.setAuthInProgress(true);

    const app = Application.instance()

    this.token = await this.getAccessToken()
    if (this.storage.has(SIGN_IN_FROM)) {
      app.router.push(this.storage.get(SIGN_IN_FROM))
      this.storage.remove(SIGN_IN_FROM)
    }

    app.newrequest
      .get(USER)
      .then(
        action(response => {
          this.user = response.data
          const isOpenCompleteUserRegistration = Boolean(
            !this.user.firstName || !this.user.lastName,
          )
          this.setHideOnboarding(isOpenCompleteUserRegistration)

          if (isOpenCompleteUserRegistration) {
            app.event.$emit('overlay.activity.completeuserregistration', {
              status: true,
            })
          }
        }),
      )
      .finally(() => this.setAuthInProgress(false))
  }

  setHideOnboarding(val) {
    this.user = {
      ...this.user.getOriginals(),
      hideOnboarding: val,
    }
  }

  /**
   * Get access token from cognito Auth service
   * @returns {Promise<CognitoUserSession>}
   */
  getAccessToken() {
    return Auth.currentSession().then(res => {
      return res ? res.getAccessToken().jwtToken : ''
    })
  }

  confirmEmail(email, code, callback) {
    new CognitoUser({
      Username: email,
      Pool: this.userPool,
    }).confirmRegistration(code, false, (err, res) => callback(err, res))
  }

  /**
   * Clear all user data from app
   */
  resetOnLogout() {
    this.token = ''
    this.user = new this.config.model()
    this.storage.remove(TOKEN_KEY)
    this.setAuthInProgress(false)

    Application.instance().store.DefaultGridStore.flush()
    Application.instance().store.UserCustomGridStore.flush()
  }

  handleSignOut = () => {
    const app = Application.instance();
    app.router.push(ROUTES.initial);
    this.resetOnLogout();
  }

  checkLoggedIn() {
    if (this.storage.has(TOKEN_KEY)) {
      return
    }

    if (!this._token) {
      this.setAuthInProgress(false)
    }
  }

  /**
   *
   *
   * @returns {Promise<any>}
   */
  check() {
    return new Promise((resolve, reject) => {
      this.checkLoading = true
      Auth.currentAuthenticatedUser()
        .then(() => {
          this.loggedIn()
          resolve()
        })
        .catch(err => {
          this.resetOnLogout()
          reject(err)
        })
        .finally(() => {
          this.checkLoading = false
        })
    })
  }

  /**
   * Activate email
   *
   * @param params
   * @returns {Promise<any>}
   */
  activateEmail(params) {}

  /**
   * User login
   *
   * @returns {Promise<any>}
   */
  signIn() {
    this.setAuthInProgress(true)
    Auth.federatedSignIn()
  }

  /**
   * SignUp with email
   *
   * @param signUpData
   * @returns {Promise<any>}
   */
  emailSignUp(signUpData) {
    return new Promise((resolve, reject) => {
      //set auth in progress true
      this.setAuthInProgress(true)
      Auth.signUp(signUpData)
        .then(data => {
          //set auth in progress in false
          this.setAuthInProgress(false)
          resolve(data)
        })
        .catch(e => {
          //set auth in progress in false
          this.setAuthInProgress(false)
          // eslint-disable-next-line no-console
          console.error(e)
          reject(e)
        })
    })
  }

  async updateUserName(firstName, lastName) {
    const app = Application.instance()
    const userData = this.user.getOriginals()

    const updatedUserData = {
      avatar: userData.avatar,
      country: userData.country,
      language: userData.language,
      firstName,
      lastName,
    }

    await app.newrequest
      .put(USER_RESTORE, updatedUserData)
      .then(response => {
        app.event.$emit('overlay.activity.completeuserregistration', {
          status: false,
        })
        this.user = response.data

        this.setHideOnboarding(false)
      })
      .catch(e => {
        console.error()
        throw new Error(e.response.data?.errorCode)
      })
  }

  /**
   * SignIn with email
   *
   * @param username
   * @param password
   * @returns {Promise<any>}
   */
  emailSignIn(username, password) {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then(data => {
          //set auth in progress in false
          resolve(data)
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          //set auth in progress in false
          reject(e)
        })
    })
  }

  /**
   * Skip authorization
   */
  skipLogin() {
    this.storage.set('login_skipped', true)
    Application.instance().event.$emit('skip.login', true)
  }

  deleteAccount() {
    const app = Application.instance()
 
    return app.newrequest.delete(USER_DELETE);
  }

  /**
   * User logout
   */
  signOut() {
    Auth.signOut()
  }

  /**
   * Send email for get activation link
   *
   * @param email
   * @returns {Promise<any>}
   */
  forgotPassword(email) {
    return Auth.forgotPassword(email)
  }

  resendSignUp(username) {
    return Auth.resendSignUp(username)
  }

  /**
   * Check reset password
   *
   * @param params
   * @returns {Promise<any>}
   */
  resetPasswordCheck(params) {
    // return Auth.forgotPasswordSubmit(username, code, new_password);
  }

  forgotPasswordSubmit(username, code, new_password) {
    return Auth.forgotPasswordSubmit(username, code, new_password)
  }
  /**
   * Recovery password
   *
   * @param credentials
   * @type {function(Promise<any>): Promise<any>}
   */
  resetPassword(credentials) {}
}

decorate(AuthService, {
  loggedIn: action,
  resetOnLogout: action,
  isAuthInProgress: action,
})
