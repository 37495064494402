import { appCreateStyles } from 'core/theme/utils/create-styles'

export function styles() {
  return appCreateStyles({
    root: {},
    item: {
      width: '100%',
      height: '100%',
    },
  })
}
