export default class Storage {
  constructor(configs) {
    this.configs = { ...this.defaults, ...configs }
    const defaultDriver = this.configs.drivers[this.configs.default]
    this.defaultDriver = new defaultDriver.Driver(defaultDriver.config)
  }

  /**
   * Returns default configurations
   *
   * @returns {Object}
   */
  get defaults() {
    return {}
  }

  /**
   * Get instance of a HTTP client by name or the default client.
   *
   * @param name
   * @returns {*}
   */
  driver(name = false) {
    if (name) {
      const config = this.configs.drivers[name]
      return new config.Driver(config.config)
    }
    return this.defaultDriver
  }
}
