import { Model } from 'services'
import { CastModel, CrewModel } from './Credits'
export default class CreditsModel extends Model {
  get fillable() {
    return {
      id: 'string',
      attribute: 'string',
      cast: 'collection',
      crew: 'collection',
    }
  }

  /**
   * Get collection by type of Person (Cast || Crew)
   *
   * @param type
   * @returns {*}
   */
  collectionOf(type) {
    const typeModel = {
      cast: CastModel,
      crew: CrewModel,
    }
    return typeModel[type].list(this[type])
  }
}
