export enum ANALYTICS_EVENT {
  HOME_PAGE = 'home_page',
  SECTION_TV_SHOWS = 'section_tv_shows',
  SECTION_MOVIES = 'section_movies',
  SECTION_LIVE = 'section_live',
  SECTION_SPORTS = 'section_sports',
  GRID_COLLECTION = 'grid_collection',
  TITLE_PAGE = 'title_page',
  SIGN_UP = 'sign_up',
  SIGN_UP_FAILURE = 'sign_up_failure',
  SIGN_IN = 'login',
  SIGN_IN_FAILURE = 'login_failure',
  SIGN_OUT = 'sign_out',
  ONBOARDING_MOVIES_TV_SHOWS = 'onboarding_movies_tv_shows',
  ONBOARDING_SPORTS = 'onboarding_sports',
  ONBOARDING_ADD_SERVICES = 'onboarding_add_services',
  ONBOARDING_FOLLOW_FRIENDS = 'onboarding_follow_friends',
  ONBOARDING_RESULT = 'onboarding_result',
  SHOW_BANNER = 'banner_shown',
  NOTIFICATIONS_OPEN = 'notifications_open',
}

export enum ANALYTICS_PARAMETER {
  ID = 'id',
  EMAIL = 'email',
  PROVIDER = 'method',
  TITLE = 'title',
  CONTENT_TYPE = 'content_type',
  GRID_ITEM_TYPE = 'grid_item_type',
  GRID_TYPE = 'grid_type',
  RESULT = 'result',
  ONBOARDING_SECTION = 'onboarding_section',
  BANNER_ID = 'banner_id',
}

export enum ANALYTICS_ONBOARDING_RESULT {
  SKIP = 'skip',
  NEXT = 'next',
}
