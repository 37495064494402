import React from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { appObserver } from 'core/state-management/utils'
import { Flex } from 'shared/components/flex'

import { styles } from './step-description.styles'

export type StepDescriptionProps = AppWithStyles<typeof styles> & {
  heading: React.ReactNode
  subheading: React.ReactNode
}

const StepDescriptionComponent: React.FC<StepDescriptionProps> = ({
  classes,
  heading,
  subheading,
}) => {
  return (
    <div className={classes.root}>
      <Flex alignItems="center" justify="center" className={classes.heading}>
        {heading}
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.subheading}
      >
        {subheading}
      </Flex>
    </div>
  )
}

export const StepDescription = appWithStyles(styles)(
  appObserver(StepDescriptionComponent),
)
