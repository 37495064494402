import React from 'react'
import { Link } from 'react-router-dom'
import logoImg from 'assets/images/logo.png'

const Logo = () => {
  return (
    <Link to="/">
      <img src={logoImg} className="logo" alt="Skroote" />
    </Link>
  )
}

export default Logo
