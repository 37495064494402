import { BaseModel } from 'core/models/base'
import { Image } from '../image/model'
import { GenreContentType } from './genre.types'

export interface GenreGetQuery {
  id: string
  images: Array<Image>
  contentType: GenreContentType
  name: string
}

export class GenreGetModel extends BaseModel<GenreGetQuery> {
  private id: string
  private images: Array<Image>
  private contentType: GenreContentType
  private name: string

  constructor(data: GenreGetQuery) {
    super(data)

    this.update(data)
  }

  get asJson(): GenreGetQuery {
    return {
      id: this.id,
      images: this.images,
      contentType: this.contentType,
      name: this.name,
    }
  }
}
