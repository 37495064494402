import { Model } from 'services'
import { GENRE } from 'const/mediaTypes'

export default class GridGenreModel extends Model {
  get fillable() {
    return {
      id: 'string',
      name: 'string',
      images: 'array',
    }
  }

  getType() {
    return GENRE
  }

  getBackDrop() {
    return (
      (this.images.filter(image => image.type === 'BACKDROP_SMALL')[0] &&
        this.images.filter(image => image.type === 'BACKDROP_SMALL')[0].url) ||
      null
    )
  }

  getLogo() {
    return (
      (this.images.filter(image => image.type === 'LOGO')[0] &&
        this.images.filter(image => image.type === 'LOGO')[0].url) ||
      null
    )
  }
}
