import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'
import { LIST_ITEM_HEIGHT } from './content-list.constants'
import { ImageOrientation } from 'shared/models/image/orientation'

const landscapePosterWidth = 80
const portraitPosterWidth = 40
const posterMargin = 12

export function styles({ spacing, palette }: AppTheme) {
  return appCreateStyles({
    root: {},
    listItem: {
      height: LIST_ITEM_HEIGHT,
      padding: spacing(0, 3),
      borderRadius: 12,

      '&:hover': {
        backgroundColor: appFade(palette.common.white, 0.05),
      },
    },
    posterWrapper: {
      width: landscapePosterWidth,
      marginRight: posterMargin,
    },
    [ImageOrientation.portrait]: {
      height: 60,
      width: portraitPosterWidth,
      cursor: 'pointer',
    },
    [ImageOrientation.landscape]: {
      height: 40,
      width: landscapePosterWidth,
      cursor: 'pointer',
    },
    [`info-${ImageOrientation.portrait}`]: {
      width: `calc(100% - ${landscapePosterWidth + posterMargin}px)`,
    },
    [`info-${ImageOrientation.landscape}`]: {
      width: `calc(100% - ${landscapePosterWidth + posterMargin}px)`,
    },
    itemTitle: {
      color: '#B2B1B2',
      fontWeight: 700,
      fontSize: 16,
      cursor: 'pointer',
    },
    itemDescription: {
      margin: spacing(1, 0),
      fontWeight: 600,
      color: appFade(palette.grey[100], 0.4),
      fontSize: 13,
    },
    itemMetadata: {
      marginRight: spacing(2),
      color: '#B5B5B5',
      fontSize: 11,
      fontWeight: 400,
    },
  })
}
