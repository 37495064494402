import { Application } from 'services'
import { ContentGetQuery } from 'shared/models/content/get-model'

type PosterViewModelProps = {
  id: ContentGetQuery['id']
}

export class PosterViewModel {
  private $app = Application.instance()
  private id: ContentGetQuery['id']

  constructor(props: PosterViewModelProps) {
    this.id = props.id
  }

  get isPosterInUserLists() {
    const { DefaultGridStore, UserCustomGridStore } = this.$app.store

    return (
      DefaultGridStore.seen.includes(this.id) ||
      DefaultGridStore.favorites.includes(this.id) ||
      DefaultGridStore.watchLater.includes(this.id) ||
      DefaultGridStore.liked.includes(this.id) ||
      UserCustomGridStore.getGridsDetails()?.some(item =>
        item?.items?.includes(this.id),
      )
    )
  }
}
