const ONBOARDING = '/groups/ONBOARDING'
const LIKE = '/groups/LIKE'
const ONBOARDING_ITEM = '/groups/ONBOARDING/grids'
const GRID_DETAILS = '/grids'
const HOMEPAGE = '/groups/HOME'
const HOMEPAGE_ITEM = '/groups/HOME/grids'
const SHOW = '/groups/SHOW'
const MOVIE = '/groups/MOVIE'
const SPORT = '/groups/SPORT'
const LIVE = '/groups/LIVE'
const USER_CUSTOM = '/groups/USER_CUSTOM'
const FOLLOWERS = '/groups/FOLLOWERS'
const FOLLOWING = '/groups/FOLLOWING'

export {
  ONBOARDING,
  ONBOARDING_ITEM,
  HOMEPAGE_ITEM,
  HOMEPAGE,
  GRID_DETAILS,
  LIKE,
  SHOW,
  MOVIE,
  SPORT,
  LIVE,
  USER_CUSTOM,
  FOLLOWERS,
  FOLLOWING,
}
