import { Url } from './'

export default class RouterService {
  constructor(configs = {}) {
    this.configs = { ...this.defaults, ...configs }
    this.driver = configs.driver
    this.history = configs.history
    this.origin = configs.origin
    this.location = this.history.location
    this.middlewareHandler()
  }

  get defaults() {
    return {}
  }

  get current() {
    const url = new Url()
    return `${this.history.location.pathname}${url.queryToString()}`
  }

  originPush(query) {
    this.origin.pushState('', '', query)
  }

  /**
   * Redirect page to *
   * @param params
   */
  push(params) {
    this.history.push(params)
  }

  goBack() {
    this.history.go(-1)
  }

  /**
   * Checks for the presence of an middleware and its result
   */
  middlewareHandler() {}
}
