import React, { useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { appObserver } from 'core/state-management/utils'
import { OnboardingStatus } from 'shared/models/user/onboading-status'
import { Ellipsis } from 'shared/components/ellipsis'

import { styles } from './stepper.styles'

export type StepperProps = AppWithStyles<typeof styles> & {
  activeStep: OnboardingStatus
  steps: Array<{ id: OnboardingStatus; label: string; isCompleted: boolean }>
}

const StepperComponent: React.FC<StepperProps> = ({
  classes,
  activeStep: _activeStep,
  steps,
}) => {
  const activeStep = useMemo(() => {
    return steps.find(({ id }) => id === _activeStep)
  }, [_activeStep, steps])

  return (
    <Grid container alignItems="center" classes={{ root: classes.root }}>
      {steps.map(({ id, label, isCompleted }) => {
        return (
          <Grid
            key={id}
            item
            xs={3}
            classes={{
              root: cx(classes.step, {
                [classes.stepActive]: id === activeStep.id || isCompleted,
              }),
            }}
          >
            <Ellipsis
              withTooltip={false}
              text={label}
              classes={{
                root: cx(classes.label, {
                  [classes.labelActive]: id === activeStep.id || isCompleted,
                }),
              }}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export const Stepper = appWithStyles(styles)(appObserver(StepperComponent))
