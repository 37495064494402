import { Application, Model, Url } from 'services'
import { LIVE } from 'const/api/live'

export default class LiveModel extends Model {
  get fillable() {
    return {
      id: 'string',
      title: 'string',
      description: 'string',
      posterPath: 'string',
      link: 'string',
      source: 'string',
      time: 'data',
      mediaType: 'string',
    }
  }

  /**
   * Get type of model
   *
   * @returns {string}
   */
  getType() {
    return this.mediaType || 'LIVE'
  }

  /**
   * Get preview Image
   *
   * @returns {string}
   */
  getImage() {
    const app = Application.instance()
    return (
      this.posterPath &&
      `${app.request.configs.baseURL}${LIVE}/image/${this.posterPath}`
    )
  }

  /**
   * Get sport link
   *
   * @returns {string}
   */
  getLink() {
    return this.link
  }

  /**
   * Get live list
   *
   * @param params
   * @returns {Promise<any>}
   */
  static list(params) {
    const app = Application.instance()
    const self = this
    const url = new Url()
    return new Promise((resolve, reject) => {
      const query = params ? url.setQuery(params).queryToString() : ''
      const endpoint = `${LIVE}${query}`
      app.request
        .get(endpoint)
        .then(response =>
          resolve(self.createFromResponse(response.data, endpoint)),
        )
        .catch(error => reject(error))
    })
  }
}
