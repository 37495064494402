import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class extends PureComponent {
  static propTypes = {
    moduleProvider: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      Component: null,
      error: false,
    }
  }

  async componentDidMount() {
    if (!this.state.Component) {
      const cmd = await this.props.moduleProvider()
      this.setState({ Component: cmd.default })
    }
  }

  componentDidCatch(error) {
    this.setState({ error })
  }

  render() {
    const { Component } = this.state

    if (this.state.error) {
      return (
        <div className="container error">
          <h4 className="white-opacity-07">
            Something went wrong... <br />
            Please, reload the page.
          </h4>
        </div>
      )
    }

    document.getElementsByTagName('body')[0].scrollTo(0, 0)
    return Component && <Component {...this.props} />
  }
}
