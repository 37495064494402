export const SCROLL_STYLES = {
  // '&::-webkit-scrollbar': {
  //   width: 4,
  //   scrollbarWidth: 'thin',
  // },
  // '&::-webkit-scrollbar-thumb': {
  //   backgroundColor: 'rgba(235, 235, 235, 0.1)',
  //   outline: '1px solid rgba(235, 235, 235, 0.1)',
  // },
}
