import { Provider, Storage } from 'services'
import { storage } from 'configs'

export default class StorageProvider extends Provider {
  boot(app) {
    app.bind('storage', () => new Storage(this.config))
  }

  get config() {
    return storage
  }
}
