import { Model } from 'services'
import { PERSON } from 'const/mediaTypes'
import { processContentImages } from 'shared/utils/image'

export default class PersonModel extends Model {
  get fillable() {
    return {
      id: 'string',
      images: 'array',
      name: 'string',
      role: 'string',
    }
  }
  getType() {
    return PERSON
  }

  getImage() {
    return processContentImages(this.images)?.PERSON?.url.large
  }
  getLink() {
    return ''
  }
}
