import { Router } from 'react-router'
import { createBrowserHistory } from 'history'

export const HISTORY_INSTANCE = createBrowserHistory()

export default {
  driver: Router,
  history: HISTORY_INSTANCE,
  origin: window.history,
}
