import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ shape, palette, spacing }: AppTheme) {
  return appCreateStyles({
    root: {
      position: 'relative',
      cursor: 'pointer',
      padding: spacing(2),
      borderRadius: shape.borderRadius,
    },
    rootSelected: {
      backgroundColor: appFade(palette.primary.dark, 0.4),
    },
    selectedIcon: {
      position: 'absolute',
      top: -8,
      right: -8,
    },
    avatar: {
      width: 64,
      height: 64,
      marginBottom: spacing(2),
    },
    name: {
      color: palette.common.white,
      fontWeight: 700,
    },
  })
}
