import { Model } from 'services'
import { PROVIDER } from 'const/mediaTypes'

export default class GridProviderModel extends Model {
  get fillable() {
    return {
      category: 'string',
      link: 'string',
      logoUrl: 'string',
      name: 'string',
      prices: 'array',
    }
  }

  getType() {
    return PROVIDER
  }
}
