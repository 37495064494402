import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

type Props = SvgIconProps & {}

export const CheckIcon: React.FC<Props> = props => {
  return (
    <SvgIcon viewBox="0 0 15 12" fill="none" {...props}>
      <path
        id="Path"
        opacity="0.8"
        d="M13.805 1.13461L14.5016 1.83846C14.8901 2.23099 14.8868 2.86415 14.4943 3.25266L7.05262 10.618C6.51697 11.1481 5.67818 11.1922 5.09211 10.7427L4.97843 10.6462L0.701425 6.6377C0.298456 6.26003 0.277948 5.6272 0.655618 5.22423L1.33281 4.50168C1.68349 4.12748 2.25419 4.08306 2.65657 4.38101L2.74626 4.45589L5.9715 7.47881L12.3907 1.12723C12.7833 0.738906 13.4164 0.742213 13.805 1.13461Z"
        fill="#ffffff"
      />
    </SvgIcon>
  )
}
