import React, { useCallback } from 'react'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Flex } from 'shared/components/flex'
import { List } from 'shared/components/list'
import { LIST_ITEM_HEIGHT } from './person-list.constants'
import { Ellipsis } from 'shared/components/ellipsis'
import { ROUTES } from 'shared/constants/routes'
import { Avatar } from 'shared/components/avatar'
import { PersonGetQuery } from 'shared/models/person/get-model'
import { processContentImages } from 'shared/utils/image'
import { Link } from 'shared/components/link'
import { DEFAULT_ITEMS_PER_PAGE } from 'shared/constants/pagination'

import { styles } from './person-list.styles'

export type PersonListProps = AppWithStyles<typeof styles> & {
  data: Array<PersonGetQuery>
  width: number
  height: number
  query: string
  total: number
  getList: (query: string, page: number) => Promise<void>
}

const PersonListComponent: React.FC<PersonListProps> = ({
  classes,
  width,
  height,
  query,
  data,
  getList: _getList,
  total,
}) => {
  const getList = useCallback(
    (page: number) => {
      return _getList(query, page)
    },
    [query, _getList],
  )

  const renderItem = useCallback(
    (data: PersonGetQuery) => {
      return (
        <Flex alignItems="center" classes={{ root: classes.listItem }}>
          <Link
            href={ROUTES.public.person(encodeURIComponent(data.id))}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Avatar
              data-id={data.id}
              loadingSize={8}
              src={processContentImages(data.images)?.PROFILE?.url.medium}
              classes={{ root: classes.avatar }}
            />
          </Link>
          <div className={classes.info}>
            <Ellipsis
              component={Link}
              withTooltip={false}
              text={data.name}
              classes={{ root: classes.name }}
              componentProps={{
                href: ROUTES.public.person(encodeURIComponent(data.id)),
                rel: 'noreferrer noopener',
                target: '_blank',
              }}
            />
          </div>
        </Flex>
      )
    },
    [classes],
  )

  return (
    <div className={classes.root}>
      <List
        idProperty="id"
        width={width}
        height={height}
        initialPage={data.length / DEFAULT_ITEMS_PER_PAGE - 1}
        itemSize={LIST_ITEM_HEIGHT}
        data={data}
        shimmerVerticalPadding={4}
        renderItem={renderItem}
        total={total}
        getData={getList}
      />
    </div>
  )
}

export const PersonList = appWithStyles(styles)(PersonListComponent)
