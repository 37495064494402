import { injectable } from 'core/di/di-utils'
import { BaseService } from 'core/services/base'
import { appObservable } from 'core/state-management/utils'
import { ILayoutService, LayoutConfig } from 'shared/types/services/layout'

const INITIAL_CONFIG = {
  onScroll: undefined,
  rootRef: null,
}
@injectable()
export class LayoutService extends BaseService implements ILayoutService {
  @appObservable private _config: LayoutConfig = INITIAL_CONFIG

  get config() {
    return this._config
  }

  setConfig: ILayoutService['setConfig'] = newConfig => {
    this._config = {
      ...this.config,
      ...newConfig,
    }
  }

  resetConfig: ILayoutService['resetConfig'] = () => {
    this._config = INITIAL_CONFIG
  }
}
