import { Collection } from '.'

const moment = require('moment')

// eslint-disable-next-line no-multi-assign
moment.prototype.toJSON = moment.prototype.toString = () =>
  this.clone().locale('en').unix()

export default class Mutator {
  constructor(value, mutator) {
    this.value = value
    this.mutator = mutator
  }

  run() {
    switch (this.mutator) {
      case 'integer':
      case 'int':
        return Number.isFinite(parseInt(this.value, 10))
          ? parseInt(this.value, 10)
          : 0
      case 'boolean':
      case 'bool':
        return !(!this.value || this.value === 'false' || this.value === '0')
      case 'real':
      case 'float':
      case 'double':
        // eslint-disable-next-line no-case-declarations
        const value = parseFloat(this.value)
        return Number.isNaN(value) ? 0 : value
      case 'string':
        // TODO: need to create all validations any type of variable to String
        return this.value === null || !this.value ? '' : String(this.value)
      case 'object':
        return this.value instanceof Object
          ? this.value
          : JSON.parse(JSON.stringify(this.value))
      case 'array':
        return this.value instanceof Array
          ? this.value
          : this.value && Array.from(this.value)
      case 'collection':
        return this.value instanceof Collection
          ? this.value
          : new Collection(this.value || [])
      case 'timestamp':
        return parseInt(this.value, 10) > 0
          ? moment.unix(this.value).format()
          : this.value
      case 'date':
        if (
          this.value === null ||
          !this.value ||
          !moment(this.value, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').isValid()
        ) {
          return ''
        }
        return moment(this.value).format('YYYY-MM-DD')
      case 'datetime':
        if (moment(this.value, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').isValid()) {
          return moment(this.value).format('YYYY-M5M-DD[T]HH:mm:ss.SSS[Z]')
        }
        return this.value
      default:
        return this.value
    }
  }
}
